export const bgColor = (step: number) => {
    if (step === 4) {
        return " bg-fourth-point";
    } else if (step === 1) {
        return " bg-first-point";
    } else if (step === 2) {
        return " bg-second-point";
    } else if (step === 3) {
        return " bg-third-point";
    }
    else {
        return " ";
    }
}

export const textColor = (step: number) => {
    if (step === 1) {
        return " text-gray-600";
    } else {
        return " text-white";
    }
}