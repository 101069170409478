import React from "react";
import AlertDangerData from "./AlertDangerData";

const AlertDialog = ({ openAlert, onOpenAlert, alarmData }: any) => {
  return (
    <div
      onClick={onOpenAlert}
      className={
        "fixed inset-0 z-10 bg-gray-700 bg-opacity-50 transition duration-300 ease-in-out" +
        (openAlert ? " " : " w-0")
      }
    >
      <section
        className={
          "overflow-scroll fixed right-0 inset-y-0 z-20 max-w-xs bg-white sm:max-w-md focus:outline-none transition transform duration-300 ease-in-out" +
          (openAlert ? "" : " translate-x-full")
        }
      >
        <div className="absolute right-14 p-2 transform translate-x-full transition duration-300 ease-in-out transform sm:duration-500">
          <button
            onClick={onOpenAlert}
            className={
              "p-2 text-gray-point rounded-md focus:outline-none focus:ring" +
              (openAlert ? "" : " hidden")
            }
          >
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        <div className="flex flex-col gap-2 my-8 mx-4 divide-y">
          <div className="flex gap-4">
            <h2 className="font-bold text-xl flex-shrink-0">알림</h2>
            <div className="text-xs">
              <strong>
                &middot; 정기입력 최근 4회 중 Grade, 재발 위험 발생시 알림
              </strong>
              <p>&middot; Grade 위험: 3, 4 단계 발생시</p>
              <p>
                &middot; 재발 위험 3가지 타입: 최소 2주 동안 3kg의 체중 감소 /
                최소 1주 동안 3등급의 2가지 증상 / 연속 2주 이상 동안 등급 점수
                6 이상
              </p>
            </div>
          </div>

          <div className="flex flex-col divide-y">
            <div className="flex flex-col gap-2">
              {alarmData.length > 0 ? (
                alarmData.map((item: any, idx: number) => (
                  <AlertDangerData key={idx} item={item} />
                ))
              ) : (
                <p className="mt-4">알림내역이 없습니다.</p>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AlertDialog;
