import React, { useState, useEffect, useContext, useCallback } from "react";
import { MainContext } from "../datas/Store";
import { useHistory } from "react-router-dom";
import GuideData from "../datas/GuideData";
import axios from "axios";
import common from "../datas/common";
import dayjs from "dayjs";
import ResultHeader from "../components/Result/ResultHeader";
import ResultCard from "../components/Analysis/ResultCard";
import ChosenOneSymptom from "../components/Analysis/ChosenOneSymptom";
import ChosenOneInput from "../components/Analysis/ChosenOneInput";
import ChosenOneNone from "../components/Analysis/ChosenOneNone";
import ChosenOneWarning from "../components/Analysis/ChosenOneWarning";
import GuideHeader from "../components/Result/GuideHeader";
import GuideDetails from "../components/Result/GuideDetails";
import BottomMenuBar from "../components/Common/BottomMenuBar";

const Analysis = () => {
  const { userId } = useContext(MainContext);
  const history = useHistory();

  const prevIcon = { backgroundImage: "url('/images/arrow-left.svg')" };
  const closeIcon = { backgroundImage: "url('/images/homebar.svg')" };
  const [historyData, setHistoryData] = useState([]);
  const [openEachDate, setOpenEachDate] = useState(false);
  const [chosenOne, setChosenOne] = useState<any>([]);
  const [chosendDate, setChosenDate] = useState("");
  const [noneSymptom, setNoneSymptom] = useState<any[]>([]);
  const [warnSymptom, setWarnSymptom] = useState<any[]>([]);
  const [warningMemo, setWarningMemo] = useState("");
  const [inputType, setInputType] = useState("");
  const [chosenGuide, setChosenGuide] = useState<any>([]);
  const [scrollToBlock, setScrollToBlock] = useState(false);

  const bringTotalData = useCallback(() => {
    axios
      .get(common.SERVER_URL + "/report/reporter/" + userId, common.headers)
      .then((res) => {
        if (res.data) {
          const data = res.data;
          // console.log(historyData[0], "data");
          setHistoryData(data.slice(0, 30)); //증상 차트 최근 입력 데이터 중 30개 추출
        } else setHistoryData([]);
      })
      .catch((err) => console.log(err));
  }, [userId]);

  useEffect(() => {
    if (!userId) {
      history.push(`/mobilelogin`);
    } else {
      window.scrollTo(0, 0);
      bringTotalData();
    }
  }, [userId, history, bringTotalData]);

  const onOpenEachOne = (
    data: any,
    date: string,
    none: any,
    warn: any,
    type: any
  ) => {
    setChosenOne(data);
    setOpenEachDate(true);
    setChosenDate(` (` + dayjs(date).format("MM.DD") + `)`);
    setNoneSymptom(none);
    setWarnSymptom(warn);
    setInputType(type);
  };

  const warnNotice = {
    none: "응급 상황에 해당하는 위험한 증상은 나타나지 않았습니다",
    call: "위험 증상 발생 시 가까운 응급실에 전화 연락 또는 방문하셔야 합니다",
  };

  const bringWarning = useCallback(() => {
    warnSymptom.length > 0
      ? setWarningMemo(warnNotice.call)
      : setWarningMemo(warnNotice.none);
  }, [warnSymptom.length, warnNotice.call, warnNotice.none]);

  const onCloseEachDate = () => {
    setOpenEachDate(false);
    window.scrollTo(0, 0);
  };

  const [openGuide, setOpenGuide] = useState(false);
  const onOpenGuide = (guide: any) => {
    setOpenGuide(true);

    let filteredGuide = GuideData.filter((row) => {
      return row.name === guide;
    });
    setChosenGuide(filteredGuide[0]);
  };

  const onCloseGuide = () => {
    setOpenGuide(false);
  };

  const onOpenStatistics = () => {
    history.push(`/stats`);
  };

  const onBlockScroll = () => {
    setScrollToBlock(true);
  };

  const onScroll = () => {
    setScrollToBlock(false);
  };

  return (
    <>
      <div
        className={
          "max-w-md h-screen flex flex-col justify-between items-center mx-auto bg-gray-100" +
          (scrollToBlock ? "fixed w-full h-full overflow-hidden" : "")
        }
      >
        <div className="w-full flex-shrink-0 flex flex-col justify-between text-gray-600">
          {!openEachDate ? (
            <>
              <ResultHeader
                headTitle={"증상 차트"}
                leftIcon={prevIcon}
                rightIcon={closeIcon}
              />
              <h2 className="mt-14 font-bold px-4 pt-2">
                &#x0002A; 최근 발생한 증상 기록 입니다.
              </h2>
              {/* 테스트 문구 */}
              {userId.indexOf("E") !== 0 ? (
                <div className="mb-20 pb-2 bg-gray-100">
                  {historyData.length > 0 ? (
                    historyData.map((row: any, idx: number) => (
                      <ResultCard
                        key={idx}
                        row={row}
                        onOpenEachOne={onOpenEachOne}
                      />
                    ))
                  ) : (
                    <div className="flex flex-col py-2 px-4">
                      <div className="p-4 bg-white rounded-xl shadow border-l-8">
                        <div className="flex justify-between items-center">
                          <div className="flex w-5/6 flex-col gap-2 font-bold text-gray-600">
                            <p className="ml-1">발생한 증상 기록이 없습니다</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="mb-20 pb-2 bg-gray-100">
                  {historyData.length > 0 ? (
                    historyData.map((row: any, idx: number) => (
                      <ResultCard
                        key={idx}
                        row={row}
                        onOpenEachOne={onOpenEachOne}
                      />
                    ))
                  ) : (
                    <div className="flex flex-col py-2 px-4">
                      <div className="p-4 bg-white rounded-xl shadow border-l-8">
                        <div className="flex justify-between items-center">
                          <div className="flex w-5/6 flex-col gap-2 font-bold text-gray-600">
                            <p className="ml-1">발생한 증상 기록이 없습니다</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          ) : !openGuide ? (
            <>
              <ResultHeader
                headTitle={
                  inputType === "R"
                    ? " 정기입력" + chosendDate
                    : " 실시간 입력" + chosendDate
                }
                leftIcon={prevIcon}
                rightIcon={closeIcon}
                onBack={onCloseEachDate}
              />
              <div>
                {chosenOne && (
                  <ChosenOneSymptom
                    chosenOne={chosenOne}
                    onOpenGuide={onOpenGuide}
                  />
                )}
              </div>
              <div>{chosenOne && <ChosenOneInput chosenOne={chosenOne} />}</div>
              <div className={inputType === "R" ? " block" : " hidden"}>
                {chosenOne && <ChosenOneNone noneSymptom={noneSymptom} />}
              </div>
              {/* Lazertinib 사용자는 숨기기 */}
              {userId.indexOf("E") !== 0 ? (
                <div>
                  {chosenOne && (
                    <ChosenOneWarning
                      warnSymptom={warnSymptom}
                      bringWarning={bringWarning}
                      warningMemo={warningMemo}
                    />
                  )}
                </div>
              ) : null}

              <div className="flex gap-4 p-4 bg-gray-100 mb-20">
                <button
                  onClick={onCloseEachDate}
                  className="w-1/2 bg-gray-point py-2 rounded-md text-white"
                >
                  뒤로 가기
                </button>
                <button
                  onClick={onOpenStatistics}
                  className="w-1/2 bg-yellow-point py-2 rounded-md text-gray-600"
                >
                  누적 통계 보기
                </button>
              </div>
            </>
          ) : (
            <>
              <GuideHeader
                headTitle={"가이드 자세히 보기"}
                rightIcon={closeIcon}
                leftIcon={prevIcon}
                onBack={onCloseGuide}
              />
              <div className="flex flex-col gap-4 bg-white mt-14">
                {chosenGuide && (
                  <GuideDetails
                    chosenGuide={chosenGuide}
                    onBlockScroll={onBlockScroll}
                    onScroll={onScroll}
                  />
                )}
              </div>
            </>
          )}
          <BottomMenuBar onBlockScroll={onBlockScroll} onScroll={onScroll} />
        </div>
      </div>
    </>
  );
};

export default Analysis;
