/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState, useContext, useEffect, useRef } from "react";
import { MainContext } from "../datas/Store";

export const InnerFormView = ({
  i,
  formViewData,
  onChangeAnswer,
  temp,
  answered,
  checked,
  updatePoint,
  allNones,
  selectIn,
  onNextMove,
}: //   saveCheck,
//   onClickPrev,
//   allSelect,
//   onNextMove,
any) => {
  const { userId, noneSelect, onNoneSelect } = useContext(MainContext);
  //   const history = createBrowserHistory;
  const [selected, setSelected] = useState(0);
  const selectIns = useRef(0);

  // selectIns을 사용해 값을 유지하는 selectIn 을 보냄
  useEffect(() => {
    setSelected(0);
    selectIns.current = 0;
  }, [i]);
  // 복수질문에서도  자동 넘기기 적용
  useEffect(() => {
    onNoneSelect(noneSelect);
    inNextMove();
  }, [noneSelect]);

  const ltselect = (e: any) => {
    setSelected(Number(e.target.value) + 1);
    selectIns.current = Number(e.target.value) + 1;
    onNoneSelect(selectIns.current);
    selectIn = selectIns.current;
  };
  // * 수정하기에서 클릭시 값을 바로 적용시키기 위함
  useEffect(() => {
    setSelected(selected);
  }, [selected]);

  const inNextMove = () => {
    // console.log(saveAnswer.current, "saveAnswer");
    if (
      userId.indexOf("E") === 0 &&
      formViewData &&
      formViewData[i].list[0] === undefined &&
      checked === 1
    ) {
      setTimeout(() => {
        // console.log("실행 됨");
        onNextMove();
      }, 500);
    } else if (
      userId.indexOf("E") === 0 &&
      formViewData[i].list.length === checked &&
      checked !== 0
    ) {
      setTimeout(() => {
        onNextMove();
      }, 500);
    } else if (noneSelect === 1 && selected === 1) {
      setTimeout(() => {
        onNextMove();
      }, 500);
    } else {
    }
  };

  return (
    <form>
      {userId.indexOf("E") !== 0 ? (
        <div
          key={formViewData[i].id}
          className="text-center font-bold mx-4 text-gray-600"
        >
          {!temp && formViewData[i].answer[0] && (
            <label
              className={
                "block mt-2.5 p-3 rounded-md border-gray-300 border hover:text-gray-800 hover:bg-lightyellow-point hover:border-yellow-point " +
                (answered === 0
                  ? "bg-lightyellow-point text-gray-800 border-yellow-point"
                  : "")
              }
            >
              <input
                type="radio"
                className="form-radio appearance-none"
                name={formViewData[i].name}
                value={0}
                onChange={onChangeAnswer}
              />
              {formViewData[i].answer[0]}
            </label>
          )}
          {formViewData[i].answer[1] && (
            <label
              className={
                "block mt-2.5 p-3 rounded-md border-gray-300 border hover:text-gray-800 hover:bg-lightyellow-point hover:border-yellow-point " +
                (answered === 1
                  ? "bg-lightyellow-point text-gray-800 border-yellow-point"
                  : "")
              }
            >
              <input
                type="radio"
                className="form-radio appearance-none"
                name={formViewData[i].name}
                value={1}
                onChange={onChangeAnswer}
              />
              {formViewData[i].answer[1]}
            </label>
          )}
          {formViewData[i].answer[2] && (
            <label
              className={
                "block mt-2.5 p-3 rounded-md border-gray-300 border hover:text-gray-800 hover:bg-lightyellow-point hover:border-yellow-point " +
                (answered === 2
                  ? "bg-lightyellow-point text-gray-800 border-yellow-point"
                  : "")
              }
            >
              <input
                type="radio"
                className="form-radio appearance-none"
                name={formViewData[i].name}
                value={2}
                onChange={onChangeAnswer}
              />
              {formViewData[i].answer[2]}
            </label>
          )}
          {formViewData[i].answer[3] && (
            <label
              className={
                "block mt-2.5   p-3 rounded-md border-gray-300 border hover:text-gray-800 hover:bg-lightyellow-point hover:border-yellow-point " +
                (answered === 3
                  ? "bg-lightyellow-point text-gray-800 border-yellow-point"
                  : "")
              }
            >
              <input
                type="radio"
                className="form-radio appearance-none"
                name={formViewData[i].name}
                value={3}
                onChange={onChangeAnswer}
              />
              {formViewData[i].answer[3]}
            </label>
          )}
          {formViewData[i].answer[4] && (
            <label
              className={
                "block mt-2.5   p-3 rounded-md border-gray-300 border hover:text-gray-800 hover:bg-lightyellow-point hover:border-yellow-point " +
                (answered === 4
                  ? "bg-lightyellow-point text-gray-800 border-yellow-point"
                  : "")
              }
            >
              <input
                type="radio"
                className="form-radio appearance-none"
                name={formViewData[i].name}
                value={4}
                onChange={onChangeAnswer}
              />
              {formViewData[i].answer[4]}
            </label>
          )}
        </div>
      ) : //*  Lazertinib 단일 질문
      userId.indexOf("E") === 0 && formViewData[i].list[0] === undefined ? (
        <div
          key={formViewData[i].id}
          className="text-center items-center rounded-full flex flex-row justify-center font-bold text-gray-600 "
        >
          {!temp && formViewData[i].answer[0] && (
            <label className={"block mt-2.5  mx-auto"}>
              <input
                type="radio"
                className="form-radio appearance-none"
                name={formViewData[i].name}
                value={0}
                onChange={onChangeAnswer}
                onClick={(e) => {
                  ltselect(e);
                  inNextMove();
                }}
              />
              <img
                src={
                  selected === 1 || answered === 0
                    ? "/images/radio-select-none.svg"
                    : "/images/radio-none.svg"
                }
                className="items-center justify-center w-14 mb-1 mx-auto"
                alt="none"
                onClick={checked >= 0 && checked < 1 ? updatePoint : null}
              ></img>
              {formViewData[i].answer[0]}
            </label>
          )}
          {formViewData[i].answer[1] && (
            <label className={"block mt-2.5 p-0 mx-auto"}>
              <input
                type="radio"
                className="form-radio appearance-none"
                name={formViewData[i].name}
                value={1}
                onChange={onChangeAnswer}
                onClick={(e) => {
                  ltselect(e);
                  inNextMove();
                }}
              />
              <img
                src={
                  selected === 2 || answered === 1
                    ? "/images/radio-select-little.svg"
                    : "/images/radio-little.svg"
                }
                className="items-center w-12 mb-3 mx-auto"
                alt="little"
                onClick={checked >= 0 && checked < 1 ? updatePoint : null}
              ></img>
              {formViewData[i].answer[1]}
            </label>
          )}
          {formViewData[i].answer[2] && (
            <label className={"block mt-2.5 p-0 mx-auto"}>
              <input
                type="radio"
                className="form-radio appearance-none"
                name={formViewData[i].name}
                value={2}
                onChange={onChangeAnswer}
                onClick={(e) => {
                  ltselect(e);
                  inNextMove();
                }}
              />
              <img
                src={
                  selected === 3 || answered === 2
                    ? "/images/radio-select-normal.svg"
                    : "/images/radio-normal.svg"
                }
                className="items-center w-10 mb-5 mx-auto"
                alt="normal"
                onClick={checked >= 0 && checked < 1 ? updatePoint : null}
              ></img>
              {formViewData[i].answer[2]}
            </label>
          )}
          {formViewData[i].answer[3] && (
            <label className={"block mt-2.5 p-0 mx-auto"}>
              <input
                type="radio"
                className="form-radio appearance-none"
                name={formViewData[i].name}
                value={3}
                onChange={onChangeAnswer}
                onClick={(e) => {
                  ltselect(e);
                  inNextMove();
                }}
              />
              <img
                src={
                  selected === 4 || answered === 3
                    ? "/images/radio-select-often.svg"
                    : "/images/radio-little.svg"
                }
                className="items-center w-12 mb-3 mx-auto"
                alt="often"
                onClick={checked >= 0 && checked < 1 ? updatePoint : null}
              ></img>
              {formViewData[i].answer[3]}
            </label>
          )}
          {formViewData[i].answer[4] && (
            <label className={"block mt-2.5 p-0 mx-auto"}>
              <input
                type="radio"
                className="form-radio appearance-none"
                name={formViewData[i].name}
                value={4}
                onChange={onChangeAnswer}
                onClick={(e) => {
                  ltselect(e);
                  inNextMove();
                }}
              />
              <img
                src={
                  selected === 5 || answered === 4
                    ? "/images/radio-select-severe.svg"
                    : "/images/radio-none.svg"
                }
                className="items-center w-14 mb-1 mx-auto"
                alt="severe"
                onClick={checked >= 0 && checked < 1 ? updatePoint : null}
              ></img>
              {formViewData[i].answer[4]}
            </label>
          )}
        </div>
      ) : (
        //*  Lazertinib 복수 질문
        //  formViewData[i].list[0]

        <div
          key={formViewData[i].id}
          className="text-center rounded-full flex flex-row justify-center font-bold mx-4 text-gray-600"
        >
          {!temp && formViewData[i].list[0].answer[0] && (
            <label className={"block mt-2.5 p-0 mx-auto"}>
              <input
                type="radio"
                className="form-radio appearance-none"
                name={formViewData[i].list[0].name}
                value={0}
                onChange={onChangeAnswer}
                onClick={(e) => {
                  ltselect(e);
                  allNones(selectIns.current);
                  updatePoint(noneSelect);
                  inNextMove();
                }}
              />
              <img
                src={
                  selected === 1 || answered === 0
                    ? "/images/radio-select-none.svg"
                    : "/images/radio-none.svg"
                }
                className="items-center justify-center w-16 mb-1 mx-auto"
                alt="none"
                onClick={checked >= 0 && checked <= 1 ? updatePoint : null}
              ></img>

              {formViewData[i].list[0].answer[0]}
            </label>
          )}
          {formViewData[i].list[0].answer[1] && (
            <label className={"block mt-2.5 p-0 mx-auto"}>
              <input
                type="radio"
                className="form-radio appearance-none"
                name={formViewData[i].list[0].name}
                value={1}
                onChange={onChangeAnswer}
                onClick={(e) => {
                  ltselect(e);
                  allNones(selectIn);
                }}
              />
              <img
                src={
                  selected === 2 || answered === 1
                    ? "/images/radio-select-little.svg"
                    : "/images/radio-little.svg"
                }
                className="items-center justify-center w-14 mb-3 mx-auto"
                alt="little"
                onClick={checked >= 0 && checked < 1 ? updatePoint : null}
              ></img>
              {formViewData[i].list[0].answer[1]}
            </label>
          )}
          {formViewData[i].list[0].answer[2] && (
            <label className={"block mt-2.5 p-0 mx-auto"}>
              <input
                type="radio"
                className="form-radio appearance-none"
                name={formViewData[i].list[0].name}
                value={2}
                onChange={onChangeAnswer}
                onClick={(e) => {
                  ltselect(e);
                  allNones(selectIn);
                }}
              />
              <img
                src={
                  selected === 3 || answered === 2
                    ? "/images/radio-select-normal.svg"
                    : "/images/radio-normal.svg"
                }
                className="items-center justify-center w-12 mb-5 mx-auto"
                alt="normal"
                onClick={checked >= 0 && checked < 1 ? updatePoint : null}
              ></img>
              {formViewData[i].list[0].answer[2]}
            </label>
          )}
          {formViewData[i].list[0].answer[3] && (
            <label className={"block mt-2.5 p-0 mx-auto"}>
              <input
                type="radio"
                className="form-radio appearance-none"
                name={formViewData[i].list[0].name}
                value={3}
                onChange={onChangeAnswer}
                onClick={(e) => {
                  ltselect(e);
                  allNones(selectIn);
                }}
              />
              <img
                src={
                  selected === 4 || answered === 3
                    ? "/images/radio-select-often.svg"
                    : "/images/radio-little.svg"
                }
                className="items-center justify-center w-14 mb-3 mx-auto"
                alt="often"
                onClick={checked >= 0 && checked < 1 ? updatePoint : null}
              ></img>
              {formViewData[i].list[0].answer[3]}
            </label>
          )}
          {formViewData[i].list[0].answer[4] && (
            <label className={"block mt-2.5 p-0 mx-auto"}>
              <input
                type="radio"
                className="form-radio appearance-none"
                name={formViewData[i].list[0].name}
                value={4}
                onChange={onChangeAnswer}
                onClick={(e) => {
                  ltselect(e);
                  allNones(selectIn);
                }}
              />
              <img
                src={
                  selected === 5 || answered === 4
                    ? "/images/radio-select-severe.svg"
                    : "/images/radio-none.svg"
                }
                className="items-center justify-center w-16 mb-1 mx-auto"
                alt="severe"
                onClick={checked >= 0 && checked < 1 ? updatePoint : null}
              ></img>
              {formViewData[i].list[0].answer[4]}
            </label>
          )}
        </div>
      )}
    </form>
  );
};

export const InnerFormView2 = ({
  i,
  formViewData,
  onChangeAnswer,
  temp,
  checked,
  updatePoint,
  allNone,
  allNones,
  selectIn,
  subI,
  onNextMove,
  answered,
  allSelect,
}: any) => {
  //   const test = formViewData[i].list.map(
  //     (formViewDatas: any) => formViewDatas.subid
  //   );
  const [selected, setSelected] = useState(0);
  const { userId, noneSelect } = useContext(MainContext);

  useEffect(() => {
    setSelected(0);
  }, [i]);

  const inputFocus = useRef<any>();

  useEffect(() => {
    if (noneSelect === 1 && checked) {
      inputFocus.current.focus();
    } else {
      return;
    }
  }, [noneSelect]);

  const ltselect = (e: any) => {
    setSelected(Number(e.target.value) + 1);
    selectIn = Number(e.target.value) + 1;
  };
  // * 수정하기에서 클릭시 값을 바로 적용시키기 위함
  useEffect(() => {
    setSelected(selected);
  }, [selected]);

  const inNextMove = () => {
    if (
      userId.indexOf("E") === 0 &&
      formViewData &&
      formViewData[i].list[0] === undefined
    ) {
      setTimeout(() => {
        // console.log("실행 됨");
        setSelected(noneSelect);
        onNextMove();
      }, 500);
    } else if (formViewData[i].list.length === checked) {
      setTimeout(() => {
        setSelected(noneSelect);
        onNextMove();
      }, 500);
    } else {
    }
  };

  return (
    <form>
      <div
        key={formViewData[i].id}
        className="text-center rounded-full flex flex-row justify-center font-bold mx-4 text-gray-600"
      >
        {!temp && formViewData[i].list[1].answer[0] && (
          <label className={"block mt-2.5 p-0 mx-auto"}>
            <input
              type="radio"
              className="form-radio appearance-none"
              name={formViewData[i].list[1].name}
              value={0}
              //   disabled={
              //     (checked >= 1 && checked <= 3) || inputFocus ? false : true
              //   }
              ref={inputFocus}
              onChange={onChangeAnswer}
              onFocus={onChangeAnswer}
              onClick={(e) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                checked >= 1 && checked <= 3
                  ? ltselect(e)
                  : subI === 2
                  ? ltselect(e)
                  : null;
                inNextMove();
              }}
              //   checked={allNone !== true ? true : false}
              //   checked={allNone === true}
              //   autoFocus={ (e:any) => globalInputChangeTrigger(e, 0)}
              //   onFocus={(e) => onChangeAnswer(e)}
              //   onInput={(e) => onChangeAnswer(e)}
              //   onBlur={(e) => onChangeAnswer(e)}
              //   onLoad={(e) => (allNone === true ? test(e) : test(e))}
            />
            <img
              src={
                selected === 1 || answered === 0
                  ? "/images/radio-select-none.svg"
                  : allNone === true
                  ? "/images/radio-select-none.svg"
                  : "/images/radio-none.svg"
              }
              className="items-center justify-center w-16 mb-1 mx-auto "
              alt="none"
              onClick={checked >= 1 && checked < 2 ? updatePoint : null}
            ></img>
            {formViewData[i].list[1].answer[0]}
          </label>
        )}
        {formViewData[i].list[1].answer[1] && (
          <label className={"block mt-2.5 p-0 mx-auto"}>
            <input
              type="radio"
              className="form-radio appearance-none"
              name={formViewData[i].list[1].name}
              value={1}
              //   disabled={checked >= 1 && checked <= 3 ? false : true}
              onChange={onChangeAnswer}
              onClick={(e) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                checked >= 1 && checked <= 3
                  ? ltselect(e)
                  : subI === 2
                  ? ltselect(e)
                  : null;
                allNones(selectIn);
                inNextMove();
              }}
            />
            {/* //todo 전혀없음 누르면 전혀없음 전체 선택 되는 부분 2번쨰 답안 부분임 allNone이 폼 별로 적용 되는건지 두번쨰 지문 먼저 선택해놓고 첫번째 답안 전혀 없음 선택하면 두번째 지문에 두개가 변해있는 현상 수정해야함 */}
            <img
              //   src={
              //     allNone === true
              //       ? "/images/radio-little.svg"
              //       : selected === 2
              //       ? "/images/radio-select-little.svg"
              //       : "/images/radio-little.svg"
              //   }
              src={
                allNone === true
                  ? "/images/radio-little.svg"
                  : selected === 2 || answered === 1
                  ? "/images/radio-select-little.svg"
                  : "/images/radio-little.svg"
              }
              className="items-center justify-center w-14 mb-3 mx-auto"
              alt="little"
              onClick={checked >= 1 && checked < 2 ? updatePoint : null}
            ></img>
            {formViewData[i].list[1].answer[1]}
          </label>
        )}
        {formViewData[i].list[1].answer[2] && (
          <label className={"block mt-2.5 p-0 mx-auto"}>
            <input
              type="radio"
              className="form-radio appearance-none"
              name={formViewData[i].list[1].name}
              //   disabled={checked >= 1 && checked <= 3 ? false : true}
              value={2}
              onChange={onChangeAnswer}
              onClick={(e) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                checked >= 1 && checked <= 3
                  ? ltselect(e)
                  : subI === 2
                  ? ltselect(e)
                  : null;
                allNones(selectIn);
                inNextMove();
              }}
            />
            <img
              //   src={
              //     selected === 3
              //       ? "/images/radio-select-normal.svg"
              //       : allNone === false
              //       ? "/images/radio-normal.svg"
              //       : "/images/radio-normal.svg"
              //   }
              //   src={
              //     allNone === true
              //       ? "/images/radio-normal.svg"
              //       : selected === 3
              //       ? "/images/radio-select-normal.svg"
              //       : "/images/radio-normal.svg"
              //   }
              src={
                allNone === true
                  ? "/images/radio-normal.svg"
                  : selected === 3 || answered === 2
                  ? "/images/radio-select-normal.svg"
                  : "/images/radio-normal.svg"
              }
              className="items-center justify-center w-12 mb-5 mx-auto"
              alt="normal"
              onClick={checked >= 1 && checked < 2 ? updatePoint : null}
            ></img>
            {formViewData[i].list[1].answer[2]}
          </label>
        )}
        {formViewData[i].list[1].answer[3] && (
          <label className={"block mt-2.5 p-0 mx-auto"}>
            <input
              type="radio"
              className="form-radio appearance-none"
              name={formViewData[i].list[1].name}
              //   disabled={checked >= 1 && checked <= 3 ? false : true}
              value={3}
              onChange={onChangeAnswer}
              onClick={(e) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                checked >= 1 && checked <= 3
                  ? ltselect(e)
                  : subI === 2
                  ? ltselect(e)
                  : null;
                allNones(selectIn);
                inNextMove();
              }}
            />
            <img
              //   src={
              //     selected === 4
              //       ? "/images/radio-select-often.svg"
              //       : "/images/radio-little.svg"
              //   }
              //   src={
              //     allNone === true
              //       ? "/images/radio-little.svg"
              //       : selected === 4
              //       ? "/images/radio-select-often.svg"
              //       : "/images/radio-little.svg"
              //   }
              src={
                allNone === true
                  ? "/images/radio-little.svg"
                  : selected === 4 || answered === 3
                  ? "/images/radio-select-often.svg"
                  : "/images/radio-little.svg"
              }
              className="items-center justify-center w-14 mb-3 mx-auto"
              alt="often"
              onClick={checked >= 1 && checked < 2 ? updatePoint : null}
            ></img>
            {formViewData[i].list[1].answer[3]}
          </label>
        )}
        {formViewData[i].list[1].answer[4] && (
          <label className={"block mt-2.5 p-0 mx-auto"}>
            <input
              type="radio"
              className="form-radio appearance-none"
              name={formViewData[i].list[1].name}
              //   disabled={checked >= 1 && checked <= 3 ? false : true}
              value={4}
              onChange={onChangeAnswer}
              onClick={(e) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                checked >= 1 && checked <= 3
                  ? ltselect(e)
                  : subI === 2
                  ? ltselect(e)
                  : null;
                allNones(selectIn);
                inNextMove();
              }}
            />
            <img
              //   src={
              //     allNone === true
              //       ? "/images/radio-none.svg"
              //       : selected === 5
              //       ? "/images/radio-select-severe.svg"
              //       : "/images/radio-none.svg"
              //   }
              src={
                allNone === true
                  ? "/images/radio-none.svg"
                  : selected === 5 || answered === 4
                  ? "/images/radio-select-severe.svg"
                  : "/images/radio-none.svg"
              }
              className="items-center justify-center w-16 mb-1 mx-auto"
              alt="severe"
              onClick={checked >= 1 && checked < 2 ? updatePoint : null}
            ></img>
            {formViewData[i].list[1].answer[4]}
          </label>
        )}
      </div>
    </form>
  );
};

export const InnerFormView3 = ({
  i,
  formViewData,
  onChangeAnswer,
  temp,
  checked,
  updatePoint,
  allNone,
  allNones,
  selectIn,
  subI,
  onNextMove,
  answered,
}: any) => {
  const { userId, noneSelect } = useContext(MainContext);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    setSelected(0);
  }, [i]);

  const inputFocus = useRef<any>();
  useEffect(() => {
    if (noneSelect === 1 && checked) {
      inputFocus.current.focus();
    } else {
      return;
    }
  }, [noneSelect]);

  const ltselect = (e: any) => {
    setSelected(Number(e.target.value) + 1);
    selectIn = Number(e.target.value) + 1;
  };
  // * 수정하기에서 클릭시 값을 바로 적용시키기 위함
  useEffect(() => {
    setSelected(selected);
  }, [selected]);

  const inNextMove = () => {
    if (
      userId.indexOf("E") === 0 &&
      formViewData &&
      formViewData[i].list[0] === undefined
    ) {
      setTimeout(() => {
        onNextMove();
      }, 500);
    } else if (formViewData[i].list.length === checked) {
      setTimeout(() => {
        onNextMove();
      }, 500);
    } else {
    }
  };

  return (
    <form>
      <div
        key={formViewData[i].id}
        className="text-center rounded-full flex flex-row justify-center font-bold mx-4 text-gray-600"
      >
        {!temp && formViewData[i].list[2].answer[0] && (
          <label className={"block mt-2.5 p-0 mx-auto"}>
            <input
              type="radio"
              className="form-radio appearance-none "
              name={formViewData[i].list[2].name}
              //   disabled={
              //     (checked >= 2 && checked <= 3) || inputFocus ? false : true
              //   }
              value={0}
              ref={inputFocus}
              onChange={onChangeAnswer}
              onFocus={onChangeAnswer}
              onClick={(e) => {
                checked >= 2 && checked <= 3
                  ? ltselect(e)
                  : subI === 3
                  ? ltselect(e)
                  : null;
                inNextMove();
              }}
            />
            <img
              src={
                selected === 1 || answered === 0
                  ? "/images/radio-select-none.svg"
                  : allNone === true
                  ? "/images/radio-select-none.svg"
                  : "/images/radio-none.svg"
              }
              className="items-center justify-center w-16 mb-1 mx-auto"
              alt="none"
              onClick={checked >= 2 && checked < 3 ? updatePoint : null}
            ></img>

            {formViewData[i].list[2].answer[0]}
          </label>
        )}
        {formViewData[i].list[2].answer[1] && (
          <label className={"block mt-2.5 p-0 mx-auto"}>
            <input
              type="radio"
              className="form-radio appearance-none"
              name={formViewData[i].list[2].name}
              //   disabled={checked >= 2 && checked <= 3 ? false : true}
              value={1}
              onChange={onChangeAnswer}
              onClick={(e) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                checked >= 2 && checked <= 3
                  ? ltselect(e)
                  : subI === 3
                  ? ltselect(e)
                  : null;
                allNones(selectIn);
                inNextMove();
              }}
            />
            <img
              //   src={
              //     selected === 2
              //       ? "/images/radio-select-little.svg"
              //       : "/images/radio-little.svg"
              //   }
              src={
                allNone === true
                  ? "/images/radio-little.svg"
                  : selected === 2 || answered === 1
                  ? "/images/radio-select-little.svg"
                  : "/images/radio-little.svg"
              }
              className="items-center justify-center w-14 mb-3 mx-auto"
              alt="little"
              onClick={checked >= 2 && checked < 3 ? updatePoint : null}
            ></img>
            {formViewData[i].list[2].answer[1]}
          </label>
        )}
        {formViewData[i].list[2].answer[2] && (
          <label className={"block mt-2.5 p-0 mx-auto"}>
            <input
              type="radio"
              className="form-radio appearance-none"
              //   disabled={checked >= 2 && checked <= 3 ? false : true}
              name={formViewData[i].list[2].name}
              value={2}
              onChange={onChangeAnswer}
              onClick={(e) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                checked >= 2 && checked <= 3
                  ? ltselect(e)
                  : subI === 3
                  ? ltselect(e)
                  : null;
                allNones(selectIn);
                inNextMove();
              }}
            />
            <img
              //   src={
              //     selected === 3
              //       ? "/images/radio-select-normal.svg"
              //       : "/images/radio-normal.svg"
              //   }
              src={
                allNone === true
                  ? "/images/radio-normal.svg"
                  : selected === 3 || answered === 2
                  ? "/images/radio-select-normal.svg"
                  : "/images/radio-normal.svg"
              }
              className="items-center justify-center w-12 mb-5 mx-auto"
              alt="normal"
              onClick={checked >= 2 && checked < 3 ? updatePoint : null}
            ></img>
            {formViewData[i].list[2].answer[2]}
          </label>
        )}
        {formViewData[i].list[2].answer[3] && (
          <label className={"block mt-2.5 p-0 mx-auto"}>
            <input
              type="radio"
              className="form-radio appearance-none"
              //   disabled={checked >= 2 && checked <= 3 ? false : true}
              name={formViewData[i].list[2].name}
              value={3}
              onChange={onChangeAnswer}
              onClick={(e) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                checked >= 2 && checked <= 3
                  ? ltselect(e)
                  : subI === 3
                  ? ltselect(e)
                  : null;
                allNones(selectIn);
                inNextMove();
              }}
            />
            <img
              //   src={
              //     selected === 4
              //       ? "/images/radio-select-often.svg"
              //       : "/images/radio-little.svg"
              //   }
              src={
                allNone === true
                  ? "/images/radio-little.svg"
                  : selected === 4 || answered === 3
                  ? "/images/radio-select-often.svg"
                  : "/images/radio-little.svg"
              }
              className="items-center justify-center w-14 mb-3 mx-auto"
              alt="often"
              onClick={checked >= 2 && checked < 3 ? updatePoint : null}
            ></img>
            {formViewData[i].list[2].answer[3]}
          </label>
        )}
        {formViewData[i].list[2].answer[4] && (
          <label className={"block mt-2.5 p-0 mx-auto"}>
            <input
              type="radio"
              className="form-radio appearance-none"
              //   disabled={checked >= 2 && checked <= 3 ? false : true}
              name={formViewData[i].list[2].name}
              value={4}
              onChange={onChangeAnswer}
              onClick={(e) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                checked >= 2 && checked <= 3
                  ? ltselect(e)
                  : subI === 3
                  ? ltselect(e)
                  : null;
                allNones(selectIn);
                inNextMove();
              }}
            />
            <img
              //   src={
              //     selected === 5
              //       ? "/images/radio-select-severe.svg"
              //       : "/images/radio-none.svg"
              //   }
              src={
                allNone === true
                  ? "/images/radio-none.svg"
                  : selected === 5 || answered === 4
                  ? "/images/radio-select-severe.svg"
                  : "/images/radio-none.svg"
              }
              className="items-center justify-center w-16 mb-1 mx-auto"
              alt="severe"
              onClick={checked >= 2 && checked < 3 ? updatePoint : null}
            ></img>
            {formViewData[i].list[2].answer[4]}
          </label>
        )}
      </div>
    </form>
  );
};
