import React, { useState, useContext, useEffect } from "react";
import { MainContext } from "../../mobile/datas/Store";
import axios from "axios";
import common from "../../mobile/datas/common";
import { useHistory } from "react-router-dom";
import Sidebar from "../components/Common/Sidebar";
import Navbar from "../components/Common/Navbar";
import SubNavbar from "../components/Common/SubNavbar";
import ContentHeader from "../components/Common/ContentHeader";
import MainReport from "../components/Patient/MainReport";
import MainReportForPrint from "../components/Patient/MainReportForPrint";
import AlertDialog from "../components/Common/AlertDialog";
import AdminInfoDialog from "../components/Authority/AdminInfoDialog";
import { ToastContainer } from "react-toastify";

const PatientReport = () => {
  const { adminId } = useContext(MainContext);
  const history = useHistory();
  const arrowDownIcon = {
    backgroundImage: "url('/images/arrow-yellow-down.svg')",
  };
  const alertNoneIcon = { backgroundImage: "url('/images/alert-none.svg')" };
  const alertTakenIcon = { backgroundImage: "url('/images/alert-notice.svg')" };
  const lockIcon = { backgroundImage: "url('/images/lock.svg')" };
  const unlockIcon = { backgroundImage: "url('/images/unlock.svg')" };
  const [openPrint, setOpenPrint] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alarmData, setAlarmData] = useState([]);
  const [openAdminInfoAlert, setOpenAdminInfoAlert] = useState(false);

  const onOpenPrint = () => {
    setOpenPrint(true); //인쇄용 페이지
    setTimeout(() => onPrint(), 1000);
  };

  const onOpenAlert = () => {
    setOpenAlert(!openAlert);
  };

  const onOpenAdminInfoAlert = () => {
    setOpenAdminInfoAlert(!openAdminInfoAlert);
  };

  const bringAlarmData = () => {
    axios
      .get(common.SERVER_URL + "/report/alarm", common.headers)
      .then((res) => {
        if (res.data) {
          setAlarmData(res.data.alarmList);
        }
      })
      .catch((err) => console.log(err));
  };

  const onPrint = () => {
    //프린트 설정
    const html = document.querySelector<any>("html");
    const printContents = document.querySelector<any>(".print-page").innerHTML;
    const printDiv = document.createElement("DIV");
    printDiv.className = "print-div";
    html.appendChild(printDiv);
    printDiv.innerHTML = printContents;
    document.body.style.display = "none";
    window.print();
    setOpenPrint(false);
    document.body.style.display = "block";
    printDiv.style.display = "none";
  };

  useEffect(() => {
    if (!adminId) {
      history.push(`/adminlogin`);
    } else {
      bringAlarmData();
    }
  }, [adminId, history]);

  return (
    <>
      {!openPrint ? (
        <>
          <div className="flex h-screen antialiased text-gray-600 bg-gray-100">
            <Sidebar />

            <div className="flex-1 h-full overflow-x-hidden overflow-y-auto">
              {/* navbar */}
              <header className="relative bg-gray-point h-12 flex flex-col md:h-28">
                <Navbar
                  alertIcon={
                    alarmData.length > 0 ? alertTakenIcon : alertNoneIcon
                  }
                  arrowDownIcon={arrowDownIcon}
                  lockIcon={lockIcon}
                  unlockIcon={unlockIcon}
                  onOpenAlert={onOpenAlert}
                  onOpenAdminInfoAlert={onOpenAdminInfoAlert}
                />
                <SubNavbar />
              </header>

              {/* main content */}
              <main>
                {/* content header */}
                <ContentHeader
                  onClickBtn={onOpenPrint}
                  headerTitle={"환자 증상 결과 요약"}
                  btnRight={"인쇄"}
                />
                {/* content */}
                <MainReport />
              </main>
            </div>
          </div>
          <ToastContainer />

          <AlertDialog
            openAlert={openAlert}
            onOpenAlert={onOpenAlert}
            alarmData={alarmData}
          />

          {openAdminInfoAlert && (
            <AdminInfoDialog
              onOpenAdminInfoAlert={onOpenAdminInfoAlert}
              adminId={adminId}
            />
          )}
        </>
      ) : (
        <MainReportForPrint />
      )}
    </>
  );
};

export default PatientReport;
