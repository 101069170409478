import React, { useContext } from "react";
import formData from "../../datas/FormData";
import formDataLazertinib from "../../datas/FormDataLazertinib";
import { MainContext } from "../../datas/Store";

import TempIcon from "./TempIcon";
import RegularHeader from "../RegularType/RegularHeader";
import TempContent from "./TempContent";
import TempAlert from "./TempAlert";

const TempChoice = ({
  checkedItems,
  openTempInner,
  openTempAlert,
  checkHandler,
  onOpenTempInner,
  onTempAlert,
  tempReportId,
  onResetData,
}: any) => {
  const closeIcon = { backgroundImage: "url('/images/close.svg')" };
  const { userId } = useContext(MainContext);

  return (
    <>
      {openTempAlert && <TempAlert onTempAlert={onTempAlert} />}

      <div className="max-w-md h-screen flex flex-col justify-between items-center mx-auto text-gray-500">
        <div className="w-full flex-shrink-0 flex flex-col justify-between">
          {!openTempInner ? (
            <>
              <RegularHeader
                headerTitle={"실시간 증상 선택"}
                rightIcon={closeIcon}
              />
              <div className="text-2xl font-extrabold px-6 py-4 text-center bg-gray-100 mt-14">
                어디가 불편하세요?
                <p className="text-base font-normal mt-2">
                  나타난 증상을 모두 선택해주세요.
                </p>
              </div>

              <form className="grid grid-cols-3 gap-4 text-center text-xs py-4 px-3 mb-16">
                {userId.indexOf("E") !== 0
                  ? formData.map((item: any, idx: number) => (
                      <TempIcon
                        key={idx}
                        item={item}
                        checkHandler={checkHandler}
                      />
                    ))
                  : formDataLazertinib.map((item: any, idx: number) => (
                      <TempIcon
                        key={idx}
                        item={item}
                        checkHandler={checkHandler}
                      />
                    ))}
              </form>
              <div
                className={
                  "fixed bottom-0 left-0 z-10 w-full flex-shrink-0 text-center transition-all transform duration-300"
                }
              >
                <div
                  className="text-2xl bg-gray-point py-4 font-extrabold text-white"
                  onClick={onOpenTempInner}
                >
                  확인
                </div>
              </div>
            </>
          ) : (
            <>
              <TempContent
                checkedItems={checkedItems}
                tempReportId={tempReportId}
                onResetData={onResetData}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default TempChoice;
