import React, { useState, useContext, useEffect } from "react";
import { MainContext } from "../../mobile/datas/Store";
import { useHistory } from "react-router-dom";
import axios from "axios";
import common from "../../mobile/datas/common";
import Sidebar from "../components/Common/Sidebar";
import Navbar from "../components/Common/Navbar";
import SubNavbar from "../components/Common/SubNavbar";
import AlertDialog from "../components/Common/AlertDialog";
import AdminInfoDialog from "../components/Authority/AdminInfoDialog";
import ContentHeader from "../components/Common/ContentHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PatientRegister = () => {
  const { adminId, handlePageChange } = useContext(MainContext);
  const history = useHistory();
  const alertNoneIcon = { backgroundImage: "url('/images/alert-none.svg')" };
  const alertTakenIcon = { backgroundImage: "url('/images/alert-notice.svg')" };
  const arrowDownIcon = {
    backgroundImage: "url('/images/arrow-yellow-down.svg')",
  };
  const lockIcon = { backgroundImage: "url('/images/lock.svg')" };
  const unlockIcon = { backgroundImage: "url('/images/unlock.svg')" };
  const [openAlert, setOpenAlert] = useState(false);
  const [alarmData, setAlarmData] = useState([]);
  const [openAdminInfoAlert, setOpenAdminInfoAlert] = useState(false);

  const onOpenAlert = () => {
    setOpenAlert(!openAlert);
  };

  const onOpenAdminInfoAlert = () => {
    setOpenAdminInfoAlert(!openAdminInfoAlert);
  };

  useEffect(() => {
    if (!adminId) {
      history.push(`/adminlogin`);
    } else {
      let isComponentMounted = true;
      const bringAlarmData = () => {
        axios
          .get(common.SERVER_URL + "/report/alarm", common.headers)
          .then((res) => {
            if (res.data) {
              if (isComponentMounted) {
                setAlarmData(res.data.alarmList);
              }
            }
          })
          .catch((err) => console.log(err));
      };
      bringAlarmData();
      return () => {
        isComponentMounted = false;
      };
    }
  }, [adminId, history]);

  const [openPatientInfo, setOpenPatientInfo] = useState(false);
  const [openPatientInfoChange, setOpenPatientInfoChange] = useState(false);

  const backToPatientList = () => {
    //환자 목록 돌아가기
    setOpenPatientInfo(false);
    setOpenPatientInfoChange(false);
    handlePageChange(1);
  };

  const [openAdminInfo, setOpenAdminInfo] = useState(false);
  const [openAdminInfoChange, setOpenAdminInfoChange] = useState(false);

  const backToAdminList = () => {
    //관리자 목록 돌아가기
    setOpenAdminInfo(false);
    setOpenAdminInfoChange(false);
    handlePageChange(1);
  };

  const [patientRegData, setPatientRegData] = useState({
    adminId,
    patientId: "",
    pass: "",
    birth: "",
    gender: "",
    height: "",
    weight: "",
    disease: "비소세포폐암",
    operationYN: "수술",
    anticancerMedicine: "",
    medicine: "",
    underlyingdisease: "",
    memo: "",
  });

  // 조건 체크
  const onSubmitForm = (e: any) => {
    e.preventDefault();
    if (!patientRegData.pass) {
      toast.error("회원번호를 입력해주세요", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!patientRegData.patientId) {
      toast.error("비밀번호를 입력해주세요", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!patientRegData.birth) {
      toast.error("생년을 4자리로 입력해주세요", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!patientRegData.gender) {
      toast.error("성별을 선택해주세요", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!patientRegData.height) {
      toast.error("키를 입력해주세요", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!patientRegData.weight) {
      toast.error("체중을 입력해주세요", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!patientRegData.anticancerMedicine) {
      toast.error("항암제군을 선택해주세요", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!patientRegData.medicine) {
      toast.error("약제를 선택해주세요", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      Number(patientRegData.height) > 250 ||
      Number(patientRegData.height) < 120
    ) {
      toast.error("키를 정확하게 입력해주세요", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      Number(patientRegData.weight) > 250 ||
      Number(patientRegData.weight) < 20
    ) {
      toast.error("체중을 정확하게 입력해주세요", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (patientRegData.birth.length !== 4) {
      toast.error("생년을 4자리로 입력해주세요", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      axios
        .post(common.SERVER_URL + "/patient", patientRegData, common.headers)
        .then((res) => {
          if (res.data.ok) {
            toast.success("환자 등록 성공", {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
            backToList();
            setPatientRegData({
              adminId,
              patientId: "",
              pass: "",
              birth: "",
              gender: "",
              height: "",
              weight: "",
              disease: "비소세포폐암",
              operationYN: "",
              anticancerMedicine: "",
              medicine: "",
              underlyingdisease: "",
              memo: "",
            });
          } else {
            toast.error(res.data.error, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const onChangeData = (e: any) => {
    const { name, value } = e.target;
    setPatientRegData({ ...patientRegData, [name]: value });
  };

  const backToList = () => {
    history.push(`/adminpatient`);
    backToPatientList();
  };

  const pathname = window.location.pathname;

  return (
    <>
      <div className="flex h-screen antialiased text-gray-600 bg-gray-100">
        {/* side bar */}
        <Sidebar
          openPatientInfo={openPatientInfo}
          openPatientInfoChange={openPatientInfoChange}
          openAdminInfo={openAdminInfo}
          openAdminInfoChange={openAdminInfoChange}
          backToPatientList={backToPatientList}
          backToAdminList={backToAdminList}
        />

        {/* content */}
        <div className="flex-1 h-full overflow-x-hidden overflow-y-auto">
          {/* navbar */}
          <header className="relative bg-gray-point h-12 flex flex-col md:h-28">
            <Navbar
              alertIcon={alarmData.length > 0 ? alertTakenIcon : alertNoneIcon}
              arrowDownIcon={arrowDownIcon}
              lockIcon={lockIcon}
              unlockIcon={unlockIcon}
              onOpenAlert={onOpenAlert}
              onOpenAdminInfoAlert={onOpenAdminInfoAlert}
            />
            <SubNavbar />
          </header>

          {/* main content */}
          <main className="mb-4">
            {/* 신규 환자 등록 */}
            <>
              {pathname === "/patientregister" && (
                <ContentHeader headerTitle={"신규 환자 등록"} />
              )}
              <form onSubmit={onSubmitForm}>
                <div className="p-4 bg-white rounded-md mx-4 text-sm">
                  <div>
                    <div className="flex flex-col md:flex-row">
                      <div className="w-full mx-2 flex-1">
                        <div className="font-bold h-6 mt-3 leading-8">
                          회원번호
                        </div>
                        <div className="bg-white my-2 p-1 flex border border-gray-200">
                          <input
                            required
                            placeholder="A1234"
                            className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                            name="patientId"
                            value={patientRegData.patientId.replace(/\s/gi, "")}
                            onChange={onChangeData}
                          />{" "}
                        </div>
                      </div>
                      <div className="w-full mx-2 flex-1">
                        <div className="font-bold h-6 mt-3 leading-8">
                          비밀번호
                        </div>
                        <div className="bg-white my-2 p-1 flex border border-gray-200">
                          <input
                            required
                            placeholder="비밀번호"
                            type="password"
                            autoComplete="on"
                            className="p-1 px-2 appearance-none outline-none w-full text-gray-800 font-mono focus:ring"
                            name="pass"
                            value={patientRegData.pass}
                            onChange={onChangeData}
                          />{" "}
                        </div>
                      </div>
                    </div>

                    {/* 22.01.06 삼성병원 미팅 후 요청으로 생년월일을 생년으로 처리 */}
                    <div className="flex flex-col md:flex-row">
                      <div className="w-full mx-2 flex-1">
                        <div className="font-bold h-6 mt-3 leading-8">생년</div>
                        <div className="bg-white my-2 p-1 flex border border-gray-200">
                          <input
                            maxLength={4}
                            placeholder="1960"
                            type="number"
                            className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                            name="birth"
                            value={patientRegData.birth}
                            onChange={onChangeData}
                          />{" "}
                        </div>
                      </div>
                      <div className="w-full mx-2 flex-1">
                        <div className="font-bold h-6 mt-3 leading-8">성별</div>
                        <div className="flex justify-around items-center my-2 p-1">
                          <label>남자</label>
                          <input
                            required
                            type="radio"
                            className="form-radio mr-2"
                            name="gender"
                            value="M"
                            onChange={onChangeData}
                          />

                          <label>여자</label>
                          <input
                            required
                            type="radio"
                            className="form-radio"
                            name="gender"
                            value="F"
                            onChange={onChangeData}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col md:flex-row">
                      <div className="w-full mx-2 flex-1">
                        <div className="font-bold h-6 mt-3 leading-8">병명</div>
                        <div className="bg-white my-2 p-1 flex border border-gray-200">
                          <input
                            disabled
                            className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                            value={patientRegData.disease}
                          />{" "}
                        </div>
                      </div>
                      <div className="w-full mx-2 flex-1">
                        <div className="font-bold h-6 mt-3 leading-8">
                          수술여부
                        </div>
                        <div className="flex justify-around items-center my-2 p-1">
                          <label>수술</label>
                          <input
                            type="radio"
                            className="form-radio mr-2"
                            name="operationYN"
                            value="수술"
                            onChange={onChangeData}
                            checked={true}
                          />

                          <label>비수술</label>
                          <input
                            type="radio"
                            className="form-radio"
                            name="operationYN"
                            value="비수술"
                            onChange={onChangeData}
                          />
                        </div>
                      </div>
                      <div className="w-full mx-2 flex-1">
                        <div className="font-bold h-6 mt-3 leading-8">
                          키(cm)
                        </div>
                        <div className="bg-white my-2 p-1 flex border border-gray-200">
                          <input
                            required
                            type="number"
                            placeholder="175"
                            className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                            name="height"
                            value={patientRegData.height}
                            onChange={onChangeData}
                            min="120"
                            max="250"
                          />
                        </div>
                      </div>
                      <div className="w-full mx-2 flex-1">
                        <div className="font-bold h-6 mt-3 leading-8">
                          체중(kg)
                        </div>
                        <div className="bg-white my-2 p-1 flex border border-gray-200">
                          <input
                            required
                            type="number"
                            placeholder="87"
                            className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                            name="weight"
                            value={patientRegData.weight}
                            onChange={onChangeData}
                            min="20"
                            max="250"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col md:flex-row">
                      <div className="w-full mx-2 flex-1">
                        <div className="font-bold h-6 mt-3 leading-8">
                          항암제군
                        </div>
                        <div className="bg-white my-2 p-1 flex border border-gray-200">
                          <select
                            required
                            name="anticancerMedicine"
                            onChange={onChangeData}
                            className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                          >
                            {/* Lazertinib연구 간호가는 환자 추가 등록시 선택사항에 '치료제'만 출력 */}
                            {adminId === "admin1" ? (
                              <>
                                <option value="">선택하세요</option>
                                <option value="1차 치료제">1차 치료제</option>
                                <option value="2차 치료제">2차 치료제</option>
                                <option value="치료제">치료제</option>
                              </>
                            ) : adminId === "smc09" ? (
                              <>
                                <option value="">선택하세요</option>
                                <option value="치료제">치료제</option>
                              </>
                            ) : (
                              <>
                                <option value="">선택하세요</option>
                                <option value="1차 치료제">1차 치료제</option>
                                <option value="2차 치료제">2차 치료제</option>
                              </>
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="w-full mx-2 flex-1">
                        <div className="font-bold h-6 mt-3 leading-8">약제</div>
                        <div className="bg-white my-2 p-1 flex border border-gray-200">
                          <select
                            required
                            name="medicine"
                            onChange={onChangeData}
                            className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                          >
                            {patientRegData.anticancerMedicine === "" ? (
                              <option value="">선택하세요</option>
                            ) : patientRegData.anticancerMedicine ===
                              "1차 치료제" ? (
                              <>
                                <option value="">선택하세요</option>
                                <option value="pembrolizumab(1차)">
                                  pembrolizumab(1차)
                                </option>
                                <option value="pembrolizumab + pemetrexed + carboplatin">
                                  pembrolizumab + pemetrexed + carboplatin
                                </option>
                                <option value="pembrolizumab + paclitaxel + carboplatin">
                                  pembrolizumab + paclitaxel + carboplatin
                                </option>
                              </>
                            ) : patientRegData.anticancerMedicine ===
                              "2차 치료제" ? (
                              <>
                                <option value="">선택하세요</option>
                                <option value="pembrolizumab(2차)">
                                  pembrolizumab(2차)
                                </option>
                                <option value="atezolizumab">
                                  atezolizumab
                                </option>
                                <option value="nivolumab">nivolumab</option>
                                <option value="docetaxel">docetaxel</option>
                              </>
                            ) : patientRegData.anticancerMedicine ===
                              "치료제" ? (
                              <>
                                <option value="">선택하세요</option>
                                <option value="Lazertinib">Lazertinib</option>
                              </>
                            ) : (
                              ""
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row">
                      <div className="w-full mx-2 flex-1">
                        <div className="font-bold h-6 mt-3 leading-8">
                          기저질환
                        </div>
                        <textarea
                          name="underlyingdisease"
                          className="bg-white my-2 p-1 w-full min-h-[100px] max-h-[300px] h-28 appearance-none 
                                block w-full bg-grey-lighter border py-4 px-4 focus:ring focus:outline-none"
                          value={patientRegData.underlyingdisease}
                          onChange={onChangeData}
                        ></textarea>
                      </div>
                      <div className="w-full mx-2 flex-1">
                        <div className="font-bold h-6 mt-3 leading-8">
                          특이사항
                        </div>
                        <textarea
                          name="memo"
                          className="bg-white my-2 p-1 w-full min-h-[100px] max-h-[300px] h-28 appearance-none 
                                block w-full bg-grey-lighter border border-grey-lighter py-4 px-4 focus:ring focus:outline-none"
                          value={patientRegData.memo}
                          onChange={onChangeData}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="flex-auto flex flex-row-reverse p-2 my-4">
                    <button
                      type="submit"
                      className="ml-2 flex justify-center px-8 py-2 rounded font-bold hover:bg-gray-600 hover:text-white bg-gray-200 border duration-200 ease-in-out border-gray-600 transition"
                    >
                      저장하기
                    </button>
                    <button
                      onClick={backToList}
                      className="flex justify-center px-8 py-2 rounded font-bold hover:bg-gray-600 hover:text-white bg-gray-200 border duration-200 ease-in-out border-gray-600 transition"
                    >
                      목록
                    </button>
                  </div>
                </div>
              </form>
              <ToastContainer />
            </>
          </main>
        </div>
      </div>

      <AlertDialog
        openAlert={openAlert}
        onOpenAlert={onOpenAlert}
        alarmData={alarmData}
      />

      {openAdminInfoAlert && (
        <AdminInfoDialog
          onOpenAdminInfoAlert={onOpenAdminInfoAlert}
          adminId={adminId}
        />
      )}
    </>
  );
};

export default PatientRegister;
