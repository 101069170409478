import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Store from "./screens/mobile/datas/Store";
import MobileLogin from "./screens/mobile/pages/Login";
import Intro from "./screens/mobile/pages/Intro";
import MyInfo from "./screens/mobile/pages/MyInfo";
import Setting from "./screens/mobile/pages/Setting";
import MainRegular from "./screens/mobile/pages/MainRegular";
import MainTemp from "./screens/mobile/pages/MainTemp";
import Analysis from "./screens/mobile/pages/Analysis";
import StatiticsAnalysis from "./screens/mobile/pages/StatisticsAnalysis";
import AdminMain from "./screens/admin/pages/AdminMain";
import AdminLogin from "./screens/admin/pages/AdminLogin";
import PatientReport from "./screens/admin/pages/PatientReport";
import PatientStatistics from "./screens/admin/pages/PatientStatistics";
import IntroGuideCard from "./screens/mobile/components/Result/IntroGuideCard";
import IntroHowToCard from "./screens/mobile/components/IntroHowToCard";
import Graph from "./screens/admin/pages/Graph";
import AdminGuide from "./screens/admin/pages/AdminGuide";
import AdminPatient from "./screens/admin/pages/AdminPatient";
import PatientRegister from "./screens/admin/pages/PatientRegister";
import AdminAccount from "./screens/admin/pages/AdminAccount";
import StatusTable from "./screens/admin/pages/StatusTable";
import AdminRegister from "./screens/admin/pages/AdminRegister";
import { NotFound } from "./screens/mobile/pages/notfound";
import PasswordChange from "./screens/mobile/pages/PasswordChange";
import PatientReporForSamsung from "./screens/admin/pages/PatientReportForSamsung";
import PatientReporForSamsungA from "./screens/admin/pages/PatientReportForSamsungA";

function App() {
  return (
    <Store>
      <BrowserRouter>
        <Switch>
          <Route path="/mobilelogin" component={MobileLogin} />
          <Route path="/intro" component={Intro} />
          <Route path="/info" component={MyInfo} />
          <Route path="/main" component={MainRegular} />
          <Route path="/maintemp" component={MainTemp} />
          <Route path="/analysis" component={Analysis} />
          <Route path="/stats" component={StatiticsAnalysis} />
          <Route path="/guide" component={IntroGuideCard} />
          <Route path="/how" component={IntroHowToCard} />
          <Route path="/setting" component={Setting} />
          <Route path="/pw" component={PasswordChange} />
          <Route path="/" component={AdminLogin} exact={true} />
          <Route path="/adminLogin" component={AdminLogin} />
          <Route path="/adminmain" component={AdminMain} />
          <Route path="/graph" component={Graph} />
          <Route path="/adminguide" component={AdminGuide} />
          <Route path="/adminpatient" component={AdminPatient} />
          <Route path="/patientregister" component={PatientRegister} />
          <Route path="/adminaccount" component={AdminAccount} />
          <Route path="/adminregister" component={AdminRegister} />
          <Route path="/statustable" component={StatusTable} />
          <Route path="/report/:id" component={PatientReport} />
          <Route path="/statistics/:id" component={PatientStatistics} />
          <Route path="/samsung/link/results/:token" component={PatientReporForSamsungA} />
          <Route path="/samsung" component={PatientReporForSamsung} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </Store>
  );
}

export default App;
