import React from 'react';

const ContentHeader = ({ printIcon, onClickBtn, headerTitle, btnRight }: any) => {

    return (
        <div className='flex justify-between items-center px-4 py-6'>
            <h1 className='text-2xl font-extrabold'>{headerTitle}</h1>

            {btnRight && <>
                <div className='relative'>
                    <div onClick={onClickBtn} className="group cursor-pointer relative flex gap-2 items-center px-8 py-2 text-sm rounded-md bg-yellow-point font-bold
                    focus:outline-none focus:ring focus:ring-primary hover:shadow-lg">
                        {printIcon && <span className='w-6 h-6 inline-flex' style={printIcon}></span>}
                        <span className='font-bold'>{btnRight}</span>
                        <div className="absolute opacity-0 w-44 right-0 bottom-full mb-2 bg-black text-white text-center rounded-lg py-2 z-10 group-hover:opacity-100 px-3 pointer-events-none">
                            인쇄 배율: 맞춤 설정 90
                            <svg className="absolute text-black h-2 w-full left-0 top-full" x="0px" y="0px" viewBox="0 0 255 255"><polygon className="fill-current" points="0,0 127.5,127.5 255,0" /></svg>
                        </div>
                    </div>
                </div>
            </>
            }
        </div>
    );
};

export default ContentHeader;