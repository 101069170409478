import React, { useState, useEffect } from "react";
import axios from "axios";
import common from "../../../mobile/datas/common";

const AdminInfoDialog = ({ onOpenAdminInfoAlert, adminId }: any) => {
  const [adminInfo, setAdminInfo] = useState<any>({});

  useEffect(() => {
    axios
      .get(common.SERVER_URL + "/admin/" + adminId, common.headers)
      .then((res) => setAdminInfo(res.data.admin))
      .catch((err) => console.log(err));
  }, [adminId])


  return (
    <>
      <div onClick={onOpenAdminInfoAlert} className="h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-60 z-10">
        <div className="bg-white rounded shadow-lg w-2/3 text-gray-500 overflow-hidden">
          <div className="px-4 py-2 flex justify-end items-center">
            <span onClick={onOpenAdminInfoAlert}  >
              <svg
                className="w-6 h-6 cursor-pointer"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </span>
          </div>

          <div className='px-6'>
            <h2 className='text-center font-extrabold text-lg'>{adminId}님 정보</h2>
            <div className="p-4 bg-white rounded-md">
              <div className="text-sm">
                <div className="flex">
                  <div className="flex mx-2 flex-1">
                    <div className="w-24 font-bold h-6 mt-3 leading-6">아이디</div>
                    <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200 rounded">
                      {adminInfo.adminId}
                    </div>
                  </div>
                  <div className="flex mx-2 flex-1">
                    <div className="w-24 font-bold h-6 mt-3 leading-6">
                      비밀번호
                    </div>
                    <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200 rounded">
                      비밀번호
                    </div>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex mx-2 flex-1">
                    <div className="w-24 font-bold h-6 mt-3 leading-6">이름</div>
                    <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200 rounded">
                      {adminInfo.name}
                    </div>
                  </div>
                  <div className="flex mx-2 flex-1">
                    <div className="w-24 font-bold mt-3 leading-6">등록일자</div>
                    <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200 rounded">
                      {adminInfo.createdAt && adminInfo.createdAt.slice(0, 10)}
                    </div>
                  </div>
                </div>

                {/* 22.01.06 삼성병원 미팅 후 요청으로 관리자 등록 이메일, 휴대전화 hidden 처리 */}
                {/* <div className="flex">
                  <div className="flex mx-2 flex-1">
                    <div className="w-24 font-bold h-6 mt-3 leading-6">이메일</div>
                    <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200 rounded">
                      {adminInfo.email}
                    </div>
                  </div>
                  <div className="flex mx-2 flex-1">
                    <div className="w-24 font-bold h-6 mt-3 leading-6">
                      휴대전화
                    </div>
                    <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200 rounded">
                      {adminInfo.phone}
                    </div>
                  </div>
                </div> */}

                <div className="flex">
                  <div className="flex mx-2 flex-1">
                    <div className="w-24 font-bold h-6 mt-3 leading-6">소속</div>
                    <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200 rounded">
                      {adminInfo.organization}
                    </div>
                  </div>
                  <div className="flex mx-2 flex-1">
                    <div className="w-24 font-bold h-6 mt-3 leading-6">구분</div>
                    <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200 rounded">
                      {(adminInfo.type === "D" ? "의사" : (adminInfo.type === "N" ? "간호사" : (adminInfo.type === "A" ? "관리자" : "최고 관리자")))}
                    </div>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex mx-2 flex-1">
                    <div className="w-24 font-bold h-6 mt-3 leading-6">메모</div>
                    <div
                      className="bg-gray-100 my-2 p-1 w-full min-h-[100px] max-h-[300px] h-20 appearance-none 
                            block w-full bg-grey-lighter border rounded-lg py-4 px-4"
                    >
                      {adminInfo.memo}
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminInfoDialog;
