import React, { useState, useContext, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { MainContext } from "../datas/Store";
import axios from "axios";
import common from "../datas/common";
import IntroChart from "../components/Charts/IntroChart";
import BottomMenuBar from "../components/Common/BottomMenuBar";
import DoctorChoice from "../components/DoctorChoice";
import IntroGuide from "../components/Result/IntroGuide";
import IntroHowTo from "../components/IntroHowTo";
import SwiperCore, { Pagination, Autoplay, Zoom } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/zoom/zoom.min.css";
import VideoDialog from "../../admin/components/Common/VideoDialog";

// install Swiper modules
SwiperCore.use([Pagination, Autoplay, Zoom]);

const Intro = () => {
  const { userId } = useContext(MainContext);
  const history = useHistory();
  const [introContents, setIntroContents] = useState({
    first: "",
    second: "",
    third: "",
    firstRate: 0,
    secondRate: 0,
    thirdRate: 0,
    regularInputDay: false,
  });
  const [doctorChoice, setDoctorChoice] = useState(false);
  const [alertInput, setAlertInput] = useState(false);
  const [scrollToBlock, setScrollToBlock] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);

  const checkDoctor = useCallback(() => {
    axios
      .get(common.SERVER_URL + "/patient/pId/" + userId, common.headers)
      .then((res) => {
        if (res.data) {
          let data = res.data;
          if (!data.patient.doctorId) {
            setDoctorChoice(true);
          } else {
            setDoctorChoice(false);
          }
        }
      })
      .catch((err) => console.log(err));
  }, [userId]);

  const bringIntro = useCallback(() => {
    axios
      .get(common.SERVER_URL + "/report/main/" + userId, common.headers)
      .then((res) => {
        if (res.data.ok) {
          setIntroContents({
            first: res.data.first,
            second: res.data.second,
            third: res.data.third,
            firstRate: res.data.firstRate,
            secondRate: res.data.secondRate,
            thirdRate: res.data.thirdRate,
            regularInputDay: res.data.isRegularInterval,
          });
          setAlertInput(res.data.isAnticancerMedicine); //true시 투약일 안넣음, alert
        }
      })
      .catch((err) => console.log(err));
  }, [userId]);

  useEffect(() => {
    //intro 주요 증상 데이터 호출
    if (!userId) {
      history.push(`/mobilelogin`);
    } else {
      window.scrollTo(0, 0);
      checkDoctor();
      if (!doctorChoice) {
        bringIntro();
      }
    }
    window.scrollTo(0, 0);
    checkDoctor();
    if (!doctorChoice) {
      bringIntro();
    }
  }, [userId, history, checkDoctor, bringIntro, doctorChoice]);

  const onCloseDoctorChoice = () => {
    setDoctorChoice(false);
  };

  const onBlockScroll = () => {
    setScrollToBlock(true);
  };

  const onScroll = () => {
    setScrollToBlock(false);
  };

  const onStopAlertInput = () => {
    setAlertInput(false);
  };
  return (
    <div
      className={
        "max-w-md h-screen flex flex-col justify-between items-center mx-auto p-6 mb-16 " +
        (scrollToBlock ? "fixed w-full h-full overflow-hidden" : "")
      }
    >
      {!doctorChoice ? (
        <>
          <div className="flex-shrink-0 flex flex-col gap-3 text-gray-600 text-center">
            <div className="bg-gray-100 rounded-xl text-sm py-0.5 mx-6 mb-4">
              <p>{userId}님 안녕하세요?</p>
              {introContents.regularInputDay && (
                <p>오늘은 정기 입력일 입니다.</p>
              )}
            </div>
            <div className="w-screen px-6">
              <Swiper
                slidesPerView={1}
                autoplay={{ delay: 4000, disableOnInteraction: false }}
                spaceBetween={20}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                zoom
              >
                <SwiperSlide>
                  <div className="pb-6">지난 일주일 동안 폐암환자들에게</div>
                  <div className="text-2xl text-gray-800 font-bold mb-8">
                    가장 많이 발생한 증상은
                    <h3>
                      <strong className="textBgYellow font-extrabold">
                        {introContents.first}
                      </strong>{" "}
                      입니다.
                    </h3>
                  </div>
                  <div>
                    {introContents && (
                      <IntroChart introContents={introContents} />
                    )}
                  </div>
                </SwiperSlide>
                {/* Lazertinib 사용자는 숨기기 */}
                {userId.indexOf("E") !== 0 ? (
                  <SwiperSlide>
                    <Link to="/guide">
                      <IntroGuide />
                    </Link>
                  </SwiperSlide>
                ) : null}
                <SwiperSlide>
                  <Link to="/how">
                    <IntroHowTo />
                  </Link>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="flex flex-col justify-between gap-6">
              <div className="mx-6">
                <button className="w-full bg-yellow-point rounded-full px-6 py-6 mb-2">
                  <Link
                    to="/main"
                    className="font28 text-gray-700 font-extrabold"
                  >
                    정기 증상 입력하기
                  </Link>
                </button>

                <p className="text-sm text-gray-400">
                  {/* Lazertinib 사용자는 7일 */}
                  {userId.indexOf("E") !== 0
                    ? "3일에 한 번 정기적으로 입력하시길 추천드립니다"
                    : "7일에 한 번 정기적으로 입력하시길 추천드립니다"}
                </p>
              </div>
              {/* Lazertinib 사용자는 숨기기 */}
              {userId.indexOf("E") !== 0 ? (
                <div className="flex justify-between gap-6 mx-6">
                  <div className="flex-grow font-bold text-left">
                    방금 나타난 증상
                    <br />
                    괜찮을까?
                  </div>
                  <button className="flex-grow rounded-full px-5 py-1 bg-gray-point">
                    <Link to="/maintemp" className="text-white font-bold">
                      실시간 증상
                    </Link>
                  </button>
                </div>
              ) : null}
              <div onClick={() => setOpenVideo(true)} className="p-4">
                <img src="images/videoBanner.png" alt="영상 배너 이미지" />
              </div>
            </div>
          </div>
          {openVideo && (
            <VideoDialog onCloseDialog={() => setOpenVideo(false)} />
          )}
          <BottomMenuBar
            onBlockScroll={onBlockScroll}
            onScroll={onScroll}
            alertInput={alertInput}
            onStopAlertInput={onStopAlertInput}
          />
        </>
      ) : (
        <DoctorChoice onCloseDoctorChoice={onCloseDoctorChoice} />
      )}
    </div>
  );
};

export default Intro;
