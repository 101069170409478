import React from 'react';
import Parser from 'html-react-parser';

const GuideIcon = ({ item, onOpenGuide }: any) => {

    return (
        <>
            <div className="flex flex-col space-y-4 justify-center items-center" >
                <div onClick={() => onOpenGuide(item)} className="bg-white w-full flex items-center py-2 px-4 rounded-xl shadow border">
                    <div className="flex items-center space-x-4 text-left">
                        <img src={item.bgIcon} alt="증상 가이드" className="w-10 h-10 inline-flex" />
                    </div>
                    <div className="flex flex-col w-5/6 px-2 text-left text-base">
                        <div className="font-bold text-gray-700">
                            {item.nameKo}
                        </div>
                        <div className="guideTitle text-gray-500 overflow-ellipsis overflow-hidden">
                            {Parser(item.guideTitle)}
                        </div>
                    </div>
                    <div>
                        <span className='w-4 h-4 bg-yellow-point inline-block rounded-full'></span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GuideIcon;