import React, { useState, useEffect } from 'react';
import Parser from 'html-react-parser';
import CommonDialog from '../Common/CommonDialog';

const GuideDetails = ({ chosenGuide, onBlockScroll, onScroll }: any) => {
    const [sourceDialog, setSourceDialog] = useState(false);
    const onOpenSourceDialog = () => {
        setSourceDialog(!sourceDialog);
        if (!sourceDialog && onBlockScroll) { onBlockScroll() } else if (sourceDialog && onBlockScroll) onScroll(); //스크롤 방지
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="text-center bg-lightyellow-point py-6 px-4">
                <div className="flex gap-2 justify-center items-center mb-3">
                    <span className="inline-flex rounded-full h-3 w-3 bg-yellow-point"></span>
                    <span className="text-lg font-extrabold text-gray-700">{chosenGuide.nameKo}</span>
                </div>
                <p className="font-bold text-left text-gray-700">{Parser(chosenGuide.guideTitle)}</p>
            </div>
            {chosenGuide.guideContent && <div className="flex flex-col gap-4 px-4 z-0 mb-20">
                {chosenGuide.guideContent && <div>
                    <strong className='textBgYellow text-gray-700 mb-2'>비약물적 관리 (가정관리 지침)</strong>
                    <p>{Parser(chosenGuide.guideContent)}</p>
                </div>}

                {chosenGuide.guideCautions && <div>
                    <strong className='textBgYellow text-gray-700 mb-2'>주의사항</strong>
                    <p> {Parser(chosenGuide.guideCautions)}</p>
                </div>}

                {chosenGuide.guideContent && <button onClick={onOpenSourceDialog} className="block my-4 p-2 rounded-md border-gray-300 border-2 hover:text-gray-700 hover:bg-lightyellow-point hover:border-gray-500 hover:font-extrabold">출처 및 권고 안내</button>}
            </div>}
            {sourceDialog && <CommonDialog title={'증상 및 부작용에 대한 대처방법 가이드는 ESMO, ASCO, NCI, NCCN, 대한항암요법연구회, 국립암센터에 근거하여 권고수준이 높은 내용을 제공합니다.'} onCloseDialog={onOpenSourceDialog} sourceDialog={sourceDialog}
                onBlockScroll={onBlockScroll} onScroll={onScroll} />}
        </>
    );
};

export default GuideDetails;