import React from "react";

const SubNavbar = ({
  openPatientInfo,
  openPatientInfoChange,
  openAdminInfo,
  openAdminInfoChange,
}: any) => {
  const pathname = window.location.pathname;

  const nameKo = (pathname: any) => {
    if (pathname === "/adminmain") {
      return <span>환자 리포트</span>;
    } else if (pathname === "/graph") {
      return <span>환자별 통계</span>;
    } else if (pathname === "/adminguide") {
      return <span>증상 가이드</span>;
    } else if (
      pathname === "/adminpatient" &&
      !openPatientInfo &&
      !openPatientInfoChange
    ) {
      return <span>환자 관리 &gt; 환자 관리 목록</span>;
    } else if (
      pathname === "/adminpatient" &&
      openPatientInfo &&
      !openPatientInfoChange
    ) {
      return <span>환자 관리 &gt; 환자 정보 내역</span>;
    } else if (
      pathname === "/adminpatient" &&
      openPatientInfo &&
      openPatientInfoChange
    ) {
      return <span>환자 관리 &gt; 환자 정보 수정</span>;
    } else if (pathname === "/patientregister") {
      return <span>환자 관리 &gt; 신규 환자 등록</span>;
    } else if (
      pathname === "/adminaccount" &&
      !openAdminInfo &&
      !openAdminInfoChange
    ) {
      return <span>권한 관리 &gt; 관리자 관리 목록</span>;
    } else if (
      pathname === "/adminaccount" &&
      openAdminInfo &&
      !openAdminInfoChange
    ) {
      return <span>권한 관리 &gt; 관리자 정보 내역</span>;
    } else if (
      pathname === "/adminaccount" &&
      openAdminInfo &&
      openAdminInfoChange
    ) {
      return <span>권한 관리 &gt; 관리자 정보 수정</span>;
    } else if (pathname === "/adminregister") {
      return <span>권한 관리 &gt; 신규 관리자 등록</span>;
    } else if (pathname === "/statustable") {
      return <span>접속 및 통계</span>;
    } else if (pathname.includes("/report")) {
      return <span>환자 리포트 &gt; 환자 증상 결과 요약</span>;
    } else if (pathname.includes("/statistics")) {
      return <span>환자별 통계 &gt; 환자별 통계 내용</span>;
    } else {
      return "";
    }
  };

  return (
    <nav className="hidden space-x-2 md:flex w-full md:items-center h-12 bg-gray-200 px-4">
      <div className="text-sm">
        <span>HOME</span>
        <span> &gt; </span>
        <span>{nameKo(pathname)}</span>
      </div>
    </nav>
  );
};

export default SubNavbar;
