import React, { useContext } from "react";
import DailyInputTableData from "./DailyInputTableData";
import { MainContext } from "../../../mobile/datas/Store";

const DailyInputTable = ({ historyData }: any) => {
  const { patientId } = useContext(MainContext);
  return (
    <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-md border-gray-400">
      <div className="block w-full overflow-x-auto">
        {/* Each daily input data table */}
        <table className="table-fixed items-center w-full bg-transparent border-collapse">
          <thead>
            <tr className="font-extrabold whitespace-nowrap align-middle">
              <th className="w-32 border border-solid px-6 py-3 border-l-0 border-r-0">
                <div className="flex gap-1 justify-center items-center">
                  <span>입력 날짜</span>
                </div>
              </th>
              <th className="w-32 border border-solid px-6 py-3 border-l-0 border-r-0">
                <div className="flex gap-1 justify-center items-center">
                  <span>정기/비정기</span>
                </div>
              </th>
              <th className="w-1/2 border border-solid px-6 py-3 border-l-0 border-r-0">
                <div className="flex gap-1 justify-center items-center">
                  <span>발생한 증상(단계)</span>
                </div>
              </th>
              <th className="border border-solid px-6 py-3 border-l-0 border-r-0">
                <div className="flex flex-col gap-1 justify-center items-center">
                  <span>위험 증상</span>
                  <p className="text-xs">(3단계 이상)</p>
                </div>
              </th>
              {patientId.indexOf("E") !== 0 ? (
                <th className="border border-solid px-6 py-3 border-l-0 border-r-0">
                  <div className="flex gap-1 justify-center items-center">
                    <div className="flex flex-col gap-1 justify-center items-center">
                      <span>응급 증상</span>
                      <p className="text-xs">
                        (12가지 선정된 증상 중 위험 증상)
                      </p>
                    </div>
                  </div>
                </th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {historyData &&
              historyData.map((row: any, idx: number) => (
                <DailyInputTableData key={idx} row={row} />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DailyInputTable;
