import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import common from "../../../mobile/datas/common";
import InfoHeader from "./InfoHeader";
import PatientInfo from "./PatientInfo";
import PercentCard from "./PercentCard";
import GradeCard from "./GradeCard";
import TotalGradeChart from "../Charts/TotalGradeChart";
import TrendCharts from "../Charts/TrendChart";
import "react-toastify/dist/ReactToastify.css";

const MainReportForPrintAtOnce = ({ patientId, idx }: any) => {
    const [frenquencyData, setFrequcyData] = useState<any>([]);
    const [gradeData, setGradeData] = useState<any>([]);
    const [graphData, setGraphData] = useState([]);
    const [totalGradeData, setTotalGradeData] = useState([]);
    const [cycleDate, setCycleDate] = useState({ startDate: "", endDate: "" });
    const [searchDate] = useState<any>({ startD: "", endD: "" });
    const [patientData, setPatientData] = useState([]);


    const bringPatientData = useCallback((patientId: any) => {
        axios.get(common.SERVER_URL + "/patient/pid/" + patientId, common.headers)
            .then(res => {
                if (res.data) {
                    const data = res.data;
                    setPatientData(data.patient);
                }
            })
            .catch(err => console.log(err))
    }, []);

    const bringUserAllData = useCallback(
        (patientId, cycle = 1, startDate, endDate) => {
            //많이 발생한 증상, 심각한 grade 증상
            let searchData = {
                patientId,
                cycle,
                startDate,
                endDate,
            };
            let start = "";
            let end = "";

            axios
                .post(common.SERVER_URL + "/report/searchDetail1", searchData, common.headers)
                .then((res) => {
                    if (res.data) {
                        setFrequcyData(
                            res.data.symptomCounts.length >= 3
                                ? res.data.symptomCounts.slice(0, 3)
                                : res.data.symptomCounts
                        ); //많이 발생한 증상
                        setGradeData(
                            res.data.symptomGrades.length >= 3
                                ? res.data.symptomGrades.slice(0, 3)
                                : res.data.symptomCounts
                        ); //심각한 증상 카드
                        start = res.data.searchStartDay.slice(0, 10);
                        end = res.data.searchEndDay.slice(0, 10);
                        setCycleDate({ startDate: start, endDate: end });
                    }
                })
                .catch((err) => console.log(err));
        },
        []
    );

    const bringUserGraphData = useCallback(
        (patientId, cycle = 1, startDate, endDate) => {
            //증상 트렌드 그래프
            let params = {
                patientId,
                cycle,
                startDate,
                endDate,
            };

            axios
                .post(common.SERVER_URL + "/report/searchDetail2", params, common.headers)
                .then((res) => {
                    setGraphData(res.data.symptomDateGrades); //총 21개 증상별 그래프
                    setTotalGradeData(res.data.dateDangerTotalGrade); //재발 위험 점수 그래프
                })
                .catch((err) => console.log(err));
        },
        []
    );

    useEffect(() => {
        bringPatientData(patientId);
        bringUserAllData(patientId, 1, "", "");
        bringUserGraphData(patientId, 1, "", "");
    }, [bringPatientData, bringUserAllData, bringUserGraphData, patientId]);

    return (
        <div className="print-page max-w-2xl flex flex-col">
            <div className="p-4 rounded-lg">
                {/* 환자 정보 헤더 */}
                <InfoHeader checkedPatient={patientData} />
                {/* 환자 증상 요약 */}
                {patientData && <PatientInfo checkedPatient={patientData} />}
            </div>

            <main>
                <div className="flex flex-col gap-2 text-sm">
                    <div className="grid xs:grid-cols-1 grid-cols-2 gap-2 rounded-md mx-4 border">
                        {/* 많이 발생한 증상 카드 */}
                        <PercentCard
                            title={"많이 발생한 증상"}
                            frenquencyData={frenquencyData}
                            cycleDate={cycleDate}
                        />

                        {/* 심각한 grade 증상 */}
                        <GradeCard
                            title={"심각한 Grade 증상"}
                            gradeData={gradeData}
                            cycleDate={cycleDate}
                        />
                    </div>

                    <div className="flex flex-col bg-white py-1 mx-4 px-4 rounded-md text-sm">
                        <div className="flex justify-between items-center">
                            <div className="font-extrabold text-lg">
                                증상 트렌드
                                <span className="font-bold text-sm text-gray-400">
                                    {" "}
                                    (X: 입력없음)
                                </span>
                            </div>
                            <div className="flex flex-col text-sm text-gray-400 font-bold">
                                {searchDate.startD
                                    ? searchDate.startD + " ~ " + searchDate.endD
                                    : cycleDate.startDate + " ~ " + cycleDate.endDate}
                            </div>
                        </div>

                        <div className="grid grid-cols-2 gap-1">
                            <div className="flex flex-col gap-2 h-44 border-t border-gray-400">
                                {totalGradeData && <TotalGradeChart item={totalGradeData} />}
                            </div>
                            {graphData &&
                                graphData.map((item) => (
                                    <div className="flex flex-col gap-2 h-44 border-t border-gray-400">
                                        <TrendCharts item={item} />
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default MainReportForPrintAtOnce;
