import React, { useState, useContext, useEffect, useCallback } from "react";
import { MainContext } from "../../../mobile/datas/Store";
import axios from "axios";
import common from "../../../mobile/datas/common";
import { useHistory } from "react-router-dom";
import InfoHeader from "./InfoHeader";
import PatientInfo from "./PatientInfo";
import PercentCard from "./PercentCard";
import GradeCard from "./GradeCard";
import TotalGradeChart from "../Charts/TotalGradeChart";
import TrendCharts from "../Charts/TrendChart";
import PatientInfoEditDialog from "../Register/PatientInfoEditDialog";
import LoadingDialog from "../Common/LoadingDialog";
import { todayFormal } from "../../../mobile/datas/dataFilter";
import { ToastContainer, toast } from "react-toastify";
import dayjs from "dayjs";

import "react-toastify/dist/ReactToastify.css";

const MainReport = () => {
  const { patientId, checkedPatient, settingForPrint } =
    useContext(MainContext);
  const history = useHistory();
  const [frenquencyData, setFrequcyData] = useState<any>([]);
  const [gradeData, setGradeData] = useState<any>([]);
  const [graphData, setGraphData] = useState([]);
  const [totalGradeData, setTotalGradeData] = useState<any>([]);
  const [cycleDate, setCycleDate] = useState({
    startDate: "",
    endDate: "",
    type: 1,
  }); //기본 1, 3 사이클
  const [weekDate, setWeekDate] = useState({
    startDate: "",
    endDate: "",
    type: 3,
  }); //기본 3, 6 주
  const [searchDate, setSearchDate] = useState<any>({ startD: "", endD: "" }); //검색 날짜
  const [openPatientInfoEdit, setOpenPatientInfoEdit] = useState(false);
  const [patinetInfo, setPatientInfo] = useState({});
  const [loading, setLoading] = useState(false); //로딩창

  const bringUserAllData = useCallback(
    (patientId, cycle, week, startDate, endDate) => {
      //많이 발생한 증상, 심각한 grade 증상
      let searchData = {
        patientId,
        cycle,
        week,
        startDate,
        endDate,
      };

      axios
        .post(
          common.SERVER_URL + "/report/searchDetail1",
          searchData,
          common.headers
        )
        .then((res) => {
          if (res.data.ok) {
            if (res.data.symptomCounts || res.data.symptomGrades) {
              setFrequcyData(res.data.symptomCounts.slice(0, 3)); //많이 발생한 증상
              setGradeData(res.data.symptomGrades.slice(0, 3)); //심각한 증상 카드
            }
            setCycleDate({
              startDate: dayjs(res.data.searchStartDay).format("YYYY.MM.DD"),
              endDate: dayjs(res.data.searchEndDay).format("YYYY.MM.DD"),
              type: cycle,
            });
            setWeekDate({
              startDate: dayjs(res.data.searchStartDay).format("YYYY.MM.DD"),
              endDate: dayjs(res.data.searchEndDay).format("YYYY.MM.DD"),
              type: week,
            });
          } else {
            toast.error(res.data.error, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    []
  );

  const bringUserGraphData = useCallback(
    (patientId, cycle, week, startDate, endDate) => {
      //증상 트렌드 그래프
      let params = {
        patientId,
        cycle,
        week,
        startDate,
        endDate,
      };

      axios
        .post(
          common.SERVER_URL + "/report/searchDetail2",
          params,
          common.headers
        )
        .then((res) => {
          if (res.data.ok) {
            setGraphData(res.data.symptomDateGrades); //총 21개 증상별 그래프
            setTotalGradeData(res.data.dateDangerTotalGrade); //재발 위험 점수 그래프
            setTimeout(() => setLoading(false), 500);
          } else {
            setTimeout(() => {
              setLoading(false);
              toast.error(res.data.error, {
                autoClose: 3000,
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 500);
          }
        })
        .catch((err) => console.log(err));
    },
    []
  );

  const settingCycle = useCallback((cycle: number) => {
    setLoading(true);
    setSearchDate({ startD: "", endD: "" });
    bringUserAllData(patientId, cycle, null, "", "");
    bringUserGraphData(patientId, cycle, null, "", "");
    // {patientId.indexOf("E") !== 0 ?  settingForPrint(cycle, "", "") : null}
    settingForPrint(cycle, "", ""); //인쇄를 위해 store에 기간 저장
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // *  Lazertinib 은 3주 6주로 기존과 다름
  const settingWeek = useCallback((week: number) => {
    setLoading(true);
    setSearchDate({ startD: "", endD: "" });
    bringUserAllData(patientId, null, week, "", "");
    bringUserGraphData(patientId, null, week, "", "");
    settingForPrint(week, "", ""); //인쇄를 위해 store에 기간 저장
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bringCheckedPatient = useCallback(() => {
    axios
      .get(common.SERVER_URL + "/patient/pId/" + patientId, common.headers)
      .then((res) => setPatientInfo(res.data.patient))
      .catch((err) => console.log(err));
  }, [patientId]);

  useEffect(() => {
    if (!patientId) {
      history.push("/adminLogin");
    } else if (!checkedPatient) {
      history.push("/adminmain");
    } else if (patientId.indexOf("E") !== 0) {
      bringCheckedPatient();
      settingCycle(1);
    } else {
      bringCheckedPatient();
      settingWeek(3);
    }
  }, [
    bringCheckedPatient,
    checkedPatient,
    history,
    patientId,
    settingCycle,
    settingWeek,
  ]);

  const onSearchDate = (e: any) => {
    const { name, value } = e.target;
    setSearchDate({ ...searchDate, [name]: value });
  };

  const onOpenSearchData = () => {
    if (!searchDate.startD || !searchDate.endD) {
      toast.error("시작일과 종료일을 입력해주세요", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setLoading(true);
      bringUserAllData(patientId, "", "", searchDate.startD, searchDate.endD);
      bringUserGraphData(patientId, "", "", searchDate.startD, searchDate.endD);
      settingForPrint(0, searchDate.startD, searchDate.endD); //인쇄를 위해 store에 기간 저장
    }
  };

  const onOpenPatientInfoEdit = () =>
    setOpenPatientInfoEdit(!openPatientInfoEdit); //환자 정보 수정 버튼 클릭 후 오픈

  return (
    <>
      <div className="main-contents px-4 flex flex-col sm:gap-4 mb-8">
        <div className="bg-white p-4 rounded-lg">
          {/* 환자 정보 헤더 */}
          {patinetInfo && <InfoHeader checkedPatient={patinetInfo} />}
          {/* 환자 증상 요약 */}
          {patinetInfo && (
            <PatientInfo
              checkedPatient={patinetInfo}
              onOpenPatientInfoEdit={onOpenPatientInfoEdit}
            />
          )}
        </div>

        <main>
          {/* 날짜 세팅 */}
          <div className="flex flex-col flex-wrap gap-4 rounded-md py-2">
            <div className="flex justify-between">
              <div className="font-extrabold text-xl">
                증상 조회{" "}
                <span className="text-sm text-gray-400">
                  {patientId.indexOf("E") !== 0
                    ? "마지막 투약일 부터 오늘까지를 기본으로 조회합니다.(기본 1사이클)"
                    : "마지막 방문일 부터 오늘까지를 기본으로 조회합니다.(기본 3주)"}
                </span>
              </div>
              <div className="font-bold">
                {searchDate.endD
                  ? searchDate.startD + " ~ " + searchDate.endD
                  : cycleDate.startDate + " ~ " + cycleDate.endDate}
              </div>
            </div>

            <div className="flex justify-between items-center flex-wrap mb-4 p-4 border-2 border-gray-700">
              {patientId.indexOf("E") !== 0 ? (
                <div className="flex gap-4">
                  <button
                    onClick={() => settingCycle(1)}
                    className={
                      "border px-4 py-1 font-bold hover:shadow-lg " +
                      (cycleDate.type === 1
                        ? "border-gray-500 text-gray-700"
                        : "border-gray-50 text-gray-400")
                    }
                  >
                    1 사이클
                  </button>
                  <button
                    onClick={() => settingCycle(3)}
                    className={
                      "border px-4 py-1 font-bold hover:shadow-lg " +
                      (cycleDate.type === 3
                        ? "border-gray-500 text-gray-700"
                        : "border-gray-50 text-gray-400")
                    }
                  >
                    3 사이클
                  </button>
                </div>
              ) : (
                <div className="flex gap-4">
                  <button
                    onClick={() => settingWeek(3)}
                    className={
                      "border px-4 py-1 font-bold hover:shadow-lg " +
                      (weekDate.type === 3
                        ? "border-gray-500 text-gray-700"
                        : "border-gray-50 text-gray-400")
                    }
                  >
                    3 주
                  </button>
                  <button
                    onClick={() => settingWeek(6)}
                    className={
                      "border px-4 py-1 font-bold hover:shadow-lg " +
                      (weekDate.type === 6
                        ? "border-gray-500 text-gray-700"
                        : "border-gray-50 text-gray-400")
                    }
                  >
                    6 주
                  </button>
                </div>
              )}

              <div className="flex gap-4">
                <div
                  className={
                    "font-bold " +
                    (!searchDate.startD
                      ? "border-gray-50 text-gray-400"
                      : "border-gray-500 text-gray-700")
                  }
                >
                  <input
                    className="pl-2"
                    type="date"
                    max={todayFormal()}
                    name="startD"
                    value={searchDate.startD}
                    onChange={onSearchDate}
                  />
                </div>

                <div
                  className={
                    "font-bold " +
                    (!searchDate.startD
                      ? "border-gray-50 text-gray-400"
                      : "border-gray-500 text-gray-700")
                  }
                >
                  <input
                    className="pl-2"
                    type="date"
                    max={todayFormal()}
                    name="endD"
                    value={searchDate.endD}
                    onChange={onSearchDate}
                  />
                </div>

                <button
                  onClick={onOpenSearchData}
                  className={
                    "ml-2 px-6 py-1 rounded-md font-bold hover:shadow-lg " +
                    (!searchDate.endD
                      ? "bg-gray-point text-white "
                      : "bg-yellow-point text-gray-600")
                  }
                >
                  조회
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-6 text-sm md:text-base">
            <div className="grid xs:grid-cols-1 grid-cols-2 gap-4">
              {/* 많이 발생한 증상 카드 */}
              {frenquencyData && (
                <PercentCard
                  title={"많이 발생한 증상"}
                  frenquencyData={frenquencyData}
                  cycleDate={cycleDate}
                />
              )}

              {/* 심각한 grade 증상 */}
              {gradeData && (
                <GradeCard
                  title={"심각한 Grade 증상"}
                  gradeData={gradeData}
                  cycleDate={cycleDate}
                />
              )}
            </div>

            <div className="flex flex-col gap-4 bg-white p-6 rounded-md text-sm md:text-base">
              <div className="flex justify-between items-center">
                <div className="font-extrabold text-xl">
                  증상 트렌드
                  {/* <span className='font-bold text-sm text-gray-400'> (X: 입력없음)</span> */}
                </div>
                <div className="flex flex-col text-sm text-gray-400 font-bold">
                  {cycleDate.startDate + " ~ " + cycleDate.endDate}
                </div>
              </div>

              <div className="grid xs:grid-cols-1 grid-cols-2 gap-4 mb-4">
                {totalGradeData.length < 1 ? (
                  <div className="flex flex-col gap-4 border-t border-gray-400">
                    <p className="mt-4">입력된 데이터가 없습니다.</p>
                  </div>
                ) : patientId.indexOf("E") !== 0 ? (
                  <div className="flex flex-col gap-4 h-60 border-t border-gray-400">
                    <TotalGradeChart item={totalGradeData} />
                  </div>
                ) : null}

                {graphData &&
                  graphData.map((item: any, idx: number) => (
                    <div
                      key={idx}
                      id="trendChart"
                      className="flex flex-col gap-4 h-60 border-t border-gray-400"
                    >
                      <TrendCharts item={item} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </main>
      </div>
      {loading && <LoadingDialog checkedPatient={checkedPatient} />}
      {openPatientInfoEdit && (
        <PatientInfoEditDialog
          checkedPatient={checkedPatient}
          onOpenPatientInfoEdit={onOpenPatientInfoEdit}
          backToList={onOpenPatientInfoEdit}
          bringCheckedPatient={bringCheckedPatient}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default MainReport;
