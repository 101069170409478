import React from 'react';
import dayjs from 'dayjs';

const HighFrequencyCard = ({ frenquencyData, cycleDate }: any) => {

    return (
        <div className='flex flex-col gap-4 p-4 rounded-xl shadow bg-white'>
            <div className='flex flex-col items-center border-b pb-2'>
                <h1 className='font-bold'>많이 발생한 증상</h1>
                <span className='text-sm text-gray-400'>{cycleDate.startDate && dayjs(cycleDate.startDate).format("YYYY.MM.DD") + ' ~ ' + dayjs(cycleDate.endDate).format("YYYY.MM.DD")}</span>
            </div>
            {frenquencyData.length > 0 ? (frenquencyData.map(
                (item: any, idx: number) => (
                    <div key={idx} className='flex justify-between'>
                        <div className='w-1/2'>
                            <span className='w-5 h-5 inline-flex justify-center items-center bg-gray-300 rounded-full text-white text-xs mr-2'>{idx + 1}</span>
                            <span>{item.name}</span>
                        </div>
                        <div className="w-1/2 flex gap-1.5 items-center text-sm">
                            <strong className="w-9">{item.num}회</strong>
                            <div className="flex-grow h-3 flex bg-gray-200 rounded-full">
                                <div
                                    style={{ width: item.num + "%" }}
                                    className="shadow-none whitespace-nowrap text-white justify-center bg-yellow-point rounded-full"
                                ></div>
                            </div>
                            <div className="w-7 inline-block text-xs text-gray-400">{item.rate}%</div>
                        </div>
                    </div>
                ))) : ('발생한 증상 데이터가 없습니다.')}
        </div>
    );
};

export default HighFrequencyCard;