import React from "react";

const VideoDialog = ({ onCloseDialog }: any) => {
    return (
        <div className="z-30 h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-60">
            <div className="bg-white p-4 m-4">
                <div className="mb-2 flex justify-end items-center">
                    <span onClick={onCloseDialog}>
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            ></path>
                        </svg>
                    </span>
                </div>
                <video poster="images/poster.png" controls autoPlay loop playsInline>
                    <source src="images/삼성서울병원_0428.mp4" type="video/mp4" ></source>
                </video>
            </div>
        </div>
    );
};

export default VideoDialog;