import React from "react";

const TempIcon = ({ item, checkHandler }: any) => {
  return (
    <>
      <label
        key={item.id}
        className={"flex justify-between items-center px-1 py-2.5 border-2 border-gray-200 rounded-lg shadow-md bg-white"}>
        <div className="flex flex-grow-1 justify-center items-center w-6 h-6">
          <img src={item.bgIcon} alt={item.nameKo} />
          <input className="hidden" type="checkbox" value={item.id} onChange={(e) => checkHandler(e)} />
        </div>
        <div className="text-base ml-1">{item.nameKo}</div>

      </label>
    </>
  );
};

export default TempIcon;
