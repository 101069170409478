import React, { useContext } from "react";
import { bgColor, textColor } from "../../datas/stepColor";
import { SymptomToKo } from "../../datas/dataFilter";
import { SymptomToQuestion } from "../../datas/dataFilter";
import { SymptomToAnswer } from "../../datas/dataFilter";
import { MainContext } from "../../datas/Store";

const ChosenOneInput = ({ chosenOne }: any) => {
  const { userId } = useContext(MainContext);
  //   console.log(chosenOne, "choseOne");
  return (
    <div className="flex flex-col px-4 py-2 bg-gray-100">
      <h2 className="font-bold text-lg mb-2.5">입력 내용</h2>
      <div className="flex flex-col gap-4 p-2 pr-2.5 bg-white rounded-md border-l-8 border">
        {chosenOne.map((one: any, i: any) => (
          <div key={one.i} className="flex flex-col gap-1">
            <h6 className="font-bold">
              {i + 1}. {SymptomToKo(one[0], userId)}
            </h6>
            <h6 className="text-sm">{SymptomToQuestion(one[0], userId)}</h6>
            <ul className="flex flex-wrap gap-2 pb-2 border-b">
              <li
                className={
                  "text-sm px-3 py-1.5 rounded-full text-white" +
                  bgColor(Number(one[1])) +
                  textColor(Number(one[1]))
                }
              >
                {Number(one[1])} 단계
              </li>
              <li className="text-sm border border-gray-300 px-3 py-1.5 rounded-full">
                {SymptomToAnswer(one[0], Number(one[1]), userId)}
              </li>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChosenOneInput;
