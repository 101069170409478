import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { MainContext } from "../../../mobile/datas/Store";
import { SymptomToKo } from "../../../mobile/datas/dataFilter";

const DailyInputTableData = ({ row, idx }: any) => {
  const [filteredHistory, setFilteredHistory] = useState<any[]>([]);
  const [warnData, setWarnData] = useState<any[]>([]);
  const [emergencyData, setEmergencyData] = useState<any>([]);
  const [nullData, setNullData] = useState<any[]>([]);

  const { patientId } = useContext(MainContext);

  useEffect(() => {
    filteringHistoryData(row);
  }, [row]);

  const filteringHistoryData = (row: any) => {
    //히스토리 데이터 중 null, 0단계 제외
    const valueSymptom = Object.entries(row);
    const nullInput = valueSymptom.filter(
      (
        item //입력 안한 값 = null은 리스트에서 제외
      ) => (item[1] === null ? item : "")
    );
    setNullData(nullInput);
    const filtered = valueSymptom.filter((item) =>
      item[0] !== "id" &&
      item[1] !== null &&
      Number(item[1]) < 5 &&
      Number(item[1]) > 0
        ? item
        : ""
    );
    setFilteredHistory(filtered);
    const warn = valueSymptom.filter(
      (
        item //히스토리 데이터중 level 3 이상만
      ) =>
        item[0] !== "id" && Number(item[1]) >= 3 && Number(item[1]) < 5
          ? item
          : ""
    );
    setWarnData(warn);

    const alertData = [
      ["pain", 3],
      ["headache", 3],
      ["fever", 4],
      ["anorexia", 4],
      ["nausea", 4],
      ["vomiting", 4],
      ["diarrhea", 4],
      ["dyspnea", 4],
      ["cough", 3],
      ["depression", 4],
      ["hoarseness", 3],
      ["hemoptysis", 4],
    ];

    let arrayFilter: any = [];
    if (warn) {
      for (let i = 0; i < alertData.length; i++) {
        let filter = warn.filter((data: any) => {
          return data[0] === alertData[i][0] && data[1] === alertData[i][1];
        });
        arrayFilter.push(...filter);
      }
      let emergencyAlertData = arrayFilter.filter(
        (item: any) => item.length !== 0
      );
      setEmergencyData(emergencyAlertData);
    }
  };

  return (
    <>
      {nullData.length !== 21 ? (
        <tr className="border-b border-gray-200 hover:bg-gray-100 text-center text-sm">
          <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
            {dayjs(row.reportDay).format("YYYY.MM.DD")}
          </td>
          <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
            {row.type === "R" ? "정기입력" : "실시간 입력"}
          </td>
          <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-wrap p-3 text-left">
            {filteredHistory.map((one) => (
              <span className="mr-2" key={one[0]}>
                {" "}
                {SymptomToKo(one[0], patientId)}
                <span className="text-xs">({one[1]}단계)</span>
              </span>
            ))}
          </td>
          <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-wrap p-3 text-center">
            <span>
              {warnData.length
                ? warnData.length + "건 발생 : "
                : "위험 증상 없음"}
            </span>
            {warnData.map((one) => (
              <span className="mr-2" key={one[0]}>
                {" "}
                {SymptomToKo(one[0], patientId)}
              </span>
            ))}
          </td>
          {/* // ? 환자별 통계 Lazertinib사용자 위험증상 숨기기 */}
          {patientId.indexOf("E") !== 0 ? (
            <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-wrap p-3 text-center">
              <span>
                {emergencyData.length
                  ? emergencyData.length + "건 발생 : "
                  : "응급 증상 없음"}
              </span>
              {emergencyData.map((one: any) => (
                <span className="mr-2" key={one[0]}>
                  {" "}
                  {SymptomToKo(one[0], patientId)}
                </span>
              ))}
            </td>
          ) : null}
        </tr>
      ) : (
        <tr></tr>
      )}
    </>
  );
};

export default DailyInputTableData;
