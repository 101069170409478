import React from "react";

const BottomBtnBar = ({ title, openContent }: any) => {
  return (
    <div className={"fixed bottom-0 left-0 z-10 w-full flex-shrink-0 text-center transition-all transform duration-300"}>
      <div className="text-2xl bg-yellow-point py-4 font-extrabold text-gray-700" onClick={openContent}>
        {title}
      </div>
    </div>
  );
};

export default BottomBtnBar;
