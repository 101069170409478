import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import common from "../../datas/common";
import GuideData from "../../datas/GuideData";
import ResultHeader from "./ResultHeader";
import CompleteMsg from "./CompleteMsg";
import ResultTopContent from "./ResultTopContent";
import ResultBottomContent from "./ResultBottomContent";
import GuideHeader from "./GuideHeader";
import GuideDetails from "./GuideDetails";
import BottomMenuBar from "../Common/BottomMenuBar";

const Result = ({
  reportId,
  onReset,
  onBack,
  onBlockScroll,
  onScroll,
}: any) => {
  const prevIcon = { backgroundImage: "url('/images/arrow-left.svg')" };
  const closeIcon = { backgroundImage: "url('/images/homebar.svg')" };
  const [loading, setLoading] = useState(true);
  const [temp, setTemp] = useState(false);
  const [filteredLastData, setFilteredLastData] = useState([]);
  const [chosenGuide, setChosenGuide] = useState([]);
  const [warningData, setWarningData] = useState([]);
  const [filteredGuideData, setFilteredGuideData] = useState<any>([]);

  const bringResult = useCallback(() => {
    //user 마지막 저장된 데이터 가져오기
    axios
      .get(common.SERVER_URL + "/report/search/" + reportId, common.headers)
      .then((res) => {
        setLoading(false);

        let arrayLastData = Object.entries(res.data);

        let filterOne: any = [];
        let filteredLast = arrayLastData
          .filter((row) => {
            return Number(row[1]) >= 1 ? row : "";
          })
          .slice(1);
        filterOne.push(...filteredLast);

        let warn: any = [];
        let filterWarning = filterOne.filter((row: any) => {
          return Number(row[1]) >= 3 ? row : "";
        });
        warn.push(...filterWarning);
        setWarningData(warn);

        let keys: any = [];
        filterOne.map((item: any) => keys.push(item[0])); //발생한 증상의 가이드 필터링
        let filter: any = [];

        keys.forEach((item: any) => {
          let filteredGuide = GuideData.filter((row) => {
            return row.name === item;
          });
          filter.push(...filteredGuide);
        });
        setFilteredGuideData(filter);
        setFilteredLastData(filterOne.sort((a: any, b: any) => b[1] - a[1])); //높은 레벨부터 출력
        if (res.data.type === "IR") {
          setTemp(true);
        } else {
          setTemp(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reportId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    let timer = setTimeout(() => {
      bringResult();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [bringResult]);

  const [openGuide, setOpenGuide] = useState(false);
  const onOpenGuide = (guide: any) => {
    setOpenGuide(true);
    setChosenGuide(guide);
  };

  const onCloseGuide = () => {
    setOpenGuide(false);
  };

  return (
    <>
      {loading ? (
        <CompleteMsg />
      ) : !openGuide ? (
        <>
          <ResultHeader
            headTitle={"결과"}
            rightIcon={closeIcon}
            leftIcon={prevIcon}
            onBack={onBack}
          />
          <div className="flex flex-col mb-20 mt-14">
            <ResultTopContent
              filteredLastData={filteredLastData}
              temp={temp}
              warningData={warningData}
              onBlockScroll={onBlockScroll}
              onScroll={onScroll}
            />
            <ResultBottomContent
              onOpenGuide={onOpenGuide}
              filteredGuideData={filteredGuideData}
            />
          </div>
          <BottomMenuBar onReset={onReset} />
        </>
      ) : (
        <>
          <GuideHeader
            headTitle={"가이드 자세히 보기"}
            rightIcon={closeIcon}
            leftIcon={prevIcon}
            onBack={onCloseGuide}
          />
          <div className="flex flex-col gap-4 mb-20 mt-14">
            {chosenGuide && <GuideDetails chosenGuide={chosenGuide} />}
          </div>
          <BottomMenuBar onReset={onReset} />
        </>
      )}
    </>
  );
};

export default Result;
