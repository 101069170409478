import React from "react";
import Parser from 'html-react-parser';

const GuideDetailsDialog = ({ checkedGuide, onOpenGuideDetails }: any) => {

  const heightStyle = {
    maxHeight: "750px",
    overflow: "auto"
  }

  return (
    <>
      <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-60 z-10">
        <div className="bg-white rounded shadow-lg w-10/12 md:w-2/3 text-gray-500" style={heightStyle}>
          <div className="px-4 py-2 flex justify-end items-center">
            <span onClick={onOpenGuideDetails}>
              <svg
                className="w-6 h-6 cursor-pointer"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </span>
          </div>

          <div className='px-4 py-4 text-center'>
            <h2 className='mb-2 font-extrabold text-3xl'>[{checkedGuide.nameKo}] 증상 가이드</h2>
            <table className="items-center w-full border-collapse bg-white rounded-md">
              <tbody>
                <tr className='text-sm whitespace-nowrap align-middle'>
                  <th className="w-24 font-bold border p-3 border-l border-r-0">구분</th>
                  <td className={"border px-6 py-3 text-left"}>{checkedGuide.nameKo}</td>
                </tr>
                <tr className='text-sm whitespace-wrap align-middle'>
                  <th className="font-bold border p-3 border-l border-r-0">요약</th>
                  <td className={"border px-6 py-3 text-left"}>{Parser(checkedGuide.guideTitle)}</td>
                </tr>
                <tr className='text-sm whitespace-wrap align-middle'>
                  <th className="font-bold border p-3 border-l border-r-0">세부 내용</th>
                  <td className={"border px-6 py-3 text-left"}>{Parser(checkedGuide.guideContent)}</td>
                </tr>
                <tr className='text-sm whitespace-wrap align-middle'>
                  <th className="font-bold border p-3 border-l border-r-0">주의사항</th>
                  <td className={"border px-6 py-3 text-left"}>{Parser(checkedGuide.guideCautions)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex justify-center items-center py-4 px-8 gap-4">
            <button onClick={onOpenGuideDetails} className="bg-gray-point py-2 px-8 rounded text-white">
              목록보기
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuideDetailsDialog;
