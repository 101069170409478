import React, { useState, useEffect, useCallback, useContext } from "react";
import axios from "axios";
import common from "../../datas/common";
import ResultHeader from "./ResultHeader";
import InputRevise from "./InputRevise";
import {
  SymptomToKo,
  SymptomToAnswer,
  SymptomToSubI,
} from "../../datas/dataFilter";
import { MainContext } from "../../datas/Store";

// import formData from "../../datas/FormData";
// import formDataLazertinib from "../../datas/FormDataLazertinib";

const InputSummary = ({
  reportId,
  onOpenResult,
  onBlockScroll,
  onScroll,
}: any) => {
  const { userId } = useContext(MainContext);

  const closeIcon = { backgroundImage: "url('/images/homebar.svg')" };
  const [inputData, setInputData] = useState<any>([]);
  const [inputRevise, setInputRevise] = useState(false);
  const [reviseOne, setReviseOne] = useState<any>([]);
  const bringInput = useCallback(() => {
    //user 마지막 저장된 데이터 가져오기
    axios
      .get(common.SERVER_URL + "/report/search/" + reportId, common.headers)
      .then((res) => {
        // userId.indexOf("E") !== 0 ? :
        setTimeout(() => {
          let arrayLastData = Object.entries(res.data).filter((row) => {
            return (
              row[0] !== "id" && Number(row[1]) >= 0 && Number(row[1]) <= 4
            );
          });
          setInputData(arrayLastData);
          // SymptomToKo();
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reportId]);

  const onOpenInputRevise = (row: any, idx: number) => {
    // * 수정시 다수 질문에 첫 질문이 전혀없음인 경우 첫 질문 제외 다음 질문들은 수정 버튼 x 클릭 여부 x => 첫 질문 변경시 수정 버튼 활성화
    if (
      userId.indexOf("E") === 0 &&
      row[1] &&
      row[1] === 0 &&
      Number(SymptomToSubI(row[idx][0])) >= 2 &&
      row[idx - 1][1] === 0
    ) {
      setInputRevise(false);
    }
    // else if (
    //   inputData[1] &&
    //   inputData[idx][1] > 0 &&
    //   Number(SymptomToSubI(inputData[idx][0])) >= 2 &&
    //   inputData[idx - 1][1] === 0
    // ) {
    //   setInputRevise(false);
    //   console.log("2");
    // }
    else {
      setInputRevise(true);
    }
    setReviseOne(row);

    // if (
    //   inputData[1] &&
    //   inputData[idx][1] > 0 &&
    //   Number(SymptomToSubI(inputData[idx][0])) >= 2 &&
    //   inputData[idx - 1][1] === 0
    // ) {
    //   setReviseOne(inputData[idx - 1][1]);
    // }
    // onBlockScroll 실행시 스크롤이 고정되는데 불필요해 보여서 주석처리함
    // onBlockScroll();
    // console.log(SymptomToKo(row[0]), "SymptomToKo(row[0])");
  };
  const onCloseInputRevise = () => {
    setInputRevise(false);
    onScroll();
  };

  useEffect(() => {
    bringInput();
    bringInput();
  }, [bringInput]);
  return (
    <>
      <ResultHeader headTitle={"입력 내용 확인"} rightIcon={closeIcon} />
      <div className="flex flex-col gap-4 bg-gray-100 text-center p-4 mb-16">
        <h2 className="mt-14 font-bold px-4">
          방금 입력하신 증상이 맞는지 확인해 보세요.
        </h2>
        <div className="grid grid-cols-2 gap-3">
          {inputData.map(
            (row: any, idx: number) =>
              inputData[idx][1] !== null && (
                <div
                  key={idx}
                  onClick={
                    // * 수정시 다수 질문에 첫 질문이 전혀없음인 경우 첫 질문 제외 다음 질문들은 클릭 여부 x => 첫 질문 변경시 수정 버튼 활성화
                    userId.indexOf("E") === 0 &&
                    inputData[idx][1] === 0 &&
                    Number(SymptomToSubI(inputData[idx][0])) >= 2 &&
                    inputData[idx - 1][1] === 0
                      ? () => null
                      : () => onOpenInputRevise(row, idx)
                  }
                  className="flex flex-col space-y-4 justify-center items-center"
                >
                  <div className="bg-white w-full flex items-center p-2 rounded-xl shadow border">
                    <div className="w-full flex flex-col gap-1.5 text-left text-base">
                      <div className="flex justify-between font-bold text-gray-700">
                        <div className="flex justify-between items-center gap-2">
                          <span className="w-2 h-2 bg-gray-point inline-block rounded-full"></span>
                          <span>{SymptomToKo(row[0], userId)}</span>
                        </div>

                        {/* // * 수정시 다수 질문에 첫 질문이 전혀없음인 경우 첫 질문 제외 다음 질문들은 수정 버튼 x => 첫 질문 변경시 수정 버튼 활성화 */}
                        {/* {userId.indexOf("E") === 0 &&
                        inputData[idx][1] === 0 &&
                        Number(SymptomToSubI(inputData[idx][0])) >= 2 &&
                        inputData[idx - 1][1] === 0 ? null : ( //   inputData[idx - 1][1] === 0) //   Number(SymptomToSubI(inputData[idx][0])) >= 2 && //   inputData[idx][1] !== 0 && // (inputData[1] && //   ||
                          <span className="w-14 bg-gray-400 border inline-block rounded-xl text-center text-white place-self-end ">
                            수정2
                          </span>
                        )} */}
                      </div>
                      <div className="flex gap-2 items-center justify-between text-gray-500 overflow-ellipsis overflow-hidden">
                        <span className="w-2 h-2 flex-shrink-0  bg-yellow-point inline-block rounded-full"></span>
                        <span className="answerTitle overflow-ellipsis overflow-hidden mr-auto">
                          {/* {inputData[1] &&
                          inputData[idx][1] !== 0 &&
                          Number(SymptomToSubI(inputData[idx][0])) >= 2 &&
                          inputData[idx - 1][1] === 0
                            ? // (setReviseOne(inputData[idx - 1][1]),
                              (inputData[idx][1] = 0)
                            : // ) */}
                          {SymptomToAnswer(row[0], Number(row[1]), userId)}
                          {/* } */}
                        </span>
                        <div className="">
                          {/* // * 수정시 다수 질문에 첫 질문이 전혀없음인 경우 첫 질문 제외 다음 질문들은 수정 버튼 x => 첫 질문 변경시 수정 버튼 활성화 */}
                          {userId.indexOf("E") === 0 &&
                          inputData[idx][1] === 0 &&
                          Number(SymptomToSubI(inputData[idx][0])) >= 2 &&
                          inputData[idx - 1][1] === 0 ? null : ( //   inputData[idx - 1][1] === 0) //   Number(SymptomToSubI(inputData[idx][0])) >= 2 && //   inputData[idx][1] !== 0 && // (inputData[1] && //   ||
                            <span className="w-14 bg-gray-400 border inline-block rounded-xl text-center text-white ">
                              수정
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
        <p>모두 확인하였으며 증상 입력을 완료합니다. </p>
        <div
          className={
            "fixed bottom-0 left-0 z-10 w-full flex-shrink-0 text-center transition-all transform duration-300"
          }
        >
          <div
            className="text-2xl bg-yellow-point py-4 font-extrabold text-gray-700"
            onClick={onOpenResult}
          >
            결과 보기
          </div>
        </div>
      </div>
      {inputRevise && (
        <InputRevise
          reportId={reportId}
          reviseOne={reviseOne}
          onCloseInputRevise={onCloseInputRevise}
          bringInput={bringInput}
          onBlockScroll={onBlockScroll}
          onScroll={onScroll}
          // checkedAnswer={CheckedAnswer}
        />
      )}
    </>
  );
};

export default InputSummary;
