import React from "react";

const PreviousInputAlert = ({ title, subTitle, onOpenYes, onOpenNo, closeCheckAlert, onCheckInut }: any) => {
  return (
    <div className="z-30 h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-70">
      <div className="bg-white rounded shadow-lg w-10/12 md:w-1/3 text-gray-500">
        <div className="px-4 py-2 flex justify-end items-center">
          <span onClick={closeCheckAlert}>
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </span>
        </div>
        <div className='px-4 py-4 text-center'>
          {title}
        </div>
        <div onClick={onCheckInut} className='px-4 py-4 text-center font-bold'>
          {subTitle && subTitle}
        </div>
        <div className="flex justify-between items-center py-4 px-8 gap-4">
          <button onClick={onOpenNo} className="w-1/2 bg-gray-point py-2 rounded-md text-white">
            아니요
          </button>
          <button onClick={onOpenYes} className="w-1/2 bg-yellow-point py-2 rounded-md text-gray-600">
            네
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreviousInputAlert;
