import React, { useContext } from "react";
import Pagination from "react-js-pagination";
import { MainContext } from "../../../mobile/datas/Store";

const PagingForGuide = ({ totalCount, page }) => {
    const { handlePageChange, postPerPage } = useContext(MainContext);

    return (
        <Pagination
            activePage={page}
            itemsCountPerPage={postPerPage}
            totalItemsCount={totalCount ? totalCount : 0}
            pageRangeDisplayed={5}
            prevPageText={"<"}
            nextPageText={">"}
            onChange={handlePageChange} />);
};
export default PagingForGuide;