const formData = [
  {
    id: 1,
    name: "fatigue",
    nameKo: "피로감",
    title: "피로, 피곤함, 기운 없음의 정도",
    question: "지난 3일간 피로, 피곤함, 기운 없음의 정도가 어떠하였습니까?",
    meaning: "정신이나 몸이 지쳐 힘든 상태",
    answer: {
      0: "증상 없음",
      1: "있으나 쉬면 나아짐",
      2: "쉬어도 나아지지 않으며, 생활에 약간 지장",
      3: "쉬어도 나아지지 않으며, 누군가 도와줘야함",
    },
    bgIcon: "/images/fatigue.svg",
  },
  {
    id: 2,
    name: "insomnia",
    nameKo: "불면증",
    title: "불면증",
    question:
      "지난 3일간 불면증(잠들기 어려움, 자주 깸, 일찍 깸) 정도가 어떠하였습니까?",
    meaning: "수면을 이루지 못하는 일",
    answer: {
      0: "증상 없음",
      1: "약간 잠에 들기 어렵거나 새벽에 일어남",
      2: "보통 잠에 들기 어렵거나 새벽에 일어남",
      3: "거의 잠에 들기 어렵거나 새벽에 일어남",
    },
    bgIcon: "/images/insomnia.svg",
  },
  {
    id: 3,
    name: "pain",
    nameKo: "통증",
    title: "통증 (두통 외)",
    question: "지난 3일간 통증 (두통 외)이 발생하였습니까? ",
    meaning:
      "실제 통증이나 잠재적 조직손상 또는 그러한 손상으로 기술된 불쾌한 감각적이고 감정적인 경험",
    answer: {
      0: "증상 없음",
      1: "약간 통증이 있음",
      2: "통증으로 생활에 약간 지장",
      3: "심한 통증으로 일상생활 지장이 있어 누군가 도와줘야함",
    },
    bgIcon: "/images/pain.svg",
  },
  {
    id: 4,
    name: "headache",
    nameKo: "두통",
    title: "두통",
    question: "지난 3일간 두통이 발생하였습니까?",
    meaning: "머리 부분에 생기는 통증",
    answer: {
      0: "증상 없음",
      1: "약간 통증이 있음",
      2: "통증으로 생활에 약간 지장",
      3: "심한 통증으로 일상생활 지장이 있어 누군가 도와줘야함",
    },
    bgIcon: "/images/headache.svg",
  },
  {
    id: 5,
    name: "fever",
    nameKo: "발열",
    title: "발열",
    question: "지난 3일간 몸의 발열이 있었습니까?",
    meaning:
      "체온조절 중추기능에 변화가 일어나 보통 때보다 체온이 상승하는 현상",
    answer: {
      0: "증상 없음",
      1: "38도초과-39도이하",
      2: "39도초과-40도이하",
      3: "40도 넘는 열이 하루이내",
      4: "40도 넘는 열이 하루이상",
    },
    bgIcon: "/images/fever.svg",
  },
  {
    id: 6,
    name: "chills",
    nameKo: "떨림",
    title: "오한",
    question: "지난 3일간 몸이 춥고 떨림(오한)이 발생하였습니까?",
    meaning: "갑자기 몸에 열이 나면서 추위를 느끼는 증세",
    answer: {
      0: "증상 없음",
      1: "약간 춥거나 떨림",
      2: "온몸이 떨림",
      3: "심하게 온몸이 떨려서 약물로도 진정 안됨",
    },
    bgIcon: "/images/chills.svg",
  },
  {
    id: 7,
    name: "weightLoss",
    nameKo: "체중감소",
    title: "체중감소",
    question: "지난 3일간 체중감소가 있었습니까?",
    meaning: "일부러 체중감량을 하지 않았는데도 체중이 감소하는 경우",
    answer: {
      0: "증상 없음",
      1: "1kg 미만",
      2: "1kg ~ 2kg",
      3: "3kg 이상",
    },
    bgIcon: "/images/weightLoss.svg",
  },
  {
    id: 8,
    name: "anorexia",
    nameKo: "식욕감소",
    title: "식욕감소",
    question: "지난 3일간 식욕감소가 있었습니까?",
    meaning: "음식물을 먹고자 하는 욕구가 떨어지거나 없어진 상태",
    answer: {
      0: "증상 없음",
      1: "입맛은 없으나 식사량은 줄지 않았음",
      2: "식사량은 줄었으나 체중은 줄지 않음",
      3: "식사를 거의 하지 못하고 평소 체중보다 20% (50kg의 경우 10kg) 이상 체중감소",
      4: "식사를 하지 못하여서 죽을 것 같음",
    },
    bgIcon: "/images/anorexia.svg",
  },
  {
    id: 9,
    name: "nausea",
    nameKo: "메스꺼움",
    title: "매스꺼움, 구역",
    question: "지난 3일간 헛구역질, 매스꺼움, 구역감이 있었습니까?",
    meaning: "신체가 불편함을 느껴서 토할 것 같은 불쾌감",
    answer: {
      0: "증상 없음",
      1: "입맛은 없으나 식사량은 줄지 않았음",
      2: "식사량은 줄었으나 체중은 줄지 않음",
      3: "식사량 감소로 인한 5kg이상 체중감소",
      4: "식사량 감소로 인한 10kg 이상 체중감소",
    },
    bgIcon: "/images/nausea.svg",
  },
  {
    id: 10,
    name: "vomiting",
    nameKo: "구토",
    title: "음식을 토함, 구토",
    question: "지난 3일간 음식을 먹었을때 토하거나 하였습니까?",
    meaning:
      "위의 내용물이 식도와 구강을 거쳐 입 밖으로 갑자기 힘차게 나오는 현상",
    answer: {
      0: "증상 없음",
      1: "가끔 있으나 약물 필요하지 않음",
      2: "완화 약물이 필요한 정도",
      3: "구토로 인하여 아예 아무것도 못 먹겠음",
      4: "구토로 인하여 죽을 것 같음",
    },
    bgIcon: "/images/vomiting.svg",
  },
  {
    id: 11,
    name: "constipation",
    nameKo: "변비",
    title: "변을 보기 힘듦, 변비",
    question: "지난 3일간 변을 보기 힘들지 않았습니까?",
    meaning: "정상적으로 배변이 이루어지지 않는 증상",
    answer: {
      0: "증상 없음",
      1: "가끔씩 변비약 복용",
      2: "매일 변비약 복용",
      3: "손으로 파내야할 정도",
      4: "손으로 파내도 나오지 않음",
    },
    bgIcon: "/images/constipation.svg",
  },
  {
    id: 12,
    name: "diarrhea",
    nameKo: "설사",
    title: "무른 변을 보거나 설사",
    question: "지난 3일간 무른 변을 보거나 설사가 있었습니까?",
    meaning: "액상 또는 액상에 가까운 대변을 부정시에 배출하는 것",
    answer: {
      0: "증상 없음 (하루 0-1회)",
      1: "하루 1-3회, 물설사",
      2: "하루 4-6회",
      3: "하루 7회 이상",
      4: "입원이 필요한 정도",
    },
    bgIcon: "/images/diarrhea.svg",
  },
  {
    id: 13,
    name: "dyspnea",
    nameKo: "숨 참",
    title: "숨이 참, 호흡 곤란",
    question: "지난 3일간 숨이 차거나 호흡곤란이 있었습니까?",
    meaning: "주관적으로 숨을 쉬는데 불편함을 느끼는 상태",
    answer: {
      0: "증상 없음",
      1: "운동을 할때 숨이 찬 정도",
      2: "잠깐만 걸어도 숨이 참",
      3: "쉬고 있을때도 숨이 참",
      4: "숨이 참으로 인하여 죽을 것 같음",
    },
    bgIcon: "/images/dyspnea.svg",
  },
  {
    id: 14,
    name: "cough",
    nameKo: "기침",
    title: "기침",
    question: "지난 3일간 기침이 발생하였습니까?",
    meaning: "소리와 함께 폐포 속 공기를 기도로 갑작스럽게 내뿜는 반사 행동",
    answer: {
      0: "증상 없음",
      1: "약간 있으나 약물 필요하지 않음",
      2: "완화 약물이 필요한 정도",
      3: "기침이 심하여 아무것도 못하겠음",
    },
    bgIcon: "/images/cough.svg",
  },
  {
    id: 15,
    name: "rash",
    nameKo: "피부발진",
    title: "피부 발진",
    question: "지난 3일간 피부 발진(붉은 두드러기나 염증)이 있었습니까?",
    meaning: "피부나 점막에 돋아난 작은 종기",
    answer: {
      0: "증상 없음",
      1: "손바닥정도 영역",
      2: "손바닥을 포함한 팔전체 정도 영역",
      3: "상반신 혹은 그 이상의 영역",
    },
    bgIcon: "/images/rash.svg",
  },
  {
    id: 16,
    name: "drySkin",
    nameKo: "가려움증",
    title: "피부 건조나 가려움증",
    question: "지난 3일간 피부 건조나 가려움증이 발생하였습니까?",
    meaning: "피부에 수분이 부족한 상태",
    answer: {
      0: "증상 없음",
      1: "손바닥정도 영역",
      2: "손바닥을 포함한 팔전체 정도 영역",
      3: "상반신 혹은 그 이상의 영역",
    },
    bgIcon: "/images/drySkin.svg",
  },
  {
    id: 17,
    name: "depression",
    nameKo: "우울함",
    title: "슬픔이나 우울함",
    question: "지난 3일간 슬픔이나 우울함을 느꼈습니까?",
    meaning: "근심스럽거나 답답하여 활기가 없는 상태",
    answer: {
      0: "증상 없음",
      1: "약간 우울한 기분이 든다",
      2: "우울증 때문에 밖에 나가기 싫음",
      3: "우울증 때문에 집 안에서 움직이고 싶지 않음",
      4: "자살 생각이 있음",
    },
    bgIcon: "/images/depression.svg",
  },
  {
    id: 18,
    name: "faceswelling",
    nameKo: "얼굴붓기",
    title: "얼굴 붓기",
    question: "지난 3일간 얼굴이 부었습니까?",
    meaning: "얼굴 부은 상태",
    answer: {
      0: "증상 없음",
      1: "얼굴이 눈에 띄게 부음",
    },
    bgIcon: "/images/faceswelling.svg",
  },
  {
    id: 19,
    name: "lumpskin",
    nameKo: "피부 혹",
    title: "피부 덩어리",
    question: "지난 3일간 피부 아래 만져지는 덩어리가 발생하였습니까?",
    meaning: "피부 아래 눈에 띄게 덩어리가 잡히거나 불편한 상태",
    answer: {
      0: "증상 없음",
      1: "피부 아래에 만져지는 덩어리가 생겼음",
    },
    bgIcon: "/images/lumpskin.svg",
  },
  {
    id: 20,
    name: "hoarseness",
    nameKo: "목소리",
    title: "목소리 변화",
    question: "지난 3일간 목소리 변화가 있습니까?",
    meaning: "목구멍에서 나는 소리가 평소와 상이한 상태",
    answer: {
      0: "증상 없음",
      1: "약간 음성이 변했으나 대화 가능",
      2: "발음하기가 어려워 여러번 단어를 반복함",
      3: "목소리가 아예 나오지 않음",
    },
    bgIcon: "/images/hoarseness.svg",
  },
  {
    id: 21,
    name: "hemoptysis",
    nameKo: "피를토함",
    title: "피를 토함(객혈)",
    question: "지난 3일간 피를 토한 적이 있습니까?",
    meaning: "피나 피가 섞인 가래를 기침과 함께 뱉어 내는 것",
    answer: {
      0: "증상 없음",
      1: "가끔 객담에 피가 묻어나옴",
      2: "하루 소주컵 1컵 이상 객혈",
      3: "하루 종이컵 1컵 이상 객혈, 어지러움/창백/두통 증상 동반",
      4: "피를 토함으로 인하여 죽을 것 같음",
    },
    bgIcon: "/images/hemoptysis.svg",
  },
];

export default formData;
