import React, { useState, useRef, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { MainContext } from "../datas/Store";
import axios from "axios";
import common from "../datas/common";
import RegularHeader from "../components/RegularType/RegularHeader";
import BottomMenuBar from "../components/Common/BottomMenuBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PasswordChange = () => {
  const { userId } = useContext(MainContext);
  const history = useHistory();
  const refPass1 = useRef<any>();
  const refPass2 = useRef<any>();
  const homeIcon = { backgroundImage: "url('/images/homebold.svg')" };
  const prevIcon = { backgroundImage: "url('/images/arrow-left.svg')" };
  const [passChange, setPassChange] = useState({ pass: "", repass: "" });

  useEffect(() => {
    if (!userId) {
      history.push(`/mobilelogin`);
    } else {
      refPass1.current.focus();
    }
  }, [userId, history]);

  const handleOnChange = (e: any) => {
    e.preventDefault();
    setPassChange({ ...passChange, [e.target.name]: e.target.value });
  };

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    if (!passChange.pass) {
      toast.error("비밀번호를 입력하세요", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
      setPassChange({ pass: "", repass: "" });
    } else if (!passChange.repass) {
      toast.error("비밀번호 확인을 입력하세요", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
      setPassChange({ pass: "", repass: "" });
    } else if (passChange.pass !== passChange.repass) {
      toast.error("비밀번호가 일치하지 않습니다.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
      setPassChange({ pass: "", repass: "" });
    } else {
      axios
        .post(
          common.SERVER_URL + "/patient/changePassword",
          { patientId: userId, pass: passChange.pass },
          common.headers
        )
        .then((res) => {
          if (res.data.ok) {
            toast.success("비밀번호가 수정되었습니다.", {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.error, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          setPassChange({ pass: "", repass: "" });
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <div className="max-w-md h-screen flex flex-col justify-between items-center mx-auto bg-gray-100">
        <div className="w-full flex-shrink-0 flex flex-col justify-between text-gray-600">
          <RegularHeader
            headerTitle={"비밀번호 변경"}
            rightIcon={homeIcon}
            leftIcon={prevIcon}
          />

          <form
            onSubmit={onSubmitForm}
            className="flex flex-col gap-4 mt-14 p-4"
          >
            <div className="flex flex-col gap-2">
              <label htmlFor="pass">새 비밀번호 입력</label>
              <input
                ref={refPass1}
                type="password"
                name="pass"
                value={passChange.pass}
                className="w-full px-4 py-2 border rounded mb-2 font-mono"
                onChange={handleOnChange}
                placeholder="새 비밀번호 입력"
                autoComplete="on"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="repass">비밀번호 확인</label>
              <input
                ref={refPass2}
                type="password"
                name="repass"
                value={passChange.repass}
                className="w-full px-4 py-2 border rounded mb-2 font-mono"
                onChange={handleOnChange}
                placeholder="비밀번호 확인"
                autoComplete="on"
              />
            </div>
            <button
              type="submit"
              className="text-2xl bg-yellow-point py-4 font-extrabold text-gray-700 text-center"
            >
              저장
            </button>
          </form>
        </div>
        <BottomMenuBar />
      </div>
      <ToastContainer />
    </>
  );
};

export default PasswordChange;
