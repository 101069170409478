import React, { useContext } from "react";
import { SymptomToKo } from "../../datas/dataFilter";
import { MainContext } from "../../datas/Store";

const ChosenOneNone = ({ noneSymptom }: any) => {
  const { userId } = useContext(MainContext);

  // * 값이 null이 아닌 값들만 새 배열로 만듬
  const NoneName = noneSymptom.filter((row: any) => row[1] !== null);
  //   let symptomName = filterName.map((item) => item.nameKo);
  //   return symptomName;

  return (
    <div className="flex flex-col gap-2 px-4 py-2 bg-gray-100">
      <h2 className="font-bold">증상 없음</h2>
      <div className="flex gap-2 flex-wrap p-2 pr-2.5 bg-white rounded-md border-l-8 border">
        {NoneName.length > 0 ? (
          NoneName.map((one: any, i: any) => (
            <div key={one.i} className="text-sm">
              <span className="mx-1"> {SymptomToKo(one[0], userId)}</span>
            </div>
          ))
        ) : (
          <div className="text-sm">증상 없음 데이터가 없습니다.</div>
        )}
      </div>
    </div>
  );
};

export default ChosenOneNone;
