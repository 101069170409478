import React from 'react';
import { ResponsiveLine } from "@nivo/line";

const TrendCharts = ({ item }) => {
    const data = [
        {
            "id": item.name,
            "data": item.dateGrade
                .map(one => {
                    return {
                        x: one.dateStr.slice(5, 10),
                        y: one.grade,
                    }
                }),
        }
    ];

    let jump = 1
    if (item != null && item.dateGrade != null && item.dateGrade.length > 0) {
        jump = parseInt((item.dateGrade.length - 1) / 8) + 1
    }
    let tickValues = []
    for (let i = 0; i < item.dateGrade.length; i += jump) {
        tickValues.push(item.dateGrade[i].dateStr.slice(5, 10))
    }

    return (
        <>
            <h6 className='text-sm font-bold mt-2'>{item.name}</h6>
            <ResponsiveLine
                data={data}
                margin={{ top: 10, right: 15, bottom: 35, left: 35 }}
                xScale={{ type: 'point' }}
                yScale={{ type: 'linear', min: 0, max: 4, stacked: true, reverse: false }}
                yFormat=" >-0.2f"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    orient: 'bottom',
                    tickSize: 0,
                    tickPadding: 10,
                    tickRotation: 0,
                    tickValues: tickValues,
                    legend: '',
                    legendOffset: 36,
                    legendPosition: 'middle'
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 0,
                    tickPadding: 7,
                    tickRotation: 0,
                    legend: 'grade',
                    legendOffset: -30,
                    legendPosition: 'end',
                    tickValues: 5,
                    format: e => Math.floor(e) === e && e

                }}
                colors="#F6CB44"
                gridYValues={5}
                pointSize={10}
                pointColor="#F6CB44"
                pointBorderWidth={2}
                pointBorderColor="#F6CB44"
                pointLabelYOffset={-12}
                crosshairType="top-right"
                useMesh={true}
                legends={[]}
                tooltip={({ point }) => {
                    return (
                        <div className="bg-white px-4 border rounded-md shadow">
                            <div>{point.data.x}</div>
                            <strong>{point.data.y}</strong>
                        </div>
                    )
                }}
            />
        </>
    );
};

export default TrendCharts;