import React, { useContext } from "react";
import RegularHeader from "./RegularHeader";
import ButtomBtnBar from "../Common/BottomBtnBar";
import { MainContext } from "../../datas/Store";

const RegularGuide = ({ openRegularContent }: any) => {
  const closeIcon = { backgroundImage: "url('/images/close.svg')" };
  const guideIcon1 = { backgroundImage: "url('/images/call911.svg')" };
  const guideIcon2 = { backgroundImage: "url('/images/doctor.svg')" };
  const guideIcon3 = { backgroundImage: "url('/images/appointment.svg')" };
  const { userId } = useContext(MainContext);

  return (
    <div className="max-w-md h-screen flex flex-col justify-between items-center bg-gray-100">
      <div className="w-full flex-shrink-0 flex flex-col justify-between text-gray-600">
        <RegularHeader headerTitle={"정기입력 안내"} rightIcon={closeIcon} />
        <p className="font26 text-gray-600 font-extrabold py-4 px-8 bg-white mt-14">
          증상 질문에 모두 답하시고 나에게 맞는 증상 가이드를 확인하세요.
        </p>

        <ul className="flex flex-col gap-5 bg-gray-100 px-4 py-4">
          <li>
            <ul className="flex items-center gap-4 bg-white rounded-xl px-2 py-4 shadow">
              <li
                className="flex flex-shrink-0 justify-center items-center w-10 h-10 bg-contain"
                style={guideIcon1}
              ></li>
              <li>
                <div className="font-extrabold text-red-600">
                  생명이 위험할 경우 119
                </div>
                <p className="font15">
                  위급한 증상일 경우에는 119에 즉시 전화하세요.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <ul className="flex items-center gap-4 bg-white rounded-xl px-2 py-4 shadow">
              <li
                className="flex flex-shrink-0 justify-center items-center w-10 h-10 bg-contain"
                style={guideIcon2}
              ></li>
              <li>
                <div className="font-extrabold">
                  입력하신 내용은 담당 의료진에게 전달됩니다.
                </div>
              </li>
            </ul>
          </li>
          <li>
            <ul className="flex items-center gap-4 bg-white rounded-xl px-2 py-4 shadow">
              <li
                className="flex flex-shrink-0 justify-center items-center w-10 h-10 bg-contain"
                style={guideIcon3}
              ></li>
              <li>
                <div className="font-extrabold">
                  없는 증상도 답을 해야하나요?
                </div>
                <p className="font15">
                  증상이 없다면 ‘없음’도 꼭 선택해주세요. 더 정확한 증상
                  모니터링을 하기 위함입니다.
                </p>
              </li>
            </ul>
          </li>
          {/* LT 사용자는 숨기기 */}
          {userId.indexOf("E") !== 0 ? (
            <li className="text-center font-bold">
              문항 : 총 21개 / 예상 소요시간 : 약 4분
            </li>
          ) : (
            <li className="text-center font-bold">
              총 43 문항 / 예상 소요시간 : 약 4분
            </li>
          )}
        </ul>
        <ButtomBtnBar title={"시작할게요"} openContent={openRegularContent} />
      </div>
    </div>
  );
};

export default RegularGuide;
