import React from 'react';
import { Link } from 'react-router-dom';

const GuideHeader = ({ leftIcon, headTitle, rightIcon, onBack }: any) => {
    return (
        <div className="fixed top-0 left-0 w-full z-10 py-4 px-3 bg-white">
            <div className="flex justify-between items-center ">
                {leftIcon ? (
                    <span onClick={onBack} className="w-6 h-6 bg-cover" style={leftIcon}></span>
                ) : (
                    <span className="w-6"></span>
                )}

                <div className="font-semibold text-gray-600">{headTitle}</div>

                {rightIcon ? (
                    <Link to='/intro' className="w-6 h-6 bg-cover" style={rightIcon}></Link>
                ) : (
                    <span className="w-6"></span>
                )}
            </div>
        </div>
    );
};

export default GuideHeader;