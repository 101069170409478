import React, { useState, useContext, useEffect } from 'react';
import { MainContext } from "../../../mobile/datas/Store";
import ContentHeader from '../Common/ContentHeader';
import GuideData from '../../../mobile/datas/GuideData';
import GuideTableData from './GuideTableList';
import GuideDetailsDialog from './GuideDetailsDialog';
import PagingForGuide from '../Common/PagingForGuide';

const GuideList = () => {
    const { page, postPerPage, checkedGuide } = useContext(MainContext);
    const [guideData, setGuideData] = useState<any[]>([])
    const [openGuideDialog, setOpenGuideDialog] = useState(false);
    const [totalCount, setTotalCount] = useState(0);

    const bringGuideData = () => {
        let GuideDataCopy = GuideData.slice(); //백업용 복사
        setGuideData(GuideDataCopy);
        setTotalCount(GuideDataCopy.length)
    }

    useEffect(() => {
        bringGuideData();
    }, []);


    const onOpenGuideDetails = () => {
        setOpenGuideDialog(!openGuideDialog);
    }

    //현재 페이지 가져오기 
    const indexOfLastPost = page * postPerPage;
    const indexOfFirstPost = indexOfLastPost - postPerPage;
    const currentPosts = guideData.slice(indexOfFirstPost, indexOfLastPost); //0~10번까지 포스트


    return (
        <>
            <ContentHeader headerTitle={'증상 가이드 목록'} />
            <div className='grid grid-cols-1 px-4'>
                <div className={"flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-md border border-gray-50 bg-white text-center"}>
                    <div className="block w-full overflow-x-auto">

                        <table className="w-full bg-transparent border-collapse">
                            <thead>
                                <tr className='font-extrabold text-sm whitespace-nowrap align-middle'>
                                    <th className={"border border-solid px-6 py-3 border-l-0 border-r-0"}>
                                        <div className='flex gap-1 justify-center items-center'>
                                            <span>No.</span>
                                        </div>
                                    </th>
                                    <th className={"border border-solid px-6 py-3 border-l-0 border-r-0"}>
                                        <div className='flex gap-1 justify-center items-center'>
                                            <span>증상</span>
                                        </div>
                                    </th>
                                    <th className={"border border-solid px-6 py-3 border-l-0 border-r-0"}>
                                        <div className='flex flex-wrap gap-1 justify-center items-center'>
                                            <span>요약 가이드</span>
                                        </div>
                                    </th>
                                    <th className={"border border-solid px-6 py-3 border-l-0 border-r-0"}>
                                        <div className='flex gap-1 justify-center items-center'>
                                            <span>자세히 보기</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentPosts && currentPosts.map((data: any, index: number) => (<GuideTableData key={data.id} data={data} onOpenGuideDetails={onOpenGuideDetails} />))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <PagingForGuide totalCount={totalCount} page={page} />
                {openGuideDialog && <GuideDetailsDialog checkedGuide={checkedGuide} onOpenGuideDetails={onOpenGuideDetails} />}
            </div>
        </>
    );
};

export default GuideList;