import React from "react";

const LoadingDialog = ({ checkedPatient }: any) => {

  return (
    <>
      <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-70 z-10">
        <div className="mx-auto">
          <div className=" flex justify-center items-center mb-16">
            <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-36 w-36"></div>
          </div>
          <p className="text-2xl font-extrabold text-white">
            {checkedPatient.patientId}님의 데이터를 분석 중입니다.<br />
            잠시만 기다려 주세요.
          </p>
        </div>
      </div>
    </>
  );
};

export default LoadingDialog;
