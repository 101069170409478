import React, { useContext } from "react";
import Parser from "html-react-parser";
import { MainContext } from "../../datas/Store";

const ResultBottomContent = ({ onOpenGuide, filteredGuideData }: any) => {
  const { userId } = useContext(MainContext);

  return (
    // LT 사용자는 숨기기
    <div className="flex flex-col gap-3 px-4 mt-6 text-gray-600 mb-14">
      {userId.indexOf("E") !== 0 ? (
        <div className="bg-lightyellow-point font-bold py-2 px-4 rounded-3xl">
          증상 가이드
        </div>
      ) : null}

      {userId.indexOf("E") !== 0 ? (
        <div className="flex flex-col gap-4">
          {filteredGuideData.length > 0
            ? filteredGuideData.map((guide: any) => (
                <div
                  key={guide.id}
                  className="flex flex-col gap-2 justify-between"
                >
                  <h2>
                    <img
                      src="/images/dot.svg"
                      alt="dot"
                      className="inline mr-2 font-bold"
                    />
                    {guide.nameKo}
                  </h2>
                  <p className="text-sm">{Parser(guide.guideTitle)}</p>
                  {guide.guideContent && (
                    <button
                      onClick={() => onOpenGuide(guide)}
                      className="block mt-2 p-2 rounded-md border-gray-300 border-2 hover:text-gray-700 hover:bg-lightyellow-point hover:border-gray-700 hover:font-extrabold"
                    >
                      자세히 보기
                    </button>
                  )}
                </div>
              ))
            : "증상 가이드가 없습니다."}
        </div>
      ) : null}
    </div>
  );
};

export default ResultBottomContent;
