import React, { useState, useContext, useEffect } from 'react';
import { MainContext } from "../../mobile/datas/Store";
import axios from "axios";
import common from "../../mobile/datas/common";
import { useHistory } from "react-router-dom";
import Sidebar from '../components/Common/Sidebar';
import Navbar from '../components/Common/Navbar';
import SubNavbar from '../components/Common/SubNavbar';
import ContentHeader from '../components/Common/ContentHeader';
import MainStatistics from '../components/Patient/MainStatistics';
import AlertDialog from "../components/Common/AlertDialog";
import AdminInfoDialog from "../components/Authority/AdminInfoDialog";

const PatientStatistics = () => {
    const { adminId, checkedPatient } = useContext(MainContext);
    const history = useHistory();
    const alertNoneIcon = { backgroundImage: "url('/images/alert-none.svg')" };
    const alertTakenIcon = { backgroundImage: "url('/images/alert-notice.svg')" };
    const arrowDownIcon = { backgroundImage: "url('/images/arrow-yellow-down.svg')" };
    const lockIcon = { backgroundImage: "url('/images/lock.svg')" };
    const unlockIcon = { backgroundImage: "url('/images/unlock.svg')" };
    const [openAlert, setOpenAlert] = useState(false);
    const [alarmData, setAlarmData] = useState([]);
    const [openAdminInfoAlert, setOpenAdminInfoAlert] = useState(false);

    const onOpenAlert = () => {
        setOpenAlert(!openAlert);
    }

    const onOpenAdminInfoAlert = () => {
        setOpenAdminInfoAlert(!openAdminInfoAlert);
    }

    const bringAlarmData = () => {
        axios.get(common.SERVER_URL + "/report/alarm", common.headers)
            .then(res => {
                if (res.data) {
                    setAlarmData(res.data.alarmList);
                }
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        if (!adminId || !checkedPatient) {
            history.push(`/adminlogin`);
        } else {
            bringAlarmData();
        }
    }, [adminId, checkedPatient, history])

    return (
        <>
            <div className='flex h-screen antialiased text-gray-600 bg-gray-100'>
                <Sidebar />

                <div className='flex-1 h-full overflow-x-hidden overflow-y-auto'>
                    {/* navbar */}
                    <header className='relative bg-gray-point h-12 flex flex-col md:h-28'>
                        <Navbar alertIcon={alarmData.length > 0 ? alertTakenIcon : alertNoneIcon}
                            arrowDownIcon={arrowDownIcon}
                            lockIcon={lockIcon}
                            unlockIcon={unlockIcon}
                            onOpenAlert={onOpenAlert}
                            onOpenAdminInfoAlert={onOpenAdminInfoAlert} />
                        <SubNavbar />
                    </header>

                    {/* main content */}
                    <main>
                        {/* content header */}
                        <ContentHeader headerTitle={'환자별 통계 내용'} />
                        {/* content */}
                        <MainStatistics />
                    </main>
                </div>
            </div>

            <AlertDialog
                openAlert={openAlert}
                onOpenAlert={onOpenAlert}
                alarmData={alarmData}
            />

            {openAdminInfoAlert &&
                <AdminInfoDialog
                    onOpenAdminInfoAlert={onOpenAdminInfoAlert}
                    adminId={adminId}
                />
            }
        </>
    );
};

export default PatientStatistics;