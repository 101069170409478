import React, { useState, useEffect, useContext, useCallback } from "react";
import axios from "axios";
import common from "../datas/common";
import { useHistory } from "react-router-dom";
import { MainContext } from "../datas/Store";
import RegularHeader from "../components/RegularType/RegularHeader";
import ButtomMenuBar from "../components/Common/BottomMenuBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Member = () => {
  const { userId } = useContext(MainContext);
  const history = useHistory();
  const prevIcon = { backgroundImage: "url('/images/arrow-left.svg')" };
  const closeIcon = { backgroundImage: "url('/images/homebar.svg')" };
  const [userInfo, setUserInfo] = useState<any>({});
  const [treatmentHistory, setTreatmentHistory] = useState<any>([]);
  const [vistCard, setVisitCard] = useState(false);
  const [scrollToBlock, setScrollToBlock] = useState(false);

  const bringPatientInfo = useCallback(() => {
    axios
      .get(common.SERVER_URL + "/patient/pId/" + userId, common.headers)
      .then((res) => {
        setUserInfo(res.data.patient);
        setTreatmentHistory(res.data.treatmentList);
      })
      .catch((err) => console.log(err));
  }, [userId]);

  useEffect(() => {
    if (!userId) {
      history.push(`/mobilelogin`);
    } else {
      window.scrollTo(0, 0);
      bringPatientInfo();
    }
  }, [userId, history, bringPatientInfo]);

  const onOpenSetting = () => {
    history.push(`/setting`);
  };

  const onOpenPasswordChange = () => {
    history.push(`/pw`);
  };

  const onOpenVisitCard = () => {
    setVisitCard(!vistCard);
  };

  const onDeleteVisitDay = (day: any) => {
    //투약일이 삭제
    let params = {
      patientId: userId,
      visitDay: day,
    };
    axios
      .post(
        common.SERVER_URL + "/patient/treatmentDelete",
        params,
        common.headers
      )
      .then((res) => {
        if (res.data.ok) {
          toast.success("투약일이 삭제 되었습니다.", {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
          bringPatientInfo();
        } else {
          toast.error(res.data.error, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const medicineToKo = (medicine: any) => {
    let nameKo = "";
    switch (medicine) {
      case "pembrolizumab":
        nameKo = "키트루다";
        break;
      case "atezolizumab":
        nameKo = "티쎈트릭";
        break;
      case "nivolumab":
        nameKo = "옵디보";
        break;
      case "docetaxel":
        nameKo = "도세탁셀";
        break;
    }
    return nameKo;
  };

  const onBlockScroll = () => {
    setScrollToBlock(true);
  };

  const onScroll = () => {
    setScrollToBlock(false);
  };

  return (
    <>
      <div
        className={
          "max-w-md h-screen bg-gray-100 flex flex-col justify-between items-center mx-auto" +
          (scrollToBlock ? "fixed w-full h-full overflow-hidden" : "")
        }
      >
        <div className="w-full flex-shrink-0 flex flex-col justify-between text-gray-600">
          <RegularHeader
            headerTitle={"내정보"}
            leftIcon={prevIcon}
            rightIcon={closeIcon}
          />

          {userInfo && (
            <>
              <div className="flex flex-col gap-4 p-4 bg-gray-100 mb-20 mt-14">
                <div className="shadow-lg rounded-xl bg-white">
                  <div className="flex flex-col gap-4 justify-center items-center justify-center p-4">
                    <div className="flex gap-4 justify-center items-center">
                      <span className="block relative">
                        <img
                          alt="profil"
                          src="/images/avatar.png"
                          className="mx-auto object-cover rounded-full h-12 w-12"
                        />
                      </span>
                      <h1 className="text-gray-800 text-xl text-center">
                        {userInfo.patientId}
                        <p className="text-gray-400 text-xs">
                          ({userInfo.birth})
                        </p>
                      </h1>
                    </div>

                    <div className="flex justify-between gap-4">
                      <p className="text-xs p-1.5 bg-red-point text-white px-4 rounded-full">
                        {userInfo.doctorName} 교수님
                      </p>
                      {/* <p
                        onClick={onOpenPasswordChange}
                        className="text-xs p-1.5 bg-gray-point text-white px-4 rounded-full"
                      >
                        비밀번호 변경
                      </p> */}
                      {/* <div className="flex justify-center"> */}
                      {/* <p
                        onClick={onOpenSetting}
                        className="text-xs p-1.5 bg-gray-point text-white px-4 rounded-full"
                      >
                        설 정
                      </p> */}
                      {/* </div> */}
                    </div>

                    <div className="rounded-lg px-6 w-full mt-4">
                      <div className="flex items-center justify-between text-gray-600">
                        <p className="flex flex-col">
                          <span className="font-bold">성별</span>
                          <span className="text-center">
                            {userInfo.gender === "M" ? "남자" : "여자"}
                          </span>
                        </p>
                        <p className="flex flex-col">
                          <span className="font-bold">체중</span>
                          <span className="text-center">
                            {userInfo.weight}kg
                          </span>
                        </p>
                        <p className="flex flex-col">
                          <span className="font-bold">키</span>
                          <span className="text-center">
                            {userInfo.height}cm
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-4 bg-white rounded-xl shadow-md">
                  <h2 className="flex items-center gap-0.5 mb-2">
                    <span className="w-4 h-4 inline-block rounded-full bg-blue-point mr-2"></span>
                    <span className="font-bold">치료정보</span>
                  </h2>
                  <div className="flex flex-col gap-4">
                    <ul className="flex space-x-4 px-2 border-b">
                      <li>병명</li>
                      <li>{userInfo.disease}</li>
                    </ul>
                    <ul className="flex space-x-4 px-2 border-b">
                      <li>항암제</li>
                      <li>
                        {userInfo.medicine &&
                          medicineToKo(userInfo.medicine) + userInfo.medicine}
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="p-4 bg-white rounded-xl shadow-md">
                  <h2
                    onClick={onOpenVisitCard}
                    className="flex justify-between gap-0.5 mb-2"
                  >
                    <div className="flex items-center">
                      <span className="w-4 h-4 inline-block rounded-full bg-blue-point mr-2"></span>
                      <span className="font-bold">
                        {/* Lazertinib은 방문일이 맞는 표현 */}
                        {userId.indexOf("E") !== 0 ? "투약일" : "병원 방문일"}
                      </span>
                    </div>
                    <span>
                      <span>더보기</span>
                      <svg
                        className={
                          "inline ml-1.5 fill-current text-red-600 h-7 w-7 transform transition-transform duration-500 " +
                          (vistCard ? "rotate-180" : "")
                        }
                        viewBox="0 0 20 20"
                      >
                        <path d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                      </svg>
                    </span>
                  </h2>
                  <ul className="flex justify-between space-x-4 px-2 border-b mb-2">
                    <li>
                      {userId.indexOf("E") !== 0
                        ? "마지막 투약일"
                        : "마지막 방문일"}
                    </li>
                    <li>{userInfo.lastTreatment}</li>
                  </ul>

                  <div
                    className={
                      "overflow-hidden max-h-0 duration-500 transition-all " +
                      (vistCard ? "max-h-full" : "")
                    }
                  >
                    <div className="flex grid grid-cols-2 gap-2">
                      {treatmentHistory &&
                        treatmentHistory.map((date: any, idx: number) => (
                          <ul
                            key={idx}
                            onClick={() => onDeleteVisitDay(date.visitDay)}
                            className="flex justify-between shadow-md py-3 px-2 rounded-md"
                          >
                            <li>{date.visitDay}</li>
                            <li className="w-6">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </li>
                          </ul>
                        ))}
                    </div>
                  </div>
                  {/* <div className="flex justify-center">
                    <span
                      onClick={onOpenSetting}
                      className="hiddenBtn w-10 h-1 inline-flex"
                    ></span>
                  </div> */}
                </div>

                <div className="flex justify-around">
                  <img
                    src="/images/herings_app_logo.svg"
                    width="127"
                    alt="herings logo"
                  />
                  <img
                    src="/images/samsung_logo.svg"
                    width="81"
                    alt="herings logo"
                  />
                </div>
              </div>
            </>
          )}
          <ButtomMenuBar onBlockScroll={onBlockScroll} onScroll={onScroll} />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Member;
