import React, { useContext } from "react";
import { MainContext } from "../../datas/Store";

import DatePicker, { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import "react-datepicker/dist/react-datepicker.css";

const RegisterVisit = ({
  isOpen,
  handleClick,
  handleChange,
  startDate,
  onSaveVisitDay,
}: any) => {
  const { userId } = useContext(MainContext);

  registerLocale("ko", ko);

  const formatDate = (d: any) => {
    const date = new Date(d);
    const monthIndex = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}년 ${`0${monthIndex}`.slice(-2)}월`;
  };

  return (
    <div
      className={
        "fixed bottom-16 left-0 z-10 w-full flex-shrink-0 transition-all transform duration-300 overscroll-none" +
        (isOpen ? " " : " translate-y-full")
      }
    >
      <div className="boxshadow relative p-4 bg-white rounded-t-3xl shadow-inner text-center">
        <div className="text-gray-600 mb-4">
          <h1 className="text-lg font-bold mb-2">
            {userId.indexOf("E") !== 0 ? "항암제 투약일" : "병원 방문일"}{" "}
          </h1>
          {userId.indexOf("E") !== 0 ? (
            <p>
              병원 방문 후 항암제를 투약하신
              <br />
              날짜를 선택해 주세요
            </p>
          ) : (
            <p>
              병원 방문일 날짜를
              <br />
              선택해 주세요
            </p>
          )}
        </div>
        <DatePicker
          selected={startDate}
          onChange={handleChange}
          disabledKeyboardNavigation
          locale="ko"
          inline
          maxDate={new Date()}
          popperModifiers={{
            // 모바일 web 환경에서 화면을  벗어나지 않도록 하는 설정
            preventOverflow: { enabled: true },
          }}
          popperPlacement="auto" // 화면 중앙에 팝업이 뜨도록
          renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
            <div className="datepickerHeader">
              <div onClick={decreaseMonth}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </div>
              <div>{formatDate(date)}</div>
              <div onClick={increaseMonth}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </div>
            </div>
          )}
        />
        <div className="flex justify-between items-center pt-2 flex justify-between items-center pt-2 px-10 gap-4 mb-4">
          <button
            onClick={handleClick}
            className="w-1/2 bg-gray-point py-2 rounded-md text-white"
          >
            취소
          </button>
          <button
            onClick={onSaveVisitDay}
            className="w-1/2 bg-yellow-point py-2 rounded-md text-gray-600"
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterVisit;
