const GuideData = [
  {
    id: 1,
    name: "fatigue",
    nameKo: "피로감",
    title: "피로, 피곤함, 기운 없음의 정도",
    question:
      "지난 3일간 피로, 피곤함, 기운 없음의 정도가 어떠하였습니까?",
    meaning: "정신이나 몸이 지쳐 힘든 상태",
    answer: {
      0: "없음",
      1: "있으나 쉬면 나아짐",
      2: "쉬어도 나아지지 않으며, 생활에 약간 지장",
      3: "쉬어도 나아지지 않으며, 누군가 도와줘야함",
    },
    bgIcon: '/images/fatigue.svg',
    guideTitle: "&middot; 적절한 휴식과 함께 적절한 수준의 신체활동을 추천<br/>&middot; 유산소(걷기, 산책) 운동, 근력(맨몸, 아령) 운동과 같은 가벼운 신체 활동은 피로를 줄일 수 있음",
    guideContent:
      `- 암 관련 피로는 오후 시간에 더 심해지는 경향이 있으므로 많은 에너지가 소모되는 일들은 오전에 계획하여 진행<br/><br/>
      - 적당한 휴식과 함께 적절한 수준의 신체 활동(요가, 걷기, 산책 등) 낮은 수준의 신체 활동을 하도록 적극 권장 매주 일정한 시간에 운동<br/><br/>
      - 운동의 강도 및 일의 양을 적절하게 정하기 (예: 근력 운동(맨몸, 아령 운동 주 2회)과 함께 주당 유산소 운동(걷기, 산책, 조깅 운동 주 3회))<br/><br/>
      - 건강식단을 구성하여 균형 잡힌 식사 식사 잘 챙겨 먹기 단백질과 칼로리가 높은 음식은 체력을 유지하는 데 도움이 됨 하루에 세 번의 큰 식사 대신 작은 식사를 많이 하는 것을 추천<br/><br/>
      - 수분 섭취 많이 하기 카페인과 알코올 섭취를 제한<br/><br/>
      - 수면의 질을 개선<br/><br/>
      - 음악을 듣고, 읽고, 명상하고, 안내 이미지를 연습하거나, 좋아하는 사람들과 시간을 보내기 에너지를 절약하고 스트레스를 줄이는 데 도움을 줌<br/><br/>
      - 피곤하다면 하루에 1 시간 미만의 짧은 낮잠  그러나 낮에 너무 많은 수면을 취하면 안됨<br/><br/>
      - 식사나 운전과 같은 중요한 작업에 대한 부분은 도움을 요청
      `,
    guideCautions:
      `- 가능한 모든 의학적 및 물질로 인한 피로 유발 요인들을 해결 필요<br/><br/>
    - 부상의 위험이 있는 경우, 수술, 신경병증, 심근병증, 암 치료로 인한 장기에 영향이 있는 경우는 반드시 의료진과 상의한 다음 운동을 시작<br/><br/>
    - 피로감이 심할 경우, 의료진과 상담이 필요`
  },
  {
    id: 2,
    name: "insomnia",
    nameKo: "불면증",
    title: "불면증",
    question:
      "지난 3일간 불면증(잠들기 어려움, 자주 깸, 일찍 깸) 정도가 어떠하였습니까?",
    meaning: "수면을 이루지 못하는 일",
    answer: {
      0: "없음",
      1: "약간 잠에 들기 어렵거나 새벽에 일어남",
      2: "보통 잠에 들기 어렵거나 새벽에 일어남",
      3: "거의 잠에 들기 어렵거나 새벽에 일어남",
    },
    bgIcon: '/images/insomnia.svg',
    guideTitle: "&middot; 낮에는 충분한 활동과 운동을 유지하고, 매일 규칙적인 생활을 유지<br/>&middot; 늦은 시간에 카페인이나 담배, 자극적인 식사, 과도한 알코올은 피하기",
    guideContent:
      `- 스트레스 감소법(글쓰기, 운동, 명상, 요가, 음악), 근육이완요법(스트레칭, 호흡이완, 운동) 등의 방법을 활용<br/><br/>
      - 소변을 자주 보는 경우, 저녁에 물을 적게 먹고 주무시기 전 꼭 화장실을 다녀오기<br/><br/>
      - 늦은 밤에는 텔레비전을 보는 것보다 조용한 음악을 듣거나 책을 읽으며 차분한 마음을 유지하는 것이 좋음<br/><br/>
      - 잠이 오지 않을 때에는 억지로 자려고 애쓰지 않기 침실 밖으로 나와서 다른 일(조용한 음악 듣기, 책 읽기 등)을 하다가 졸릴 때 다시 잠자리 들기<br/><br/>
      - 매일 같은 시간에 자고 일어나려고 노력해야함 전날 일찍 잠들지 못했더라도 아침에 항상 일정한 시각에 일어나기<br/><br/>
      - 피곤함을 느낄 만큼 낮에 활동과 운동을 유지하고 너무 늦지 않은 오후에 짧은 낮잠을 자기<br/><br/>
      - 낮에 규칙적으로 운동을 하면 수면에 도움이 됨 단, 잠들기 전 2시간 안에는 운동하지 않는 것이 좋음<br/><br/>
      - 늦은 시간에 카페인(커피, 홍차, 녹차, 콜라 등)이나 담배, 자극적인 식사, 과도한 알코올은 피하기<br/><br/>
      - 잠자러 가기 2시간 이전의 운동이나 식사, 특히 수분 섭취는 피하며 자기 직전에 소변보기<br/><br/>
      - 조용하며 너무 덥지 않도록, 이불은 주름이 없고 깨끗하고 단정한 것으로 준비하기<br/><br/>
      - 15~20분 이내 잠들기 어려우면 일어나서 다른 방으로 이동하고 다시 졸릴 때 수면 취하기 필요한 만큼 여러 번 반복<br/><br/>
      - 취침 시간 외에는 잠자리에 눕지 않기<br/><br/>
      - 수면 전 스마트 폰, 휴대 전화 사용을 삼가고, 자다가 시간을 확인하지 않기
      `,
    guideCautions: `- 불면증은 간혹 불안증이나 우울증의 징후일 수 있으므로 이를 적극적으로 관리<br/><br/>
    - 수면을 방해하는 모든 건강상태 문제를 해결하도록 노력하기 예로 항구토제나 진통제를 복용하여 증상을 완화시키고 속 쓰림, 고혈당 등을 철저히 관리<br/><br/>
    - 스테로이드와 같은 일부 약물은 불면증을 유발할 수 있으므로 의료진과 상의 후 복용 시간을 조정<br/><br/>
    - 다양한 노력에도 불구하고 수면 장애가 당신의 일상생활에 불편감을 초래한다면 의료진과 상담 필요`,
  },
  {
    id: 3,
    name: "pain",
    nameKo: "통증",
    title: "통증 (두통 외)",
    question: "지난 3일간 통증 (두통 외)이 발생하였습니까? ",
    meaning:
      "실제 통증이나 잠재적 조직손상 또는 그러한 손상으로 기술된 불쾌한 감각적이고 감정적인 경험",
    answer: {
      0: "없음",
      1: "약간 통증이 있음",
      2: "통증으로 생활에 약간 지장",
      3: "심한 통증으로 일상생활 지장이 있어 누군가 도와줘야함",
    },
    bgIcon: '/images/pain.svg',
    guideTitle: "&middot; 산책, 이완이나 심상요법, 기분 전환 요법은 통증을 제거하는데 도움이 될 수 있음<br/>&middot; 마사지는 통증을 증가시키는 근육의 긴장을 줄여 줄 수 있음",
    guideContent:
      `- 통증을 치료하는 가장 좋은 방법은 통증이 악화되는 것을 예방하는 것 의료진의 처방받은 진통제를 복용하기<br/><br/>
      - 산책, 이완이나 심상요법, 기분 전환 요법은 통증을 제거하는데 도움이 될 수 있음 마사지는 통증을 증가시키는 근육의 긴장을 줄여 줄 수 있음 따뜻한 온습포나 얼음이 도움이 될 수 있음<br/><br/>
      - 통증 일기를 기록하는 것도 좋은 방법<br/><br/>    
      <b>&check; 스스로 할수 있는 통증 조절 방법</b><br/>
      - 상상요법<br/>
      자신을 행복하게 만들었던 대와 장소를 생각하기<br/><br/>
      - 기분전환<br/>
      영화나 텔레비전을 보거나, 음악을 듣거나, 친구 또는 가족을 만나 즐거운 시간을 보내기<br/><br/>
      - 마사지나 지압<br/>
      아픈 부위 주변을 마사지 혹은 가볍게 두드리기<br/><br/>
      - 냉찜질 혹은 온찜질<br/>
      아픈 부위에 얼음주머니나 따뜻한 물주머니를 대거나 따뜻한 물로 목욕 추천: 한 부위에 15분 이상 대지 않고, 1회 시행 이후 1시간 후에 반복, 감각이 둔한 곳은 특별한 주의가 필요<br/><br/>
      - 심호흡과 이완요법<br/>
      &middot; 숨을 천천히 깊게 들이 마시기<br/>
      &middot; 숨을 천천히 내쉬면서 근육을 이완시키기<br/>
      &middot; 편안한 속도로 천천히, 규칙적으로 호흡<br/>
      &middot; 천천히 박자를 맞춰'하나, 둘, 셋'하면서 들이마시고, '둘,둘,셋'하면서 내쉬기 <br/>
      &middot; 내쉴 때 '평화롭다'라고 생각하기<br/>
      &middot; 위의 내용 20분 반복하기<br/>
      &middot; 반복이 완료 후 천천히 깊게 호흡하면서 끝마치기<br/>
      &middot; 숨을 내쉬면서 '나는 정신이 맑고 이완하였다"라고 생각하기<br/><br/>
      - 통증 일기장 작성 방법<br/>
      &middot; 통증을 일기장에 자신이 생각하는 날짜, 시간, 통증점수(0-10점)를 작성하기<br/> 
      &middot; 통증 수준을 스스로 느끼고 추적해보기<br/>
      &middot; 매일 느끼는 고통에 대해 적어보기<br/>
      &middot; 위의 질문에 대한 답을 적어 두면 추후 의사나 간호사에게 통증을 설명하는 데 도움을 줌
      `,
    guideCautions:
      `- 통증이 심해질 때까지 기다렸다가 약을 복용할 필요 없음 통증은 심할 때보다 약할 때 조절하기가 쉬우며, 처방 된 진통제를 규칙적으로 제시간에 복용하는 것이 효과적으로 통증을 예방할 수 있는 방법<br/><br/>
      - 통증이 있으면 추후 진료를 받을 때마다 이야기하기<br/><br/>
      - 진통제에 의해 발생할 수 있는 다른 부작용(울렁거림이나 구토, 졸림, 가려움증 등)이 있다면 의료진과 상담 필요`,
  },
  {
    id: 4,
    name: "headache",
    nameKo: "두통",
    title: "두통",
    question: "지난 3일간 두통이 발생하였습니까?",
    meaning: "머리 부분에 생기는 통증",
    answer: {
      0: "없음",
      1: "약간 통증이 있음",
      2: "통증으로 생활에 약간 지장",
      3: "심한 통증으로 일상생활 지장이 있어 누군가 도와줘야함",
    },
    bgIcon: '/images/headache.svg',
    guideTitle: "&middot; 얼음 주머니를 사용하거나 이마에 차가운 수건을 대어 통증 완화 하루 종일 물, 과일 주스, 진저 에일, 차 같은 음료 등을 조금씩 마시기",
    guideContent:
      `- 두통에 대한 다른 감염을 예방하기 위하여 손 위생을 철저히 하는 것이 가장 중요한 방법<br/><br/>
      - 얼음 주머니를 사용하거나 이마에 차가운 수건을 대어 통증 완화<br/><br/> 
      - 하루 물, 과일 주스, 진저 에일, 차 또는 스포츠 음료를 조금씩 마시기 <br/><br/>
      - 두통을 동반한 열이 있을 경우 생화나 살아있는 식물, 고여 있는 물과 접촉 금지<br/><br/>
      - 몸이 힘들게 느껴지는 경우 체온을 확인하기
      `,
    guideCautions:
      `- 두통을 동반한 열감이 있을 때 체온도 재지 않고 의료진과 상의 없이 열을 떨어뜨리기 위해서 임의로 해열제를 사용하지 않기<br/><br/>
      - 의료진과 상의 후 아세트 아미노펜, 이부프로펜 또는 아스피린과 같은 진통 효과가 있는 일반 의약품을 복용하기 증상이 지속되거나 심해지면 의료진과 재상담 필요`,
  },
  {
    id: 5,
    name: "fever",
    nameKo: "발열",
    title: "발열",
    question: "지난 3일간 몸의 발열이 있었습니까?",
    meaning:
      "체온조절 중추기능에 변화가 일어나 보통 때보다 체온이 상승하는 현상",
    answer: {
      0: "없음",
      1: "38도초과-39도이하",
      2: "39도초과-40도이하",
      3: "40도 넘는 열이 하루이내",
      4: "40도 넘는 열이 하루이상",
    },
    bgIcon: '/images/fever.svg',
    guideTitle: "&middot; 아침 저녁으로 체온 확인하기<br/>&middot; 열이 나면 체액이 손실되므로 탈수를 방지하기 위해 물을 마시는 것이 중요<br/>&middot; 휴식을 취하고 이마에 얼음 찜질하기",
    guideContent:
      `- 아침 저녁으로 체온 확인하기<br/><br/>
      - 열이 나면 체액이 손실되므로 탈수를 방지하기 위해 물을 마시는 것이 중요 휴식을 취하고 이마에 얼음 찜질하기<br/><br/> 
      - 발열에 대한 다른 감염을 예방하기 위하여 손 위생을 철저히 하는 것이 가장 중요한 방법<br/><br/>
      - 음식 섭취 시 익히지 않는 과일이나 야채는 잘 씻고 육류와 생선은 적절한 온도에서 조리하기<br/><br/>
      - 부드러운 칫솔질과 치실 사용, 구강함수액을 이용한 가글 등 빈번한 구강관리 필요<br/><br/>
      - 열이 심할 때 생화나 살아있는 식물, 고여 있는 물과 접촉 금지
      `,
    guideCautions: `
    - 열감이 있을 때 체온도 재지 않고 의료진과 상의 없이 열을 떨어뜨리기 위해서 임의로 해열제를 사용하지 않기<br/>의료진이 처방하지 않은 약을 먹기 전에 의료진과 상담 필요`,
  },
  {
    id: 6,
    name: "chills",
    nameKo: "떨림",
    title: "오한",
    question:
      "지난 3일간 몸이 춥고 떨림(오한)이 발생하였습니까?",
    meaning: "갑자기 몸에 열이 나면서 추위를 느끼는 증세",
    answer: {
      0: "없음",
      1: "약간 춥거나 떨림",
      2: "온몸이 떨림",
      3: "심하게 온몸이 떨려서 약물로도 진정 안됨",
    },
    bgIcon: '/images/chills.svg',
    guideTitle: "&middot; 몸 전체에 이불을 덮으면 체온이 더 올라갈 수 있으므로 되도록 이불을 덮지 않기<br/>&middot; 오한(갑자기 몸에 열이 나면서 추위를 느끼는 증세)이 있을 경우, 몸 전체를 담요로 덮지 말고 목, 손목, 발목을 중점적으로 따뜻하게 하여 체온을 유지하기",
    guideContent:
      `-오한이 있을 경우 당신의 체온을 따듯하게 유지하기 다만 체온이 더 올라갈 수 있으므로 되도록 몸 전체를 담요, 이불로 덮지 않기 목, 손목, 발목을 중점적으로 따뜻하게 하여 체온을 유지하기<br/><br/>
      - 오한에 대한 다른 감염을 예방하기 위하여 손 위생을 철저히 하는 것이 가장 중요한 방법<br/><br/>
      - 부드러운 칫솔질과 치실 사용, 구강함수액을 이용한 가글 등 빈번한 구강관리 필요<br/><br/>
      - 열이 심할 때 생화나 살아있는 식물, 고여 있는 물과 접촉 금지<br/><br/>
      - 음식 섭취 시 익히지 않는 과일이나 야채는 잘 씻고 육류와 생선은 적절한 온도에서 조리하기
      `,
    guideCautions: `
    - 열감이 있을 때 체온도 재지 않고 의료진과 상의 없이 열을 떨어뜨리기 위해서 임의로 해열제를 사용하지 않기<br/><br/>
    - 몸이 힘들게 느껴지는 경우 체온을 확인하기<br/><br/>
    - 오한이 심할 경우, 의료진의 상담이 필요`,
  },
  {
    id: 7,
    name: "weightLoss",
    nameKo: "체중감소",
    title: "체중감소",
    question: "지난 3일간 체중감소가 있었습니까?",
    meaning: "일부러 체중감량을 하지 않았는데도 체중이 감소하는 경우",
    answer: {
      0: "없음",
      1: "1kg 미만",
      2: "1kg ~ 2kg",
      3: "3kg 이상",
    },
    bgIcon: '/images/weightLoss.svg',
    guideTitle: "&middot; 시간에 맞추어 규칙적으로 식사하는 습관 들이기, 목넘김이 쉽고 열량이 높은 음식을 먹도록 노력, 고열량과 고단백질 음식을 충분히 섭취 필요<br/>&middot; 평소에 좋아했던 음식이나 먹고 싶은 음식을 미리 준비하여 소량씩 나누어 먹기",
    guideContent:
      `- 시간을 맞추어 규칙적으로 식사하는 습관 들이기<br/><br/>
      - 미리 하루의 식단을 계획 보통 아침에 식욕이 가장 좋으므로 아침식사는 꼭 하기 하루에 필요한 단백질량과 칼로리의 1/3을 아침에 섭취하는 것을 고려<br/><br/>
      - 식사량이 부족한 경우는 마시는 형태의 영양보충식품(단백질, 비타민 ,야채음료 등)을 이용<br/><br/>
      - 목넘김이 쉽고 열량이 높은 음식을 먹도록 노력 예를 들면 푸딩, 젤리, 아이스크림, 요구르트, 밀크 쉐이크와 같은 유제품이나 계란 찜, 치즈, 우유, 계란, 콩, 고기, 견과류 등의 섭취<br/><br/>
      - 하루에 5-6끼로 나누어서 작은 접시에 담아서 섭취해보기<br/><br/>
      - 식사 30분 전에는 되도록 물을 먹지 말고 양배추나 탄산음료(사이다, 콜라)는 가스로 인한 복부팽만을 유발하므로 가급적 피하기<br/><br/>
      - 식사시간에는 서두르지 않도록 하고 천천히 씹어서 식사하기<br/><br/>
      - 평소에 좋아했던 음식이나 먹고 싶은 음식을 미리 준비하여 소량씩 나누어 냉동고에 보관하기<br/><br/>
      - 쉽게 손이 갈 수 있는 곳에 음식을 두고 식욕을 느낄 때마다 먹기<br/><br/>
      - 평소에 구강관리를 자주하고 식사 전후로 입안을 청결하게 하기<br/><br/>
      - 시간을 맞추어 규칙적으로 식사하는 습관을 가지기<br/><br/>
      - 따뜻하게 먹는 음식과 차게 먹는 음식을 함께 먹지 않기
      `,
    guideCautions: `- 평소의 활동량을 늘리고 통증으로 식욕이 없다면 식사 전에 처방받은 진통제를 먼저 복용하기<br/><br/>
    - 약물 부작용으로 고혈압, 혈당상승, 수분적체, 변비, 피로감, 소화불량 등이 있을 수 있으므로 심할 경우 의료진과 상담 필요`,
  },
  {
    id: 8,
    name: "anorexia",
    nameKo: "식욕감소",
    title: "식욕감소",
    question: "지난 3일간 식욕감소가 있었습니까?",
    meaning: "음식물을 먹고자 하는 욕구가 떨어지거나 없어진 상태",
    answer: {
      0: "없음",
      1: "입맛은 없으나 식사량은 줄지 않았음",
      2: "식사량은 줄었으나 체중은 줄지 않음",
      3: "식사를 거의 하지 못하고 평소 체중보다 20% (50kg의 경우 10kg) 이상 체중감소",
      4: "식사를 하지 못하여서 죽을 것 같음",
    },
    bgIcon: '/images/anorexia.svg',
    guideTitle: "&middot; 새로운 음식을 시도하고 가족에게 입맛이 당기는 음식을 알려주기<br/>&middot; 단백질과 다양한 영양소, 영양가 있는 음식을 선택하여 먹기<br/>&middot; 배가 고프지 않더라도 조금 챙겨 먹기",
    guideContent:
      `- 가족에게 입맛이 당기는 음식을 알려주기<br/><br/>
      - 새로운 음식을 시도해보기<br/><br/>
      - 식욕이 없으면 음료나 주스 같은 음식으로 대체하기<br/><br/>
      - 혼자서 식사하지 않고 가족이나 친구와 함께 즐겁게 식사하기<br/><br/>
      - 음식을 조리하는 냄새가 싫을 때는 그 장소에서 피하기<br/><br/>
      - 식전에 가벼운 스트레칭 및 운동하기 활동적인 것은 실제로 식욕을 증가시키므로 매일 짧은 산책을 추천<br/><br/>
      - 식전에 포도주를 마시기 신맛은 입맛을 자극하기 때문에 구강 염증이 없다면 레몬에이드나 오렌지 주스와 같은 신맛이 나는 과일 주스가 도움이 됨<br/><br/>
      - 식욕이 부족한 경우에는 물을 많이 마시는 것이 중요<br/><br/>
      - 식사 중에는 수분의 섭취를 제한하기 적어도 식사하기 30분전에는 물을 마시지 않기<br/><br/>
      - 건강하고 영양가 높은 음식을 선택하기 (단백질과 칼로리가 높은 다양한 영양소가 풍부한 음식: 단백질이 많은 치즈, 우유, 요구르트, 계란, 콩류, 고기, 견과류)<br/><br/>
      - 배가 고프지 않더라도 조금 먹기<br/><br/>
      - 하루에 5-6끼를 섭취 세 번의 큰 식사 대신 하루에 다섯 번 또는 여섯 번의 작은 식사를 하는 것이 도움이 될 수 있음
      `,
    guideCautions: `약물의 부작용으로 고혈압, 혈당상승, 수분적체, 변비, 피로감, 소화불량 등이 있을 수 있으므로 식욕부진과 관련하여 약물 복용은 의료진과 상담 후 복용 필요`,
  },
  {
    id: 9,
    name: "nausea",
    nameKo: "메스꺼움",
    title: "매스꺼움, 구역",
    question:
      "지난 3일간 헛구역질, 매스꺼움, 구역감이 있었습니까?",
    meaning: "신체가 불편함을 느껴서 토할 것 같은 불쾌감",
    answer: {
      0: "없음",
      1: "입맛은 없으나 식사량은 줄지 않았음",
      2: "식사량은 줄었으나 체중은 줄지 않음",
      3: "식사량 감소로 인한 5kg이상 체중감소",
      4: "식사량 감소로 인한 10kg 이상 체중감소",
    },
    bgIcon: '/images/nausea.svg',
    guideTitle: "&middot; 위를 압박하는 옷보다는 헐렁한(편한) 옷을 입도록 하며 어지럽지 않게 천천히 움직이기<br/>&middot; 창문을 열어 환기를 시키고 맑은 공기를 마시도록 하며, 메스꺼운 느낌이 들면 긴장을 풀고 천천히 깊게 숨쉬기<br/>&middot; 입안, 구강을 청결하게 하고 식사를 하지 않더라도 하루에 2회 이상 양치질 추천",
    guideContent:
      `- 창문을 열어 환기를 시키고 맑은 공기를 마시도록 하며, 메스꺼운 느낌이 들면 긴장을 풀고 천천히 깊게 숨쉬기<br/><br/>
      - 머리나 목에 차가운 수건을 얹어 놓고 심호흡을 하거나 얼음조각을 입에 물고 있으면 진정에 도움<br/><br/>
      - 자극이 적고 부드럽고 소화가 잘 되는 음식을 먹기<br/><br/>
      - 먼저 소량씩 천천히 그리고 자주 섭취<br/><br/>
      - 물을 마시는 것은 포만감을 느끼게 할 수 있기 때문에 식사를 할 때는 너무 많은 국물이나 음료는 피하기<br/><br/>
      - 입안, 구강을 청결하게 하기, 위를 자극하지 않도록 입을 자주 헹구어서 상쾌한 상태를 유지, 식사를 하지 않더라도 하루에 2회 이상 양치질 추천, 구강 청결제 중 알코올 성분이 들어 있는 것은 입안을 건조하게 하므로 피하기<br/><br/>
      - 물 종류만 먹을 수 있을 때는 꿀물, 설탕물, 이온음료 먹기<br/><br/>
      - 기름진 음식, 튀긴 음식, 짜고 매운 음식, 지나치게 단 음식은 피하기<br/><br/>
      - 뜨거운 음식은 메스꺼움을 느끼게 할 수 있으므로 음료나 음식은 차게 섭취하도록 하고 좋아하는 음료수를 얼려서 마시는 것도 좋은 방법<br/><br/>
      - 일반적으로 메스꺼움과 구토에는 비스킷, 토스트, 요구르트, 부드럽고 자극적이지 않은 복숭아 통조림과 같은 과일과 야채, 얼음 조각 등을 추천<br/><br/>
      - 식사를 하는 장소는 자신과 맞지 않는 음식 냄새가 나지 않고 환기가 잘 되는 곳을 추천<br/><br/>
      - 식사 후 바로 눕지 않기 휴식이 필요하다면 최소 30분~1시간 정도는 상체를 세우고 있거나 기대어 있기<br/><br/>
      - 식사 직후에 움직이는 것은 소화를 느리게 하므로 식후에는 잠시 쉬도록 하며 식사 후 한 시간 정도 똑바로 앉아서 휴식을 취하는 것이 가장 좋음<br/><br/>
      - 위를 압박하는 옷보다는 헐렁한(편한) 옷을 입도록 하며 어지럽지 않게 천천히 움직이기<br/><br/>
      - 메스꺼운 증상에만 집중하지 않도록 음악이나 게임, TV, 명상, 요가 등을 이용하여 관심을 다른 곳에 집중하는 것도 좋은 방법<br/><br/>
      - 변비로 인해 메스꺼움이 생길 수도 있으니 미리 조절하기<br/><br/>
      - 메스꺼운 증상이 있을 때 잠을 자는 것도 좋은 방법<br/><br/>
      - 일반적으로 메스꺼움과 구토에는 비스킷, 토스트, 요구르트, 부드럽고 자극적이지 않은 복숭아 통조림과 같은 과일과 야채, 얼음 조각 등을 추천<br/><br/>
      <b>&check; 메스꺼움의 주된 원인</b><br/>
      - 메스꺼움은 암환자에게 나타나는 가장 일반적인 부작용 그 원인으로는 이런 증상을 흔히 일으키는 약물을 투여 받고 있거나 복부, 머리방향에 방사선치료를 받고 있을 경우 주로 나타나며 암 자체가 증상을 일으키기도 함<br/><br/>
      - 모든 항암제가 메스꺼움을 일으키는 것은 아니며 투여되고 있는 약물의 종류, 용량, 투여 기간, 연령, 이전에 받은 치료 경험 등 개인의 특성에 따라 그 정도가 다양하게 나타날 수 있음<br/><br/>
      `,
    guideCautions: `- 메스꺼움이 며칠이상 지속되거나 메스꺼움 때문에 중요한 일을 하지 못할 때, 심하게 힘이 없거나 현기증이 있을 경우, 의료진이 처방한 진통제를 복용했는데도 메스꺼움이 계속되고 부작용이 발생했을 때 의료진과 상담 필요`,
  },
  {
    id: 10,
    name: "vomiting",
    nameKo: "구토",
    title: "음식을 토함, 구토",
    question:
      "지난 3일간 음식을 먹었을때 토하거나 하였습니까?",
    meaning:
      "위의 내용물이 식도와 구강을 거쳐 입 밖으로 갑자기 힘차게 나오는 현상",
    answer: {
      0: "없음",
      1: "가끔 있으나 약물 필요하지 않음",
      2: "완화 약물이 필요한 정도",
      3: "구토로 인하여 아예 아무것도 못 먹겠음",
      4: "구토로 인하여 죽을 것 같음",
    },
    bgIcon: '/images/vomiting.svg',
    guideTitle: "&middot; 구토가 멈출 때까지는 음료나 음식을 먹지 않기 구토 후 1~2시간 정도의 시간이 경과한 후에 탄산음료는 되도록 피하고 수분 섭취하기<br/>&middot; 위를 압박하는 옷보다는 헐렁한(편한) 옷을 입도록 하며 어지럽지 않게 천천히 움직이기",
    guideContent:
      `- 구토가 멈출 때까지는 음료나 음식을 먹지 않기<br/><br/>
      - 구토 후 1~2시간 정도의 시간이 경과한 후에 수분 섭취 탄산음료는 되도록 피하고 물을 섭취하는 것이 좋음<br/><br/>
      - 입안, 구강을 청결하게 하고 식사를 하지 않더라도 하루에 2회 이상 양치질 추천: 구강 청결제 중 알코올 성분이 들어 있는 것은 입안을 건조하게 하므로 피하기<br/><br/>
      - 심호흡을 하거나 얼음조각을 입에 물고 있으면 진정이 됨<br/><br/>
      - 위를 압박하는 옷보다는 헐렁한(편한) 옷을 입도록 하며 어지럽지 않게 천천히 움직이기<br/><br/>
      - 기름진 음식, 튀긴 음식, 짜고 매운 음식, 지나치게 단 음식은 피하기<br/><br/>
      - 물 종류만 먹을 수 있을 때는 섭취할 수 있을 만큼만 꿀물, 설탕 물, 이온음료 마시기<br/><br/>
      - 구토 후 식사 시 자극이 적고 부드럽고 소화가 잘 되는 음식을 먹기 소량씩 천천히 그리고 자주 섭취하는 것이 좋음<br/><br/>
      - 식사를 하는 장소는 자신과 맞지 않는 음식 냄새가 나지 않고 환기가 잘 되는 곳<br/><br/>
      - 일반적으로 메스꺼움과 구토에는 비스킷, 토스트, 요구르트, 부드럽고 자극적이지 않은 복숭아 통조림과 같은 과일과 야채, 얼음 조각 등이 좋음<br/><br/>
      - 물을 너무 많이 마시는 것은 포만감을 느끼게 할 수 있기 때문에 식사를 할 때는 너무 많은 국물이나 음료는 피하기<br/><br/>
      <b>&check; 구토의 주된 원인</b><br/>
      - 구토는 암환자에게 나타나는 가장 일반적인 부작용 중의 하나입니다 그 원인으로는 이런 증상을 흔히 일으키는 약물을 투여 받고 있거나 복부, 머리방향으로 방사선치료를 받고 있을 경우 주로 나타나며 암 자체가 증상을 일으키기도 합니다<br/>모든 항암제가 구토를 일으키는 것은 아니며 투여되고 있는 약물의 종류, 용량, 투여 기간, 연령, 이전에 받은 치료 경험 등 개인의 특성에 따라 그 정도가 다양하게 나타날 수 있습니다`,
    guideCautions: `- 지속적으로 구토를 하는 환자들은 수분공급과 전해질의 균형을 유지하기 위해서 정맥 또는 피하 체액 주사가 필요할 수도 있음 이 때는 의료진의 상담이 필요<br/><br/>
    - 의사가 처방한 진통제를 복용했는데도 구토가 계속되고 부작용이 발생했을 때, 심한 구토 때문에 약을 먹을 수 없을 때, 온종일 물을 제대로 마시지 못하거나 식사를 하지 못한 경우 의료진 상담이 필요
    `,
  },
  {
    id: 11,
    name: "constipation",
    nameKo: "변비",
    title: "변을 보기 힘듦, 변비",
    question: "지난 3일간 변을 보기 힘들지 않았습니까?",
    meaning: "정상적으로 배변이 이루어지지 않는 증상",
    answer: {
      0: "없음",
      1: "가끔씩 변비약 복용",
      2: "매일 변비약 복용",
      3: "손으로 파내야할 정도",
      4: "손으로 파내도 나오지 않음",
    },
    bgIcon: '/images/constipation.svg',
    guideTitle: "&middot; 섬유질이 많은 음식(야채, 채소, 현미, 견과류 등)을 먹도록 하며 금기가 아니라면 매일 최소 8컵의 물을 마시기<br/>&middot; 따뜻하거나 뜨거운 액체를 마시는 것도 도움이 됨, 식사 후 규칙적으로 배변을 시도하고 가능한 아침식사 후 규칙적으로 배변을 시도<br/>&middot; 일상적으로 할 수 있는 운동을 진행, 매일 15-30 분 동안 걷거나 가볍게 자전거를 타는 것을 추천",
    guideContent:
      `- 식사 후 규칙적으로 배변을 시도(아침 식사후 규칙적인 배변 시도를 추천)<br/><br/>
      - 대변을 보고 싶을 때 참지 않기<br/><br/>
      - 배변 시 편안하고 사생활이 보장되는 환경을 조성<br/><br/>
      - 섬유질이 많은 음식(야채, 채소, 현미, 견과류 등)을 섭취하기<br/><br/>
      - 금기가 아니라면 하루 8-10잔 이상의 수분을 섭취하기<br/><br/>
      - 가능한 한 일상 활동이나 운동을 평상시대로 유지(걷기, 자전거타기 등)<br/><br/>
      - 따뜻하거나 뜨거운 액체를 마시는 것도 도움이 됨<br/><br/>
      - 되도록이면 매일 활발하게 활동 (예: 침대나 의자에서도 가벼운 운동 매일 15-30 분 동안 걷거나 가볍게 자전거를 타는 것을 추천)<br/><br/>
      - 의료진이 추후 정보를 공유할 수 있도록 배변 기록을 보관하는 것도 좋은 방법<br/><br/> 
      `,
    guideCautions: `- 섬유질이 많은 음식을 먹을 때 장폐색이나 장 수술을 받은 적이 있다면 고 섬유질 식단 금지<br/><br/>
    - 의료진이 처방 한 변비 치료제와 의약품만 사용해야 하며 심할 경우 의료진과 상담 필요(일부 암 치료를 받는 사람들에게 변비로 인한 출혈, 감염 또는 기타 유해한 부작용을 유발할 수 있음)
    `,
  },
  {
    id: 12,
    name: "diarrhea",
    nameKo: "설사",
    title: "무른 변을 보거나 설사",
    question:
      "지난 3일간 무른 변을 보거나 설사가 있었습니까?",
    meaning: "액상 또는 액상에 가까운 대변을 부정시에 배출하는 것",
    answer: {
      0: "없음 (하루 0-1회)",
      1: "하루 1-3회, 물설사",
      2: "하루 4-6회",
      3: "하루 7회 이상",
      4: "입원이 필요한 정도",
    },
    bgIcon: '/images/diarrhea.svg',
    guideTitle: "&middot; 식사를 소량씩 자주 하며 매일 많은 양의 수분을 섭취, 설사를 악화시킬 수 있는 음식과 음료를 제한하기<br/>&middot; 설사로 인한 합병증을 예방하기 위하여 항문 부위에 따뜻한 물과 물티슈를 사용하여 깨끗하게 유지, 따뜻하고 얕은 목욕(좌욕)을 하는 것을 추천",
    guideContent:
      `- 설사를 악화시킬 수 있는 음식과 음료를 제한하기<br/><br/>
      - 식사는 소량씩 자주 섭취, 수분은 충분히 섭취<br/><br/>
      - 세 끼의 큰 식사 대신 하루에 6-8 끼의 작은 식사하기<br/><br/>
      - 칼륨과 나트륨이 많은 음식 (설사시 손실된 미네랄)을 추천<br/><br/>
      - 항문 부위를 따뜻한 물과 물티슈를 사용하여 깨끗하고 건조하게 유지<br/><br/>
      - 따뜻하고 얕은 목욕(좌욕)을 하는 것을 추천<br/><br/>
      
      `,
    guideCautions: `- 피해야 할 음식: 알코올, 카페인 함유 제품, 우유 및 유제품, 고지방식, 고섬유식, 과일 주스, 매운 음식 등<br/><br/>
    - 설사약을 복용하기 전에 의료진의 처방대로 복용하고 심할 경우 의료진 상담 필요
    `,
  },
  {
    id: 13,
    name: "dyspnea",
    nameKo: "숨 참",
    title: "숨이 참, 호흡 곤란",
    question: "지난 3일간 숨이 차거나 호흡곤란이 있었습니까?",
    meaning: "주관적으로 숨을 쉬는데 불편함을 느끼는 상태",
    answer: {
      0: "없음",
      1: "운동을 할때 숨이 찬 정도",
      2: "잠깐만 걸어도 숨이 참",
      3: "쉬고 있을때도 숨이 참",
      4: "숨이 참으로 인하여 죽을 것 같음",
    },
    bgIcon: '/images/dyspnea.svg',
    guideTitle: "&middot; 조이는 옷을 입지 않고 편한 옷을 착용 가벼운 호흡을 진행하며 창문을 열어 두거나 손 선풍기를 사용하여 냉기를 얼굴에 직접 혹은 간접적으로 쐬는 것이 도움이 됨<br/>&middot; 호흡곤란을 악화시킬 수 있는 환경을 피하고 안정적인 환경을 조성(본인이 편한 자세, 편안한 마음가짐, 호흡 운동)이 중요",
    guideContent:
      `- 창문을 열어 두거나 손 선풍기를 사용하여 냉기를 얼굴에 직접 혹은 간접적으로 쐬는 것이 도움이 됨<br/><br/>
      - 안정적인 환경을 조성(본인이 편한 자세, 편안한 마음가짐, 호흡 운동)하고 만약 처방된 산소가 있을 시 흡입<br/><br/>
      - 조이는 옷을 입지 않고 편한 옷을 착용<br/><br/>
      - 호흡곤란과 당시 상황을 기록(안정 후 회복 정도, 약물복용, 호흡곤란의 간격과 강도 scale 0-10)<br/><br/>
      - 증상 유발 요인 방지, 활동 시 정도와 우선순위를 정하여 활동<br/><br/>
      - 호흡곤란을 악화시킬 수 있는 환경 피하기(찬 공기를 직접 쐬는 것, 흡연, 부적절한 습도)<br/><br/>
      - 코로 산소를 공급하거나 가습기나 젖은 물수건을 이용해서 습한 공기를 제공해 주는 것도 좋은 방법<br/><br/>
      - 호흡곤란이 있는 환자들은 답답하다고 느낄 수 있으므로, 상황에 맞게 창문을 열어 얼굴에 공기를 쐬기 얼굴에 촉촉한 천을 덮는 것이 도움<br/><br/>
      - 음악을 듣거나 그림을 그리는 것과 같은 기분전환을 통해 호흡곤란 증세를 완화<br/><br/>
      <b>&check; 스스로 할수 있는 호흡 조절 방법</b><br/>
      - 호흡운동<br/>
      &middot; 반듯이 눕는 자세를 피하고 상체를 지지해서 반 정도 앉은 상태를 유지하는 것이 좋으며, 침대나 자리에서 몸을 일으키고 베개로 무릎을 받쳐 편안하게 해줌 탁상이 있다면 팔은 탈상에 편안하게 놓고 머리를 약간 앞으로 숙인 자세롤 취할 수 있도록 함<br/> 
      &middot; 호흡곤란과 근심을 줄일 수 있는 숨쉬기 방법(예: 입을 오므려서 숨을 내쉬는 것은 숨을 내쉴 때 도움이 됨 천천히, 규칙적으로, 깊게 숨을 쉬는 것이 좋음)
      `,
    guideCautions: `- 호흡곤란이 심할 경우, 의료진 상담이 필요`,
  },
  {
    id: 14,
    name: "cough",
    nameKo: "기침",
    title: "기침",
    question: "지난 3일간 기침이 발생하였습니까?",
    meaning: "소리와 함께 폐포 속 공기를 기도로 갑작스럽게 내뿜는 반사 행동",
    answer: {
      0: "없음",
      1: "약간 있으나 약물 필요하지 않음",
      2: "완화 약물이 필요한 정도",
      3: "기침이 심하여 아무것도 못하겠음",
    },
    bgIcon: '/images/cough.svg',
    guideTitle: "&middot; 가습기를 사용하여 실내 습도를 높여주고 가능한 수분 섭취 많이 하기 공기 중에 분무 되는 물(추천: 염수 2~5% 소금물)로 건조하고 염증이 생긴 목을 촉촉하게 하기<br/>&middot; 기침, 가래를 완화시키기 위해서는 가능한 곧은 자세를 유지하도록 해야 함<br/>&middot; 가벼운 호흡 운동은 폐에 분비물이 과잉 분비되는 폐암환자에게 효과적임",
    guideContent:
      `- 원인교정(기저질환 혹은 암 치료 등)이 필요<br/><br/>
      - 기침이 멈출 수 있도록 자세를 편안하게 유지<br/><br/>
      - 가습기를 사용하여 실내 습도를 높여주고 가능한 수분 섭취 많이 하기 공기 중에 분무되는 물(추천: 염수2~5% 소금물)은 건조하고 염증이 생긴 목을 촉촉하게 하고, 들러붙은 분비물을 완화시켜줌<br/><br/>
      -숨쉬기(호흡) 운동은 폐에 분비물이 과잉 분비되는 폐암 환자에게 효과적이며, 가래를 완화시키기 위해서는 가능한 곧은 자세를 유지하도록 함<br/><br/>
      -흉부를 세게 치거나 진동시키는 것은 무기폐(폐의 허탈), 저산소혈증을 야기하여 폐의 기능이 더 나빠질 수 있기 때문에 피하기    
      `,
    guideCautions:
      `- 밤에 잠을 방해하거나, 피곤, 통증, 기절, 구토, 흉통, 복통, 두통을 일으키거나 가끔씩 늑골골절 등을 일으키는 괴로운 증상일 수 있으니 주의<br/><br/>
      - 천식에 의한 기침인 경우에는 기도를 열어주고 기도 염증을 줄여주는 작용을 하는 흡입기를 처방받아 흡입<br/><br/>
      - 알레르기에 의한 기침인 경우에는 처방된 알레르기 약을 복용<br/><br/>
      - 심할 경우, 의료진 상담이 필요<br/><br/>
      - 다량의 객혈이 발생한 경우 의료진에게 연락하고 응급실로 내원
    `,
  },
  {
    id: 15,
    name: "rash",
    nameKo: "피부 발진",
    title: "피부 발진",
    question:
      "지난 3일간 피부 발진(붉은 두드러기나 염증)이 있었습니까?",
    meaning: "피부나 점막에 돋아난 작은 종기",
    answer: {
      0: "없음",
      1: "손바닥정도 영역",
      2: "손바닥을 포함한 팔전체 정도 영역",
      3: "상반신 혹은 그 이상의 영역",
    },
    bgIcon: '/images/rash.svg',
    guideTitle: "&middot; 자외선으로부터 피부를 보호(피부를 가리는 옷, 선글라스, 자외선 차단제) 샤워나 목욕 시 뜨거운 물 사용은 피하고 오일이나 순한 비누와 샴푸를 사용<br/>&middot; 하루 2회 이상 몸 전체에 보습제 사용(알코올이 함유된 제품은 피부를 건조시키므로 사용 금지) 피부를 자극하지 않도록 헐렁한(편한) 옷을 착용",
    guideContent:
      `- 자외선으로부터 피부를 보호(피부를 가리는 옷, 선글라스, 자외선 차단제)<br/><br/>
      - 샤워 시 오일이나 순한 비누와 샴푸를 사용, 향수나 알코올 색소가 함유되지 않은 제품<br/><br/>
      - 피부를 자극하지 않도록 헐렁한(편한) 옷을 착용<br/><br/>
      - 샤워나 목욕 시 뜨거운 물 사용은 피하기<br/><br/>
      - 하루 2회 이상 몸 전체에 보습제를 사용(알코올이 함유된 제품은 피부를 건조시키므로 사용 금지)<br/><br/>
      - 향이 강한 세탁제는 피하기<br/><br/>
      - 화장품 사용은 가능하지만 저알레르기성 제품을 선택(의료진이 권장하는 제품 사용)<br/><br/>
      - 피부 보습 유지를 위해 적어도 하루 8잔 이상의 물을 섭취<br/><br/>
      - 남성의 경우 수염을 너무 많이 기르지 않기 (예: 다중 면도날, 면도용 에멀젼, 보습용 애프터 쉐이브를 사용, 알코올이 함유된 제품이나 전기면도기는 사용하지 않기)
      `,
    guideCautions: `- 임의로 약물을 구입해 복용하거나 피부에 바르지 않기<br/><br/>
    - 발진은 약물 작용에 대한 피부의 염증 반응 여드름처럼 보이기도 하지만 다른 종류의 발진이며 치료방법도 다름 일반적인 여드름치료제를 사용하면 발진이 악화될 수 있으므로 사용 금지<br/><br/>
    - 때때로 피부 문제에 의학적 치료(약용 크림, 알약 등)가 필요할 수도 있으니 심한 발진이 생기면 의료진에게 상담 필요
    `
  },
  {
    id: 16,
    name: "drySkin",
    nameKo: "가려움증",
    title: "피부 건조나 가려움증",
    question:
      "지난 3일간 피부 건조나 가려움증이 발생하였습니까?",
    meaning: "피부에 수분이 부족한 상태",
    answer: {
      0: "없음",
      1: "손바닥정도 영역",
      2: "손바닥을 포함한 팔전체 정도 영역",
      3: "상반신 혹은 그 이상의 영역",
    },
    bgIcon: '/images/drySkin.svg',
    guideTitle: "&middot; 피부에 권장 크림이나 로션으로 수분을 공급 피부를 자극하지 않는 순한 비누를 사용<br/>&middot; 자외선 차단제를 사용하고 야외에서는 햇볕에 타는 것을 방지하기 위해 헐렁한 긴팔 셔츠, 바지, 챙이 넓은 모자를 착용",
    guideContent:
      `- 피부를 자극하지 않는 순한 비누를 사용 뜨거운 물이 아닌 미지근한 물로 짧은 샤워나 목욕  샤워 후 의료진이 권장하는 스킨 크림이나 연고를 사용 건조하고 가려운 피부에 시원한 수건이나 얼음이 도움이 됨<br/><br/>
      - 피부에 수분을 공급 피부가 건조해지고 가려워지는 것을 방지하기 위해 권장 크림이나 로션을 사용<br/><br/>
      - 피부 보호를 위하여 자외선 차단제를 사용 야외에서는 햇볕에 타는 것을 방지하기 위해 헐렁한 긴 팔 셔츠, 바지, 챙이 넓은 모자를 착용<br/><br/>
      -알코올이나 향이 함유된 제품은 피부를 건조하게 하거나 자극을 줄 수 있으므로 피하기 
      `,
    guideCautions: `- 방사선 요법을 받고 있는 경우 치료 부위에 온열 패드, 얼음 팩 또는 붕대를 사용하지 않기<br/><br/>
    - 때때로 피부 문제에 의학적 치료(약용 크림, 알약)가 필요할 수도 있으니 심한 피부건조가 생기면 의료진과 상담 필요    
    `,
  },
  {
    id: 17,
    name: "depression",
    nameKo: "우울함",
    title: "슬픔이나 우울함",
    question: "지난 3일간 슬픔이나 우울함을 느꼈습니까?",
    meaning: "근심스럽거나 답답하여 활기가 없는 상태",
    answer: {
      0: "없음",
      1: "약간 우울한 기분이 든다",
      2: "우울증 때문에 밖에 나가기 싫음",
      3: "우울증 때문에 집 안에서 움직이고 싶지 않음",
      4: "자살 생각이 있음",
    },
    bgIcon: '/images/depression.svg',
    guideTitle: `&middot; 충분한 휴식을 취하고 규칙적으로 몸을 움직이기 균형 잡힌 식사를 하고 체중을 줄이지 않기<br/>&middot; 자신을 우울하게 만들며 불안을 악화시키는 요인(수면부족, 불충분한 통증 조절, 음주, 많은 양의 카페인 섭취, 니코틴 금단 증상 등)을 제한하기<br/>&middot; 가족이나 주위 사람들에게 감정을 솔직하게 이야기하기<br/>&middot; 하루에 여러 번 깊은 심호흡을 하거나 신체 근육 이완을 시도해보기
    `,
    guideContent:
      `- 가족이나 주위 사람들에게 감정을 솔직하게 이야기하기(두려움, 슬픔, 외로움 등 어떤 감정이든 말하기)<br/><br/>
      - 면담이나 정신적 공감을 줄 수 있는 사람이나 성직자, 단체를 찾는 것도 좋은 방법<br/><br/>
      - 불안하고 두려울 때 자기 자신이나 다른 사람을 비난하지 않기 대신 불안하고 두려운 원인을 찾아보고 그 이유에 대해 대화하기<br/><br/>
      - 충분한 휴식을 취하고 규칙적으로 몸을 움직이기 가벼운 신체활동은 심신의 에너지를 높여줌<br/><br/>
      - 균형 잡힌 식사를 하고 체중을 줄이지 않고 유지하기 음식에 있는 많은 영양소는 건강을 유지하기 위해 매우 중요<br/><br/>
      - 음주하지 않기 음주는 우울증을 유발하는 요인임<br/><br/>
      <b>&check; 불안을 스스로 조절하는 방법</b><br/>
      <b>- 근육이완, 호흡하는 법</b><br/>
      &middot; 눈을 감고 심호흡을 하고 몸의 각 부분에 집중했다가 하나씩 근육을 이완<br/>
      &middot; 하루에 여러 번 깊은 심호흡을 하거나 이완을 시도 심호흡을 시도할 때에는 들숨보다 날숨의 길이를 길게 해보기 호흡에 집중하는 것만으로도 도움 복식호흡 하기<br/><br/>
      <b>- 명상(심상유도)하는 방법</b><br/>
      &middot; 자신에게 편안하고 안전하다고 느껴지는 장소를 떠올리기 예: 따스한 햇살이 비치는 해변이나 시냇물이 졸졸 흐르는 숲속 등<br/>
      &middot; 그곳에서 한가롭게 쉬고 있는 자신을 상상하며 눈앞에 펼쳐진 장면을 생생하게 그려 보기<br/>
      &middot; 어떤 소리가 들리는지, 어떤 냄새가 나는지, 피부에 무엇이 느껴지는지에 집중해보기<br/><br/>
      <b>&check; 보호자가 도와줄 수 있는 방법</b><br/>
      &middot; 환자의 불안감이나 두려움, 우울한 느낌에 대해서 부드럽게 접근<br/>
      &middot; 환자가 준비되기 전에 이야기를 강요하지 않음<br/>
      &middot; 환자의 감정이나 느낌을 판단하려 하지 말고 조용히 주의 깊게 들어줌 부정적인 생각을 지적하는 정도는 가능<br/>
      &middot; 힘든 점을 환자와 대화하고 공유하고 지지하도록 노력<br/>
      &middot; 보호자도 스트레스를 많이 받을 수 있으니 보호자가 쉴 수 있는 시간을 갖기<br/>
      &middot; 극심한 불안이나 우울, 공포를 느낄 때 설득이나 반박은 도움이 되지 않으니 그대로 들어주고 추후 의료진에게 알려주기`,
    guideCautions: `- 우울은 금방 좋아지지 않을 수 있고 개선될 때까지 몇 주에서 몇 달이 걸릴 수 있기 때문에 만약 처방된 약이 있다면 약물복용을 중단하지 않기<br/>
    - 담당 의료진에게 암과 치료에 의한 부작용에 대해 확인하기<br/>
    - 복용하는 약물의 목록을 작성한 후 추후 의료진에게 보여주기<br/>
    - 심할 경우 의료진 및 주치의와 상담 필요`,
  },
  {
    id: 18,
    name: "faceswelling",
    nameKo: "얼굴 붓기",
    title: "얼굴 붓기",
    question: "지난 3일간 얼굴이 부었습니까?",
    meaning: "얼굴 부은 상태",
    answer: {
      0: "없음",
      1: "얼굴이 눈에 띄게 부음",
    },
    bgIcon: '/images/faceswelling.svg',
    guideTitle: "&middot; 편한 옷과 신발을 착용 급격한 온도 변화를 피하기<br/>&middot; 부종이 있는 얼굴부위를 가볍게 마사지해주고 신체 일부를 움직이거나 가벼운 운동을 해 볼 것<br/>&middot; 소금(나트륨) 칩, 베이컨, 햄, 수프 통조림과 같은 음식을 피하기",
    guideContent:
      `- 너무 꽉 끼지 않는 느슨한(편한) 옷과 신발을 착용 자세를 편안하게 유지하고 앉거나 누울 때는 의자나 베개로 발을 들어 올리기<br/><br/>
      - 앉을 때 다리를 꼬지 않기<br/><br/>
      - 얼굴 이외에 부종이 있는 신체 일부를 움직이는 것이 도움이 됨<br/><br/>
      - 혈액 순환을 개선하기 위해 걷기를 포함한 가벼운 운동을 해 볼 것 단, 한 번에 너무 많이 서거나 걷지 않기<br/><br/>
      - 소금 (나트륨), 간장, 칩, 베이컨, 햄, 수프 통조림과 같은 음식을 피하기<br/><br/>
      - 급격한 온도 변화를 피하기<br/><br/>
      - 술이나 탄산 음료를 마시지 않기<br/><br/>
      - 너무 빨리 먹지 않기
      `,
    guideCautions: `
    - 의료진이 이뇨제라고하는 약을 처방하는 경우 지시된 대로 정확하게 복용<br/><br/>
- 의료진이 처방한 약이 있다면 여분의 수분과 염분을 몸 밖으로 옮기는 데 도움이 됨<br/><br/>
- 부기가 심한 경우 혈액 순환에 도움이 되는 특수 스타킹, 소매 또는 장갑을 착용하는 것에 대해 의료진과 상의 후 착용<br/><br/>
- 심할 경우, 의료진과 상담이 필요
    `,
  },
  {
    id: 19,
    name: "lumpskin",
    nameKo: "피부 혹",
    title: "피부 덩어리",
    question: "지난 3일간 피부 아래 만져지는 덩어리가 발생하였습니까?",
    meaning: "피부 아래 눈에 띄게 덩어리가 잡히거나 불편한 상태",
    answer: {
      0: "없음",
      1: "피부 아래에 만져지는 덩어리가 생겼음",
    },
    bgIcon: '/images/lumpskin.svg',
    guideTitle: "의료진과 상담을 해보세요",
    guideContent: ``,
    guideCautions: ``,
  },
  {
    id: 20,
    name: "hoarseness",
    nameKo: "목소리",
    title: "목소리 변화",
    question: "지난 3일간 목소리 변화가 있습니까?",
    meaning: "목구멍에서 나는 소리가 평소와 상이한 상태",
    answer: {
      0: "없음",
      1: "약간 음성이 변했으나 대화 가능",
      2: "발음하기가 어려워 여러번 단어를 반복함",
      3: "목소리가 아예 나오지 않음",
    },
    bgIcon: '/images/hoarseness.svg',
    guideTitle: "적절한 휴식을 취하고 따뜻한 음료를 섭취 심할 경우 의료진과 상담 필요",
    guideContent: ``,
    guideCautions: ``,
  },
  {
    id: 21,
    name: "hemoptysis",
    nameKo: "피를 토함",
    title: "피를 토함(객혈)",
    question: "지난 3일간 피를 토한 적이 있습니까?",
    meaning: "피나 피가 섞인 가래를 기침과 함께 뱉어 내는 것",
    answer: {
      0: "없음",
      1: "가끔 객담에 피가 묻어나옴",
      2: "하루 소주컵 1컵 이상 객혈",
      3: "하루 종이컵 1컵 이상 객혈, 어지러움/창백/두통 증상 동반",
      4: "피를 토함으로 인하여 죽을 것 같음",
    },
    bgIcon: '/images/hemoptysis.svg',
    guideTitle: "출혈이 있으면 앉거나 누워 안정을 취해야 함 심할 경우 의료진과 상담 필요",
    guideContent: ``,
    guideCautions: ``,
  },
];

export default GuideData;
