import React from 'react';
import MainReportForPrintAtOnce from './MainReportForPrintAtOnce';

const PrintAtOnce = ({ checkedItems }: any) => {
    let arrayCheckedItems = [...checkedItems];

    return (
        <>
            {arrayCheckedItems.map((patientId: any, idx: number) => (
                <MainReportForPrintAtOnce key={idx} patientId={patientId} />
            ))}
        </>
    );
};

export default PrintAtOnce;