import React from "react";
import InfoHeader from "./InfoHeader";
import PatientInfo from "./PatientInfo";
import PercentCard from "./PercentCard";
import GradeCard from "./GradeCard";
import TotalGradeChart from "../Charts/TotalGradeChart";
import TrendCharts from "../Charts/TrendChart";
import { todayFormal } from "../../../mobile/datas/dataFilter";

const MainReportForSamsung = ({
  patientId,
  patinetInfo,
  loading,
  frenquencyData,
  gradeData,
  graphData,
  totalGradeData,
  cycleDate,
  searchDate,
  onSearchDate,
  onOpenSearchData,
  settingCycle,
}: any) => {
  console.log(patinetInfo, "patinetInfo");

  return (
    <>
      <div className="main-contents px-4 flex flex-col sm:gap-4 mb-8">
        <div className="bg-white p-4 rounded-lg">
          {/* 환자 정보 헤더 */}
          {patinetInfo && <InfoHeader checkedPatient={patinetInfo} />}
          {/* 환자 증상 요약 */}
          {patinetInfo && <PatientInfo checkedPatient={patinetInfo} />}
        </div>

        <main>
          {/* 날짜 세팅 */}
          <div className="flex flex-col flex-wrap gap-4 rounded-md py-2">
            <div className="flex justify-between">
              <div className="font-extrabold text-xl">
                증상 조회{" "}
                <span className="text-sm text-gray-400">
                  마지막 투약일 부터 오늘까지를 기본으로 조회합니다.(기본
                  1사이클)
                </span>
              </div>
              <div className="font-bold">
                {searchDate.endD
                  ? searchDate.startD + " ~ " + searchDate.endD
                  : cycleDate.startDate + " ~ " + cycleDate.endDate}
              </div>
            </div>

            <div className="flex justify-between items-center flex-wrap mb-4 p-4 border-2 border-gray-700">
              <div className="flex gap-4">
                <button
                  onClick={() => settingCycle(1)}
                  className={
                    "border px-4 py-1 font-bold hover:shadow-lg " +
                    (cycleDate.type === 1
                      ? "border-gray-500 text-gray-700"
                      : "border-gray-50 text-gray-400")
                  }
                >
                  1 사이클
                </button>

                <button
                  onClick={() => settingCycle(3)}
                  className={
                    "border px-4 py-1 font-bold hover:shadow-lg " +
                    (cycleDate.type === 3
                      ? "border-gray-500 text-gray-700"
                      : "border-gray-50 text-gray-400")
                  }
                >
                  3 사이클
                </button>
              </div>
              <div className="flex gap-4">
                <div
                  className={
                    "font-bold " +
                    (!searchDate.startD
                      ? "border-gray-50 text-gray-400"
                      : "border-gray-500 text-gray-700")
                  }
                >
                  <input
                    className="pl-2"
                    type="date"
                    max={todayFormal()}
                    name="startD"
                    value={searchDate.startD}
                    onChange={onSearchDate}
                  />
                </div>

                <div
                  className={
                    "font-bold " +
                    (!searchDate.startD
                      ? "border-gray-50 text-gray-400"
                      : "border-gray-500 text-gray-700")
                  }
                >
                  <input
                    className="pl-2"
                    type="date"
                    max={todayFormal()}
                    name="endD"
                    value={searchDate.endD}
                    onChange={onSearchDate}
                  />
                </div>

                <button
                  onClick={onOpenSearchData}
                  className={
                    "ml-2 px-6 py-1 rounded-md font-bold hover:shadow-lg " +
                    (!searchDate.endD
                      ? "bg-gray-point text-white "
                      : "bg-yellow-point text-gray-600")
                  }
                >
                  조회
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-6 text-sm md:text-base">
            <div className="grid xs:grid-cols-1 grid-cols-2 gap-4">
              {/* 많이 발생한 증상 카드 */}
              {frenquencyData && (
                <PercentCard
                  title={"많이 발생한 증상"}
                  frenquencyData={frenquencyData}
                  cycleDate={cycleDate}
                />
              )}

              {/* 심각한 grade 증상 */}
              {gradeData && (
                <GradeCard
                  title={"심각한 Grade 증상"}
                  gradeData={gradeData}
                  cycleDate={cycleDate}
                />
              )}
            </div>

            <div className="flex flex-col gap-4 bg-white p-6 rounded-md text-sm md:text-base">
              <div className="flex justify-between items-center">
                <div className="font-extrabold text-xl">
                  증상 트렌드
                  {/* <span className='font-bold text-sm text-gray-400'> (X: 입력없음)</span> */}
                </div>
                <div className="flex flex-col text-sm text-gray-400 font-bold">
                  {cycleDate.startDate + " ~ " + cycleDate.endDate}
                </div>
              </div>

              <div className="grid xs:grid-cols-1 grid-cols-2 gap-4 mb-4">
                {totalGradeData.length < 1 ? (
                  <div className="flex flex-col gap-4 border-t border-gray-400">
                    <p className="mt-4">입력된 데이터가 없습니다.</p>
                  </div>
                ) : (
                  <div className="flex flex-col gap-4 h-60 border-t border-gray-400">
                    <TotalGradeChart item={totalGradeData} />
                  </div>
                )}
                {graphData &&
                  graphData.map((item: any, idx: number) => (
                    <div
                      key={idx}
                      id="trendChart"
                      className="flex flex-col gap-4 h-60 border-t border-gray-400"
                    >
                      <TrendCharts item={item} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </main>
      </div>
      {loading && (
        <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-70 z-10">
          <div className="mx-auto">
            <div className=" flex justify-center items-center mb-16">
              <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-36 w-36"></div>
            </div>
            <p className="text-2xl font-extrabold text-white">
              데이터를 분석 중입니다.
              <br />
              잠시만 기다려 주세요.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default MainReportForSamsung;
