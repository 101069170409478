import React, { useState, useContext, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { MainContext } from "../datas/Store";
import axios from "axios";
import common from "../datas/common";
import { SymptomToId } from "../datas/dataFilter";
import RegularGuide from "../components/RegularType/RegularGuide";
import RegularContent from "../components/RegularType/RegularContent";
import formData from "../datas/FormData";
import formDataLazertinib from "../datas/FormDataLazertinib";

import PreviousInputAlert from "../components/PreviousInputAlert";

const MainRegular = () => {
  const { userId, onNoneSelect } = useContext(MainContext);
  const history = useHistory();
  const [contentOpen, setContentOpen] = useState(false);
  const [missingContentOpen, setMissingContentOpen] = useState(false);
  const [reportId, setReportId] = useState("");
  const [summaryOpen, setSummaryOpen] = useState(false);
  let [i, setI] = useState(0); //문진 번호
  const [openCheckAlert, setOpenCheckAlert] = useState(false);
  const [openCheckAlert2, setOpenCheckAlert2] = useState(false);

  const [oldInputData, setOldInputData] = useState([]);
  const [missingInfo, setMissingInfo] = useState([]);
  const [inputData, setInputData] = useState<any>([]);
  const [missingQty, setMissingQty] = useState(0);
  const [allSelect, setAllSelect] = useState("");
  const alertTitle1 =
    "입력 중인 내용이 저장되어 있습니다. 마저 입력하시겠습니까?";
  const alertTitle2 =
    "이미 입력 완료한 내용이 있습니다. 처음부터 다시 입력하시겠습니까?";
  const subTitle1 = `${missingQty}개가 미입력 되어있습니다.`;
  const subTitle2 = "내가 입력한 내용 보기 >";

  const checkOldInput = useCallback(() => {
    //3일 안에 기입력건 확인 Lazertinib은 7일
    axios
      .get(common.SERVER_URL + "/report/checkReport/" + userId, common.headers)
      .then((res) => {
        // console.log(res.data.report, "/report/checkReport");

        let filteredMissingOne = Object.entries(res.data.report).filter(
          (item) => item[1] === null
        ); //미입력값만 필터
        let missing: any = [];

        if (userId.indexOf("E") !== 0) {
          filteredMissingOne.forEach((item: any) => {
            let formDataCopy = formData.slice(); //백업용 복사
            let missingData = formDataCopy.filter((row) => {
              return row.id === Number(SymptomToId(item[0], userId));
            });
            missing.push(...missingData);
          });
        } else {
          filteredMissingOne.forEach((item: any) => {
            let formDataCopy = formDataLazertinib.slice(); //백업용 복사
            let missingData = formDataCopy.filter((row) => {
              return row.id === Number(SymptomToId(item[0], userId));
            });
            missing.push(...missingData);
          });
        }

        // * 입력 내용이 있으면서 전체 입력은 아닐때
        if (res.data.isExist && missing.length !== 0) {
          setMissingQty(missing.length);

          //3일 안에 부분 입력한 내역이 있을때
          // console.log(res.data.report, "/report/checkReport");
          setOpenCheckAlert(true); //기 부분 입력건 알림창 오픈
          setOldInputData(res.data.report);
          setReportId(res.data.report.id); //기존 reportId 전달
          setI(0);

          // * 전체 입력한 내역이 있을때
        } else if (missing.length === 0) {
          //3일 안에 전체 입력한 내역이 있을때
          setOpenCheckAlert2(true); //기 전체 입력건 알림창 오픈
          setReportId(res.data.report.id); //기존 reportId 전달
          setI(0);
        } else {
          setOpenCheckAlert(false); //처음으로 돌아가기
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    //시작하자마자 기입력건 확인
    if (!userId) {
      history.push(`/mobilelogin`);
    } else {
      setSummaryOpen(false);
      // 레이저티닙은 7일 안
      checkOldInput(); //3일 안에 기입력건 확인
    }
  }, [userId, history, checkOldInput]);

  const checkContinueToInput = () => {
    //마저 입력
    setOpenCheckAlert(false);
    let missingOne = Object.entries(oldInputData);

    let missing: any = [];
    let filteredMissingOne = missingOne.filter((item) => item[1] === null); //미입력값만 필터
    if (userId.indexOf("E") !== 0) {
      filteredMissingOne.forEach((item: any) => {
        let formDataCopy = formData.slice(); //백업용 복사
        let missingData = formDataCopy.filter((row) => {
          return row.id === Number(SymptomToId(item[0], userId));
        });
        missing.push(...missingData);
      });
    } else {
      filteredMissingOne.forEach((item: any) => {
        let formDataCopy = formDataLazertinib.slice(); //백업용 복사
        let missingData = formDataCopy.filter((row) => {
          return row.id === Number(SymptomToId(item[0], userId));
        });
        missing.push(...missingData);
      });
    }
    setMissingInfo(missing); //미입력 문진 데이터
    setMissingContentOpen(true);
  };

  const backToFirstInput = () => {
    //dialog2 아니요 입력 후, 처음부터 입력, 기존 reportId로 덮어쓰기
    if (reportId) {
      axios
        .get(
          common.SERVER_URL + "/report/clearReport/" + reportId,
          common.headers
        )
        .then((res) => {
          setReportId(reportId);
          setOpenCheckAlert(false);
          setOpenCheckAlert2(false);
          setContentOpen(false);
        })
        .catch((err) => console.log(err));
    } else return;
  };

  const closeCheckAlert = () => {
    setOpenCheckAlert(false);
    setOpenCheckAlert2(false);
    history.push(`/intro`);
  };

  const openRegularContent = () => {
    //정기문진 가이드 시작할게요 버튼 클릭 후
    setContentOpen(true);
    if (reportId) {
      axios
        .post(common.SERVER_URL + "/report", reportId, common.headers)
        .then((res) => {
          setReportId(reportId);
        })
        .catch((err) => console.log(err));
    } else {
      let inputUser = {
        type: "R",
        reporter: userId,
        reportDay: new Date(),
      };

      axios
        .post(common.SERVER_URL + "/report", inputUser, common.headers)
        .then((res) => {
          setReportId(res.data.id);
        })
        .catch((err) => console.log(err));
    }
  };

  const closeContent = () => {
    setContentOpen(false);
  };

  const onClickNext = (data: any) => {
    //다음 문진 번호
    if (i < data.length - 1) {
      i = i + 1;
    } else if ((i = data.length - 1)) {
      setSummaryOpen(true);
    }
    return setI(i);
  };

  const onClickPrev = (data: any) => {
    //이전 문진 번호
    if (i > 0) {
      i = i - 1;
    } else if ((i = 0)) {
      i = data.length - 1;
    }
    return setI(i);
  };

  const onClickBack = () => {
    //현재 사용안함
    setI(0);
  };

  let doubleSubmitFlag = false;

  const doubleSubmitCheck = () => {
    //더블 클릭 방지용, 최초 1회 false, 2회부터 true 반환
    if (doubleSubmitFlag) {
      return doubleSubmitFlag;
    } else {
      doubleSubmitFlag = true;
      return false;
    }
  };

  const onChangeAnswer = (e: any) => {
    //클릭시마다 입력값 전달
    let sendAnswer = {
      [e.target.name]: e.target.value,
    };

    setTimeout(() => {
      if (!sendAnswer || sendAnswer === null) {
        return;
      } else {
        if (doubleSubmitCheck() && userId.indexOf("E") !== 0)
          return; //doubleSubmitCheck 함수의 return 값이 false 이면 등록하고 true이면 return 함수 빠져나옴
        else if (userId.indexOf("E") === 0) {
          onSubmitForm(sendAnswer);
        } else {
          onSubmitForm(sendAnswer);
        }
        if (missingInfo.length > 0 && userId.indexOf("E") !== 0) {
          //기입력건 다음 문진부터
          if (i < missingInfo.length - 1) {
            onClickNext(missingInfo);
          } else {
            bringInput();
            setSummaryOpen(true);
          }
        } else if (userId.indexOf("E") !== 0) {
          //문진 1번부터
          if (i < formData.length - 1) {
            onClickNext(formData);
          } else {
            bringInput();
            setSummaryOpen(true);
          }
        }
      }
    }, 500);
  };

  // Lazertinib- 다음 질문으로 넘어감
  const onNextMove = () => {
    // setTimeout(() => {
    if (missingInfo.length > 0) {
      //기입력건 다음 문진부터
      if (i < missingInfo.length - 1 && userId.indexOf("E") === 0) {
        onNoneSelect(0);
        onClickNext(missingInfo);
      } else {
        bringInput();
        setSummaryOpen(true);
      }
    } else if (userId.indexOf("E") === 0) {
      if (i < formDataLazertinib.length - 1) {
        onNoneSelect(0);
        onClickNext(formDataLazertinib);
      } else {
        bringInput();
        setSummaryOpen(true);
      }
    }
    // }, 200);
  };

  const bringInput = () => {
    //user 마지막 저장된 데이터 가져오기
    axios
      .get(common.SERVER_URL + "/report/search/" + reportId, common.headers)
      .then((res) => {
        // console.log(res, "search res");
        let arrayLastData = Object.entries(res.data).filter((row) => {
          return row[0] !== "id" && Number(row[1]) >= 0 && Number(row[1]) <= 4;
        });
        setInputData(arrayLastData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmitForm = (data: any) => {
    let resultSubmit = {
      id: reportId,
      ...data,
    };

    axios
      .put(common.SERVER_URL + "/report", resultSubmit, common.headers)
      .then((res) => {
        // console.log(res, "res submit");
        if (!res.data.ok) {
          return;
        }
      })
      .catch((err) => console.log(err));
  };

  const onReset = () => {
    //홈버튼 클릭시 reset 처리
    setI(0);
    closeContent();
    setSummaryOpen(false);
  };

  const onCheckInut = () => {
    //입력 내용 확인 페이지 이동
    setContentOpen(true);
    setSummaryOpen(true);
    setOpenCheckAlert(false);
    setOpenCheckAlert2(false);
  };

  return (
    <>
      {!missingContentOpen ? (
        contentOpen ? (
          // 정기문진 1번부터
          <RegularContent
            closeContent={closeContent}
            reportId={reportId}
            onClickPrev={onClickPrev}
            i={i}
            onNextMove={onNextMove}
            onClickNext={onClickNext}
            onClickBack={onClickBack}
            onReset={onReset}
            onSubmitForm={onSubmitForm}
            onChangeAnswer={onChangeAnswer}
            summaryOpen={summaryOpen}
            inputData={inputData}
            allSelect={allSelect}
          />
        ) : (
          <RegularGuide openRegularContent={openRegularContent} />
        )
      ) : (
        //기부분 입력 다음 문진 번호부터
        <RegularContent
          closeContent={closeContent}
          reportId={reportId}
          onClickPrev={onClickPrev}
          i={i}
          onClickNext={onClickNext}
          onNextMove={onNextMove}
          onClickBack={onClickBack}
          onReset={onReset}
          onSubmitForm={onSubmitForm}
          onChangeAnswer={onChangeAnswer}
          summaryOpen={summaryOpen}
          missingInfo={missingInfo}
          inputData={inputData}
          allSelect={allSelect}
        />
      )}
      <>
        {openCheckAlert && (
          <PreviousInputAlert
            title={alertTitle1}
            onOpenYes={checkContinueToInput}
            onOpenNo={backToFirstInput}
            closeCheckAlert={closeCheckAlert}
            subTitle={subTitle1}
          />
        )}
        {openCheckAlert2 && (
          <PreviousInputAlert
            title={alertTitle2}
            onOpenYes={backToFirstInput}
            onOpenNo={closeCheckAlert}
            closeCheckAlert={closeCheckAlert}
            onCheckInut={onCheckInut}
            subTitle={subTitle2}
          />
        )}
      </>
    </>
  );
};

export default MainRegular;
