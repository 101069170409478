import React, { useState, useContext, useEffect, useCallback } from "react";
import { MainContext } from "../../../mobile/datas/Store";
import axios from "axios";
import common from "../../../mobile/datas/common";
import InfoHeader from "./InfoHeader";
import PatientInfo from "./PatientInfo";
import PercentCard from "./PercentCard";
import GradeCard from "./GradeCard";
import TotalGradeChart from "../Charts/TotalGradeChart";
import TrendCharts from "../Charts/TrendChart";
import dayjs from "dayjs";

const MainReportForPrint = () => {
  const { patientId, checkedPatient, dateSetting } = useContext(MainContext);
  const [frenquencyData, setFrequcyData] = useState<any>([]);
  const [gradeData, setGradeData] = useState<any>([]);
  const [graphData, setGraphData] = useState([]);
  const [totalGradeData, setTotalGradeData] = useState([]);
  const [cycleDate, setCycleDate] = useState({ startDate: "", endDate: "" });
  const [weekDate, setWeekDate] = useState({ startDate: "", endDate: "" });

  const [searchDate] = useState<any>({ startD: "", endD: "" });

  const bringUserAllData = useCallback(
    (patientId, cycle, week, startDate, endDate) => {
      //많이 발생한 증상, 심각한 grade 증상
      let searchData = {
        patientId,
        cycle,
        week,
        startDate,
        endDate,
      };

      axios
        .post(
          common.SERVER_URL + "/report/searchDetail1",
          searchData,
          common.headers
        )
        .then((res) => {
          if (res.data.ok) {
            if (res.data.symptomCounts || res.data.symptomGrades) {
              setFrequcyData(res.data.symptomCounts.slice(0, 3)); //많이 발생한 증상
              setGradeData(res.data.symptomGrades.slice(0, 3)); //심각한 증상 카드
            }
            setCycleDate({
              startDate: dayjs(res.data.searchStartDay).format("YYYY.MM.DD"),
              endDate: dayjs(res.data.searchEndDay).format("YYYY.MM.DD"),
            });
            // todo hoho 불필요
            setWeekDate({
              startDate: dayjs(res.data.searchStartDay).format("YYYY.MM.DD"),
              endDate: dayjs(res.data.searchEndDay).format("YYYY.MM.DD"),
            });
          }
        })
        .catch((err) => console.log(err));
    },
    []
  );

  const bringUserGraphData = useCallback(
    (patientId, cycle, week, startDate, endDate) => {
      //증상 트렌드 그래프
      let params = {
        patientId,
        cycle,
        week,
        startDate,
        endDate,
      };

      axios
        .post(
          common.SERVER_URL + "/report/searchDetail2",
          params,
          common.headers
        )
        .then((res) => {
          if (res.data.ok) {
            setGraphData(res.data.symptomDateGrades); //총 21개 증상별 그래프
            setTotalGradeData(res.data.dateDangerTotalGrade); //재발 위험 점수 그래프
          } else {
            return;
          }
        })
        .catch((err) => console.log(err));
    },
    []
  );

  useEffect(() => {
    if (dateSetting) {
      //store에서 설정된 기간 정보 가져옴
      if (dateSetting.cycle === 1) {
        bringUserAllData(patientId, 1, null, "", "");
        bringUserGraphData(patientId, 1, null, "", "");
      } else if (dateSetting.cycle === 3 && patientId.indexOf("E") !== 0) {
        bringUserAllData(patientId, 3, null, "", "");
        bringUserGraphData(patientId, 3, null, "", "");
      } else if (dateSetting.cycle === 3 && patientId.indexOf("E") === 0) {
        bringUserAllData(patientId, null, 3, "", "");
        bringUserGraphData(patientId, null, 3, "", "");
      } else if (dateSetting.cycle === 6) {
        bringUserAllData(patientId, null, 6, "", "");
        bringUserGraphData(patientId, null, 6, "", "");
      } else if (dateSetting.cycle === 0 && dateSetting.end) {
        bringUserAllData(patientId, 0, 0, dateSetting.start, dateSetting.end);
        bringUserGraphData(patientId, 0, 0, dateSetting.start, dateSetting.end);
      } else {
        bringUserAllData(patientId, 1, 1, "", "");
        bringUserGraphData(patientId, 1, 1, "", "");
      }
    } else {
      bringUserAllData(patientId, 1, 1, "", "");
      bringUserGraphData(patientId, 1, 1, "", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="print-page max-w-2xl flex flex-col">
      <div className="p-4 rounded-lg">
        {/* 환자 정보 헤더 */}
        <InfoHeader checkedPatient={checkedPatient} />
        {/* 환자 증상 요약 */}
        {checkedPatient && <PatientInfo checkedPatient={checkedPatient} />}
      </div>

      <main>
        <div className="flex flex-col gap-4 text-sm">
          <div className="grid xs:grid-cols-1 grid-cols-2 gap-2 rounded-md mx-4 border">
            {/* 많이 발생한 증상 카드 */}
            <PercentCard
              title={"많이 발생한 증상"}
              frenquencyData={frenquencyData}
              cycleDate={cycleDate}
            />

            {/* 심각한 grade 증상 */}
            <GradeCard
              title={"심각한 Grade 증상"}
              gradeData={gradeData}
              cycleDate={cycleDate}
            />
          </div>

          <div className="flex flex-col bg-white p-4 rounded-md text-sm">
            <div className="flex justify-between items-center">
              <div className="font-extrabold text-lg mb-1">
                증상 트렌드
                {/* <span className="font-bold text-sm text-gray-400">
                                    {" "}
                                    (X: 입력없음)
                                </span> */}
              </div>
              <div className="flex flex-col text-sm text-gray-400 font-bold">
                {searchDate.startD
                  ? searchDate.startD + " ~ " + searchDate.endD
                  : cycleDate.startDate + " ~ " + cycleDate.endDate}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              {patientId.indexOf("E") !== 0 && (
                <div className="flex flex-col gap-4 h-44 border-gray-400">
                  {totalGradeData && <TotalGradeChart item={totalGradeData} />}
                </div>
              )}
              {graphData &&
                graphData.map((item, idx) => (
                  <div
                    key={idx}
                    className="flex flex-col gap-4 h-44 border-gray-400"
                  >
                    <TrendCharts item={item} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default MainReportForPrint;
