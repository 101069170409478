import React from 'react';


const IntroHowTo = () => {

    return (
        <>
            <h1 className="textBgYellow text-3xl font-bold leading-tight text-gray-800 mb-12">함께하는 치료 여정</h1>
            <div className="flex flex-row m-auto justify-center items-center text-left bg-gray-100 px-4 py-6 gap-8 rounded-lg shadow">
                <div>
                    <div className="text-lg text-gray-600 mb-4 font-bold">앱 이용 방법과 주의사항을 살펴보세요.</div>
                    <div className="inline-flex text-xs text-gray-600 px-6 py-1 rounded-full border-2 bg-white border-yellow-point">앱 이용 방법 알아보기</div>
                </div>
                <img src='/images/doctor.svg' className="w-16 h-16" alt='증상 가이드' />
            </div>
        </>
    );
};

export default IntroHowTo;