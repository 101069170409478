import React from 'react';
import { ResponsivePie } from '@nivo/pie'

const AgeChart = ({ age }) => {
    const data = [
        {
            "id": "20대",
            "label": "20대",
            "value": age[0] ? age[0].count : ''
        },
        {
            "id": "30대",
            "label": "30대",
            "value": age[1] ? age[1].count : ''
        },
        {
            "id": "40대",
            "label": "40대",
            "value": age[2] ? age[2].count : ''
        },
        {
            "id": "50대",
            "label": "50대",
            "value": age[3] ? age[3].count : ''
        },
        {
            "id": "60대 이상",
            "label": "60대 이상",
            "value": age[4] ? age[4].count : ''
        }
    ];

    return (
        <>
            <h6 className='text-sm font-bold m-2 text-center'>연령 분포</h6>
            <ResponsivePie
                data={data}
                margin={{ top: 20, right: 50, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                colors={{ scheme: 'nivo' }}
                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                legends={[
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: 56,
                        itemsSpacing: 0,
                        itemWidth: 70,
                        itemHeight: 18,
                        itemTextColor: '#999',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 18,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}
            />
        </>
    );
};

export default AgeChart;