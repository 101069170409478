import React, { useState, createContext } from "react";

export const MainContext = createContext({
  //mobile
  userId: "",
  onLoggedUser: (data: any) => {}, //로그인한 user id 담는 함수
  onLoggedOutUser: () => {},
  noneSelect: 0,
  onNoneSelect: (select: any) => {},
  //admin
  adminId: "",
  patientId: "",
  onLoggedAdmin: (e: any) => {}, //로그인한 admin id 담는 함수
  checkedPatient: {},
  onProfileCheck: (e: any) => {},
  checkedAdmin: new Set(),
  onAdminCheck: (e: any) => {},
  checkedAdminId: "",
  checkedGuide: new Set(),
  onGuideCheck: (e: any) => {},
  page: 1,
  postPerPage: 10,
  openTab: 0,
  sortedTitle: "",
  searchedText: "",
  handlePageChange: (page: number) => {},
  onSetSort: (sort: any) => {},
  onSeachTextSort: (text: any) => {},
  onOpenTab: (idx: number) => {},
  doctorSort: "",
  onCheckedDoctor: (data: any) => {},
  dateSetting: { cycle: 1, start: "", end: "" },
  settingForPrint: (cycle: number, start: any, end: any) => {},
});

const Store = (props: any) => {
  //mobile page
  const [userId, setUserId] = useState(""); //로그인한 user id

  const onLoggedUser = (data: any) => {
    setUserId(data.patientId);
  };

  const onLoggedOutUser = () => {
    setUserId("");
  };

  // 증상문진 첫 질문 전혀없음 시 사용됨
  const [noneSelect, setNoneSelect] = useState(0);

  const onNoneSelect = (select: any) => {
    setNoneSelect(select);
  };

  // admin page
  const [adminId, setAdminId] = useState(""); //로그인한 admin id
  const [patientId, setPatientId] = useState(""); //어드민에서 선택한 환자 id
  const onLoggedAdmin = (data: any) => {
    setAdminId(data);
  };

  const [checkedPatient, setCheckedPatient] = useState<any>({});
  const onProfileCheck = (data: any) => {
    setCheckedPatient(data);
    setPatientId(data.patientId);
  };

  const [checkedAdmin, setCheckedAdmin] = useState(new Set());
  const [checkedAdminId, setCheckedAdminId] = useState("");
  const onAdminCheck = (data: any) => {
    setCheckedAdmin(data);
    setCheckedAdminId(data.adminId);
  };

  const [checkedGuide, setCheckedGuide] = useState(new Set());
  const onGuideCheck = (data: any) => {
    setCheckedGuide(data);
  };

  const [dateSetting, setDateSetting] = useState({
    cycle: 1,
    start: "",
    end: "",
  });

  const settingForPrint = (cycle: number, start: string, end: string) => {
    setDateSetting({ cycle, start, end });
  };

  //pagination
  const [page, setPage] = useState(1);
  const [sortedTitle, setSortedTitle] = useState("");
  const [searchedText, setSearchedText] = useState("");
  const handlePageChange = (page: number) => {
    setPage(page);
  };
  const [postPerPage] = useState(10); //페이지당 포스트 개수
  const [openTab, setOpenTab] = useState(0);
  const [doctorSort, setDoctorSort] = useState("");
  const onCheckedDoctor = (data: any) => {
    setDoctorSort(data);
  };
  const onOpenTab = (idx: number) => {
    setOpenTab(idx);
  };
  const onSetSort = (sort: any) => {
    setSortedTitle(sort);
  };
  const onSeachTextSort = (text: any) => {
    setSearchedText(text);
  };

  return (
    <MainContext.Provider
      value={{
        //mobile
        userId,
        onLoggedUser,
        onLoggedOutUser,
        noneSelect,
        onNoneSelect,
        //admin
        adminId,
        patientId,
        onLoggedAdmin,
        checkedPatient,
        onProfileCheck,
        checkedAdmin,
        onAdminCheck,
        checkedAdminId,
        checkedGuide,
        onGuideCheck,
        page,
        postPerPage,
        openTab,
        onOpenTab,
        sortedTitle,
        searchedText,
        handlePageChange,
        onSetSort,
        onSeachTextSort,
        doctorSort,
        onCheckedDoctor,
        dateSetting,
        settingForPrint,
      }}
    >
      {props.children}
    </MainContext.Provider>
  );
};

export default Store;
