import React, { useContext } from "react";
import { MainContext } from "../../../mobile/datas/Store";
import { gettingOriginalAge } from "../../../mobile/datas/dataFilter";

const PatientAdminTableData = ({ data, onOpenPatientDetails }: any) => {
  const { onProfileCheck } = useContext(MainContext);

  const onOpenPatient = () => {
    if (data) {
      onOpenPatientDetails();
      onProfileCheck(data);
      // console.log(data, "data");
    } else {
      return;
    }
  };

  return (
    <tr
      onClick={onOpenPatient}
      className="text-sm border-b border-gray-200 hover:bg-gray-100 cursor-pointer"
    >
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
        {data.patientId}
      </td>
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
        {data.lastTreatment}
      </td>
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
        {gettingOriginalAge(data.birth)}
      </td>
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
        {data.gender === "M" ? "남자" : "여자"}
      </td>
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
        {data.medicine}
      </td>
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
        {data.doctorName}
      </td>
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
        <span
          className={
            "font-bold py-1 px-4 rounded-full text-white" +
            (data.researchEnd ? " bg-red-500" : " bg-blue-500")
          }
        >
          {data.researchEnd ? "연구 중단" : "연구 진행중"}
        </span>
      </td>
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
        <span className="font-bold py-1 px-4 bg-yellow-point rounded-full">
          더보기
        </span>
      </td>
    </tr>
  );
};

export default PatientAdminTableData;
