import React, { useContext } from 'react';
import { MainContext } from "../../../mobile/datas/Store";

const AdminTableData = ({ data, onOpenAdminDetails }: any) => {
    const { onAdminCheck } = useContext(MainContext);

    const onOpenAdmin = () => {
        if (data) {
            onOpenAdminDetails();
            onAdminCheck(data);
        } else {
            return;
        }
    }

    return (
        <tr onClick={onOpenAdmin} className="text-sm border-b border-gray-200 hover:bg-gray-100 cursor-pointer">
            <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
                {data.adminId}
            </td>
            <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
                {data.name}
            </td>
            <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
                {(data.type === "D" ? "의사" : (data.type === "N" ? "간호사" : (data.type === "A" ? "관리자" : "최고 관리자")))}
            </td>
            <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
                {data.createdAt.slice(0, 10)}
            </td>
            <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
                {data.organization}
            </td>
            <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
                <span className="font-bold py-1 px-4 bg-yellow-point rounded-full">더보기</span>
            </td>
        </tr>
    );
};

export default AdminTableData;