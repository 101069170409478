import React from 'react';

const PercentCard = ({ title, frenquencyData, cycleDate }: any) => {

    return (
        <div className='flex flex-col gap-3 px-4 py-4 bg-white rounded-md'>
            <div className='flex justify-between items-center border-b pb-2'>
                <h1 className='font-extrabold'>{title}</h1>
                <span className='font-bold text-sm text-gray-400'>{cycleDate.startDate + ' ~ ' + cycleDate.endDate}</span>
            </div>
            {frenquencyData.length > 0 ? (frenquencyData.map(
                (item: any, idx: number) => (
                    <div key={item.name} className='flex justify-between pb-1'>
                        <div className='w-1/2 font-bold'>
                            <span className='w-5 h-5 inline-flex justify-center items-center bg-gray-400 rounded-full text-white text-xs mr-2'>{idx + 1}</span>
                            <span>{item.name}</span>
                        </div>
                        <div className="w-1/2 flex gap-4 items-center">
                            <strong className="w-10">{item.num}회</strong>
                            <div className="flex-grow h-4 flex bg-gray-200 rounded-full">
                                <div
                                    style={{ width: item.rate + "%" }}
                                    className="shadow-none whitespace-nowrap text-white justify-center bg-blue-400 rounded-full"
                                ></div>
                            </div>
                            <div className="w-10 inline-block font-bold text-sm text-gray-400">{item.rate}%</div>
                        </div>
                    </div>
                )
            )) : <p>입력된 리포트 데이터가 없습니다.</p>}
        </div>
    );
};

export default PercentCard;