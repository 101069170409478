import formData from "./FormData";
import formDataLazertinib from "./FormDataLazertinib";
// import { map } from "lodash";
// import formViewData from "../components/InnerFormView";

export const SymptomToKo = (symptom, userId) => {
  //증상명 한국어로 출력
  if (userId) {
    if (userId.indexOf("E") !== 0) {
      let filterName = formData.filter((row) => row.name === symptom);
      let symptomName = filterName.map((item) => item.nameKo);
      return symptomName;
    } else {
      let filterName = formDataLazertinib.filter((row) => {
        if (row.list[0] === undefined) {
          return row.name === symptom;
        } else {
          var isFind = false;
          for (var data of row.list) {
            if (data.name === symptom) {
              isFind = true;
            }
          }
          return isFind;
        }
      });
      // item.list[0] !== undefined && item.list[0].nameKo  ? :

      let symptomName = filterName.map((item) => {
        if (item.list[0] === undefined) {
          return item.nameKo;
        } else {
          for (var data of item.list) {
            if (data.name === symptom) {
              return data.nameKo;
            }
          }
        }
        return symptomName;
      });
      return symptomName;
    }
  }
};

// * 질문
export const SymptomToQuestion = (symptom, userId) => {
  if (userId) {
    if (userId.indexOf("E") !== 0) {
      let filterName = formData.filter((row) => row.name === symptom);
      let symptomQuestion = filterName.map((item) => item.question);
      return symptomQuestion;
    } else {
      let filterName = formDataLazertinib.filter((row) => {
        if (row.list[0] === undefined) {
          return row.name === symptom;
        } else {
          var isFind = false;
          for (var data of row.list) {
            if (data.name === symptom) {
              isFind = true;
            }
          }
          return isFind;
        }
      });
      //* 각각 다른값으로 하나씩 저장 되게 하기
      let symptomQuestion = filterName.map((item) => {
        if (item.list[0] === undefined) {
          return item.question;
        } else {
          for (var data of item.list) {
            if (data.name === symptom) {
              return data.question;
            }
          }
        }
        return symptomQuestion;
      });
      return symptomQuestion;
    }
  }
};

// * 선택 값
export const SymptomToAnswer = (symptom, one, userId) => {
  if (userId) {
    if (userId.indexOf("E") !== 0) {
      let filterName = formData.filter((row) => row.name === symptom);
      let symptomAnswer = filterName.map((item) => item.answer[one]);
      return symptomAnswer;
    } else {
      let filterName = formDataLazertinib.filter((row) => {
        if (row.list[0] === undefined) {
          return row.name === symptom;
        } else {
          var isFind = false;
          for (var data of row.list) {
            if (data.name === symptom) {
              isFind = true;
            }
          }
          return isFind;
        }
      });
      //* 각각 다른값으로 하나씩 저장 되게 하기
      let symptomAnswer = filterName.map((item) => {
        if (item.list[0] === undefined) {
          return item.answer[one];
        } else {
          for (var data of item.list) {
            symptom === "id" && console.log(data.name, "symptom22");

            if (data.name === symptom) {
              //   console.log(data.answer[one], "data.answer[one]");
              return data.answer[one];
            }
          }
        }
        return symptomAnswer;
      });
      return symptomAnswer;
    }
  }
};

export const SymptomCheckedAnswer = (symptom, one, userId) => {
  if (userId) {
    if (userId.indexOf("E") !== 0) {
      let filterName = formData.filter((row) => row.name === symptom);
      let symptomCheckedAnswer = filterName.map((item) => one);
      return symptomCheckedAnswer;
    } else {
      let filterName = formDataLazertinib.filter((row) => {
        if (row.list[0] === undefined) {
          return row.name === symptom;
        } else {
          var isFind = false;
          for (var data of row.list) {
            if (data.name === symptom) {
              isFind = true;
            }
          }
          return isFind;
        }
      });
      //* 각각 다른값으로 하나씩 저장 되게 하기
      let symptomCheckedAnswer = filterName.map((item) => {
        if (item.list[0] === undefined) {
          return one;
        } else {
          for (var data of item.list) {
            if (data.name === symptom) {
              //   console.log(data.answer[one], "data.answer[one]");
              return one;
            }
          }
        }
        return symptomCheckedAnswer;
      });

      return symptomCheckedAnswer;
    }
  }
};

// * 증상설문 이어서하기에 사용
export const SymptomToI = (symptom, userId) => {
  // * ! 수정카드 띄우는 파트
  if (userId) {
    if (userId.indexOf("E") !== 0) {
      let filterName = formData.filter((row) => row.name === symptom);
      let symptomAnswer = filterName.map((item) => item.id - 1);
      return symptomAnswer;
    } else {
      let filterName = formDataLazertinib.filter((row) => {
        if (row.list[0] === undefined) {
          return row.name === symptom;
        } else {
          var isFind = false;
          for (var data of row.list) {
            if (data.name === symptom) {
              isFind = true;
            }
          }
          return isFind;
        }
      });
      // item.list[0] !== undefined && item.list[0].nameKo  ? :

      let symptomAnswer = filterName.map((item) => {
        // filterName.map((item) => item.id - 1);

        if (item.list[0] === undefined) {
          return item.id - 1;
        } else {
          for (var data of item.list) {
            if (data.name === symptom) {
              return item.id - 1;
            }
          }
        }
        return symptomAnswer;
      });
      return symptomAnswer;
    }
  }
};

//   if (formData) {
//     let filterName = formData.filter((row) => row.name === symptom);
//     let symptomAnswer = filterName.map((item) => item.id - 1);
//     return symptomAnswer;
//   } else {
//     let filterName = formDataLazertinib.filter((row) => row.name === symptom);
//     let symptomAnswer = filterName.map((item) => item.id - 1);
//     return symptomAnswer;
//   }

// * 다수질문 subid 리턴
export const SymptomToSubI = (symptom) => {
  // * 수정카드 띄우는 파트 (inner폼2, inner폼3 answer 출력하기 위함)
  let filterName = formDataLazertinib.filter((row) => {
    if (row.list[0] === undefined) {
      return row.name === symptom;
    } else {
      var isFind = false;
      for (var data of row.list) {
        if (data.name === symptom) {
          isFind = true;
        }
      }
      return isFind;
    }
  });

  let symptomSubid = filterName.map((item) => {
    if (item.list[0] === undefined) {
      return 0;
    } else {
      for (var data of item.list) {
        if (data.name === symptom) {
          return data.subid;
        }
      }
    }
    return symptomSubid;
  });
  return symptomSubid;
};

// * 각 질문 id 리턴
export const SymptomToId = (symptom, userId) => {
  if (userId) {
    if (userId.indexOf("E") !== 0) {
      let filterName = formData.filter((row) => row.name === symptom);
      let symptomId = filterName.map((item) => item.id);
      return symptomId;
    } else {
      let filterName = formDataLazertinib.filter((row) => row.name === symptom);
      let symptomId = filterName.map((item) => item.id);
      return symptomId;
    }
  }
};

export const todayTime = () => {
  // 인트로 화면용 현재 날짜
  let now = new Date();
  let todayYear = now.getFullYear();
  let todayMonth =
    now.getMonth() + 1 > 9 ? now.getMonth() + 1 : "0" + (now.getMonth() + 1);
  let todayDate = now.getDate() > 9 ? now.getDate() : "0" + now.getDate();
  const week = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  let dayOfWeek = week[now.getDay()];
  let hours = now.getHours() > 9 ? now.getHours() : "0" + now.getHours();
  let minutes =
    now.getMinutes() > 9 ? now.getMinutes() : "0" + now.getMinutes();
  return (
    todayYear +
    ". " +
    todayMonth +
    ". " +
    todayDate +
    " " +
    dayOfWeek +
    " " +
    hours +
    " : " +
    minutes
  );
};

export const todayFormal = () => {
  //현재 년/월/일
  let now = new Date();
  let todayYear = now.getFullYear();
  let todayMonth =
    now.getMonth() + 1 > 9 ? now.getMonth() + 1 : "0" + (now.getMonth() + 1);
  let todayDate = now.getDate() > 9 ? now.getDate() : "0" + now.getDate();
  return todayYear + "-" + todayMonth + "-" + todayDate;
};

export const todayTimeFormal = () => {
  //현재 년/월/일/시간
  let now = new Date();
  let todayYear = now.getFullYear();
  let todayMonth =
    now.getMonth() + 1 > 9 ? now.getMonth() + 1 : "0" + (now.getMonth() + 1);
  let todayDate = now.getDate() > 9 ? now.getDate() : "0" + now.getDate();
  let hours = now.getHours() > 9 ? now.getHours() : "0" + now.getHours();
  let minutes =
    now.getMinutes() > 9 ? now.getMinutes() : "0" + now.getMinutes();
  return (
    todayYear + "-" + todayMonth + "-" + todayDate + " " + hours + ":" + minutes
  );
};

export const SymptomIcon = (symptom) => {
  //   const { userId } = useContext(MainContext);
  //   if (userId.indexOf("E") !== 0) {
  if (formData) {
    let filterName = formData.filter((row) => row.nameKo === symptom);
    let symptomBgIcon = filterName.map((item) => item.bgIcon);
    return symptomBgIcon[0];
  } else {
    let filterName = formDataLazertinib.filter((row) => row.nameKo === symptom);
    let symptomBgIcon = filterName.map((item) => item.bgIcon);
    return symptomBgIcon[0];
  }
};

export const gettingOriginalAge = (birth) => {
  const today = new Date();
  let age = today.getFullYear() - Number(birth.slice(0, 4)) + 1;
  //let mon = today.getMonth() - Number(birth.slice(5, 7));
  // if (mon < 0 || (mon === 0 && today.getDate() < Number(birth.slice(8, 10)))) {
  //     return age = age - 1;
  // } else {
  //     return age;
  // }
  return age;
};
