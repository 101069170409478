import React, { useState, useContext } from "react";
import axios from "axios";
import common from "../../datas/common";
import {
  InnerFormView,
  InnerFormView2,
  InnerFormView3,
} from "../InnerFormView";
import formData from "../../datas/FormData";
import formDataLazertinib from "../../datas/FormDataLazertinib";
import { MainContext } from "../../datas/Store";
import {
  SymptomToKo,
  SymptomToQuestion,
  SymptomToI,
  SymptomToSubI,
} from "../../datas/dataFilter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InputRevise = ({
  reportId,
  reviseOne,
  onCloseInputRevise,
  bringInput,
}: any) => {
  const { userId } = useContext(MainContext);
  const [edit, setEdit] = useState({});
  const [pass, setPass] = useState<any>();

  // * 선택 값 수정
  const onChangeAnswer = (e: any) => {
    setEdit({ [e.target.name]: e.target.value });
    // console.log(edit, "onChangeAnswer");
  };

  const onSubmitForm = () => {
    let resultSubmit = {
      id: reportId,
      ...edit,
    };

    if (Object.keys(edit).length === 0) {
      toast.error("답을 선택해주세요", { position: toast.POSITION.TOP_RIGHT });
    } else {
      axios
        .put(common.SERVER_URL + "/report", resultSubmit, common.headers)
        .then((res) => {
          if (!res.data.ok) {
            return;
          } else {
            bringInput();
            onCloseInputRevise();
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const allNones = () => {
    setPass(pass);
  };

  const updatePoint = () => {
    setPass(pass);
  };
  const onNextMove = () => {
    setPass(pass);
  };

  // console.log(reviseOne[0], "reviseOne[0]");
  return (
    <div className="z-20 h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-70">
      <div className="bg-white rounded-md shadow-lg w-10/12 md:w-1/3 text-gray-500">
        <div className="px-4 py-2 flex justify-end items-center">
          <span onClick={onCloseInputRevise}>
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </span>
        </div>
        <>
          <div className="flex flex-col text-center p-4 mb-4 bg-gray-100">
            <div className="rounded-full bg-gray-400 px-4 py-1   text-white mx-auto">
              {SymptomToKo(reviseOne[0], userId)}
            </div>
            <div className="font-bold text-gray-800 text-xl mt-3">
              {SymptomToQuestion(reviseOne[0], userId)}
            </div>
          </div>
          {/* 결과, 수정 질문 매핑 */}
          {/* i에 i마다 값을 줘서 그 값에 따라 선택표시? */}
          {userId.indexOf("E") !== 0 ? (
            <InnerFormView
              i={SymptomToI(reviseOne[0], userId)}
              formViewData={formData}
              onChangeAnswer={onChangeAnswer}
              answered={
                Object.keys(edit).length > 0
                  ? Object.values(edit)
                  : reviseOne[1]
              }
            />
          ) : //  subid에 따라 해당 답 수정
          Number(SymptomToSubI(reviseOne[0])) === 0 ||
            Number(SymptomToSubI(reviseOne[0])) === 1 ? (
            <InnerFormView
              i={SymptomToI(reviseOne[0], userId)}
              formViewData={formDataLazertinib}
              onChangeAnswer={onChangeAnswer}
              updatePoint={updatePoint}
              onNextMove={onNextMove}
              answered={
                Object.keys(edit).length > 0
                  ? Object.values(edit)
                  : reviseOne[1]
              }
              allNones={allNones}
            />
          ) : Number(SymptomToSubI(reviseOne[0])) === 2 ? (
            <InnerFormView2
              i={SymptomToI(reviseOne[0], userId)}
              formViewData={formDataLazertinib}
              onChangeAnswer={onChangeAnswer}
              answered={
                Object.keys(edit).length > 0
                  ? Object.values(edit)
                  : reviseOne[1]
              }
              subI={Number(SymptomToSubI(reviseOne[0]))}
              allNones={allNones}
            />
          ) : Number(SymptomToSubI(reviseOne[0])) === 3 ? (
            <InnerFormView3
              i={SymptomToI(reviseOne[0], userId)}
              formViewData={formDataLazertinib}
              onChangeAnswer={onChangeAnswer}
              answered={
                Object.keys(edit).length > 0
                  ? Object.values(edit)
                  : reviseOne[1]
              }
              subI={Number(SymptomToSubI(reviseOne[0]))}
              allNones={allNones}
            />
          ) : null}
        </>
        <div className="flex justify-between items-center py-4 px-8 gap-4">
          <button
            onClick={onCloseInputRevise}
            className="w-1/2 bg-gray-point py-2 rounded-md text-white"
          >
            취소
          </button>
          <button
            onClick={onSubmitForm}
            className="w-1/2 bg-yellow-point py-2 rounded-md text-gray-600"
          >
            수정
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

// {
//   /* <InnerFormView2
//   i={SymptomToI(reviseOne[0], userId)}
//   formViewData={formDataLazertinib}
//   onChangeAnswer={onChangeAnswer}
//   answered={
//     Object.keys(edit).length > 0
//       ? Object.values(edit)
//       : reviseOne[1]
//   }
// />

// <InnerFormView3
//   i={SymptomToI(reviseOne[0], userId)}
//   formViewData={formDataLazertinib}
//   onChangeAnswer={onChangeAnswer}
//   answered={
//     Object.keys(edit).length > 0
//       ? Object.values(edit)
//       : reviseOne[1]
//   }
// /> */
// }
export default InputRevise;
