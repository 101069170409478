import React, { useState, useRef, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import common from "../../mobile/datas/common";
import ContentHeader from "../components/Common/ContentHeader";
import MainReportForSamsung from "../components/Patient/MainReportForSamsung";
import MainReportForPrintSamsung from "../components/Patient/MainReportForPrintSamsung";

/* eslint no-restricted-globals: ["off"] */
const PatientReporForSamsung = (props: any) => {
  history.replaceState({}, "", location.pathname);
  // interface RouteParams { id: string }
  // let params = useParams<RouteParams>();
  // const patientId = useRef<string>(params.id);

  let search = new URLSearchParams(props.location.search);
  const patientId = search.get("patientId");
  const token = search.get("key");
  let tokenOk = true;
  if (token != "sshherings2015moskomunich2022") {
    tokenOk = false;
  }
  const [openPrint, setOpenPrint] = useState(false);
  const [patinetInfo, setPatientInfo] = useState({});
  const [loading, setLoading] = useState(false); //로딩창
  const [frenquencyData, setFrequcyData] = useState<any>([]);
  const [gradeData, setGradeData] = useState<any>([]);
  const [graphData, setGraphData] = useState([]);
  const [totalGradeData, setTotalGradeData] = useState<any>([]);
  const [cycleDate, setCycleDate] = useState({
    startDate: "",
    endDate: "",
    type: 1,
  }); //기본 1, 3 사이클
  const [searchDate, setSearchDate] = useState<any>({ startD: "", endD: "" }); //검색 날짜

  const bringUserAllData = useCallback(
    (patientId, cycle, startDate, endDate) => {
      //많이 발생한 증상, 심각한 grade 증상
      let searchData = {
        patientId,
        cycle,
        startDate,
        endDate,
      };

      axios
        .post(
          common.SERVER_URL + "/report/searchDetail1",
          searchData,
          common.headers
        )
        .then((res) => {
          if (res.data.ok) {
            if (res.data.symptomCounts || res.data.symptomGrades) {
              setFrequcyData(res.data.symptomCounts.slice(0, 3)); //많이 발생한 증상
              setGradeData(res.data.symptomGrades.slice(0, 3)); //심각한 증상 카드
            }
            setCycleDate({
              startDate: res.data.searchStartDay.slice(0, 10),
              endDate: res.data.searchEndDay.slice(0, 10),
              type: cycle,
            });
          } else {
            toast.error(res.data.error, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    []
  );

  const bringUserGraphData = useCallback(
    (patientId, cycle, startDate, endDate) => {
      //증상 트렌드 그래프
      let params = {
        patientId,
        cycle,
        startDate,
        endDate,
      };

      axios
        .post(
          common.SERVER_URL + "/report/searchDetail2",
          params,
          common.headers
        )
        .then((res) => {
          if (res.data.ok) {
            setGraphData(res.data.symptomDateGrades); //총 21개 증상별 그래프
            setTotalGradeData(res.data.dateDangerTotalGrade); //재발 위험 점수 그래프
            setTimeout(() => setLoading(false), 500);
          } else {
            setTimeout(() => {
              setLoading(false);
              toast.error(res.data.error, {
                autoClose: 3000,
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 500);
          }
        })
        .catch((err) => console.log(err));
    },
    []
  );

  const settingCycle = useCallback((cycle: number) => {
    setLoading(true);
    setSearchDate({ startD: "", endD: "" });
    bringUserAllData(patientId, cycle, "", "");
    bringUserGraphData(patientId, cycle, "", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bringCheckedPatient = useCallback(() => {
    axios
      .get(common.SERVER_URL + "/patient/pId/" + patientId, common.headers)
      .then((res) => setPatientInfo(res.data.patient))
      .catch((err) => console.log(err));
  }, [patientId]);

  const onSearchDate = (e: any) => {
    const { name, value } = e.target;
    setSearchDate({ ...searchDate, [name]: value });
  };

  const onOpenSearchData = () => {
    if (!searchDate.startD || !searchDate.endD) {
      toast.error("시작일과 종료일을 입력해주세요", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setLoading(true);
      bringUserAllData(patientId, "", searchDate.startD, searchDate.endD);
      bringUserGraphData(patientId, "", searchDate.startD, searchDate.endD);
    }
  };

  const onOpenPrint = () => {
    setOpenPrint(true); //인쇄용 페이지
    setTimeout(() => onPrint(), 1000);
  };

  const onPrint = () => {
    //프린트 설정
    const html = document.querySelector<any>("html");
    const printContents = document.querySelector<any>(".print-page").innerHTML;
    const printDiv = document.createElement("DIV");
    printDiv.className = "print-div";
    html.appendChild(printDiv);
    printDiv.innerHTML = printContents;
    document.body.style.display = "none";
    window.print();
    setOpenPrint(false);
    document.body.style.display = "block";
    printDiv.style.display = "none";
  };

  useEffect(() => {
    bringCheckedPatient();
    settingCycle(1);
  }, [bringCheckedPatient, patientId, settingCycle]);

  return (
    <>
      {tokenOk ? (
        !openPrint ? (
          <>
            <div className="flex h-screen antialiased text-gray-600 bg-gray-100">
              <div className="flex-1 h-full overflow-x-hidden overflow-y-auto">
                <main>
                  <ContentHeader
                    onClickBtn={onOpenPrint}
                    headerTitle={"환자 증상 결과 요약"}
                    btnRight={"인쇄"}
                  />
                  <MainReportForSamsung
                    patientId={patientId}
                    patinetInfo={patinetInfo}
                    loading={loading}
                    frenquencyData={frenquencyData}
                    gradeData={gradeData}
                    graphData={graphData}
                    totalGradeData={totalGradeData}
                    cycleDate={cycleDate}
                    searchDate={searchDate}
                    onSearchDate={onSearchDate}
                    onOpenSearchData={onOpenSearchData}
                    settingCycle={settingCycle}
                  />
                </main>
              </div>
            </div>
            <ToastContainer />
          </>
        ) : (
          <MainReportForPrintSamsung
            patientId={patientId}
            patinetInfo={patinetInfo}
            searchDate={searchDate}
            frenquencyData={frenquencyData}
            gradeData={gradeData}
            graphData={graphData}
            totalGradeData={totalGradeData}
            cycleDate={cycleDate}
          />
        )
      ) : (
        <div className="text-center mt-10 text-3xl">key error</div>
      )}
    </>
  );
};

export default PatientReporForSamsung;
