import React, { useContext } from "react";
import { MainContext } from "../../../mobile/datas/Store";
import Parser from 'html-react-parser';

const GuideTableList = ({
    data,
    onOpenGuideDetails
}: any) => {
    const { onGuideCheck } = useContext(MainContext);

    const onOpenGuide = () => {
        if (data) {
            onOpenGuideDetails();
            onGuideCheck(data);
        }
    };

    return (
        <tr
            onClick={onOpenGuide}
            className="text-xs border-b border-gray-200 hover:bg-gray-100 cursor-pointer"
        >
            <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
                {data.id}
            </td>
            <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
                {data.nameKo}
            </td>
            <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-wrap p-3 text-left overflow-ellipsis overflow-hidden">
                {Parser(data.guideTitle)}
            </td>
            <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3 cursor-pointer">
                <span className="px-4 py-1 bg-yellow-point font-bold rounded-full">
                    더보기
                </span>
            </td>
        </tr>
    );
};

export default GuideTableList;
