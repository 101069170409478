const common = {
  //SERVER_URL: "http://3.36.83.224:7777"
  //   SERVER_URL: "http://192.168.0.90:5000",
  SERVER_URL: "https://atti-back.rhexium.com", // 실서버
  //   SERVER_URL: "http://192.168.0.61:6000",
  // 3.35.200.154:5000
  // SERVER_URL: "http://192.168.0.68:7777", // 모스크 로컬
  // SERVER_URL: "http://localhost:7777", // 로컬

  headers: {
    headers: {
      authorization:
        "Bearer lcl+lKlNHJjlc1bl7fMtckjZLw4TorpK3QlnCijPFQWs+zKXqg6/kgcDz7FB2cJWT/j2DcCjahwecvLPa2LO5g==",
    },
  },
};

export default common;
