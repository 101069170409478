import React, { useState, useEffect, useContext } from "react";
import RegularHeader from "../components/RegularType/RegularHeader";
import BottomMenuBar from "./Common/BottomMenuBar";
import { MainContext } from "../datas/Store";

const IntroHowToCard = () => {
  const { userId } = useContext(MainContext);

  const closeIcon = { backgroundImage: "url('/images/homebar.svg')" };
  const [scrollToBlock, setScrollToBlock] = useState(false);

  const onBlockScroll = () => {
    setScrollToBlock(true);
  };

  const onScroll = () => {
    setScrollToBlock(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        className={
          "w-full h-screen flex flex-col justify-between items-center mx-auto text-gray-500" +
          (scrollToBlock ? "fixed w-full h-full overflow-hidden" : "")
        }
      >
        <div className="w-full flex-shrink-0 flex flex-col justify-between">
          <RegularHeader
            headerTitle={"서비스 이용방법"}
            rightIcon={closeIcon}
          />
          {userId.indexOf("E") !== 0 ? (
            <div className="mt-14 mb-20">
              <img src="/images/brochure1.svg" alt="아띠 메뉴얼" />
              <img src="/images/brochure2.svg" alt="아띠 메뉴얼" />
            </div>
          ) : (
            <div className="mt-14 mb-20">
              <img src="/images/brochure1-Lazer.svg" alt="아띠 메뉴얼" />
              <img src="/images/brochure2-Lazer.svg" alt="아띠 메뉴얼" />
            </div>
          )}
        </div>
        <BottomMenuBar onBlockScroll={onBlockScroll} onScroll={onScroll} />
      </div>
    </>
  );
};

export default IntroHowToCard;
