import React, { useEffect, useContext } from "react";
import { bgColor } from "../../datas/stepColor";
import { SymptomToKo } from "../../datas/dataFilter";
import { MainContext } from "../../datas/Store";

const ChosenOneSymptom = ({ chosenOne, onOpenGuide }: any) => {
  const { userId } = useContext(MainContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mt-14 flex flex-col px-4 py-2 bg-gray-100">
      <h2 className="font-bold text-lg mb-2.5">발생한 증상</h2>
      <div className="px-2.5 bg-white rounded-md border-l-8 border">
        {chosenOne.map((one: any, i: any) => (
          <div
            key={i}
            className="flex flex-wrap justify-between items-center my-4"
          >
            <h6 className="font-bold">{SymptomToKo(one[0], userId)}</h6>
            <ul className="flex items-center">
              <li
                className={
                  "w-5 h-5 rounded-full flex-grow-0" + bgColor(Number(one[1]))
                }
              ></li>
              <li className="pl-1 pr-3 py-1 rounded-full">
                {Number(one[1])} 단계
              </li>
              {/* LT 사용자는 숨기기(증상차트 -> 더보기(가이드 보기)) */}
              {userId.indexOf("E") !== 0 ? (
                <li
                  onClick={() => onOpenGuide(one[0])}
                  className="text-sm bg-gray-point px-3 py-1 text-white rounded-full"
                >
                  가이드 보기<span className="ml-2">&gt;</span>
                </li>
              ) : null}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChosenOneSymptom;
