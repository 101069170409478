import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { MainContext } from "../../mobile/datas/Store";
import axios from "axios";
import common from "../../mobile/datas/common";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminLogin = () => {
  const { onLoggedAdmin } = useContext(MainContext);
  const history = useHistory();
  const warningIcon = { backgroundImage: "url('/images/warning.svg')" };
  const refAdminId = useRef<any>();
  const refAdminPass = useRef<any>();
  const [adminLogin, setAdminLogin] = useState({
    adminId: "",
    pass: "",
  });
  const [openErrorMsg, setOpenErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openLogin, setOpenLogin] = useState(false);

  const onChangeId = (e: any) => {
    const { name, value } = e.target;
    setAdminLogin({ ...adminLogin, [name]: value });
  };

  const onChangePw = (e: any) => {
    const { name, value } = e.target;
    setAdminLogin({ ...adminLogin, [name]: value });
  };

  const onAdminLogin = (e: any) => {
    e.preventDefault();
    if (!adminLogin.adminId) {
      setOpenErrorMsg(true);
      setErrorMsg("아이디를 입력하세요");
    } else if (!adminLogin.pass) {
      setOpenErrorMsg(true);
      setErrorMsg("비밀번호를 입력하세요");
    } else {
      axios
        .post(common.SERVER_URL + "/admin/login", adminLogin, common.headers)
        .then((res) => {
          if (res.data.ok) {
            setOpenErrorMsg(false);
            window.localStorage.setItem("adminId", adminLogin.adminId);
            toast.success("LOGIN 성공", {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
            onLoggedAdmin(adminLogin.adminId); //store admin id 보냄
            history.push("/adminmain"); //admin main 페이지 전환
          } else {
            setOpenErrorMsg(true);
            setErrorMsg("가입하지 않은 아이디이거나 잘못된 비밀번호 입니다");
            toast.error(res.data.error, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
            setAdminLogin({
              adminId: "",
              pass: "",
            });
            refAdminId.current.focus();
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    const checkUser = () => {
      //로그인 유지
      const loggedInfo = window.localStorage.getItem("adminId") as string;
      if (loggedInfo) {
        //기존 로그인 데이터가 있을때
        setOpenLogin(true);
        //onLoggedAdmin(loggedInfo); //store admin id 보냄
        setAdminLogin({ adminId: loggedInfo, pass: "" });
        if (refAdminPass.current) refAdminPass.current.focus();
        //history.push("/adminmain"); //admin main 페이지 전환
      } else {
        //기존 데이터 없을때
        setOpenLogin(true);
        if (refAdminId.current) refAdminId.current.focus();
      }
    };
    checkUser();
  }, [history, onLoggedAdmin]);

  return openLogin ? (
    <>
      <div className="flex flex-col items-center justify-center min-h-screen p-4 space-y-4 antialiased text-gray-600 bg-gray-100">
        <main>
          <div className="w-full max-w-sm px-4 py-6 space-y-6 bg-white rounded-md">
            <h1 className="uppercase font-extrabold text-3xl text-center">
              admin login
            </h1>
            <form onSubmit={onAdminLogin} className="space-y-6 text-sm">
              <input
                ref={refAdminId}
                type="text"
                value={adminLogin.adminId}
                name="adminId"
                onChange={onChangeId}
                placeholder="아이디를 입력하세요"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring"
              />
              <input
                ref={refAdminPass}
                type="password"
                value={adminLogin.pass}
                autoComplete="on"
                name="pass"
                onChange={onChangePw}
                placeholder="비밀번호를 입력하세요"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring font-mono"
              />

              {openErrorMsg ? (
                <div className="flex items-center gap-2 mb-4">
                  <span
                    className="inline-block w-5 h-5 bg-cover"
                    style={warningIcon}
                  ></span>
                  <p>{errorMsg}</p>
                </div>
              ) : (
                <p className="h-5"></p>
              )}
              <button
                type="submit"
                className="w-full py-2 text-center font-bold text-white transition-colors duration-200 rounded-md bg-gray-point focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-1"
              >
                로그인
              </button>
            </form>
          </div>
        </main>
        <ToastContainer />
      </div>
    </>
  ) : (
    <div></div>
  );
};

export default AdminLogin;
