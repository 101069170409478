import React, { useState, useContext, useEffect, useRef } from "react";
import { MainContext } from "../../../mobile/datas/Store";
import ProfTableData from "./ProfTableData";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ProfTable = ({ patientData, bringPatientList, checkHandler }: any) => {
  const {
    adminId,
    page,
    postPerPage,
    doctorSort,
    onSetSort,
    onSeachTextSort,
    handlePageChange,
  } = useContext(MainContext);
  // * toggle
  const [onCheck1, setOnCheck1] = useState(false);
  const [onCheck3, setOnCheck3] = useState(false);
  const [onCheck4, setOnCheck4] = useState(false);
  const [onCheck5, setOnCheck5] = useState(false);
  const [onCheck6, setOnCheck6] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [firstIn, setFirstIn] = useState(true);

  const onSorting = (
    doctorId: string,
    sort: string,
    searchType: string,
    searchText: string,
    page: number,
    postPerPage: number
  ) => {
    bringPatientList(doctorId, sort, searchType, searchText, page, postPerPage);
  };

  const inputClick = useRef<any>();
  const inputClick2 = useRef<any>();
  // 기존 아띠 연구부터 출력
  useEffect(() => {
    if (adminId !== "smc09" && adminId !== "smc08") {
      if (firstIn === true) {
        inputClick.current.click();
        inputClick2.current.click();
      } else if (searchText === "AL" && firstIn === false) {
        inputClick.current.click();
        inputClick2.current.click();
      }
    }
  }, [searchText, firstIn]);

  const onCheckPaitentId = () => {
    if (!onCheck1) {
      //체크 안되어있을때
      setOnCheck1(true);
      onSorting(
        doctorSort,
        "patientId",
        searchText !== "" ? "patientId" : "",
        searchText !== "" ? searchText : "",
        page,
        postPerPage
      );
      setOnCheck3(false);
      setOnCheck4(false);
      setOnCheck5(false);
      setOnCheck6(false);
      //   setSearchText("");
      onSetSort("patientId");
      //   onSeachTextSort("");
    } else {
      //체크 되어있을때
      setOnCheck1(false);
      onSorting(
        doctorSort,
        "",
        searchText !== "" ? "patientId" : "",
        searchText !== "" ? searchText : "",
        page,
        postPerPage
      );
      //   onSeachTextSort("");
    }
  };
  const onCheckLastTreatmentDay = () => {
    if (!onCheck3) {
      //체크 안되어있을때
      setOnCheck3(true);
      onSorting(
        doctorSort,
        "lastTreatment",
        searchText !== "" ? "patientId" : "",
        searchText !== "" ? searchText : "",
        page,
        postPerPage
      );
      setOnCheck1(false);
      setOnCheck4(false);
      setOnCheck5(false);
      setOnCheck6(false);
      //   setSearchText("");
      onSetSort("lastTreatment");
      //   onSeachTextSort("");
    } else {
      //체크 되어있을때
      setOnCheck3(false);
      onSorting(
        doctorSort,
        "",
        searchText !== "" ? "patientId" : "",
        searchText !== "" ? searchText : "",
        page,
        postPerPage
      );
      //   onSeachTextSort("");
    }
  };

  const onCheckRecurrenceDanger = () => {
    if (!onCheck4) {
      //체크 안되어있을때
      setOnCheck4(true);
      onSorting(
        doctorSort,
        "recurrenceDanger",
        searchText !== "" ? "patientId" : "",
        searchText !== "" ? searchText : "",
        page,
        postPerPage
      );
      setOnCheck1(false);
      setOnCheck3(false);
      setOnCheck5(false);
      setOnCheck6(false);
      //   setSearchText("");
      onSetSort("recurrenceDanger");
      //   onSeachTextSort("");
    } else {
      //체크 되어있을때
      setOnCheck4(false);
      onSorting(
        doctorSort,
        "",
        searchText !== "" ? "patientId" : "",
        searchText !== "" ? searchText : "",
        page,
        postPerPage
      );
      //   onSeachTextSort("");
    }
  };
  // 값 바로 반영시키기

  const onCheckGradeDanger = () => {
    if (!onCheck5) {
      //체크 안되어있을때
      setOnCheck5(true);
      onSorting(
        doctorSort,
        "gradeDanger",
        searchText !== "" ? "patientId" : "",
        searchText !== "" ? searchText : "",
        page,
        postPerPage
      );
      setOnCheck1(false);
      setOnCheck3(false);
      setOnCheck4(false);
      setOnCheck6(false);
      onSetSort("gradeDanger");
      //   onSeachTextSort("");
    } else {
      //체크 되어있을때
      setOnCheck5(false);
      onSorting(
        doctorSort,
        "",
        searchText !== "" ? "patientId" : "",
        searchText !== "" ? searchText : "",
        page,
        postPerPage
      );
      //   onSeachTextSort("");
    }
  };

  const onCheckResearchEnd = () => {
    if (!onCheck6) {
      //체크 안되어있을때
      setOnCheck6(true);
      onSorting(
        doctorSort,
        "researchEnd",
        searchText !== "" ? "patientId" : "",
        searchText !== "" ? searchText : "",
        page,
        postPerPage
      );
      setOnCheck1(false);
      setOnCheck3(false);
      setOnCheck4(false);
      setOnCheck5(false);
      //   setSearchText("");
      onSetSort("researchEnd");
      //   onSeachTextSort("");
    } else {
      //체크 되어있을때
      setOnCheck6(false);
      onSorting(
        doctorSort,
        "",
        searchText !== "" ? "patientId" : "",
        searchText !== "" ? searchText : "",
        page,
        postPerPage
      );
      //   onSeachTextSort("");
    }
  };

  const onSearchText = (e: any) => {
    e.preventDefault();
    if (!searchText) {
      onSorting(doctorSort, "", "", "", page, postPerPage);
    } else {
      onSorting(doctorSort, "", "patientId", searchText, page, postPerPage);
      onSetSort("");
      //   setSearchText("");
      setOnCheck1(false);
      setOnCheck3(false);
      setOnCheck4(false);
      setOnCheck5(false);
      setOnCheck6(false);
      handlePageChange(1);
      onSeachTextSort(searchText);
    }
  };

  const pathname = window.location.pathname;

  return (
    <>
      <div className="flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-md bg-white">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <form
            onSubmit={onSearchText}
            className="flex  items-center gap-2 text-sm"
          >
            <button onClick={() => setSearchText}></button>
            {adminId === "smc09" ? null : adminId === "smc08" ? null : (
              <div className="justify-start order-1">
                <button
                  className={
                    "border border-current p-2 h-14 rounded-2xl bg-white focus:bg-yellow-200 font-semibold text-center tracking-wide subpixel-antialiased text-base font-mono"
                  }
                  ref={inputClick}
                  onClick={() => {
                    setSearchText("AL");
                    setFirstIn(false);
                  }}
                >
                  Atti
                </button>

                <button
                  className="border border-current p-2 h-14 ml-4 rounded-2xl bg-white focus:bg-yellow-200 font-semibold text-center tracking-wide subpixel-antialiased text-base font-mono"
                  onClick={() => setSearchText("E")}
                >
                  Lazertinib
                </button>
              </div>
            )}

            <div className="justify-end ml-auto mr-4 order-2">
              <span className="mr-2">회원번호</span>
              <input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="pl-2 pr-2 border focus:outline-none rounded-md focus:ring"
              />
              <button
                type="submit"
                className="px-4 py-1 text-sm text-white rounded-md bg-gray-point font-bold focus:outline-none focus:ring focus:ring-primary focus:ring-offset-1"
                ref={inputClick2}
              >
                검색
              </button>
            </div>
          </form>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Each professor table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr className="font-extrabold text-sm whitespace-nowrap align-middle">
                {pathname === `/adminmain` ? (
                  <th className="border border-solid px-6 py-3 border-l-0 border-r-0">
                    선택
                  </th>
                ) : (
                  <th></th>
                )}
                <th
                  onClick={onCheckPaitentId}
                  className={
                    "border border-solid px-6 py-3 border-l-0 border-r-0"
                  }
                >
                  <div className="flex gap-1 justify-center items-center cursor-pointer">
                    <span>회원번호</span>
                    <div
                      className={
                        "transform transition duration-500 ease-in-out " +
                        (!onCheck1 ? " " : "rotate-180")
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th
                  onClick={onCheckLastTreatmentDay}
                  className={
                    "border border-solid px-6 py-3 border-l-0 border-r-0"
                  }
                >
                  <div className="flex gap-1 justify-center items-center cursor-pointer">
                    <span>
                      {" "}
                      {searchText === "E" || adminId === "smc09"
                        ? "병원 방문일"
                        : "투약일"}
                    </span>
                    <div
                      className={
                        "transform transition duration-500 ease-in-out " +
                        (!onCheck3 ? " " : "rotate-180")
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th
                  className={
                    "border border-solid px-6 py-3 border-l-0 border-r-0"
                  }
                >
                  <div className="flex gap-1 justify-center items-center">
                    <span>약제</span>
                  </div>
                </th>
                <th
                  className={
                    "border border-solid px-6 py-3 border-l-0 border-r-0"
                  }
                >
                  <div className="flex gap-1 justify-center items-center">
                    <span>담당 교수</span>
                  </div>
                </th>
                <th
                  onClick={onCheckRecurrenceDanger}
                  className={
                    "border border-solid px-6 py-3 border-l-0 border-r-0"
                  }
                >
                  <div className="flex gap-1 justify-center items-center cursor-pointer">
                    <span>재발 위험 경고</span>
                    <div
                      className={
                        "transform transition duration-500 ease-in-out " +
                        (!onCheck4 ? " " : "rotate-180")
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th
                  onClick={onCheckGradeDanger}
                  className={
                    "border border-solid px-6 py-3 border-l-0 border-r-0"
                  }
                >
                  <div className="flex gap-1 justify-center items-center cursor-pointer">
                    <span>Grade 위험 경고</span>
                    <div
                      className={
                        "transform transition duration-500 ease-in-out " +
                        (!onCheck5 ? " " : "rotate-180")
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th
                  onClick={onCheckResearchEnd}
                  className={
                    "border border-solid px-6 py-3 border-l-0 border-r-0"
                  }
                >
                  <div className="flex gap-1 justify-center items-center cursor-pointer">
                    <span>연구 상태</span>
                    <div
                      className={
                        "transform transition duration-500 ease-in-out " +
                        (!onCheck6 ? " " : "rotate-180")
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th
                  className={
                    "border border-solid px-6 py-3 border-l-0 border-r-0"
                  }
                >
                  <div className="flex gap-1 justify-center items-center">
                    <span>더보기</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {patientData &&
                patientData.map((data: any) => (
                  <ProfTableData
                    key={data.id}
                    data={data}
                    checkHandler={checkHandler}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ProfTable;
