import React, { useState, useEffect, useContext } from "react";
import formData from "../../datas/FormData";
import formDataLazertinib from "../../datas/FormDataLazertinib";
import { MainContext } from "../../datas/Store";

import axios from "axios";
import common from "../../datas/common";
import { useHistory } from "react-router-dom";
import InnerHeader from "../InnerHeader";
import TempInnerContent from "./TempInnerContent";
import Result from "../Result/Result";
import CommonDialog from "../Common/CommonDialog";

const TempContent = ({ checkedItems, tempReportId, onResetData }: any) => {
  const history = useHistory();
  const { userId } = useContext(MainContext);

  let [tempI, setTempI] = useState(0);
  const [tempInfo, setTempInfo] = useState<any[]>([]);
  const [tempResultOpen, setTempResultOpen] = useState(false);

  const prevIcon = { backgroundImage: "url('/images/arrow-left.svg')" };
  const closeIcon = { backgroundImage: "url('/images/close.svg')" };

  useEffect(() => {
    tempInfoList(Array.from(checkedItems).sort());
  }, [checkedItems]);

  const tempInfoList = (checkedOne: any) => {
    if (userId.indexOf("E") !== 0) {
      let formDataCopy = formData.slice(); //백업용 복사
      let tempData: any = []; //선택된 데이터만 필터링
      checkedOne.forEach((item: any) => {
        let checkedFormData = formDataCopy.filter((row) => {
          return row.id === Number(item) ? row : "";
        });
        tempData.push(...checkedFormData);
      });
      setTempInfo(tempData);
    } else {
      let formDataCopy = formDataLazertinib.slice(); //백업용 복사
      let tempData: any = []; //선택된 데이터만 필터링
      checkedOne.forEach((item: any) => {
        let checkedFormData = formDataCopy.filter((row) => {
          return row.id === Number(item) ? row : "";
        });
        tempData.push(...checkedFormData);
      });
      setTempInfo(tempData);
    }
  };

  const onClickNext = (data: any) => {
    //다음 문진 번호
    if (tempI < data.length - 1) {
      tempI = tempI + 1;
    } else if ((tempI = data.length - 1)) {
      return;
    }
    return setTempI(tempI);
  };

  const onClickPrev = (data: any) => {
    //이전 문진 번호
    if (tempI > 0) {
      tempI = tempI - 1;
    } else if ((tempI = 0)) {
      tempI = data.length - 1;
    }
    return setTempI(tempI);
  };

  const onClickBack = () => {
    setTempI(0);
  };

  const onChangeTempAnswer = (e: any) => {
    //클릭시마다 입력값 전달
    let sendAnswer = {
      [e.target.name]: e.target.value,
    };
    onSubmitTempForm(sendAnswer);
    setTimeout(function () {
      if (tempI < tempInfo.length - 1) {
        onClickNext(tempInfo);
      } else {
        setTempResultOpen(true);
      }
    }, 500);
  };

  const onSubmitTempForm = (data: any) => {
    let resultSubmit = {
      id: tempReportId,
      ...data,
    };

    if (!data) {
      return;
    } else {
      axios
        .put(common.SERVER_URL + "/report", resultSubmit, common.headers)
        .then((res) => {
          if (!res.data.ok) {
            return;
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const [closeDiaglog, setCloseDialog] = useState(false);
  const onOpenCloseDialog = () => {
    setCloseDialog(true);
  };

  const onCloseDialog = () => {
    setCloseDialog(false);
  };

  const onCheck = () => {
    onCloseDialog();
    history.push("/intro");
  };

  return (
    <>
      {!tempResultOpen ? (
        <>
          <InnerHeader
            headerData={tempInfo}
            i={tempI}
            onClickNext={onClickNext}
            onClickPrev={onClickPrev}
            onClickBack={onClickBack}
            leftIcon={prevIcon}
            rightIcon={closeIcon}
            onOpenCloseDialog={onOpenCloseDialog}
          />
          <TempInnerContent
            onChangeAnswer={onChangeTempAnswer}
            tempInfo={tempInfo}
            i={tempI}
          />
          {closeDiaglog && (
            <CommonDialog
              title={
                "실시간 증상위험 입력을 그만하시겠습니까? 입력하던 내용은 저장됩니다."
              }
              onCheck={onCheck}
              onCloseDialog={onCloseDialog}
            />
          )}
        </>
      ) : (
        <>
          <Result reportId={tempReportId} />
        </>
      )}
    </>
  );
};

export default TempContent;
