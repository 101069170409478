import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { SymptomToKo } from "../../datas/dataFilter";
import { MainContext } from "../../datas/Store";

const ResultCard = ({ row, onOpenEachOne }: any) => {
  const [filteredHistory, setFilteredHistory] = useState<any[]>([]);
  const [noneData, setNoneData] = useState<any[]>([]);
  const [warnData, setWarnData] = useState<any[]>([]);
  const [nullData, setNullData] = useState<any[]>([]);
  const { userId } = useContext(MainContext);

  useEffect(() => {
    filteringHistoryData(row);
  }, [row]);
  const filteringHistoryData = (row: any) => {
    //히스토리 데이터중 null, 0단계 제외
    const valueSymptom = Object.entries(row);
    const nullInput = valueSymptom.filter(
      (
        item //입력 안한 값 = null은 리스트에서 제외
      ) => (item[1] === null ? item : "")
    );
    setNullData(nullInput);
    const filtered = valueSymptom.filter((item) =>
      item[0] !== "id" &&
      item[1] !== null &&
      Number(item[1]) < 5 &&
      Number(item[1]) > 0
        ? item
        : ""
    );
    setFilteredHistory(filtered);
    const none = valueSymptom.filter(
      (
        item //히스토리 데이터중 null, 0단계만
      ) => (item[1] === null || item[1] === 0 ? item : "")
    );
    setNoneData(none);
    const warn = valueSymptom.filter(
      (
        item //히스토리 데이터중 level 3 이상만
      ) => (Number(item[1]) >= 3 && Number(item[1]) < 5 ? item : "")
    );
    setWarnData(warn);
  };

  return (
    // LT사용자가 아니면 실시간 입력까지 보여주고 LT사용자라면 실시간 입력은 제외하고 출력
    <>
      {filteredHistory.length > 0 && userId.indexOf("E") !== 0 ? (
        <div className="flex flex-col py-2 px-4">
          <div
            onClick={() =>
              onOpenEachOne(
                filteredHistory,
                row.updatedAt,
                noneData,
                warnData,
                row.type
              )
            }
            className={
              "p-3 bg-white rounded-xl shadow border-l-8 " +
              (row.type === "R"
                ? "border-gray-point"
                : "border-yellow-secondPoint")
            }
          >
            <div className="flex justify-between items-center">
              <div className="flex w-5/6 flex-col gap-2 font-bold text-gray-600">
                <h6>
                  {row.type === "R" ? "정기입력" : "실시간 입력"}
                  <span className="ml-1">
                    ({dayjs(row.reportDay).format("YYYY.MM.DD")})
                  </span>
                </h6>
                <div className="flex flex-wrap gap-1">
                  {filteredHistory.map((one) => (
                    <h2 className="text-sm font-normal" key={one[0]}>
                      {SymptomToKo(one[0])}
                    </h2>
                  ))}
                </div>
              </div>
              <span className="inline-flex justify-center items-center rounded-full w-14 h-6 bg-yellow-point text-xs">
                더보기
              </span>
            </div>
          </div>
        </div>
      ) : userId.indexOf("E") === 0 && row.type === "R" ? (
        <div className="flex flex-col py-2 px-4">
          <div
            onClick={() =>
              onOpenEachOne(
                filteredHistory,
                row.updatedAt,
                noneData,
                warnData,
                row.type
              )
            }
            className={
              "p-3 bg-white rounded-xl shadow border-l-8 " +
              (row.type === "R"
                ? "border-gray-point"
                : "border-yellow-secondPoint")
            }
          >
            <div className="flex justify-between items-center">
              <div className="flex w-5/6 flex-col gap-2 font-bold text-gray-600">
                <h6>
                  {row.type === "R" ? "정기입력" : "실시간 입력"}
                  <span className="ml-1">
                    ({dayjs(row.reportDay).format("YYYY.MM.DD")})
                  </span>
                </h6>
                <div className="flex flex-wrap gap-1">
                  {filteredHistory.map((one) => (
                    <h2 className="text-sm font-normal" key={one[0]}>
                      {SymptomToKo(one[0])}
                    </h2>
                  ))}
                </div>
              </div>
              <span className="inline-flex justify-center items-center rounded-full w-14 h-6 bg-yellow-point text-xs">
                더보기
              </span>
            </div>
          </div>
        </div>
      ) : nullData.length === 0 ? (
        <div className="flex flex-col py-2 px-4">
          <div
            className={
              "p-3 bg-white rounded-xl shadow border-l-8 " +
              (row.type === "R"
                ? "border-gray-point"
                : "border-yellow-secondPoint")
            }
          >
            <div className="flex justify-between items-center">
              <div className="flex w-5/6 flex-col gap-2 font-bold text-gray-600">
                <h6>
                  {row.type === "R" ? "정기입력" : "실시간 입력"}
                  <span className="ml-1">
                    ({dayjs(row.reportDay).format("YYYY.MM.DD")})
                  </span>
                </h6>
                <div className="flex flex-wrap gap-1">
                  '발생한 증상이 없습니다.'
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ResultCard;
