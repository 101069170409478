import React, { useState, useRef, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { MainContext } from "../datas/Store";
import axios from "axios";
import common from "../datas/common";
import RegularHeader from "../components/RegularType/RegularHeader";
import BottomMenuBar from "../components/Common/BottomMenuBar";
import CommonDialog from "../components/Common/CommonDialog";
import { ToastContainer, toast } from "react-toastify";

const Setting = () => {
  const { userId, onLoggedOutUser } = useContext(MainContext);
  const history = useHistory();

  const homeIcon = { backgroundImage: "url('/images/homebold.svg')" };
  const prevIcon = { backgroundImage: "url('/images/arrow-left.svg')" };
  const refToggle = useRef<any>();
  const [pushChecked, setPushChecked] = useState(true); //기본값 true
  const [logoutDialog, setLogOutDialog] = useState(false);
  useEffect(() => {
    // if (!userId) {
    //   history.push(`/mobilelogin`);
    // } else {
    axios
      .get(common.SERVER_URL + "/patient/pId/" + userId, common.headers)
      .then((res) => {
        if (res.data.patient.pushOn) {
          setPushChecked(true);
        } else {
          setPushChecked(false);
        }
      })
      .catch((err) => console.log(err));
    // }
  }, [userId, history]);

  const onChangeToggle = (e: any) => {
    setPushChecked(e.target.checked);

    let params = {
      patientId: userId,
      pushOn: e.target.checked,
    };
    axios
      .post(common.SERVER_URL + "/patient/pushOn", params, common.headers)
      .then((res) => {
        if (res.data.ok) {
          if (params.pushOn) {
            //checked시 알람 발송(기본값)
            toast.success("푸쉬 알람 설정이 저장되었습니다", {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            //unchecked시 알람 미발송
            toast.success("푸쉬 알람 미발송 처리 되었습니다", {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else {
          toast.error(res.data.error, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const onLogout = () => {
    setLogOutDialog(true);
  };

  const onCloseAlertDialog = () => {
    setLogOutDialog(false);
  };
  const logout = () => {
    let params = {
      patientId: userId,
    };
    axios
      .post(common.SERVER_URL + "/patient/logout", params, common.headers)
      .then((res) => {
        if (res.data.ok) {
          onLoggedOutUser();
          history.push(`/mobilelogin`);
        } else {
          toast.error(res.data.error, { position: toast.POSITION.TOP_RIGHT });
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <div className="max-w-md h-screen flex flex-col justify-between items-center mx-auto bg-gray-100">
        <div className="w-full flex-shrink-0 flex flex-col justify-between text-gray-600">
          <RegularHeader
            headerTitle={"알림 설정"}
            rightIcon={homeIcon}
            leftIcon={prevIcon}
          />

          <div className="flex flex-col gap-4 mt-14 p-4">
            <div className="bg-white p-4 shadow-lg rounded-xl">
              <div className="flex justify-between items-center">
                <h4 className="font-bold">푸쉬 알림</h4>
                <div className="relative inline-block w-12 mr-2 align-middle">
                  <input
                    ref={refToggle}
                    type="checkbox"
                    name="toggle"
                    id="toggle"
                    onChange={onChangeToggle}
                    className={
                      pushChecked
                        ? " right-0 duration-200 ease-in absolute block w-7 h-7 rounded-full bg-blue-point border-4 outline-none appearance-none cursor-pointer"
                        : " left-0 bg-white absolute block w-7 h-7 rounded-full border-4 appearance-none cursor-pointer"
                    }
                  />
                  <label
                    htmlFor="toggle"
                    className="block overflow-hidden h-7 rounded-full bg-gray-300 cursor-pointer"
                  ></label>
                </div>
              </div>
              <div className="mt-4">
                <p>
                  &middot; 푸쉬알림을 해제하시면 정기입력 알람을 받으실 수
                  없습니다
                </p>
                <p>&middot; 알림받기 설정을 권고 드립니다</p>
              </div>
            </div>

            <div className="bg-white shadow-lg rounded-xl flex justify-between items-center p-4">
              <h4 className="font-bold">로그아웃 설정</h4>
              <button
                onClick={onLogout}
                className="inline-block bg-gray-point text-white font-bold px-4 py-1.5 rounded-md"
              >
                로그아웃
              </button>
            </div>
          </div>
        </div>
        <BottomMenuBar />
      </div>
      <ToastContainer />
      <>
        {!logoutDialog ? (
          <></>
        ) : (
          <CommonDialog
            title={
              "로그아웃 처리시 푸쉬 알림을 받으실 수 없습니다. 로그아웃 하시겠습니까?"
            }
            onCloseDialog={onCloseAlertDialog}
            onCheck={logout}
          />
        )}
      </>
    </>
  );
};

export default Setting;
