import React, { useState, useContext, useEffect, useCallback } from "react";
import { MainContext } from "../../mobile/datas/Store";
import { useHistory } from "react-router-dom";
import axios from "axios";
import common from "../../mobile/datas/common";
import Sidebar from "../components/Common/Sidebar";
import Navbar from "../components/Common/Navbar";
import SubNavbar from "../components/Common/SubNavbar";
import AlertDialog from "../components/Common/AlertDialog";
import AdminAccountList from "../components/Authority/AdminAccountList";
import AdminInfoDialog from "../components/Authority/AdminInfoDialog";

const AdminAccount = () => {
    const { adminId, page, postPerPage, doctorSort, handlePageChange, sortedTitle, searchedText } = useContext(MainContext);
    const history = useHistory();
    const [totalAdminCount, setTotalAdminCount] = useState();
    const alertNoneIcon = { backgroundImage: "url('/images/alert-none.svg')" };
    const alertTakenIcon = { backgroundImage: "url('/images/alert-notice.svg')" };
    const arrowDownIcon = { backgroundImage: "url('/images/arrow-yellow-down.svg')" };
    const lockIcon = { backgroundImage: "url('/images/lock.svg')" };
    const unlockIcon = { backgroundImage: "url('/images/unlock.svg')" };
    const [adminData, setAdminData] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [alarmData, setAlarmData] = useState([]);
    const [openAdminInfoAlert, setOpenAdminInfoAlert] = useState(false);

    const onOpenAlert = () => {
        setOpenAlert(!openAlert);
    };

    const onOpenAdminInfoAlert = () => {
        setOpenAdminInfoAlert(!openAdminInfoAlert);
    };

    const bringAlarmData = () => {
        axios.get(common.SERVER_URL + "/report/alarm", common.headers)
            .then(res => {
                if (res.data) {
                    setAlarmData(res.data.alarmList);
                }
            })
            .catch(err => console.log(err))
    }

    const bringAdminList = useCallback((
        sort = "",
        searchText = "",
        page = 1,
        postPerPage
    ) => {
        let params = {
            sort: sort ? sort : "",
            searchText: searchText ? searchText : "",
            page,
            countPerPage: postPerPage,
        };

        axios
            .post(common.SERVER_URL + "/admin/searchPage", params, common.headers)
            .then((res) => {
                if (res.data) {
                    setAdminData(res.data.adminList);
                    setTotalAdminCount(res.data.totalCount);
                }
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        if (!adminId) {
            history.push(`/adminlogin`);
        } else {
            bringAlarmData();
            if (searchedText) {
                bringAdminList("", searchedText, page, postPerPage);
            } else {
                bringAdminList(sortedTitle, "", page, postPerPage);
            }

        }
    }, [page, adminId, sortedTitle, doctorSort, history, postPerPage, searchedText, bringAdminList]);

    const [openPatientInfo, setOpenPatientInfo] = useState(false);
    const [openPatientInfoChange, setOpenPatientInfoChange] = useState(false);

    const backToPatientList = () => { //환자 목록 돌아가기
        setOpenPatientInfo(false);
        setOpenPatientInfoChange(false);
        handlePageChange(1);
    };

    const [openAdminInfo, setOpenAdminInfo] = useState(false);
    const [openAdminInfoChange, setOpenAdminInfoChange] = useState(false);

    const onOpenAdminDetails = () => { //관리자 정보 내역
        setOpenAdminInfo(true);
    }

    const onOpenAdminChange = () => { //관리자 정보 수정
        setOpenAdminInfoChange(true);
    };

    const backToAdminList = () => { //관리자 목록 돌아가기
        setOpenAdminInfo(false);
        setOpenAdminInfoChange(false);
        handlePageChange(1);
    };

    return (
        <>
            <div className="flex h-screen antialiased text-gray-600 bg-gray-100">
                {/* side bar */}
                <Sidebar
                    openPatientInfo={openPatientInfo}
                    openPatientInfoChange={openPatientInfoChange}
                    openAdminInfo={openAdminInfo}
                    openAdminInfoChange={openAdminInfoChange}
                    backToPatientList={backToPatientList}
                    backToAdminList={backToAdminList}
                />

                {/* content */}
                <div className="flex-1 h-full overflow-x-hidden overflow-y-auto">
                    {/* navbar */}
                    <header className="relative bg-gray-point h-12 flex flex-col md:h-28">
                        <Navbar
                            alertIcon={alarmData.length > 0 ? alertTakenIcon : alertNoneIcon}
                            arrowDownIcon={arrowDownIcon}
                            lockIcon={lockIcon}
                            unlockIcon={unlockIcon}
                            onOpenAlert={onOpenAlert}
                            onOpenAdminInfoAlert={onOpenAdminInfoAlert}
                        />
                        <SubNavbar
                            openAdminInfo={openAdminInfo}
                            openAdminInfoChange={openAdminInfoChange} />
                    </header>

                    {/* main content */}
                    <main className="mb-4">
                        {/* 관리자 관리 목록 */}
                        <AdminAccountList
                            bringAdminList={bringAdminList}
                            adminData={adminData}
                            totalCount={totalAdminCount}
                            openAdminInfo={openAdminInfo}
                            openAdminInfoChange={openAdminInfoChange}
                            onOpenAdminDetails={onOpenAdminDetails}
                            onOpenAdminChange={onOpenAdminChange}
                            backToAdminList={backToAdminList}
                        />
                    </main>
                </div>
            </div>

            <AlertDialog
                openAlert={openAlert}
                onOpenAlert={onOpenAlert}
                alarmData={alarmData}
            />

            {openAdminInfoAlert &&
                <AdminInfoDialog
                    onOpenAdminInfoAlert={onOpenAdminInfoAlert}
                    adminId={adminId}
                />
            }
        </>
    );
};

export default AdminAccount;
