import React from 'react';
import dayjs from 'dayjs';
import { bgColor } from '../../datas/stepColor';

const HighGradeCard = ({ gradeData, cycleDate }: any) => {

    return (
        <div className='flex flex-col gap-4 p-4 rounded-xl shadow bg-white'>
            <div className='flex flex-col items-center border-b pb-2'>
                <h1 className='font-bold'>심각한 Grade 증상</h1>
                <span className='text-sm text-gray-400'>{cycleDate.startDate && dayjs(cycleDate.startDate).format("YYYY.MM.DD") + ' ~ ' + dayjs(cycleDate.endDate).format("YYYY.MM.DD")}</span>
            </div>
            {gradeData.length > 0 ? (gradeData.map(
                (item: any, idx: number) => (
                    <div key={idx} className='flex justify-between'>
                        <div>
                            <span className='w-5 h-5 inline-flex justify-center items-center bg-gray-300 rounded-full text-white text-xs font-bold mr-2'>{idx + 1}</span>
                            <span>{item.name}</span>
                        </div>
                        <div className="flex gap-1.5 items-center text-sm">
                            <span className={'w-5 h-5 rounded-full flex-grow-0' + bgColor(item.num)}></span>
                            <div className='mr-1'>Grade {item.num}</div>
                        </div>
                    </div>
                ))) : ('발생한 증상 데이터가 없습니다.')}
        </div >
    );
};

export default HighGradeCard;