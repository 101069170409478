import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const RegularHeader = ({ headerTitle, rightIcon, leftIcon }: any) => {
  const history = useHistory();
  const goBack = () => { //뒤로가기 
    history.goBack();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className="fixed top-0 left-0 w-full z-10 p-4 bg-white">
      <div className="flex justify-between">
        <span onClick={goBack} className="w-6 h-6 bg-cover" style={leftIcon}></span>
        <div className="font-extrabold">{headerTitle}</div>
        <Link to="/intro" className="w-6 h-6 bg-cover" style={rightIcon}></Link>
      </div>
    </div>
  );
};

export default RegularHeader;
