import React, { useContext } from "react";
import { MainContext } from "../../../mobile/datas/Store";
import { useHistory } from "react-router-dom";

const ProfTableData = ({ data, checkHandler }: any) => {
  const { onProfileCheck } = useContext(MainContext);
  const history = useHistory();
  const pathname = window.location.pathname;
  const onProfile = () => {
    if (data) {
      onProfileCheck(data);
      if (pathname === `/adminmain`) {
        history.push(`/report/${data.patientId}`);
      } else if (pathname === `/graph`) {
        history.push(`/statistics/${data.patientId}`);
      }
    } else {
      return;
    }
  };

  return (
    <tr className="text-sm text-gray-500 border-b border-gray-200 hover:bg-gray-100">
      {pathname === `/adminmain` ? (
        <td className="border-t-0  align-middle flex  justify-center items-center  border-l-0 border-r-0 whitespace-nowrap mt-3">
          <label className="bg-white border-2 rounded border-gray-400 w-5 h-5  flex flex-shrink-0 justify-center items-center focus-within:border-gray-500">
            <input
              type="checkbox"
              value={data.patientId}
              onChange={(e) => checkHandler(e)}
              className="opacity-0 absolute "
            />
          </label>
        </td>
      ) : (
        <td></td>
      )}

      <td
        onClick={onProfile}
        className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3 cursor-pointer
            hover:font-bold"
      >
        {data.patientId}
      </td>
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
        {data.lastTreatment}
      </td>
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
        {data.medicine}
      </td>
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
        {data.doctorName}
      </td>
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
        {data.patientId.indexOf("E") !== 0 ? (
          <span>
            {data.reportDanger.isRecurrenceDanger ? "재발 위험 발생" : "없음"}
          </span>
        ) : (
          <span>-</span>
        )}
      </td>
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
        {data.patientId.indexOf("E") !== 0 ? (
          <span>
            {data.reportDanger.isGradeDanger ? "G3 이상 발생" : "없음"}
          </span>
        ) : (
          <span>-</span>
        )}
      </td>
      <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3">
        <span
          className={
            "font-bold py-1 px-4 rounded-full text-white" +
            (data.researchEnd ? " bg-red-point" : " bg-blue-point")
          }
        >
          {data.researchEnd ? "연구 중단" : "연구 진행중"}
        </span>
      </td>
      <td
        onClick={onProfile}
        className="border-t-0 px-4 align-middle border-l-0 border-r-0 whitespace-nowrap p-3 cursor-pointer"
      >
        <span className="font-bold py-1 px-4 rounded-full text-gray-600 bg-yellow-point">
          더보기
        </span>
      </td>
    </tr>
  );
};

export default ProfTableData;
