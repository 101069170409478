import React, { useContext, useState, useEffect, useCallback } from "react";
import { MainContext } from "../../../mobile/datas/Store";
import axios from "axios";
import common from "../../../mobile/datas/common";
import CommonDialog from "../../../mobile/components/Common/CommonDialog";
import { todayFormal, todayTimeFormal } from "../../../mobile/datas/dataFilter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PatientInfoChange = ({
  backToList,
  bringCheckedPatient,
  bringPatientList,
}: any) => {
  const { adminId, patientId, postPerPage } = useContext(MainContext);
  const [patientInfo, setPatientInfo] = useState<any>({});
  const [doctorList, setDoctorList] = useState<any[]>([]);
  const [treatmentHistory, setTreatmentHistory] = useState<any>([]);
  const [previousVisitDay, setPreviousVisitDay] = useState("");
  const [researchEndDialog, setResearchEndDialog] = useState(false);
  const [researchEndData, setResearchEndData] = useState({
    patientId,
    researchEnd: Boolean,
    adminId,
    researchEndType: "",
    reason: "",
    other: "",
    inputAdminName: "",
  });

  const onOpenAlertDialog = () => {
    if (!researchEndData.researchEndType) {
      toast.error("종료 구분을 선택해주세요", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!researchEndData.reason) {
      toast.error("종료 사유를 선택해주세요", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setResearchEndDialog(true);
    }
  };

  const onCloseDialog = () => {
    setResearchEndDialog(false);
  };

  const bringPatientInfo = useCallback(() => {
    axios
      .get(common.SERVER_URL + "/patient/pId/" + patientId, common.headers)
      .then((res) => {
        setPatientInfo(res.data.patient);
        setTreatmentHistory(res.data.treatmentList);
      })
      .catch((err) => console.log(err));
  }, [patientId]);

  const bringDoctorList = () => {
    let params = {
      type: "D",
    };
    axios
      .post(common.SERVER_URL + "/admin/search", params, common.headers)
      .then((res) => {
        if (res.data) {
          let doctorArray = [];
          doctorArray = res.data.adminList;
          doctorArray.push({ adminId: "", name: "선택하세요" });
          setDoctorList([...doctorArray.reverse()]);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    bringPatientInfo();
    bringDoctorList();
  }, [patientId, bringPatientInfo]);

  const [patientRevData, setPatientRevData] = useState({
    adminId,
    patientId: patientInfo.patientId,
    pass: "",
    birth: patientInfo.birth,
    gender: patientInfo.gender,
    weight: patientInfo.weight,
    height: patientInfo.height,
    disease: patientInfo.disease,
    operationYN: patientInfo.operationYN,
    anticancerMedicine: patientInfo.anticancerMedicine,
    medicine: patientInfo.medicine,
    underlyingdisease: patientInfo.underlyingdisease,
    memo: patientInfo.memo,
    doctorId: patientInfo.doctorId,
  });

  const onChangePreviousVisitDay = (e: any) => {
    setPreviousVisitDay(e.target.value);
  };

  let visits: any = [];

  const AddPreviousVisitDay = () => {
    let params = {
      patientId: patientInfo.patientId,
      visitDay: previousVisitDay,
      adminId: adminId,
    };

    axios
      .post(common.SERVER_URL + "/patient/treatment", params, common.headers)
      .then((res) => {
        if (res.data.ok) {
          toast.success(
            patientId.indexOf("E") !== 0
              ? "투약일이 저장 되었습니다."
              : "방문일이 저장 되었습니다.",
            {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          if (bringCheckedPatient) {
            bringCheckedPatient();
          }
          bringPatientInfo();
          backToList();
          bringPatientList("", "", "", "", 1, postPerPage);
        } else {
          toast.error(res.data.error, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const onSubmitPreviousVisitDay = (e: any) => {
    //투약일 등록
    e.preventDefault();

    if (!previousVisitDay) {
      toast.error(
        patientId.indexOf("E") !== 0
          ? "투약일을 선택해주세요."
          : "방문일을 선택해주세요.",
        {
          autoClose: 3000,
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else if (treatmentHistory) {
      //중복일 검사
      let dates = treatmentHistory.map((item: any) => item.visitDay);
      visits.push(...dates);
      if (visits.includes(previousVisitDay)) {
        toast.error(
          patientId.indexOf("E") !== 0
            ? "이미 등록된 투약일입니다."
            : "이미 등록된 방문일입니다.",
          {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else {
        AddPreviousVisitDay();
      }
    } else {
      AddPreviousVisitDay();
    }
  };

  const onDeletePrevioouseVisitDay = (row: any) => {
    //투약일이 삭제
    let params = {
      patientId: patientInfo.patientId,
      visitDay: row,
      adminId: adminId,
    };
    axios
      .post(
        common.SERVER_URL + "/patient/treatmentDelete",
        params,
        common.headers
      )
      .then((res) => {
        if (res.data.ok) {
          toast.success(
            patientId.indexOf("E") !== 0
              ? "투약일이 삭제 되었습니다."
              : "방문일이 삭제 되었습니다.",
            {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          bringPatientInfo();
          backToList();
        } else {
          toast.error(res.data.error, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    let patientParams = {
      adminId,
      patientId: patientInfo.patientId,
      pass: patientRevData.pass ? patientRevData.pass : "",
      birth: patientRevData.birth ? patientRevData.birth : patientInfo.birth,
      gender: patientRevData.gender
        ? patientRevData.gender
        : patientInfo.gender,
      weight: patientRevData.weight
        ? patientRevData.weight
        : patientInfo.weight,
      height: patientRevData.height
        ? patientRevData.height
        : patientInfo.height,
      disease: patientRevData.disease
        ? patientRevData.disease
        : patientInfo.disease,
      operationYN: patientRevData.operationYN
        ? patientRevData.operationYN
        : patientInfo.operationYN,
      anticancerMedicine: patientRevData.anticancerMedicine
        ? patientRevData.anticancerMedicine
        : patientInfo.anticancerMedicine,
      medicine: patientRevData.medicine
        ? patientRevData.medicine
        : patientInfo.medicine,
      underlyingdisease: patientRevData.underlyingdisease
        ? patientRevData.underlyingdisease
        : patientInfo.underlyingdisease,
      doctorId: patientRevData.doctorId
        ? patientRevData.doctorId
        : patientInfo.doctorId,
    };

    if (
      !patientRevData.anticancerMedicine &&
      !patientRevData.birth &&
      !patientRevData.disease &&
      !patientRevData.underlyingdisease &&
      !patientRevData.doctorId &&
      !patientRevData.gender &&
      !patientRevData.height &&
      !patientRevData.medicine &&
      !patientRevData.memo &&
      !patientRevData.operationYN &&
      !patientRevData.pass &&
      !patientRevData.weight
    ) {
      toast.error("환자 정보를 수정한 내역이 없습니다.", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      axios
        .post(
          common.SERVER_URL + "/patient/edit",
          patientParams,
          common.headers
        )
        .then((res) => {
          if (res.data.ok) {
            toast.success("회원 정보가 수정되었습니다.", {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
            if (bringCheckedPatient) {
              bringCheckedPatient();
            }
            if (bringPatientList) {
              bringPatientList("", "", "", "", 1, postPerPage);
            }
            backToList();
          } else {
            toast.error(res.data.error, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const onChangeData = (e: any) => {
    const { name, value } = e.target;
    setPatientRevData({ ...patientRevData, [name]: value });
  };

  const onChangeGender = (e: any) => {
    setPatientRevData({ ...patientRevData, gender: e.target.value });
  };

  const onChangeAnticancerMedicine = (e: any) => {
    setPatientRevData({
      ...patientRevData,
      anticancerMedicine: e.target.value,
    });
  };

  const onChangeDoctor = (e: any) => {
    setPatientRevData({ ...patientRevData, doctorId: e.target.value });
  };

  const onChangeOperation = (e: any) => {
    setPatientRevData({ ...patientRevData, operationYN: e.target.value });
  };

  const onChangeMedicine = (e: any) => {
    setPatientRevData({ ...patientRevData, medicine: e.target.value });
  };

  const onChangeReseachEndData = (e: any) => {
    const { name, value } = e.target;
    setResearchEndData({ ...researchEndData, [name]: value });
  };

  const onChangeReseachReason = (e: any) => {
    const { name, value } = e.target;
    setResearchEndData({ ...researchEndData, [name]: value });
  };

  const onSaveResearchEnd = () => {
    //연구 중단
    let params = {
      patientId,
      researchEnd: true,
      adminId,
      researchEndType: researchEndData.researchEndType,
      reason: researchEndData.reason,
      reasonOther: researchEndData.other,
      inputAdminName: adminId + " (" + todayTimeFormal() + ")",
    };
    if (!researchEndData.researchEndType) {
      toast.error("종료 구분을 선택해주세요", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!researchEndData.reason) {
      toast.error("종료 사유를 입력해주세요", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      axios
        .post(
          common.SERVER_URL + "/patient/researchEnd",
          params,
          common.headers
        )
        .then((res) => {
          if (res.data.ok) {
            toast.success(patientId + "님의 연구 종료가 입력되었습니다", {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
            onCloseDialog();
            backToList();
            bringPatientList("", "", "", "", 1, postPerPage);
          } else {
            toast.error(res.data.error, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const onSaveResearchStart = () => {
    //연구 종료 취소 및  개시
    let params = {
      patientId,
      researchEnd: false,
      adminId,
    };

    axios
      .post(common.SERVER_URL + "/patient/researchEnd", params, common.headers)
      .then((res) => {
        if (res.data.ok) {
          toast.success(patientId + "님의 연구가 개시되었습니다.", {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
          onCloseDialog();
          backToList();
          bringPatientList("", "", "", "", 1, postPerPage);
        } else {
          toast.error(res.data.error, {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <form className="bg-white rounded p-4 mb-4" onSubmit={onSubmitForm}>
        <div className="flex text-sm">
          <div className="flex mx-2 flex-1">
            <div className="w-28 flex-shrink-0 font-bold h-6 mt-3 leading-6">
              회원번호
            </div>
            <div className="w-full my-2 p-1 flex border border-gray-200">
              <input
                disabled
                placeholder={patientInfo.patientId}
                className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
              />
            </div>
          </div>
          <div className="flex mx-2 flex-1">
            <div className="w-28 flex-shrink-0 font-bold h-6 mt-3 leading-6">
              비밀번호
            </div>
            <div className="w-full bg-white my-2 p-1 flex border border-gray-200">
              <input
                className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring font-mono"
                name="pass"
                type="password"
                autoComplete="on"
                placeholder="비밀번호"
                value={patientRevData.pass || ""}
                onChange={onChangeData}
              />
            </div>
          </div>
        </div>

        <div className="flex text-sm">
          <div className="flex mx-2 flex-1">
            <div className="w-28 flex-shrink-0 font-bold h-6 mt-3 leading-6">
              생년
            </div>
            <div className="w-full bg-white my-2 p-1 flex border border-gray-200">
              <input
                placeholder={patientInfo.birth}
                className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                name="birth"
                maxLength={4}
                value={patientRevData.birth || ""}
                onChange={onChangeData}
              />
            </div>
          </div>
          <div className="flex mx-2 flex-1">
            <div className="w-28 flex-shrink-0 font-bold h-6 mt-3 leading-6">
              성별
            </div>
            <div className="flex w-full gap-4 items-center my-2 p-1">
              <label htmlFor="gender">남자</label>
              <input
                type="radio"
                className="form-radio mr-2"
                name="gender"
                value="M"
                onChange={onChangeGender}
                checked={
                  !patientRevData.gender
                    ? patientInfo.gender === "M"
                      ? true
                      : false
                    : patientRevData.gender === "M"
                    ? true
                    : false
                }
              />

              <label htmlFor="gender">여자</label>
              <input
                type="radio"
                className="form-radio"
                name="gender"
                value="F"
                onChange={onChangeGender}
                checked={
                  !patientRevData.gender
                    ? patientInfo.gender === "F"
                      ? true
                      : false
                    : patientRevData.gender === "F"
                    ? true
                    : false
                }
              />
            </div>
          </div>
        </div>

        <div className="flex text-sm">
          <div className="flex mx-2 flex-1">
            <div className="w-28 flex-shrink-0 font-bold h-6 mt-3 leading-6">
              담당교수
            </div>
            <div className="w-full bg-white my-2 p-1 flex border border-gray-200">
              <select
                name="anticancerMedicine"
                onChange={onChangeDoctor}
                className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                value={patientRevData.doctorId || patientRevData.doctorId}
              >
                {doctorList.map((doctor: any, idx: number) => (
                  <option key={doctor.adminId} value={doctor.adminId}>
                    {doctor.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex mx-2 flex-1">
            <div className="flex flex-1">
              <div className="w-28 flex-shrink-0 font-bold h-6 mt-3 leading-6">
                키(cm)
              </div>
              <div className="w-full bg-white my-2 p-1 flex border border-gray-200">
                <input
                  placeholder={patientInfo.height}
                  type="number"
                  className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                  name="height"
                  value={patientRevData.height || ""}
                  onChange={onChangeData}
                  min="120"
                  max="250"
                />
              </div>
            </div>

            <div className="flex mx-2 flex-1">
              <div className="w-28 flex-shrink-0 font-bold h-6 mt-3 leading-6">
                체중(kg)
              </div>
              <div className="w-full bg-white my-2 p-1 flex border border-gray-200">
                <input
                  placeholder={patientInfo.weight}
                  type="number"
                  className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                  name="weight"
                  value={patientRevData.weight || ""}
                  onChange={onChangeData}
                  min="20"
                  max="250"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex text-sm">
          <div className="flex mx-2 flex-1">
            <div className="w-28 flex-shrink-0 font-bold h-6 mt-3 leading-6">
              병명
            </div>
            <div className="w-full bg-white my-2 p-1 flex border border-gray-200">
              <input
                disabled
                placeholder={patientInfo.disease}
                className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
              />
            </div>
          </div>
          <div className="flex mx-2 flex-1">
            <div className="w-28 flex-shrink-0 font-bold h-6 mt-3 leading-6">
              수술여부
            </div>
            <div className="flex w-full gap-4 items-center my-2 p-1">
              <label htmlFor="operationYN">수술</label>
              <input
                type="radio"
                className="form-radio mr-2"
                name="operationYN"
                value="수술"
                onChange={onChangeOperation}
                checked={
                  !patientRevData.operationYN
                    ? patientInfo.operationYN === "수술"
                      ? true
                      : false
                    : patientRevData.operationYN === "수술"
                    ? true
                    : false
                }
              />

              <label htmlFor="operationYN">비수술</label>
              <input
                type="radio"
                className="form-radio"
                name="operationYN"
                value="비수술"
                onChange={onChangeOperation}
                checked={
                  !patientRevData.operationYN
                    ? patientInfo.operationYN === "비수술"
                      ? true
                      : false
                    : patientRevData.operationYN === "비수술"
                    ? true
                    : false
                }
              />
            </div>
          </div>
          <div className="flex mx-2 flex-1">
            <div className="w-28 flex-shrink-0 font-bold h-6 mt-3 leading-6">
              항암제군
            </div>
            <div className="w-full bg-white my-2 p-1 flex border border-gray-200">
              <select
                name="anticancerMedicine"
                onChange={onChangeAnticancerMedicine}
                className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                value={
                  patientRevData.anticancerMedicine ||
                  patientInfo.anticancerMedicine ||
                  ""
                }
              >
                {/* Lazertinib연구 간호가는 환자 추가 등록시 선택사항에 '치료제'만 출력 */}
                {adminId === "admin1" ? (
                  <>
                    <option value="">선택하세요</option>
                    <option value="1차 치료제">1차 치료제</option>
                    <option value="2차 치료제">2차 치료제</option>
                    <option value="치료제">치료제</option>
                  </>
                ) : adminId === "smc09" ? (
                  <>
                    <option value="">선택하세요</option>
                    <option value="치료제">치료제</option>
                  </>
                ) : (
                  <>
                    <option value="">선택하세요</option>
                    <option value="1차 치료제">1차 치료제</option>
                    <option value="2차 치료제">2차 치료제</option>
                  </>
                )}
              </select>
            </div>
          </div>
          <div className="flex mx-2 flex-1">
            <div className="w-28 flex-shrink-0 font-bold h-6 mt-3 leading-6">
              약제
            </div>
            <div className="w-full bg-white my-2 p-1 flex border border-gray-200">
              <select
                name="medicine"
                onChange={onChangeMedicine}
                className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                value={patientRevData.medicine || patientInfo.medicine || ""}
              >
                {patientRevData.anticancerMedicine === "1차 치료제" ||
                (patientRevData.anticancerMedicine !== "2차 치료제" &&
                  patientRevData.anticancerMedicine !== "치료제" &&
                  patientInfo.anticancerMedicine === "1차 치료제") ? (
                  <>
                    <option value="">선택하세요</option>
                    <option value="pembrolizumab(1차)">
                      pembrolizumab(1차)
                    </option>
                    <option value="pembrolizumab + pemetrexed + carboplatin">
                      pembrolizumab + pemetrexed + carboplatin
                    </option>
                    <option value="pembrolizumab + paclitaxel + carboplatin">
                      pembrolizumab + paclitaxel + carboplatin
                    </option>
                  </>
                ) : patientRevData.anticancerMedicine === "2차 치료제" ||
                  (patientRevData.anticancerMedicine !== "1차 치료제" &&
                    patientRevData.anticancerMedicine !== "치료제" &&
                    patientInfo.anticancerMedicine === "2차 치료제") ? (
                  <>
                    <option value="">선택하세요</option>
                    <option value="pembrolizumab(2차)">
                      pembrolizumab(2차)
                    </option>
                    <option value="atezolizumab">atezolizumab</option>
                    <option value="nivolumab">nivolumab</option>
                    <option value="docetaxel">docetaxel</option>
                  </>
                ) : patientRevData.anticancerMedicine === "치료제" ||
                  (patientRevData.anticancerMedicine !== "1차 치료제" &&
                    patientRevData.anticancerMedicine !== "2차 치료제" &&
                    patientInfo.anticancerMedicine === "치료제") ? (
                  <>
                    <option value="">선택하세요</option>
                    <option value="Lazertinib">Lazertinib</option>
                  </>
                ) : null}
              </select>
            </div>
          </div>
        </div>

        <div className="flex text-sm">
          <div className="flex mx-2 flex-1">
            <div className="w-28 flex-shrink-0 font-bold h-6 mt-3 leading-6">
              기저질환
            </div>
            <div className="w-full bg-white my-2 p-1 flex border border-gray-200">
              <input
                placeholder={patientInfo.underlyingdisease}
                className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                name="underlyingdisease"
                value={patientRevData.underlyingdisease || ""}
                onChange={onChangeData}
              />
            </div>
          </div>
          <div className="flex mx-2 flex-1">
            <div className="w-28 flex-shrink-0 font-bold h-6 mt-3 leading-6">
              특이사항
            </div>
            <div className="w-full bg-white my-2 p-1 flex border border-gray-200">
              <input
                placeholder={patientInfo.memo}
                className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                name="memo"
                value={patientRevData.memo || ""}
                onChange={onChangeData}
              ></input>
            </div>
          </div>
        </div>

        <div className="flex-auto flex flex-row-reverse p-2 mt-4">
          <button
            type="submit"
            className="ml-2 focus:outline-none flex justify-center px-8 py-2 rounded font-bold
    hover:bg-gray-600 hover:text-white bg-gray-200 border duration-200 ease-in-out border-gray-600 transition"
          >
            저장하기
          </button>
          <button
            onClick={backToList}
            className="focus:outline-none flex justify-center px-8 py-2 rounded font-bold
        hover:bg-gray-600 hover:text-white bg-gray-200 border duration-200 ease-in-out border-gray-600 transition"
          >
            목록
          </button>
        </div>
      </form>

      <div className="bg-white rounded mb-4 p-4 text-sm">
        <div className="flex">
          <div className="flex mx-2 flex-1">
            <div className="w-28 flex-shrink-0 font-bold h-6 mt-3 leading-6">
              연구 상태
            </div>
            <div className="w-full bg-white my-2 p-1 flex border border-gray-200">
              {patientInfo.researchEnd ? "연구 종료" : "연구 진행중"}
            </div>
          </div>
          <div
            className={
              "mx-2 items-center " +
              (patientInfo.researchEnd ? "hidden" : "flex flex-1")
            }
          >
            '연구 종료'로 변경 시 아래 내용을 입력 후 '연구 종료' 버튼을
            클릭해주세요.
          </div>
          <div
            onClick={onSaveResearchStart}
            className={
              "flex justify-center items-center m-2 text-white px-4 bg-gray-point rounded-md cursor-pointer " +
              (patientInfo.researchEnd ? "flex" : "hidden")
            }
          >
            연구 개시
          </div>
        </div>
      </div>

      {!patientInfo.researchEnd && (
        <div className="w-full">
          <div className="flex p-4 rounded bg-white mb-4">
            <div className="flex flex-grow flex-col pr-2.5">
              <h3 className="font-bold mb-1">연구 종료 입력</h3>
              <div className="flex">
                <div className="flex mx-2 flex-1">
                  <div className="w-28 flex-shrink-0 font-bold h-6 mt-3 leading-6">
                    종료 구분
                  </div>
                  <div className="flex w-full gap-2 items-center my-2 p-1">
                    <label>임상시험 종료</label>
                    <input
                      type="radio"
                      className="form-radio mr-6"
                      name="researchEndType"
                      value={"임상시험 종료" || ""}
                      onChange={onChangeReseachEndData}
                    />

                    <label>앱 사용 종료</label>
                    <input
                      type="radio"
                      className="form-radio"
                      name="researchEndType"
                      value={"앱 사용 종료" || ""}
                      onChange={onChangeReseachEndData}
                    />
                  </div>
                </div>
              </div>
              <div className="flex">
                <div className="flex mx-2 flex-1">
                  <div className="w-28 flex-shrink-0 font-bold h-6 mt-3 leading-6">
                    종료 사유
                  </div>
                  <div className="w-full bg-white my-2 mr-4 p-1 flex border border-gray-200">
                    <select
                      name="reason"
                      onChange={onChangeReseachReason}
                      className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                    >
                      {researchEndData.researchEndType === "" ? (
                        <>
                          <option value="">선택하세요</option>
                          <option value="시험 추척관찰 완료">
                            시험 추척관찰 완료
                          </option>
                          <option value="동의 철회">동의 철회</option>
                          <option value="임상실험 담당 의사 판단">
                            임상실험 담당 의사 판단
                          </option>
                          <option value="임상실험 계획 위반">
                            임상실험 계획 위반
                          </option>
                          <option value="추적 관찰 실패">추적 관찰 실패</option>
                          <option value="이상 반응">이상 반응</option>
                          <option value="질병의 진행">질병의 진행</option>
                          <option value="사망">사망</option>
                          <option value="앱 사용만 종료">앱 사용만 종료</option>
                          <option value="기타">기타</option>
                        </>
                      ) : researchEndData.researchEndType ===
                        "임상시험 종료" ? (
                        <>
                          <option value="">선택하세요</option>
                          <option value="시험 추척관찰 완료">
                            시험 추척관찰 완료
                          </option>
                          <option value="동의 철회">동의 철회</option>
                          <option value="임상실험 담당 의사 판단">
                            임상실험 담당 의사 판단
                          </option>
                          <option value="임상실험 계획 위반">
                            임상실험 계획 위반
                          </option>
                          <option value="추적 관찰 실패">추적 관찰 실패</option>
                          <option value="이상 반응">이상 반응</option>
                          <option value="질병의 진행">질병의 진행</option>
                          <option value="사망">사망</option>
                          <option value="기타">기타</option>
                        </>
                      ) : (
                        <>
                          <option value="">선택하세요</option>
                          <option value="앱 사용만 종료">앱 사용만 종료</option>
                          <option value="기타">기타</option>
                        </>
                      )}
                    </select>
                  </div>
                  <div className="w-full bg-white my-2 p-1 flex border border-gray-200">
                    <input
                      placeholder="기타 사유"
                      className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                      name="other"
                      value={researchEndData.other || ""}
                      onChange={onChangeReseachEndData}
                    />
                  </div>
                </div>
              </div>

              <div className="flex">
                <div className="flex mx-2 flex-1">
                  <div className="w-28 flex-shrink-0 font-bold h-6 mt-3 leading-6">
                    종료 입력자
                  </div>
                  <div className="w-full bg-white my-2 p-1 flex border border-gray-200">
                    <input
                      disabled
                      placeholder={adminId + " (" + todayTimeFormal() + ")"}
                      className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                      name="inputAdminName"
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end items-end">
              <div
                onClick={onOpenAlertDialog}
                className="py-3 w-40 text-lg text-center text-white bg-gray-point rounded-lg font-bold cursor-pointer"
              >
                연구 종료
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex items-center bg-white p-4 text-sm">
        <div className="flex items-center mx-2 flex-1">
          <div className="w-28 flex-shrink-0 font-bold h-6 leading-6">
            {patientId.indexOf("E") !== 0 ? "투약일 추가" : "방문일 추가"}
          </div>
          <form
            onSubmit={onSubmitPreviousVisitDay}
            className="w-full bg-white my-2 p-1 flex gap-4 "
          >
            <input
              type="date"
              max={todayFormal()}
              className="border border-gray-200 p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
              onChange={onChangePreviousVisitDay}
            />
            <button
              type="submit"
              className="w-20 bg-yellow-point py-1.5 px-5 text-white font-semibold rounded-lg hover:shadow-lg transition duration-3000"
            >
              추가
            </button>
          </form>
        </div>

        <div className="flex items-center mx-2 flex-1">
          <div className="w-36 font-bold h-6 leading-6">
            {patientId.indexOf("E") !== 0 ? "투약일" : "방문일"}
          </div>
          <ul className="flex flex-col items-center gap-1">
            {treatmentHistory && (
              <li className="flex flex-wrap items-center gap-4 p-1">
                {treatmentHistory.map((date: any) => (
                  <span
                    onClick={() => onDeletePrevioouseVisitDay(date.visitDay)}
                    key={date.id}
                    className="flex items-center"
                  >
                    {date.visitDay}
                    <button className="mx-1 p-1 inline-flex items-center text-gray-800 max-w-max shadow-sm hover:shadow-lg rounded-full w-5 h-5">
                      <svg
                        width="32"
                        height="32"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 32 32"
                      >
                        <path d="M12 12h2v12h-2z" fill="currentColor"></path>
                        <path d="M18 12h2v12h-2z" fill="currentColor"></path>
                        <path
                          d="M4 6v2h2v20a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8h2V6zm4 22V8h16v20z"
                          fill="currentColor"
                        ></path>
                        <path d="M12 2h8v2h-8z" fill="currentColor"></path>
                      </svg>
                    </button>
                    |{" "}
                  </span>
                ))}
              </li>
            )}
          </ul>
        </div>
      </div>
      {researchEndDialog && (
        <CommonDialog
          title={`${patientId} 환자를 연구종료 하시겠습니까? 종료하시면 환자의 앱사용이 중단됩니다 확인 후 클릭해주세요`}
          onCloseDialog={onCloseDialog}
          onCheck={onSaveResearchEnd}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default PatientInfoChange;
