import { PieChart } from 'react-minimal-pie-chart';

const IntroChart = ({ introContents }) => {

    return (
        <div className="max-w-md mx-auto grid grid-cols-3 gap-4 text-center font-bold pb-10">
            <div className='relative'>
                <PieChart
                    data={[{ value: introContents.firstRate, color: '#F6CB44', name: introContents.first }]}
                    reveal={introContents.firstRate}
                    lineWidth={20}
                    background="#f3f3f3"
                    lengthAngle={360}
                    rounded
                    animate
                    label={({ dataEntry }) => dataEntry.value + '%'}
                    labelStyle={{
                        fontSize: '26px',
                        fill: '#33333'
                    }}
                    labelPosition={0}
                />
                <strong className='absolute text-sm text-gray-400 bottom-4 right-0 left-0 margin-auto'>{introContents.first}</strong>
            </div>

            <div className='relative'>
                <PieChart
                    data={[{ value: introContents.secondRate, color: '#F6CB44', name: introContents.second }]}
                    reveal={introContents.secondRate}
                    lineWidth={20}
                    background="#f3f3f3"
                    lengthAngle={360}
                    rounded
                    animate
                    label={({ dataEntry }) => dataEntry.value + '%'}
                    labelStyle={{
                        fontSize: '26px',
                        fill: '#333333'
                    }}
                    labelPosition={0}
                />
                <strong className='absolute text-sm text-gray-400 bottom-4 right-0 left-0 margin-auto'>{introContents.second}</strong>
            </div>

            <div className='relative'>
                <PieChart
                    data={[{ value: introContents.thirdRate, color: '#F6CB44', name: introContents.third }]}
                    reveal={introContents.thirdRate}
                    lineWidth={20}
                    background="#f3f3f3"
                    lengthAngle={360}
                    rounded
                    animate
                    label={({ dataEntry }) => dataEntry.value + '%'}
                    labelStyle={{
                        fontSize: '26px',
                        fill: '#333333'
                    }}
                    labelPosition={0}
                />
                <strong className='absolute text-sm text-gray-400 bottom-4 right-0 left-0 margin-auto'>{introContents.third}</strong>
            </div>
        </div>
    );
};

export default IntroChart;