import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../datas/Store";
import axios from "axios";
import common from "../datas/common";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DoctorChoice = ({ onCloseDoctorChoice }: any) => {
  const { userId } = useContext(MainContext);
  const [doctorList, setDoctorList] = useState<any[]>([]);
  const [doctor, setDoctor] = useState('');

  const bringDoctorList = () => {
    let params = {
      type: "D",
    };
    axios
      .post(common.SERVER_URL + "/admin/search", params, common.headers)
      .then((res) => {
        if (res.data) {
          let doctorArray = [];
          doctorArray = res.data.adminList;
          setDoctorList([...doctorArray]);
        }
      })
      .catch((err) => console.log(err));
  };

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    let doctorParams = {
      patientId: userId,
      doctorId: doctor
    }

    if (!doctor) {
      toast.error('담당 의사 선생님을 선택해주세요', { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
    } else {
      axios.post(common.SERVER_URL + "/patient/registDoctor", doctorParams, common.headers)
        .then((res) => {
          if (res.data.ok) {
            toast.success("담당 의사 선생님이 저장되었습니다", { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
            onCloseDoctorChoice();
          } else {
            toast.error(res.data.error, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
          }
        })
        .catch((err) => console.log(err));
    }
  }

  useEffect(() => {
    bringDoctorList();
  }, []);

  return (
    <>
      <div className='w-full'>
        <div className="flex-shrink-0 flex flex-col gap-4 text-gray-600">
          <div className="font-extrabold text-xl text-center">의료진 선택</div>
          <div className="text-2xl font-extrabold text-center mb-6">
            진료 받을 담당 의사 선생님을 선택해주세요.
          </div>
        </div>
        <form onSubmit={onSubmitForm}>
          <div className="grid grid-cols-2 gap-4 text-center">
            {doctorList && doctorList.map((doctor: any, idx: number) => (
              <label
                key={idx}
                className="flex gap-1 justify-between items-center px-2 py-2 border border-gray-300 rounded-lg 
                hover:bg-lightyellow-point focus:bg-lightyellow-point shadow"
              >
                <div className="flex flex-grow-1 justify-center items-center w-8 h-8">
                  <img src='/images/doctor.svg' alt='doctor' />
                  <input className="hidden" type="checkbox" value={doctor.adminId} onChange={(e) => setDoctor(e.target.value)} />
                </div>
                <div>{doctor.name}</div>
              </label>
            ))}
          </div>

          <button type="submit" className="w-full bg-yellow-point w-full py-2.5 text-sm font-semibold text-center rounded mt-6">
            확인
          </button>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default DoctorChoice;
