import React, { useContext } from "react";
import { MainContext } from "../../datas/Store";

const CompleteMsg = ({ openTodayResult }: any) => {
  const { userId } = useContext(MainContext);

  return (
    <>
      <div className="flex flex-col h-screen gap-8 justify-center text-center">
        <div className="mx-auto">
          <div className=" flex justify-center items-center mb-6">
            <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-28 w-28"></div>
          </div>
          <p className="font-bold text-gray-600">
            {userId}님의 증상을 분석 중입니다.<br />
            잠시만 기다려 주세요.
          </p>
        </div>
      </div>
    </>
  );
};

export default CompleteMsg;
