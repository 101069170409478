import React, { useState, useContext, useEffect, useCallback } from "react";
import { MainContext } from "../../../mobile/datas/Store";
import axios from "axios";
import common from "../../../mobile/datas/common";
import { useHistory } from "react-router-dom";
import InfoHeader from "./InfoHeader";
import PatientInfo from "./PatientInfo";
import TrendCharts from "../Charts/TrendChart";
import PatientInfoEditDialog from "../Register/PatientInfoEditDialog";
import { SymptomIcon } from "../../../mobile/datas/dataFilter";
import DailyInputTable from "../Charts/DailyInputTable";
import LoadingDialog from "../Common/LoadingDialog";
import { todayFormal } from "../../../mobile/datas/dataFilter";
import { ToastContainer, toast } from "react-toastify";
import dayjs from "dayjs";

import "react-toastify/dist/ReactToastify.css";

const MainStatistics = () => {
  const { patientId, checkedPatient } = useContext(MainContext);
  const history = useHistory();
  const [graphData, setGraphData] = useState<any>([]);
  const [cycleDate, setCycleDate] = useState({
    startDate: "",
    endDate: "",
    type: 1,
  }); //기본 1, 3 사이클
  const [weekDate, setWeekDate] = useState({
    startDate: "",
    endDate: "",
    type: 3,
  }); //기본 3, 6 주
  const [searchDate, setSearchDate] = useState<any>({ startD: "", endD: "" }); //검색 날짜
  const [chosenSymptom, setChosenSymptom] = useState();
  const [historyData, setHistoryData] = useState([]);
  const [openPatientInfoEdit, setOpenPatientInfoEdit] = useState(false);
  const [patinetInfo, setPatientInfo] = useState({});
  const [loading, setLoading] = useState(false); //로딩창

  const bringUserGraphData = useCallback(
    (patientId, cycle, week, startDate, endDate) => {
      //증상 트렌드 그래프
      let params = {
        patientId,
        cycle,
        week,
        startDate,
        endDate,
      };

      axios
        .post(
          common.SERVER_URL + "/report/searchDetail2",
          params,
          common.headers
        )
        .then((res) => {
          if (res.data.ok) {
            setGraphData(res.data.symptomDateGrades); //총 21개 증상별 그래프
            setLoading(false);
            setCycleDate({
              startDate: dayjs(res.data.searchStartDay).format("YYYY.MM.DD"),
              endDate: dayjs(res.data.searchEndDay).format("YYYY.MM.DD"),
              type: cycle,
            });
            setWeekDate({
              startDate: dayjs(res.data.searchStartDay).format("YYYY.MM.DD"),
              endDate: dayjs(res.data.searchEndDay).format("YYYY.MM.DD"),
              type: week,
            });
          } else {
            toast.error(res.data.error, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    []
  );

  const bringTotalData = useCallback(() => {
    //날짜별 증상 목록
    axios
      .get(common.SERVER_URL + "/report/reporter/" + patientId, common.headers)
      .then((res) => {
        if (res.data) {
          const data = res.data;
          setHistoryData(data.slice(0, 30));
        }
      })
      .catch((err) => console.log(err));
  }, [patientId]);

  const settingCycle = useCallback((cycle: number) => {
    setLoading(true);
    setSearchDate({ startD: "", endD: "" });
    bringUserGraphData(patientId, cycle, null, "", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // *   Lazertinib 사용자는 싸이클이 아닌 3, 6주를 기준으로 그래프 나타냄
  const settingWeek = useCallback((week: number) => {
    setLoading(true);
    setSearchDate({ startD: "", endD: "" });
    bringUserGraphData(patientId, null, week, "", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bringCheckedPatient = useCallback(() => {
    axios
      .get(common.SERVER_URL + "/patient/pId/" + patientId, common.headers)
      .then((res) => setPatientInfo(res.data.patient))
      .catch((err) => console.log(err));
  }, [patientId]);

  useEffect(() => {
    if (!patientId) {
      history.push("/adminLogin");
    } else if (!checkedPatient) {
      history.push("/adminmain");
    } else if (patientId.indexOf("E") !== 0) {
      bringCheckedPatient();
      settingCycle(1);
      bringTotalData();
    } else {
      bringCheckedPatient();
      settingWeek(3);
      bringTotalData();
    }
  }, [
    bringCheckedPatient,
    bringTotalData,
    checkedPatient,
    history,
    patientId,
    settingCycle,
    settingWeek,
  ]);

  const onSearchDate = (e: any) => {
    const { name, value } = e.target;
    setSearchDate({ ...searchDate, [name]: value });
  };

  const onOpenSearchData = () => {
    if (!searchDate.startD || !searchDate.endD) {
      toast.error("시작일과 종료일을 입력해주세요", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      bringUserGraphData(patientId, "", "", searchDate.startD, searchDate.endD);
    }
  };

  const onOpenSymptom = (item: any) => {
    setChosenSymptom(item);
  };

  const onOpenPatientInfoEdit = () =>
    setOpenPatientInfoEdit(!openPatientInfoEdit); //환자 정보 수정 버튼 클릭 후 오픈

  return (
    <>
      {!openPatientInfoEdit ? (
        <>
          <div className="px-4 flex flex-col gap-8 sm:gap-4 mb-8">
            <div className="bg-white p-6 rounded-lg">
              {patinetInfo && <InfoHeader checkedPatient={patinetInfo} />}
              {/* 환자 증상 요약 */}
              {patinetInfo && (
                <PatientInfo
                  checkedPatient={patinetInfo}
                  onOpenPatientInfoEdit={onOpenPatientInfoEdit}
                />
              )}
            </div>

            <main>
              {/* 날짜 세팅 */}
              <div className="flex flex-col flex-wrap gap-4 rounded-md py-2">
                <div className="flex justify-between">
                  <div className="font-extrabold text-xl">
                    증상 조회{" "}
                    <span className="text-sm text-gray-400">
                      {patientId.indexOf("E") !== 0
                        ? "1 사이클 동안 많이 발생한 증상 순서대로 출력됩니다.(기본 1사이클)"
                        : "조회 기간에 많이 발생한 증상이 순서대로 출력됩니다. ​(기본 3주)"}
                    </span>
                  </div>
                  <div className="font-bold">
                    {searchDate.endD
                      ? searchDate.startD + " ~ " + searchDate.endD
                      : cycleDate.startDate + " ~ " + cycleDate.endDate}
                  </div>
                </div>

                <div className="flex justify-between items-center flex-wrap mb-4 p-4 border-2 border-gray-700">
                  {patientId.indexOf("E") !== 0 ? (
                    <div className="flex gap-4">
                      <button
                        onClick={() => settingCycle(1)}
                        className={
                          "border px-4 py-1 font-bold hover:shadow-lg " +
                          (cycleDate.type === 1
                            ? "border-gray-500 text-gray-700"
                            : "border-gray-50 text-gray-400")
                        }
                      >
                        1 사이클
                      </button>
                      <button
                        onClick={() => settingCycle(3)}
                        className={
                          "border px-4 py-1 font-bold hover:shadow-lg " +
                          (cycleDate.type === 3
                            ? "border-gray-500 text-gray-700"
                            : "border-gray-50 text-gray-400")
                        }
                      >
                        3 사이클
                      </button>
                    </div>
                  ) : (
                    <div className="flex gap-4">
                      <button
                        onClick={() => settingWeek(3)}
                        className={
                          "border px-4 py-1 font-bold hover:shadow-lg " +
                          (weekDate.type === 3
                            ? "border-gray-500 text-gray-700"
                            : "border-gray-50 text-gray-400")
                        }
                      >
                        3 주
                      </button>
                      <button
                        onClick={() => settingWeek(6)}
                        className={
                          "border px-4 py-1 font-bold hover:shadow-lg " +
                          (weekDate.type === 6
                            ? "border-gray-500 text-gray-700"
                            : "border-gray-50 text-gray-400")
                        }
                      >
                        6 주
                      </button>
                    </div>
                  )}
                  <div className="flex gap-4">
                    <div
                      className={
                        "font-bold " +
                        (!searchDate.startD
                          ? "border-gray-50 text-gray-400"
                          : "border-gray-500 text-gray-700")
                      }
                    >
                      <input
                        className="pl-2"
                        type="date"
                        max={todayFormal()}
                        name="startD"
                        value={searchDate.startD}
                        onChange={onSearchDate}
                      />
                    </div>

                    <div
                      className={
                        "font-bold " +
                        (!searchDate.startD
                          ? "border-gray-50 text-gray-400"
                          : "border-gray-500 text-gray-700")
                      }
                    >
                      <input
                        className="pl-2"
                        type="date"
                        max={todayFormal()}
                        name="endD"
                        value={searchDate.endD}
                        onChange={onSearchDate}
                      />
                    </div>

                    <button
                      onClick={onOpenSearchData}
                      className={
                        "ml-2 px-6 py-1 rounded-md font-bold hover:shadow-lg " +
                        (!searchDate.endD
                          ? "bg-gray-point text-white "
                          : "bg-yellow-point text-gray-600")
                      }
                    >
                      조회
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-6">
                {graphData !== null && graphData.length > 0 ? (
                  <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-8 gap-4 bg-white p-4 rounded-md">
                    {/* 그래프 증상 이름 카드 */}
                    {graphData &&
                      graphData[0] &&
                      graphData.map((item: any, idx: number) => (
                        <button
                          key={idx}
                          onClick={() => onOpenSymptom(item)}
                          className="bg-gray-50 border-2 px-4 py-2 rounded-lg shadow-md flex justify-between hover:border-yellow-point hover:bg-gray-100"
                        >
                          {patientId.indexOf("E") !== 0 ? (
                            <img
                              className="w-7 h-7 object-cover"
                              src={
                                SymptomIcon(item.name)
                                  ? SymptomIcon(item.name)
                                  : ""
                              }
                              alt={item.name}
                            />
                          ) : null}

                          <div className="flex flex-col justify-center items-center">
                            <p className="font-bold">{item.name}</p>
                          </div>
                        </button>
                      ))}
                  </div>
                ) : (
                  ""
                )}

                <div className="flex flex-col gap-4 bg-white p-6 rounded-md">
                  <div className="flex justify-between items-center">
                    <div className="font-extrabold text-xl">
                      증상 트렌드
                      <span className="text-sm text-gray-400">
                        {/* 정기입력, 최대: 3사이클 */}
                        {patientId.indexOf("E") !== 0
                          ? " 정기입력, 최대: 3사이클"
                          : "정기입력, 최대: 6주"}
                      </span>
                    </div>
                    {/* <span className='font-bold text-sm text-gray-400'>X: 입력없음</span> */}
                  </div>

                  {graphData !== null && graphData.length > 0 ? (
                    <div className="grid grid-cols-1 gap-4 mb-4 h-72">
                      <TrendCharts
                        item={chosenSymptom ? chosenSymptom : graphData[0]}
                      />
                    </div>
                  ) : (
                    <p className="mt-4">입력된 데이터가 없습니다.</p>
                  )}
                </div>

                <div className="flex flex-col gap-4 bg-white p-6 rounded-md">
                  <div className="flex justify-between items-center">
                    <div className="font-extrabold text-xl">
                      날짜별 목록
                      <span className="text-sm text-gray-400">
                        {" "}
                        (정기입력/실시간 입력, 최대 30건)
                      </span>
                    </div>
                    <span className="font-bold text-sm text-gray-400">
                      {patientId.indexOf("E") !== 0
                        ? "응급 증상 발생시 환자에게 병원 응급방문 알림창이 뜹니다."
                        : null}
                      {/* &middot; 응급 증상 발생시 환자에게 병원 응급방문 알림창이
                      뜹니다. */}
                    </span>
                  </div>
                  {historyData !== null && historyData.length > 0 ? (
                    <DailyInputTable historyData={historyData} />
                  ) : (
                    <p className="mt-4">입력된 데이터가 없습니다.</p>
                  )}
                </div>
              </div>
            </main>
          </div>
          {loading && <LoadingDialog checkedPatient={checkedPatient} />}
        </>
      ) : (
        <PatientInfoEditDialog
          checkedPatient={checkedPatient}
          onOpenPatientInfoEdit={onOpenPatientInfoEdit}
          backToList={onOpenPatientInfoEdit}
          bringCheckedPatient={bringCheckedPatient}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default MainStatistics;
