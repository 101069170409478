import React from "react";
import PatientInfoChange from './PatientInfoChange';

const PatientInfoEditDialog = ({ onOpenPatientInfoEdit, backToList, bringCheckedPatient }: any) => {

  return (
    <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-60 z-10">
      <div className="bg-white rounded shadow-lg w-2/3 h-5/6 text-gray-500 overflow-y-scroll bg-gray-100">
        <div className="px-4 py-2 flex justify-end items-center">
          <span onClick={onOpenPatientInfoEdit} >
            <svg
              className="w-6 h-6 cursor-pointer"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </span>
        </div>

        <div className='p-4 pt-0'>
          <h2 className='text-center font-extrabold text-xl mb-2'>환자 정보 수정</h2>
          <PatientInfoChange backToList={backToList} bringCheckedPatient={bringCheckedPatient} />
        </div>
      </div>
    </div>
  );
};

export default PatientInfoEditDialog;
