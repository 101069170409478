import React, { useContext, useState, useEffect } from 'react';
import { MainContext } from "../../../mobile/datas/Store";
import axios from "axios";
import common from "../../../mobile/datas/common";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminInfoChange = ({ backToList, bringAdminList }: any) => {
    const { adminId, checkedAdminId, postPerPage } = useContext(MainContext);
    const [adminInfo, setAdminInfo] = useState<any>({});
    const [adminRevData, setAdminRevData] = useState({
        adminId,
        regAdminId: adminInfo.adminId,
        pass: adminInfo.pass,
        name: adminInfo.name,
        //email: adminInfo.email,
        //phone: adminInfo.phone,
        organization: adminInfo.organization,
        type: adminInfo.type,
        memo: adminInfo.memo,
    });

    const onSubmitForm = (e: any) => {
        e.preventDefault();
        //let patternPhone = /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/;

        let adminParams = {
            adminId,
            regAdminId: adminRevData.regAdminId ? adminRevData.regAdminId : adminInfo.adminId,
            pass: adminRevData.pass ? adminRevData.pass : "",
            name: adminRevData.name ? adminRevData.name : adminInfo.name,
            //email: adminRevData.email ? adminRevData.email : adminInfo.email,
            //phone: adminRevData.phone ? adminRevData.phone : adminInfo.phone,
            organization: adminRevData.organization ? adminRevData.organization : adminInfo.organization,
            type: adminRevData.type ? adminRevData.type : adminInfo.type,
            memo: adminRevData.memo ? adminRevData.memo : adminInfo.memo,
        }
        if (!adminRevData.regAdminId && !adminRevData.pass && !adminRevData.name && !adminRevData.organization && !adminRevData.type && !adminRevData.memo) {
            toast.error("관리자 정보를 수정한 내역이 없습니다.", { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
        } else {
            axios.post(common.SERVER_URL + "/admin/edit", adminParams, common.headers)
                .then((res) => {
                    if (res.data.ok) {
                        toast.success("관리자 정보가 수정되었습니다", { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
                        bringAdminList("", "", 1, postPerPage);
                        backToList();
                    } else {
                        toast.error(res.data.error, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    const onChangeData = (e: any) => {
        const { name, value } = e.target;
        setAdminRevData({ ...adminRevData, [name]: value });
    }

    useEffect(() => {
        axios
            .get(common.SERVER_URL + "/admin/" + checkedAdminId, common.headers)
            .then((res) => setAdminInfo(res.data.admin))
            .catch((err) => console.log(err));
    }, [checkedAdminId]);

    return (
        <>
            <form className="p-4 bg-white rounded-md" onSubmit={onSubmitForm}>
                <div className="text-sm">
                    <div className="flex">
                        <div className="flex mx-2 flex-1">
                            <div className="w-28 font-bold h-6 mt-3 leading-6">아이디</div>
                            <div className="w-full bg-white my-2 p-1 flex border border-gray-200">
                                <input disabled placeholder={adminInfo.adminId} className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring" />
                            </div>
                        </div>
                        <div className="flex mx-2 flex-1">
                            <div className="w-28 font-bold h-6 mt-3 leading-6">비밀번호</div>
                            <div className="w-full bg-white my-2 p-1 flex border border-gray-200">
                                <input type='password' className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring font-mono"
                                    name="pass" autoComplete="on" placeholder="비밀번호" value={adminRevData.pass} onChange={onChangeData} />
                            </div>
                        </div>
                    </div>

                    <div className="flex">
                        <div className="flex mx-2 flex-1">
                            <div className="w-28 font-bold h-6 mt-3 leading-6">이름</div>
                            <div className="w-full bg-white my-2 p-1 flex border border-gray-200">
                                <input placeholder={adminInfo.name} className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                                    name="name" value={adminRevData.name} onChange={onChangeData} />
                            </div>
                        </div>
                        <div className="flex mx-2 flex-1">
                            <div className="w-28 font-bold h-6 mt-3 leading-6">등록일자</div>
                            <div className="w-full bg-white my-2 p-1 flex border border-gray-200">
                                <input disabled placeholder={adminInfo.createdAt && adminInfo.createdAt.slice(0, 10)} className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring" />
                            </div>
                        </div>

                    </div>

                    {/* 22.01.06 삼성병원 미팅 후 요청으로 관리자 등록 이메일, 휴대전화 hidden 처리 */}
                    {/* <div className="flex">
                        <div className="flex mx-2 flex-1">
                            <div className="w-28 font-bold h-6 mt-3 leading-6">이메일</div>
                            <div className="w-full bg-white my-2 p-1 flex border border-gray-200">
                                <input type="email" placeholder={adminInfo.email} className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                                    name="email" value={adminRevData.email} onChange={onChangeData} />
                            </div>
                        </div>
                        <div className="flex mx-2 flex-1">
                            <div className="w-28 font-bold h-6 mt-3 leading-6">휴대전화</div>
                            <div className="w-full bg-white my-2 p-1 flex border border-gray-200">
                                <input type='tel' placeholder={adminInfo.phone} className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                                    name="phone" value={adminRevData.phone} onChange={onChangeData} />
                            </div>
                        </div>
                    </div> */}

                    <div className="flex">
                        <div className="flex mx-2 flex-1">
                            <div className="w-28 font-bold h-6 mt-3 leading-6">소속</div>
                            <select className="w-full bg-white my-2 p-1 flex border border-gray-200 py-1.5 px-4 border focus:ring"
                                name="organization" value={!adminRevData.organization ? adminInfo.organization : adminRevData.organization} onChange={onChangeData} >
                                <option value="">선택하세요</option>
                                <option value="헤링스">헤링스</option>
                                <option value="서울삼성병원">서울삼성병원</option>
                                <option value="기타">기타</option>
                            </select>
                        </div>
                        <div className="flex mx-2 flex-1">
                            <div className="w-28 font-bold h-6 mt-3 leading-6">구분</div>
                            <select className="w-full bg-white my-2 p-1 flex border border-gray-200 py-1.5 px-4 border focus:ring"
                                name="type" value={!adminRevData.type ? adminInfo.type : adminRevData.type} onChange={onChangeData} >
                                <option value="">선택하세요</option>
                                <option value="N">간호사</option>
                                <option value="D">의사</option>
                                <option value="A">관리자</option>
                                <option value="SA">최고 관리자</option>
                            </select>
                        </div>
                    </div>

                    <div className="flex">
                        <div className="flex mx-2 flex-1">
                            <div className="w-28 font-bold h-6 mt-3 leading-6">메모</div>
                            <textarea placeholder={adminInfo.memo} className="bg-white my-2 p-1 w-full min-h-[100px] max-h-[300px] h-28 appearance-none 
                            block w-full bg-grey-lighter border py-4 px-4 focus:ring focus:outline-none"
                                name="memo" value={adminRevData.memo} onChange={onChangeData}></textarea>
                        </div>
                    </div>
                </div>

                <div className="flex-auto flex flex-row-reverse p-2 mt-4">
                    <button type='submit' className="ml-2 flex justify-center px-8 py-2 rounded font-bold hover:bg-gray-600 hover:text-white bg-gray-200 border duration-200 ease-in-out border-gray-600 transition">저장하기</button>
                    <button onClick={backToList} className="flex justify-center px-8 py-2 rounded font-bold hover:bg-gray-600 hover:text-white bg-gray-200 border duration-200 ease-in-out border-gray-600 transition">목록</button>
                </div>
            </form >
            <ToastContainer />
        </>
    );
};

export default AdminInfoChange;