import React, { useState, useContext, useEffect, useCallback } from "react";
import { MainContext } from "../../datas/Store";
import { bgColor, textColor } from "../../datas/stepColor";
import { SymptomToKo } from "../../datas/dataFilter";
import DangerGuideDialog from "./DangerGuideDialog";

const ResultTopContent = ({
  filteredLastData,
  temp,
  warningData,
  onBlockScroll,
  onScroll,
}: any) => {
  const { userId } = useContext(MainContext);
  const [openDangerGuide, setDangerGuide] = useState(false);
  const [emergencyData, setEmergencyData] = useState<any>([]);

  const onOpenDangerGuide = () => {
    setDangerGuide(!openDangerGuide);
    if (!openDangerGuide && onBlockScroll) {
      onBlockScroll();
    } else if (openDangerGuide && onBlockScroll) onScroll(); //스크롤 방지
  };

  const filterEmergencyAlert = useCallback(() => {
    //12가지 중 고위험 필터링
    const alertData = [
      ["pain", 3],
      ["headache", 3],
      ["fever", 4],
      ["anorexia", 4],
      ["nausea", 4],
      ["vomiting", 4],
      ["diarrhea", 4],
      ["dyspnea", 4],
      ["cough", 3],
      ["depression", 4],
      ["hoarseness", 3],
      ["hemoptysis", 4],
    ];

    let arrayFilter: any = [];
    if (warningData) {
      for (let i = 0; i < alertData.length; i++) {
        let filter = warningData.filter((data: any) => {
          return data[0] === alertData[i][0] && data[1] === alertData[i][1];
        });
        arrayFilter.push(...filter);
      }
      let emergencyAlertData = arrayFilter.filter(
        (item: any) => item.length !== 0
      );
      setEmergencyData(emergencyAlertData);
    }
  }, [warningData]);

  let keys: any = [];
  emergencyData.map((item: any) => keys.push(item[0]));

  useEffect(() => {
    filterEmergencyAlert();
  }, [filterEmergencyAlert]);

  return (
    <>
      {warningData[0] && (
        <p className="text-center bg-gray-point text-yellow-point py-2">
          위험 증상이 발생하였습니다.
          <span className="text-xs">(3단계 이상)</span>
        </p>
      )}
      <div className="text-center bg-gray-100 p-4 mb-4">
        {!temp ? (
          // LT 사용자는 숨기기
          <p className="text-lg">
            <span>{userId}님은 </span>
            {userId.indexOf("E") !== 0 ? (
              <span className="font-bold">지난 3일 동안</span>
            ) : (
              <span className="font-bold">지난 7일 동안</span>
            )}
            <br />
            <span className="font-extrabold text-gray-600">
              {filteredLastData.length}가지 증상이 발생하였습니다.
            </span>
          </p>
        ) : (
          <p className="text-lg">
            <span>{userId}님의 </span>
            <span className="inline font-bold">
              실시간 증상 확인 결과입니다.
            </span>
          </p>
        )}
      </div>

      <div className="flex flex-col gap-3 px-4 text-gray-600">
        <div className="bg-lightyellow-point font-bold py-2 px-4 rounded-3xl">
          {!temp ? "발생한 증상" : "증상 단계"}
        </div>
        {filteredLastData.length > 0
          ? filteredLastData.map((item: any, idx: number) => (
              <ul key={idx}>
                <li>
                  <ul className="flex justify-between mx-4">
                    <li className="text-gray-700">
                      {SymptomToKo(item[0], userId)}
                    </li>
                    <li
                      className={
                        "rounded-3xl px-4 " +
                        bgColor(Number(item[1])) +
                        textColor(Number(item[1]))
                      }
                    >
                      {Number(item[1])} 단계
                    </li>
                  </ul>
                </li>
                <li
                  onClick={onOpenDangerGuide}
                  className={
                    keys.includes(item[0])
                      ? " flex justify-around my-2"
                      : " hidden"
                  }
                >
                  {/* LT 사용자는 숨기기 */}
                  {userId.indexOf("E") !== 0 ? (
                    <span className="relative inline-flex rounded-md shadow-md">
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-1.5 border border-gray-400 text-white leading-6 rounded-md bg-gray-point 
                  focus:border-yellow-300 transition ease-in-out duration-150"
                      >
                        병원 응급 방문이 필요합니다.
                        <span className="ml-2">&gt;</span>
                      </button>
                      <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-point opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-yellow-point"></span>
                      </span>
                    </span>
                  ) : null}
                </li>
              </ul>
            ))
          : "발생한 증상이 없습니다."}
      </div>
      {openDangerGuide && (
        <DangerGuideDialog onOpenDangerGuide={onOpenDangerGuide} />
      )}
    </>
  );
};

export default ResultTopContent;
