import React, { useState, useEffect, useContext, useCallback } from "react";
import { MainContext } from "../datas/Store";
import { useHistory } from "react-router-dom";
import axios from "axios";
import common from "../datas/common";
import ResultHeader from "../components/Result/ResultHeader";
import HighFrequencyCard from "../components/Result/HighFrequencyCard";
import HighGradeCard from "../components/Result/HighGradeCard";
import FrequencyGraphCard from "../components/Result/FrequencyGraphCard";
import BottomMenuBar from "../components/Common/BottomMenuBar";
import CompleteMsg from "../components/Result/CompleteMsg";
import dayjs from "dayjs";

const StatiticsAnalysis = () => {
  const { userId } = useContext(MainContext);
  const history = useHistory();
  const prevIcon = { backgroundImage: "url('/images/arrow-left.svg')" };
  const closeIcon = { backgroundImage: "url('/images/homebar.svg')" };
  const [frenquencyData, setFrequcyData] = useState<any>([]);
  const [gradeData, setGradeData] = useState<any>([]);
  const [cycleDate, setCycleDate] = useState({ startDate: "", endDate: "" });
  const [graphData, setGraphData] = useState<any>([]);
  const [openData, setOpenDate] = useState(true);
  const [scrollToBlock, setScrollToBlock] = useState(false);

  const bringUserAllDate = useCallback(() => {
    const searchData = {
      patientId: userId,
      count: 15, //최근 입력건 최대 15건
      startDate: "",
      endDate: "",
    };
    let start = "";
    let end = "";

    axios
      .post(
        common.SERVER_URL + "/report/searchDetail1/",
        searchData,
        common.headers
      )

      .then((res) => {
        if (res.data) {
          setFrequcyData(res.data.symptomCounts.slice(0, 3)); //많이 발생한 증상
          setGradeData(res.data.symptomGrades.slice(0, 3)); //심각한 증상 카드
          // 기존 slice로 날짜를 얻는 방식은 하루 오차가 발생, dayjs 사용해 정상 결과 가져옴
          start = dayjs(res.data.searchStartDay).format("YYYY.MM.DD");
          end = dayjs(res.data.searchEndDay).format("YYYY.MM.DD");
          setCycleDate({ startDate: start, endDate: end });
        }
      })
      .catch((err) => console.log(err));
  }, [userId]);

  const bringUserGraphData = useCallback(() => {
    setOpenDate(true);
    let params = {
      patientId: userId,
      count: 15,
      startDate: "",
      endDate: "",
    };
    axios
      .post(common.SERVER_URL + "/report/searchDetail2", params, common.headers)
      .then((res) => {
        if (res.data.ok) {
          setGraphData(res.data.symptomDateGrades);
          setOpenDate(false);
        } else {
          setOpenDate(true);
        }
      })
      .catch((err) => console.log(err));
  }, [userId]);

  const onBlockScroll = () => {
    setScrollToBlock(true);
  };

  const onScroll = () => {
    setScrollToBlock(false);
  };

  useEffect(() => {
    if (!userId) {
      history.push(`/mobilelogin`);
    } else {
      window.scrollTo(0, 0);
      bringUserAllDate();
      bringUserGraphData();
    }
  }, [userId, history, bringUserAllDate, bringUserGraphData]);

  return (
    <>
      {!openData ? (
        <div
          className={
            "max-w-md mx-auto flex-shrink-0 flex flex-col justify-between text-gray-600 " +
            (scrollToBlock ? "fixed w-full h-full overflow-hidden" : "")
          }
        >
          <ResultHeader
            headTitle={"누적 통계 확인"}
            leftIcon={prevIcon}
            rightIcon={closeIcon}
          />
          <h2 className="mt-14 font-bold px-4 pt-2 bg-gray-100">
            &#x0002A; 정기입력 증상만 반영됩니다.
          </h2>
          <div className="flex flex-col gap-4 p-4 pt-2 mb-20 bg-gray-100">
            {frenquencyData && (
              <HighFrequencyCard
                frenquencyData={frenquencyData}
                cycleDate={cycleDate}
              />
            )}
            {userId.indexOf("E") !== 0
              ? gradeData && (
                  <HighGradeCard gradeData={gradeData} cycleDate={cycleDate} />
                )
              : null}

            {graphData && (
              <FrequencyGraphCard cycleDate={cycleDate} graphData={graphData} />
            )}
          </div>
          <BottomMenuBar onBlockScroll={onBlockScroll} onScroll={onScroll} />
        </div>
      ) : (
        <CompleteMsg />
      )}
    </>
  );
};

export default StatiticsAnalysis;
