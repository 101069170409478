import React, { useContext } from "react";
import formData from "../datas/FormData";
import formDataLazertinib from "../datas/FormDataLazertinib";
import { MainContext } from "../../mobile/datas/Store";

const InnerHeader = ({
  i,
  onClickPrev,
  headerData,
  leftIcon,
  rightIcon,
  onOpenCloseDialog,
  missingInfo,
}: any) => {
  const { userId } = useContext(MainContext);

  const progress = Math.floor(((i + 1) / headerData.length) * 100);

  return (
    <>
      {headerData && userId.indexOf("E") !== 0 ? (
        <div className="z-10">
          <div className="flex justify-between items-center py-5 px-3 bg-white">
            {i !== 0 ? (
              <span
                className="w-6 h-6 bg-cover"
                style={leftIcon}
                onClick={() =>
                  onClickPrev(missingInfo ? missingInfo : formData)
                }
              ></span>
            ) : (
              <span className="w-6"></span>
            )}

            <div className="font-extrabold">{headerData[i]?.nameKo}</div>

            {rightIcon ? (
              <span
                onClick={onOpenCloseDialog}
                className="w-6 h-6 bg-cover"
                style={rightIcon}
              ></span>
            ) : (
              <span className="w-6"></span>
            )}
          </div>

          <div className="text-xs text-gray-500 bg-gray-100">
            <div className="overflow-hidden h-2 mb-2 flex bg-gray-200">
              <div
                style={{ width: progress + "%" }}
                className="shadow-none whitespace-nowrap text-white justify-center bg-yellow-point"
              ></div>
            </div>
            <div className="flex justify-between items-center mx-2">
              <div className="font-bold inline-block">
                {i + 1}
                <span className="text-gray-400">/{headerData.length}</span>
              </div>

              <div className="text-xs font-semibold inline-block">
                {progress}%
              </div>
            </div>
          </div>
        </div>
      ) : (
        // * formDataLazertinib
        <div className="z-10">
          <div className="flex justify-between items-center py-5 px-3 bg-white">
            {i !== 0 ? (
              <span
                className="w-6 h-6 bg-cover"
                style={leftIcon}
                onClick={() =>
                  onClickPrev(missingInfo ? missingInfo : formDataLazertinib)
                }
              ></span>
            ) : (
              <span className="w-6"></span>
            )}

            <div className="font-extrabold">{headerData[i]?.nameKo}</div>

            {rightIcon ? (
              <span
                onClick={onOpenCloseDialog}
                className="w-6 h-6 bg-cover"
                style={rightIcon}
              ></span>
            ) : (
              <span className="w-6"></span>
            )}
          </div>

          <div className="text-xs text-gray-500 bg-gray-100">
            <div className="overflow-hidden h-2 mb-2 flex bg-gray-200">
              <div
                style={{ width: progress + "%" }}
                className="shadow-none whitespace-nowrap text-white justify-center bg-yellow-point"
              ></div>
            </div>
            <div className="flex justify-between items-center mx-2">
              <div className="font-bold inline-block">
                {i + 1}
                <span className="text-gray-400">/{headerData.length}</span>
              </div>

              <div className="text-xs font-semibold inline-block">
                {progress}%
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InnerHeader;
