import React from "react";
import { gettingOriginalAge } from "../../../mobile/datas/dataFilter";

const PatientInfo = ({ checkedPatient, onOpenPatientInfoEdit }: any) => {
  let danger = checkedPatient.reportDanger;
  // console.log(checkedPatient, "checkedPatient");
  return (
    <div
      className={
        checkedPatient.patientId && checkedPatient.patientId.indexOf("E") !== 0
          ? "grid xs:grid-cols-1 text-xs grid-cols-2 gap-4"
          : "grid xs:grid-cols-1 text-xs gap-4"
      }
    >
      {/* 환자 기본 정보 */}
      <div className="flex flex-col border rounded-md">
        <div className="flex justify-between py-2 px-3">
          <h1 className="font-extrabold text-lg">{checkedPatient.patientId}</h1>
          {onOpenPatientInfoEdit && (
            <button
              onClick={onOpenPatientInfoEdit}
              className="font-bold border border-gray-400 rounded px-2 py-1 bg-gray-50 hover:shadow-lg hover:text-gray-700"
            >
              환자 정보 수정
            </button>
          )}
        </div>

        <div className="flex border-t p-1 divide-x">
          <div className="flex w-1/2">
            <strong className="font-bold px-2">
              {checkedPatient.patientId &&
              checkedPatient.patientId.indexOf("E") !== 0
                ? "마지막 투약일"
                : "마지막 방문일"}
            </strong>
            <div>{checkedPatient.lastTreatment}</div>
          </div>

          <div className="flex w-1/2">
            <strong className="font-bold px-2">등록일</strong>
            <div>{checkedPatient.firstShotDay}</div>
          </div>
        </div>

        <div className="flex border-t p-1 divide-x">
          <div className="flex w-1/2">
            <strong className="font-bold px-2">성별</strong>
            <div>{checkedPatient.gender}</div>
          </div>

          <div className="flex w-1/2">
            <strong className="font-bold px-2">생년(나이)</strong>
            {checkedPatient.birth && (
              <div>
                {checkedPatient.birth} (
                {gettingOriginalAge(checkedPatient.birth)}세)
              </div>
            )}
          </div>
        </div>

        <div className="flex border-t p-1 divide-x">
          <div className="flex w-1/2">
            <strong className="font-bold px-2">키/체중</strong>
            <div>
              {checkedPatient.height}cm / {checkedPatient.weight}kg
            </div>
          </div>
          <div className="flex w-1/2">
            <strong className="font-bold px-2">항암제</strong>
            <div>{checkedPatient.medicine}</div>
          </div>
        </div>
      </div>

      {/* 환자 위험 경고, 메모 */}
      {checkedPatient.patientId &&
      checkedPatient.patientId.indexOf("E") !== 0 ? (
        <div className="flex flex-col border-l border-r text-xs rounded-md">
          <div className="flex h-1/2 border-t border-b veticle-middle">
            <strong className="w-1/5 font-bold px-1 my-auto text-center">
              Grade 위험
            </strong>

            <div className="w-4/5 p-1 border-l">
              {danger && danger.isGradeDanger ? (
                danger.gradeDangerList.map((item: any, idx: number) => (
                  <span key={idx} className="mr-2">
                    {item.name}
                  </span>
                ))
              ) : (
                <p>Grade 위험이 없습니다.</p>
              )}
            </div>
          </div>

          <div className="flex h-1/2 border-b">
            <strong className="w-1/5 font-bold p-1 my-auto text-center">
              재발 위험
            </strong>
            <div className="w-4/5 p-1 border-l">
              {danger && danger.isRecurrenceDanger ? (
                danger.recurrenceDangerList.map((item: any, idx: number) => (
                  <span key={idx} className="mr-2">
                    {item.description}
                  </span>
                ))
              ) : (
                <p>재발 위험이 없습니다.</p>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PatientInfo;
