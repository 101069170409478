import React from "react";
import { ResponsivePie } from "@nivo/pie";

const GenderChart = ({ gender }) => {
  const data = gender.map((one) => {
    var obj = {
      id: one ? one.name : "",
      label: one ? one.name : "",
      value: one ? one.count : "",
    };
    return obj;
  });
  // [
  // {
  //     "id": "남자",
  //     "label": "남자",
  //     "value": gender[0] ? gender[0].count : ''
  // },
  // {
  //     "id": "여자",
  //     "label": "여자",
  //     "value": gender[1] ? gender[1].count : ''
  // },
  // ]

  return (
    <>
      <h6 className="text-sm font-bold m-2 text-center">성별 분포</h6>
      <ResponsivePie
        data={data}
        margin={{ top: 20, right: 50, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        colors={{ scheme: "nivo" }}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
        legends={[
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateX: 0,
            translateY: 56,
            itemsSpacing: 0,
            itemWidth: 70,
            itemHeight: 18,
            itemTextColor: "#999",
            itemDirection: "left-to-right",
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "#000",
                },
              },
            ],
          },
        ]}
      />
    </>
  );
};

export default GenderChart;
