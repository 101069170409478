import React, { useContext, useState, useEffect } from "react";
import { MainContext } from "../../../mobile/datas/Store";
import { useHistory } from "react-router-dom";
import axios from "axios";
import common from "../../../mobile/datas/common";
import { todayTime } from "../../../mobile/datas/dataFilter";
import SidebarNavTitle from "./SidebarNavTitle";
import SidebarNavBottomTitle from "./SidebarNavBottomTitle";

const Sidebar = ({
  backToPatientList,
  backToAdminList,
  openPatientInfo,
  openPatientInfoChange,
  openAdminInfo,
  openAdminInfoChange,
}: any) => {
  const {
    adminId,
    handlePageChange,
    onCheckedDoctor,
    onOpenTab,
    onSetSort,
    onSeachTextSort,
    patientId,
  } = useContext(MainContext);
  const history = useHistory();
  const [adminType, setAdminType] = useState<any>("");

  const navTitle =
    // Lazertinib연구 간호사는 가이드 제공 x
    adminId !== "smc09"
      ? [
          {
            id: 1,
            titleName: "환자 리포트",
            section: "adminmain",
            secondSection: `report/${patientId}`,
          },
          {
            id: 2,
            titleName: "환자별 통계",
            section: "graph",
            secondSection: `statistics/${patientId}`,
          },
          { id: 3, titleName: "가이드", section: "adminguide" },
        ]
      : // else
        [
          {
            id: 1,
            titleName: "환자 리포트",
            section: "adminmain",
            secondSection: `report/${patientId}`,
          },
          {
            id: 2,
            titleName: "환자별 통계",
            section: "graph",
            secondSection: `statistics/${patientId}`,
          },
        ];

  const navBottomTitle = [
    {
      id: 1,
      titleName: "환자 관리",
      section1: "adminpatient",
      section2: "patientregister",
      subMenu1: "환자 관리 목록",
      subMenu2: "신규 환자 등록",
    },
    {
      id: 2,
      titleName: "권한 관리",
      section1: "adminaccount",
      section2: "adminregister",
      subMenu1: "관리자 관리 목록",
      subMenu2: "신규 관리자 등록",
    },
    {
      id: 3,
      titleName: "접속 통계 및 현황",
      section1: "statustable",
      subMenu1: "통계 및 현황",
    },
  ];

  const navBottomTitleForDoc = [
    {
      id: 1,
      titleName: "환자 관리",
      section1: "adminpatient",
      section2: "patientregister",
      subMenu1: "환자 관리 목록",
      subMenu2: "신규 환자 등록",
    },
    {
      id: 2,
      titleName: "접속 통계 및 현황",
      section1: "statustable",
      subMenu1: "통계 및 현황",
    },
  ];

  const onOpenMain = () => {
    history.push(`/adminmain`);
    onCheckedDoctor("");
    handlePageChange(1);
    onOpenTab(0);
    onSetSort("");
    onSeachTextSort("");
  };

  useEffect(() => {
    let isComponentMounted = true;
    const checkAdminType = () => {
      axios
        .get(common.SERVER_URL + "/admin/" + adminId, common.headers)
        .then((res) => {
          if (res.data.ok) {
            const item = res.data.admin;
            if (isComponentMounted) {
              setAdminType(item?.type);
            }
          }
        })
        .catch((err) => console.log(err));
    };
    checkAdminType();
    return () => {
      isComponentMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <aside
      className={"flex-shrink-0 sm:w-40 md-block md:w-64 bg-white border-r "}
    >
      <h1
        onClick={onOpenMain}
        className="bg-yellow-point relative cursor-pointer md:h-16 h-12"
      >
        <img
          className="m-auto absolute top-0 bottom-0 left-0 right-0 md:h-5 sm:h-4"
          src="/images/herings.svg"
          alt="herings logo"
        />
      </h1>
      {/* side bar nav */}
      <div className="flex flex-col">
        <h2 className="hidden md:block h-32 relative m-1">
          <img
            className="m-auto h-6 absolute top-0 bottom-0 left-0 right-0"
            src="/images/e-pro.svg"
            alt="epro logo"
          />
        </h2>
        <div className="bg-gray-100 text-center py-4 md:text-base text-xs">
          {todayTime()}
        </div>

        <nav className="flex-1 py-4 space-y-4 overflow-y-hidden hover:overflow-y-auto">
          <ul className="border-b">
            {navTitle.map((item) => (
              <SidebarNavTitle key={item.id} navTitle={item} />
            ))}
          </ul>

          <ul>
            {adminType &&
              (adminType === "A" || adminType === "SA"
                ? navBottomTitle.map((item) => (
                    <SidebarNavBottomTitle
                      key={item.id}
                      navBottomTitle={item}
                      backToPatientList={backToPatientList}
                      openPatientInfo={openPatientInfo}
                      openPatientInfoChange={openPatientInfoChange}
                      openAdminInfo={openAdminInfo}
                      openAdminInfoChange={openAdminInfoChange}
                      backToAdminList={backToAdminList}
                    />
                  ))
                : navBottomTitleForDoc.map((item) => (
                    <SidebarNavBottomTitle
                      key={item.id}
                      navBottomTitle={item}
                      backToPatientList={backToPatientList}
                      openPatientInfo={openPatientInfo}
                      openPatientInfoChange={openPatientInfoChange}
                      openAdminInfo={openAdminInfo}
                      openAdminInfoChange={openAdminInfoChange}
                      backToAdminList={backToAdminList}
                    />
                  )))}
          </ul>
        </nav>
      </div>
      <footer className="hidden md:block fixed bottom-0 text-xs p-3">
        <p>
          &copy; <span className="thisYear">{todayTime().slice(0, 4)}</span>{" "}
          HERINGS All Rights Reserved.
        </p>
      </footer>
    </aside>
  );
};

export default Sidebar;
