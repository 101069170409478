import React, { useState, useEffect } from "react";
import RegularHeader from "../RegularType/RegularHeader";
import GuideData from "../../datas/GuideData";
import GuideIcon from "./GuideIcon";
import GuideHeader from "./GuideHeader";
import GuideDetails from "./GuideDetails";
import BottomMenuBar from "../Common/BottomMenuBar"

const IntroGuideCard = () => {
  const prevIcon = { backgroundImage: "url('/images/arrow-left.svg')" };
  const closeIcon = { backgroundImage: "url('/images/homebar.svg')" };
  const [chosenGuide, setChosenGuide] = useState([]);
  const [openGuide, setOpenGuide] = useState(false);
  const [scrollToBlock, setScrollToBlock] = useState(false);

  const onOpenGuide = (guide: any) => {
    setOpenGuide(true);
    setChosenGuide(guide);
  }

  const onCloseGuide = () => {
    setOpenGuide(false);
  }

  const onBlockScroll = () => {
    setScrollToBlock(true)
  }

  const onScroll = () => {
    setScrollToBlock(false)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <div className={"max-w-md h-screen flex flex-col justify-between items-center mx-auto text-gray-500"
        + (scrollToBlock ? "fixed w-full h-full overflow-hidden" : "")}>
        <div className="w-full flex-shrink-0 flex flex-col justify-between">
          {!openGuide ? (<>
            <RegularHeader headerTitle={"가이드 확인 안내"} rightIcon={closeIcon} />
            <div className="text-2xl font-extrabold px-6 text-center bg-gray-100 pt-4 mt-14">
              어디가 불편하세요?
              <p className="text-base font-normal mt-2">
                궁금한 증상을 선택해주세요.
              </p>
            </div>

            <form className="grid grid-cols-1 gap-3.5 text-center text-xs p-4 bg-gray-100 mb-20">
              {GuideData.map((item: any, idx: number) => (
                <GuideIcon key={idx} item={item} onOpenGuide={onOpenGuide} onCloseGuide={onCloseGuide} />
              ))}
            </form>
            <BottomMenuBar onBlockScroll={onBlockScroll} onScroll={onScroll} />
          </>) : (
            <>
              <GuideHeader headTitle={"가이드 자세히 보기"} rightIcon={closeIcon} leftIcon={prevIcon} onBack={onCloseGuide} />
              <div className="flex flex-col gap-4 mt-14">
                {chosenGuide && <GuideDetails chosenGuide={chosenGuide} onBlockScroll={onBlockScroll} onScroll={onScroll} />}
              </div>
              <BottomMenuBar onBlockScroll={onBlockScroll} onScroll={onScroll} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default IntroGuideCard;
