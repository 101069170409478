import React from "react";
import { ResponsiveLine } from "@nivo/line";

const TrendCharts = ({ item }) => {
  const data = [
    {
      id: "total grade graph",
      data: item.map((one) => {
        return {
          x: one.dateStr.slice(5, 10),
          y: one.grade,
        };
      }),
    },
  ];

  let jump = 1;
  if (item != null && item.length > 0) {
    jump = parseInt((item.length - 1) / 8) + 1;
  }
  let tickValues = [];
  for (let i = 0; i < item.length; i += jump) {
    tickValues.push(item[i].dateStr.slice(5, 10));
  }

  return (
    <>
      <h6 className="text-sm font-bold mt-2">재발 위험 점수</h6>
      <ResponsiveLine
        data={data}
        margin={{ top: 10, right: 15, bottom: 35, left: 35 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: 0,
          max: 30,
          stacked: true,
          reverse: false,
        }}
        yFormat=" >-0.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          tickValues: tickValues,
          legend: "",
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 0,
          tickPadding: 7,
          tickRotation: 0,
          legend: "score",
          legendOffset: -30,
          legendPosition: "end",
          tickValues: 5,
          format: (e) => Math.floor(e) === e && e,
        }}
        colors="#06A9F3"
        gridYValues={5}
        pointSize={10}
        pointColor="#06A9F3"
        pointBorderWidth={2}
        pointBorderColor="#06A9F3"
        pointLabelYOffset={-12}
        enableCrosshair={false}
        crosshairType="top-right"
        useMesh={true}
        legends={[]}
        tooltip={({ point }) => {
          return (
            <div className="bg-white px-4 border rounded-md shadow">
              <div>{point.data.x}</div>
              <strong>{point.data.y}</strong>
            </div>
          );
        }}
        // markers={[
        //     {
        //         axis: 'x',
        //         value: data[0].data[2].x,
        //         lineStyle: { stroke: '#b0413e', strokeWidth: 2 },
        //         legend: 'x marker',
        //     },
        // ]}
      />
    </>
  );
};

export default TrendCharts;
