import React from "react";
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";

const ResultHeader = ({ headTitle, leftIcon, rightIcon, onBack }: any) => {
  const history = useHistory();

  const goBack = () => { //뒤로가기 
    history.goBack();
    window.scrollTo(0, 0);
  }

  return (
    <div className="fixed top-0 left-0 w-full z-10 py-4 px-3 bg-white">
      <div className="flex justify-between items-center ">
        {leftIcon ? (
          <div onClick={onBack ? onBack : goBack} className="w-7 h-6 bg-cover" style={leftIcon}></div>
        ) : (
          <span className="w-6"></span>
        )}

        <div className="font-semibold text-gray-600">{headTitle}</div>

        {rightIcon ? (
          <Link to='/intro' className="w-6 h-6 bg-cover" style={rightIcon}></Link>
        ) : (
          <span className="w-6"></span>
        )}
      </div>
    </div>
  );
};

export default ResultHeader;
