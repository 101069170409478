import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../datas/Store";
import { useHistory } from "react-router-dom";
import axios from "axios";
import common from "../datas/common";
import TempGuide from "../components/TempType/TempGuide";
import TempChoice from "../components/TempType/TempChoice";

const MainTemp = () => {
  const { userId } = useContext(MainContext);
  const history = useHistory();
  const [tempContentOpen, setTempContentOpen] = useState(false);
  const [tempReportId, setTempReportId] = useState(-1);
  const [isChecked, setIsChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState(new Set());
  const [openTempInner, setOpenTempInner] = useState(false);
  const [openTempAlert, setOpenTempAlert] = useState(false);

  useEffect(() => {
    if (!userId) {
      history.push(`/mobilelogin`);
    }
  }, [userId, history]);

  const openTempContent = () => { //비정기문진 가이드 버튼 클릭 후
    setTempContentOpen(true);

    let inputUser = {
      type: "IR",
      reporter: userId,
      reportDay: new Date(),
    };

    axios
      .post(common.SERVER_URL + "/report", inputUser, common.headers)
      .then((res) => {
        setTempReportId(res.data.id);
      })
      .catch((err) => console.log(err));
    setCheckedItems(new Set());
  };

  const checkHandler = ({ target }: any) => {
    setIsChecked(!isChecked);
    checkedItemHandler(
      target.parentNode.parentNode,
      target.value,
      target.checked
    );
  };

  const checkedItemHandler = (icon: any, id: any, isChecked: any) => {
    if (isChecked) { //증상 체크 되었을때
      checkedItems.add(id);
      setCheckedItems(checkedItems);
      icon.style.backgroundColor = "#F6CB44";
      icon.style.border = "2px solid #4D4D4F";
    } else if (!isChecked && checkedItems.has(id)) { //증상 체크가 안되었고, id가 있을때(클릭 2번시)
      checkedItems.delete(id);
      setCheckedItems(checkedItems);
      icon.style.backgroundColor = "#fff";
      icon.style.border = "2px solid #E5E7EB";
    }
    return checkedItems;
  };

  const onOpenTempInner = () => {
    if (checkedItems.size > 0) {
      setOpenTempInner(!openTempInner);
    } else {
      setOpenTempAlert(!openTempAlert);
    }
  };

  const onTempAlert = () => {
    setOpenTempAlert(!openTempAlert);
  };

  const onResetData = () => {
    setTempContentOpen(false)
    setCheckedItems(new Set());
  }

  return (
    <>
      {tempContentOpen ? (
        <TempChoice
          checkHandler={checkHandler}
          checkedItems={checkedItems}
          openTempInner={openTempInner}
          openTempAlert={openTempAlert}
          onOpenTempInner={onOpenTempInner}
          onTempAlert={onTempAlert}
          tempReportId={tempReportId}
          onResetData={onResetData}
        />
      ) : (
        <TempGuide openTempContent={openTempContent} />
      )}
    </>
  );
};

export default MainTemp;
