import React from "react";
import { todayFormal } from "../../../mobile/datas/dataFilter";

const InfoHeader = ({ checkedPatient }: any) => {
  return (
    <div className="flex justify-between items-center py-2 mb-2">
      <div className="flex gap-2 text-sm">
        <span className="w-5 h-5 rounded-full bg-red-point"></span>
        <strong>{todayFormal()}</strong>
      </div>
      <h1 className="font-extrabold text-2xl">환자 증상 결과</h1>
      <strong
        className={
          "px-6 py-1 bg-gray-100 rounded-full border border-gray-200 font-bold font15 " +
          (checkedPatient.doctorName ? "opacity-1" : "opacity-0")
        }
      >
        {checkedPatient.doctorName} 교수님
      </strong>
    </div>
  );
};

export default InfoHeader;
