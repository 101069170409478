import React from "react";
import dayjs from "dayjs";
import LineChart from "../Charts/LineChart";

const FrequencyGraphCard = ({ cycleDate, graphData }: any) => {
  return (
    <div className="flex flex-col gap-3 p-4 rounded-xl shadow bg-white">
      <div className="flex flex-col items-center border-b pb-2">
        <h1 className="font-bold">나의 증상 그래프</h1>
        <span className="text-sm text-gray-400">
          {cycleDate.startDate &&
            dayjs(cycleDate.startDate).format("YYYY.MM.DD") +
              " ~ " +
              dayjs(cycleDate.endDate).format("YYYY.MM.DD")}
        </span>
      </div>
      {graphData.length > 0
        ? graphData.map((item: any, idx: number) => (
            <div key={idx} className="h-32 mb-4 pb-4">
              <LineChart item={item} />
            </div>
          ))
        : "발생한 증상 데이터가 없습니다."}
    </div>
  );
};

export default FrequencyGraphCard;
