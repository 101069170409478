import React, { useState, useContext, useEffect } from "react";
import { MainContext } from "../../../mobile/datas/Store";
import axios from "axios";
import common from "../../../mobile/datas/common";

const AdminAccountDetails = ({ onOpenAdminChange, backToList }: any) => {
    const { checkedAdminId } = useContext(MainContext);
    const [adminInfo, setAdminInfo] = useState<any>({});

    useEffect(() => {
        axios
            .get(common.SERVER_URL + "/admin/" + checkedAdminId, common.headers)
            .then((res) => setAdminInfo(res.data.admin))
            .catch((err) => console.log(err));
    }, [checkedAdminId])

    return (
        <div className="p-4 bg-white rounded-md">
            <div className="text-sm">
                <div className="flex">
                    <div className="flex mx-2 flex-1">
                        <div className="w-24 font-bold h-6 mt-3 leading-6">아이디</div>
                        <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
                            {adminInfo.adminId}
                        </div>
                    </div>
                    <div className="flex mx-2 flex-1">
                        <div className="w-24 font-bold h-6 mt-3 leading-6">
                            비밀번호
                        </div>
                        <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
                            비밀번호
                        </div>
                    </div>
                </div>

                <div className="flex">
                    <div className="flex mx-2 flex-1">
                        <div className="w-24 font-bold h-6 mt-3 leading-6">이름</div>
                        <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
                            {adminInfo.name}
                        </div>
                    </div>
                    <div className="flex mx-2 flex-1">
                        <div className="w-24 font-bold mt-3 leading-6">등록일자</div>
                        <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
                            {adminInfo.createdAt && adminInfo.createdAt.slice(0, 10)}
                        </div>
                    </div>
                </div>

                {/* 22.01.06 삼성병원 미팅 후 요청으로 관리자 등록 이메일, 휴대전화 hidden 처리 */}
                {/* <div className="flex">
                    <div className="flex mx-2 flex-1">
                        <div className="w-24 font-bold h-6 mt-3 leading-6">이메일</div>
                        <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
                            {adminInfo.email}
                        </div>
                    </div>
                    <div className="flex mx-2 flex-1">
                        <div className="w-24 font-bold h-6 mt-3 leading-6">
                            휴대전화
                        </div>
                        <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
                            {adminInfo.phone}
                        </div>
                    </div>
                </div> */}

                <div className="flex">
                    <div className="flex mx-2 flex-1">
                        <div className="w-24 font-bold h-6 mt-3 leading-6">소속</div>
                        <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
                            {adminInfo.organization}
                        </div>
                    </div>
                    <div className="flex mx-2 flex-1">
                        <div className="w-24 font-bold h-6 mt-3 leading-6">구분</div>
                        <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
                            {(adminInfo.type === "D" ? "의사" : (adminInfo.type === "N" ? "간호사" : (adminInfo.type === "A" ? "관리자" : "최고 관리자")))}
                        </div>
                    </div>
                </div>

                <div className="flex">
                    <div className="flex mx-2 flex-1">
                        <div className="w-24 font-bold h-6 mt-3 leading-6">메모</div>
                        <div className="bg-gray-100 my-2 p-1 w-full min-h-[100px] max-h-[300px] h-20 appearance-none block w-full bg-grey-lighter border py-4 px-4">
                            {adminInfo.memo}
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex-auto flex flex-row-reverse p-2 mt-4">
                <button
                    onClick={onOpenAdminChange}
                    className="ml-2 focus:outline-none flex justify-center px-8 py-2 rounded font-bold
                hover:bg-gray-600 hover:text-white bg-gray-200 border duration-200 ease-in-out border-gray-600 transition">
                    수정하기
                </button>
                <button
                    onClick={backToList}
                    className="focus:outline-none flex justify-center px-8 py-2 rounded font-bold
                hover:bg-gray-600 hover:text-white bg-gray-200 border duration-200 ease-in-out border-gray-600 transition">
                    목록
                </button>
            </div>
        </div>
    );
};

export default AdminAccountDetails;
