import React, { useContext } from "react";
import Pagination from "react-js-pagination";
import { MainContext } from "../../../mobile/datas/Store";

const Paging = ({ totalCount }) => {
    const { page, postPerPage, handlePageChange } = useContext(MainContext);

    return (
        <Pagination
            activePage={page}
            itemsCountPerPage={postPerPage}
            totalItemsCount={totalCount ? totalCount : 0}
            pageRangeDisplayed={10}
            prevPageText={'<'}
            nextPageText={'>'}
            onChange={handlePageChange} />);
};
export default Paging;