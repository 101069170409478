import React, { useState, useContext, useEffect } from "react";
import { MainContext } from "../../../mobile/datas/Store";
import axios from "axios";
import common from "../../../mobile/datas/common";

const PatientDetails = ({ onOpenPatientChange, backToList }: any) => {
  const { patientId } = useContext(MainContext);
  const [patientInfo, setPatientInfo] = useState<any>({});
  const [treatmentHistory, setTreatmentHistory] = useState<any>([]);

  useEffect(() => {
    axios
      .get(common.SERVER_URL + "/patient/pId/" + patientId, common.headers)
      .then((res) => {
        setPatientInfo(res.data.patient);
        setTreatmentHistory(res.data.treatmentList);
      })
      .catch((err) => console.log(err));
  }, [patientId]);

  // const medicineToKo = (medicine: any) => {
  //     let nameKo = "";
  //     switch (medicine) {
  //         case 'pembrolizumab':
  //             nameKo = '키트루다'
  //             break;
  //         case 'atezolizumab':
  //             nameKo = '티쎈트릭'
  //             break;
  //         case 'nivolumab':
  //             nameKo = '옵디보'
  //             break;
  //         case 'docetaxel':
  //             nameKo = '도세탁셀'
  //             break;
  //     }
  //     return nameKo;
  // }

  return (
    <div className="p-4 bg-white rounded-md">
      <div className="text-sm">
        <div className="flex">
          <div className="flex mx-2 flex-1">
            <div className="w-28 font-bold mt-3 leading-6">회원번호</div>
            <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
              {patientInfo.patientId}
            </div>
          </div>
          <div className="flex mx-2 flex-1">
            <div className="w-28 font-bold h-6 mt-3 leading-6">담당교수</div>
            <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
              {patientInfo.doctorName}
            </div>
          </div>
        </div>

        <div className="flex">
          <div className="flex mx-2 flex-1">
            <div className="w-28 font-bold h-6 mt-3 leading-6">생년</div>
            <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
              {patientInfo.birth}
            </div>
          </div>
          <div className="flex mx-2 flex-1">
            <div className="w-28 font-bold h-6 mt-3 leading-6">성별</div>
            <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
              {patientInfo.gender === "M" ? "남자" : "여자"}
            </div>
          </div>
        </div>

        <div className="flex">
          <div className="flex mx-2 flex-1">
            <div className="w-28 font-bold h-6 mt-3 leading-6">체중</div>
            <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
              {patientInfo.weight}
            </div>
          </div>
          <div className="flex mx-2 flex-1">
            <div className="w-28 font-bold h-6 mt-3 leading-6">키</div>
            <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
              {patientInfo.height}
            </div>
          </div>
        </div>

        <div className="w-1/2 flex">
          <div className="flex mx-2 flex-1">
            <div className="w-28 font-bold h-6 mt-3 leading-6">등록 일자</div>
            <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
              {patientInfo.createdAt && patientInfo.createdAt.slice(0, 10)}
            </div>
          </div>
        </div>

        <div className="border-t my-4"></div>

        <div className="flex">
          <div className="flex mx-2 flex-1">
            <div className="w-28 font-bold h-6 mt-3 leading-6">병명</div>
            <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
              {patientInfo.disease}
            </div>
          </div>
          <div className="flex mx-2 flex-1">
            <div className="w-28 font-bold h-6 mt-3 leading-6">수술여부</div>
            <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
              {patientInfo.operationYN}
            </div>
          </div>
        </div>

        <div className="flex">
          <div className="flex mx-2 flex-1">
            <div className="w-28 font-bold mt-3 leading-6">항암제군</div>
            <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
              {patientInfo.anticancerMedicine}
            </div>
          </div>
          <div className="flex mx-2 flex-1">
            <div className="w-28 font-bold h-6 mt-3 leading-6">약제</div>
            <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
              {patientInfo.medicine}
            </div>
          </div>
        </div>

        <div className="flex">
          <div className="flex mx-2 flex-1">
            <div className="w-28 font-bold h-6 mt-3 leading-6">
              {patientId.indexOf("E") !== 0 ? "마지막 투약일" : "마지막 방문일"}
            </div>
            <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
              {patientInfo.lastTreatment}
            </div>
          </div>
          <div className="flex mx-2 flex-1">
            <div className="w-28 font-bold h-6 mt-3 leading-6">
              {patientId.indexOf("E") !== 0 ? "투약일" : "방문일"}
            </div>

            <div className="w-full bg-gray-100 my-2 p-1 border border-gray-200">
              {treatmentHistory &&
                treatmentHistory.map((date: any) => (
                  <span key={date.id} className="mr-2">
                    {" "}
                    {date.visitDay}{" "}
                  </span>
                ))}
            </div>
          </div>
        </div>

        <div className="flex">
          <div className="flex mx-2 flex-1">
            <div className="w-28 font-bold h-6 mt-3 leading-6">기저질환</div>
            <div
              className="bg-gray-100 my-2 p-1 w-full min-h-[100px] max-h-[300px] h-24 appearance-none 
                                block w-full bg-grey-lighter border py-4 px-4"
            >
              {patientInfo.underlyingdisease}
            </div>
          </div>
          <div className="flex mx-2 flex-1">
            <div className="w-28 font-bold h-6 mt-3 leading-6">특이사항</div>
            <div
              className="bg-gray-100 my-2 p-1 w-full min-h-[100px] max-h-[300px] h-24 appearance-none 
                                block w-full bg-grey-lighter border py-4 px-4"
            >
              {patientInfo.memo}
            </div>
          </div>
        </div>

        <div className="border-t my-4"></div>

        <div className="w-1/2 flex">
          <div className="flex mx-2 flex-1">
            <div className="w-28 font-bold h-6 mt-3 leading-6">연구 상태</div>
            <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
              {patientInfo.researchEnd ? "연구 중단" : "연구 진행중"}
            </div>
          </div>
        </div>

        {patientInfo.researchEndDetail && (
          <div className={patientInfo.researchEnd ? " flex" : " hidden"}>
            <div className="flex mx-2 flex-1">
              <div className="w-28 font-bold h-6 mt-3 leading-6">연구 종료</div>
              <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
                구분: {patientInfo.researchEndDetail.researchEndType} / 입력자:{" "}
                {patientInfo.researchEndDetail.inputAdminName}
              </div>
            </div>
            <div className="flex mx-2 flex-1">
              <div className="w-28 font-bold h-6 mt-3 leading-6">종료 사유</div>
              <div className="w-full bg-gray-100 my-2 p-1 flex border border-gray-200">
                {patientInfo.researchEndDetail.reason}
                {patientInfo.researchEndDetail.reasonOther
                  ? ` (기타사유: ` +
                    patientInfo.researchEndDetail.reasonOther +
                    `)`
                  : ""}
              </div>
            </div>
          </div>
        )}

        <div className="border-t my-4"></div>
      </div>

      <div className="flex-auto flex flex-row-reverse p-2 mt-4">
        <button
          onClick={onOpenPatientChange}
          className="ml-2  focus:outline-none flex justify-center px-8 py-2 rounded font-bold cursor-pointer 
                    hover:bg-gray-600 hover:text-white bg-gray-200 border duration-200 ease-in-out border-gray-600 transition"
        >
          수정하기
        </button>
        <button
          onClick={backToList}
          className="focus:outline-none flex justify-center px-8 py-2 rounded font-bold cursor-pointer 
                    hover:bg-gray-600 hover:text-white bg-gray-200 border duration-200 ease-in-out border-gray-600 transition"
        >
          목록
        </button>
      </div>
    </div>
  );
};

export default PatientDetails;
