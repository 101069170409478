import React, { useEffect } from 'react';

const ChosenOneWarning = ({ warnSymptom, bringWarning, warningMemo }: any) => {
    useEffect(() => {
        bringWarning()
    }, [bringWarning]);


    return (
        <div className="flex flex-col gap-2 px-4 pt-2 bg-gray-100">
            <h2 className='font-bold'>위험 증상</h2>
            <div className="flex flex-col gap-4 p-2 pr-2.5 bg-white rounded-md border-l-8 border">
                <p className='font-bold'>위험 증상 {warnSymptom.length}개 발생</p>
                <p className='text-sm'>{warningMemo}</p>
            </div>
        </div>
    );
};

export default ChosenOneWarning;