import React from "react";
import RegularHeader from "../RegularType/RegularHeader";
import ButtomBtnBar from "../Common/BottomBtnBar";

const TempGuide = ({ openTempContent }: any) => {
  const closeIcon = { backgroundImage: "url('/images/close.svg')" };
  const guideIcon1 = { backgroundImage: "url('/images/call911.svg')" };
  const guideIcon3 = { backgroundImage: "url('/images/appointment.svg')" };

  return (
    <>
      <div className="max-w-md h-screen flex flex-col justify-between items-center mx-auto bg-gray-100">
        <div className="w-full flex-shrink-0 flex flex-col justify-between text-gray-600">
          <RegularHeader headerTitle={"실시간 증상 확인 안내"} rightIcon={closeIcon} />
          <p className="font26 text-gray-600 font-extrabold py-4 px-8 bg-white mt-14">
            어떤 증상이 발생했나요?<br />지금 나타난 증상을 확인해보세요.
          </p>

          <ul className="flex flex-col gap-5 bg-gray-100 px-4 py-6">
            <li>
              <ul className="flex items-center gap-4 bg-white rounded-xl px-2 py-4 shadow">
                <li className="flex flex-shrink-0 justify-center items-center w-10 h-10 bg-contain" style={guideIcon1}></li>
                <li>
                  <div className="font-extrabold text-red-600">
                    생명이 위험할때 119
                  </div>
                  <p className='font15'>위급한 상황일때는 119에 즉시 전화하세요.</p>
                </li>
              </ul>
            </li>
            <li>
              <ul className="flex items-center gap-4 bg-white rounded-xl px-2 py-4 shadow">
                <li className="flex flex-shrink-0 justify-center items-center w-10 h-10 bg-contain" style={guideIcon3}></li>
                <li>
                  <div className="font-extrabold">실시간 증상 입력은 누적통계에 반영되지 않습니다.</div>
                </li>
              </ul>
            </li>
          </ul>

          <ButtomBtnBar title={"실시간 증상 확인 시작"} openContent={openTempContent} />
        </div>
      </div>
    </>
  );
};

export default TempGuide;
