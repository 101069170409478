import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import { MainContext } from "../datas/Store";
import axios from "axios";
import common from "../datas/common";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MobileLogin = (props: any) => {
  const { onLoggedUser } = useContext(MainContext);
  const history = useHistory();
  const refId = useRef<any>();
  const refPass = useRef<any>();
  const [loginData, setLoginData] = useState({
    patientId: "",
    pass: "",
    firebaseToken: "",
  });
  const [openErrorMsg, setOpenErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openLogin, setOpenLogin] = useState(false);

  let search = new URLSearchParams(props.location.search);
  let firebaseToken = search.get("firebaseToken");

  const onLogin = useCallback(() => {
    //자동 로그인
    if (firebaseToken != null && firebaseToken !== "") {
      //token 있을때
      window.sessionStorage.setItem("firebaseToken", firebaseToken);
      loginData.firebaseToken = firebaseToken;
      axios
        .post(
          common.SERVER_URL + "/patient/autoLogin",
          loginData,
          common.headers
        )
        .then((res) => {
          if (res.data.ok) {
            loginData.patientId = res.data.patient.patientId;
            onLoggedUser(loginData); //store userId 보냄
            history.push("/intro"); //intro 페이지 전환
          } else {
            setOpenLogin(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setOpenLogin(true);
        });
    } else {
      //token 없을때
      setOpenLogin(true);
    }
  }, [firebaseToken, history, loginData, onLoggedUser]);

  const onChangeUserId = (e: any) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const onChangeUserpw = (e: any) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const onSubmitLogin = (e: any) => {
    e.preventDefault();
    const sessionFirebaseToken = window.sessionStorage.getItem("firebaseToken");
    if (firebaseToken != null && firebaseToken !== "") {
      loginData.firebaseToken = firebaseToken;
    } else if (sessionFirebaseToken != null && sessionFirebaseToken !== "") {
      loginData.firebaseToken = sessionFirebaseToken;
    }
    if (!loginData.patientId) {
      setOpenErrorMsg(true);
      setErrorMsg("아이디를 입력하세요");
    } else if (!loginData.pass) {
      setOpenErrorMsg(true);
      setErrorMsg("비밀번호를 입력하세요");
    } else {
      axios
        .post(common.SERVER_URL + "/patient/login", loginData, common.headers)
        .then((res) => {
          if (res.data.ok) {
            setOpenErrorMsg(false);
            toast.success("LOGIN 성공", {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
            onLoggedUser(loginData); //store userId 보냄
            history.push("/intro"); //intro 페이지 전환
          } else {
            setOpenErrorMsg(true);
            setErrorMsg("가입하지 않은 회원번호이거나 잘못된 비밀번호 입니다");
            toast.error(res.data.error, {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoginData({
              patientId: "",
              pass: "",
              firebaseToken: "",
            });
            refId.current.value = "";
            refPass.current.value = "";
            refId.current.focus();
          }
          // const patientId = res.data.patient.patientId;
          // if (res.data.ok && patientId.indexOf("E") === 0) {
          //   console.log(res.data, "res.data");
          //   console.log(res.data.patient.patientId, "res.data.patientId");
          //   console.log(patientId, "patientId");
          //   console.log(patientId.indexOf("E"), "patientId.indexOf");

          //   setOpenErrorMsg(false);
          //   toast.success("LOGIN 성공", {
          //     autoClose: 3000,
          //     position: toast.POSITION.TOP_RIGHT,
          //   });
          //   onLoggedUser(loginData); //store userId 보냄
          //   history.push("/intro"); //intro 페이지 전환
          // } else if (res.data.ok) {
          //   console.log(res.data, "res.data");
          //   console.log(res.data.patient.patientId, "res.data.patientId");

          //   setOpenErrorMsg(false);
          //   toast.success("LOGIN 성공", {
          //     autoClose: 3000,
          //     position: toast.POSITION.TOP_RIGHT,
          //   });
          //   onLoggedUser(loginData); //store userId 보냄
          //   history.push("/intro"); //intro 페이지 전환
          // } else {
          //   setOpenErrorMsg(true);
          //   setErrorMsg("가입하지 않은 회원번호이거나 잘못된 비밀번호 입니다");
          //   alert(errorMsg);
          //   toast.error(res.data.error, {
          //     autoClose: 3000,
          //     position: toast.POSITION.TOP_RIGHT,
          //   });
          //   setLoginData({
          //     patientId: "",
          //     pass: "",
          //     firebaseToken: "",
          //   });
          //   refId.current.value = "";
          //   refPass.current.value = "";
          //   refId.current.focus();
          // }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    onLogin();
    if (openLogin) refId.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSendEmail = (e: any) => {
    // window.location.href = "mailto:help@heringsglobal.com";
    window.location.href = "mailto:product@heringsglobal.com";
    e.preventDefault();
  };

  return openLogin ? (
    <>
      <div className="max-w-md h-screen flex flex-col justify-center items-center mx-auto p-4">
        <div className="flex-shrink-0 flex flex-col text-gray-600 gap-4">
          <img
            className="w-36 mx-auto"
            src="/images/loginLogo.svg"
            alt="atti 로고"
          />
          <form onSubmit={onSubmitLogin}>
            <input
              ref={refId}
              type="text"
              value={loginData.patientId}
              name="patientId"
              onChange={onChangeUserId}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:ring my-2.5"
              placeholder="회원번호"
            />

            <input
              ref={refPass}
              type="password"
              value={loginData.pass}
              name="pass"
              autoComplete="on"
              onChange={onChangeUserpw}
              className="w-full px-4 py-2 border rounded focus:outline-none focus:ring mb-2 font-mono"
              placeholder="비밀번호"
            />
            {openErrorMsg ? (
              <p className="text-xs mb-2">
                <svg
                  className="w-5 h-5 inline text-red-600 mr-0.5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  ></path>
                </svg>
                <span>{errorMsg}</span>
              </p>
            ) : (
              <p className="h-5"></p>
            )}
            <button
              type="submit"
              className="bg-yellow-point w-full py-4 font-semibold text-center rounded mb-4"
            >
              로그인
            </button>
          </form>
          <p className="h-5 text-sm mb-8">
            비밀번호를 잊으신 경우{" "}
            <span onClick={onSendEmail} className="underline">
              {/* help@heringsglobal.com */}
              product@heringsglobal.com
            </span>{" "}
            <br />
            으로 이메일을 보내주시기 바랍니다.
          </p>
          <div className="flex justify-around">
            <img
              src="/images/herings_app_logo.svg"
              width="127"
              alt="herings logo"
            />
            <img src="/images/samsung_logo.svg" width="81" alt="herings logo" />
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  ) : (
    <div></div>
  );
};

export default MobileLogin;
