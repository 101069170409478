import React, { useState, useContext, useEffect } from "react";
import { MainContext } from "../../mobile/datas/Store";
import { useHistory } from "react-router-dom";
import axios from "axios";
import common from "../../mobile/datas/common";
import Sidebar from "../components/Common/Sidebar";
import Navbar from "../components/Common/Navbar";
import SubNavbar from "../components/Common/SubNavbar";
import AlertDialog from "../components/Common/AlertDialog";
import AdminInfoDialog from "../components/Authority/AdminInfoDialog";
import ContentHeader from '../components/Common/ContentHeader';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminRegister = () => {
    const { adminId, handlePageChange } = useContext(MainContext);
    const history = useHistory();
    const alertNoneIcon = { backgroundImage: "url('/images/alert-none.svg')" };
    const alertTakenIcon = { backgroundImage: "url('/images/alert-notice.svg')" };
    const arrowDownIcon = { backgroundImage: "url('/images/arrow-yellow-down.svg')" };
    const lockIcon = { backgroundImage: "url('/images/lock.svg')" };
    const unlockIcon = { backgroundImage: "url('/images/unlock.svg')" };
    const [openAlert, setOpenAlert] = useState(false);
    const [alarmData, setAlarmData] = useState([]);
    const [openAdminInfoAlert, setOpenAdminInfoAlert] = useState(false);

    const onOpenAlert = () => {
        setOpenAlert(!openAlert);
    };

    const onOpenAdminInfoAlert = () => {
        setOpenAdminInfoAlert(!openAdminInfoAlert);
    };

    useEffect(() => {
        let isComponentMounted = true
        if (!adminId) {
            history.push(`/adminlogin`);
        } else {
            const bringAlarmData = () => {
                axios.get(common.SERVER_URL + "/report/alarm", common.headers)
                    .then(res => {
                        if (res.data) {
                            if (isComponentMounted) {
                                setAlarmData(res.data.alarmList);
                            }
                        }
                    })
                    .catch(err => console.log(err))
            }
            bringAlarmData();
            return () => {
                isComponentMounted = false
            }
        }
    }, [adminId, history]);

    const [openPatientInfo, setOpenPatientInfo] = useState(false);
    const [openPatientInfoChange, setOpenPatientInfoChange] = useState(false);

    const backToPatientList = () => { //환자 목록 돌아가기
        setOpenPatientInfo(false);
        setOpenPatientInfoChange(false);
        handlePageChange(1);
    };

    const [openAdminInfo, setOpenAdminInfo] = useState(false);
    const [openAdminInfoChange, setOpenAdminInfoChange] = useState(false);

    const backToAdminList = () => { //관리자 목록 돌아가기
        setOpenAdminInfo(false);
        setOpenAdminInfoChange(false);
        handlePageChange(1);
    };


    const [adminRegData, setAdminRegData] = useState({
        adminId,
        regAdminId: '',
        pass: '',
        name: '',
        //email: '',
        //phone: '',
        organization: '',
        type: '',
        memo: ''
    });

    const onSubmitForm = (e: any) => {
        e.preventDefault();
        //let patternEmail = /^[a-z0-9_+.-]+@([a-z0-9-]+\.)+[a-z0-9]{2,4}$/;
        //let patternPhone = /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/;

        if (!adminRegData.regAdminId) {
            toast.error("아이디를 입력해주세요", { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
        } else if (!adminRegData.pass) {
            toast.error("비밀번호를 입력해주세요", { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
        } else if (!adminRegData.name) {
            toast.error("이름을 입력해주세요", { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
        } else if (!adminRegData.organization) {
            toast.error("소속을 선택해주세요", { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
        } else if (!adminRegData.type) {
            toast.error("구분을 선택해주세요", { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
        } else {
            axios.post(common.SERVER_URL + "/admin", adminRegData, common.headers)
                .then(res => {
                    if (res.data.ok) {
                        toast.success("관리자 등록 성공", { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
                        backToList();
                        setAdminRegData({
                            adminId,
                            regAdminId: '',
                            pass: '',
                            name: '',
                            //email: '',
                            //phone: '',
                            organization: '',
                            type: '',
                            memo: ''
                        })
                    } else {
                        toast.error(res.data.error, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
                    }
                })
                .catch(err => console.log(err));
        }
    }

    const onChangeData = (e: any) => {
        const { name, value } = e.target;
        setAdminRegData({ ...adminRegData, [name]: value });
    }

    const backToList = () => {
        history.push(`/adminaccount`);
        backToAdminList();
    }


    return (
        <>
            <div className="flex h-screen antialiased text-gray-600 bg-gray-100">
                {/* side bar */}
                <Sidebar
                    openPatientInfo={openPatientInfo}
                    openPatientInfoChange={openPatientInfoChange}
                    openAdminInfo={openAdminInfo}
                    openAdminInfoChange={openAdminInfoChange}
                    backToPatientList={backToPatientList}
                    backToAdminList={backToAdminList}
                />

                {/* content */}
                <div className="flex-1 h-full overflow-x-hidden overflow-y-auto">
                    {/* navbar */}
                    <header className="relative bg-gray-point h-12 flex flex-col md:h-28">
                        <Navbar
                            alertIcon={alarmData.length > 0 ? alertTakenIcon : alertNoneIcon}
                            arrowDownIcon={arrowDownIcon}
                            lockIcon={lockIcon}
                            unlockIcon={unlockIcon}
                            onOpenAlert={onOpenAlert}
                            onOpenAdminInfoAlert={onOpenAdminInfoAlert}
                        />
                        <SubNavbar />
                    </header>

                    {/* main content */}
                    <main className="mb-4">
                        {/* 신규 관리자 등록 */}
                        <ContentHeader headerTitle={'신규 관리자 등록'} />
                        <form onSubmit={onSubmitForm}>
                            <div className="p-4 bg-white rounded-md mx-4">
                                <div>
                                    <div className="flex flex-col md:flex-row">
                                        <div className="w-full mx-2 flex-1">
                                            <div className="font-bold h-6 mt-3 text-xs leading-8">아이디</div>
                                            <div className="bg-white my-2 p-1 flex border border-gray-200">
                                                <input required placeholder="A1234" className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                                                    name="regAdminId" value={adminRegData.regAdminId.replace(/\s/gi, "")} onChange={onChangeData} /> </div>
                                        </div>
                                        <div className="w-full mx-2 flex-1">
                                            <div className="font-bold h-6 mt-3 text-xs leading-8">비밀번호</div>
                                            <div className="bg-white my-2 p-1 flex border border-gray-200">
                                                <input required placeholder="1234" type='password' className="p-1 px-2 appearance-none outline-none w-full text-gray-800 font-mono focus:ring"
                                                    name="pass" autoComplete="on" value={adminRegData.pass} onChange={onChangeData} /> </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-col md:flex-row">
                                        <div className="w-full  mx-2 flex-1">
                                            <div className="font-bold h-6 mt-3 text-xs leading-8">이름</div>
                                            <div className="bg-white my-2 p-1 flex border border-gray-200">
                                                <input required type='text' className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                                                    name="name" value={adminRegData.name} onChange={onChangeData} /> </div>
                                        </div>
                                        <div className="w-full mx-2 flex-1">

                                        </div>

                                    </div>

                                    {/* 22.01.06 삼성병원 미팅 후 요청으로 관리자 등록 이메일, 휴대전화 hidden 처리 */}
                                    {/* <div className="flex flex-col md:flex-row">
                                        <div className="w-full mx-2 flex-1">
                                            <div className="font-bold h-6 mt-3 text-xs leading-8">이메일</div>
                                            <div className="bg-white my-2 p-1 flex border border-gray-200">
                                                <input required type='email' className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                                                    name="email" value={adminRegData.email} onChange={onChangeData} /> </div>
                                        </div>
                                        <div className="w-full mx-2 flex-1">
                                            <div className="font-bold h-6 mt-3 text-xs leading-8">휴대전화</div>
                                            <div className="bg-white my-2 p-1 flex border border-gray-200">
                                                <input type='text' placeholder="010-1234-1234" className="p-1 px-2 appearance-none outline-none w-full text-gray-800 focus:ring"
                                                    name="phone" value={adminRegData.phone} onChange={onChangeData} /> </div>
                                        </div>
                                    </div> */}


                                    <div className="flex flex-col md:flex-row">
                                        <div className="w-full mx-2 flex-1">
                                            <div className="font-bold h-6 mt-3 text-xs leading-8">소속</div>

                                            <select required className="w-full bg-white my-2 p-1 flex border border-gray-200 py-1.5 px-4 border focus:ring"
                                                name="organization" value={adminRegData.organization} onChange={onChangeData} >
                                                <option value="">선택하세요</option>
                                                <option value="헤링스">헤링스</option>
                                                <option value="서울삼성병원">서울삼성병원</option>
                                                <option value="기타">기타</option>
                                            </select>

                                        </div>
                                        <div className="w-full mx-2 flex-1">
                                            <div className="font-bold h-6 mt-3 text-xs leading-8">구분</div>

                                            <select required className="w-full bg-white my-2 p-1 flex border border-gray-200 py-1.5 px-4 border focus:ring"
                                                name="type" value={adminRegData.type} onChange={onChangeData} >
                                                <option value="">선택하세요</option>
                                                <option value="N">간호사</option>
                                                <option value="D">의사</option>
                                                <option value="A">관리자</option>
                                                <option value="SA">최고 관리자</option>
                                            </select>

                                        </div>
                                    </div>
                                    <div className="flex flex-col md:flex-row">
                                        <div className="w-full mx-2 flex-1">
                                            <div className="font-bold h-6 mt-3 text-xs leading-8">메모</div>
                                            <textarea className="bg-white my-2 p-1 w-full min-h-[100px] max-h-[300px] h-28 appearance-none 
                                block w-full bg-grey-lighter border py-4 px-4 focus:ring focus:outline-none"
                                                name="memo" value={adminRegData.memo} onChange={onChangeData} ></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex-auto flex flex-row-reverse p-2 mt-4">
                                    <button type='submit' className="ml-2 flex justify-center px-8 py-2 rounded font-bold hover:bg-gray-600 hover:text-white bg-gray-200 border duration-200 ease-in-out border-gray-600 transition">저장하기</button>
                                    <button onClick={backToList} className="flex justify-center px-8 py-2 rounded font-bold hover:bg-gray-600 hover:text-white bg-gray-200 border duration-200 ease-in-out border-gray-600 transition">목록</button>
                                </div>
                            </div>
                        </form>
                        <ToastContainer />
                    </main>
                </div>
            </div>

            <AlertDialog
                openAlert={openAlert}
                onOpenAlert={onOpenAlert}
                alarmData={alarmData}
            />

            {openAdminInfoAlert &&
                <AdminInfoDialog
                    onOpenAdminInfoAlert={onOpenAdminInfoAlert}
                    adminId={adminId}
                />
            }
        </>
    );
};

export default AdminRegister;
