import React, { useState, useContext, useEffect } from "react";
import { MainContext } from "../../mobile/datas/Store";
import { useHistory } from "react-router-dom";
import axios from "axios";
import common from "../../mobile/datas/common";
import Sidebar from "../components/Common/Sidebar";
import Navbar from "../components/Common/Navbar";
import SubNavbar from "../components/Common/SubNavbar";
import AlertDialog from "../components/Common/AlertDialog";
import GuideList from "../components/Guide/GuideList";
import AdminInfoDialog from "../components/Authority/AdminInfoDialog";

const AdminGuide = () => {
    const { adminId, handlePageChange } = useContext(MainContext);
    const history = useHistory();
    const alertNoneIcon = { backgroundImage: "url('/images/alert-none.svg')" };
    const alertTakenIcon = { backgroundImage: "url('/images/alert-notice.svg')" };
    const arrowDownIcon = { backgroundImage: "url('/images/arrow-yellow-down.svg')" };
    const lockIcon = { backgroundImage: "url('/images/lock.svg')" };
    const unlockIcon = { backgroundImage: "url('/images/unlock.svg')" };
    const [openAlert, setOpenAlert] = useState(false);
    const [alarmData, setAlarmData] = useState([]);
    const [openAdminInfoAlert, setOpenAdminInfoAlert] = useState(false);

    const onOpenAlert = () => {
        setOpenAlert(!openAlert);
    };

    const onOpenAdminInfoAlert = () => {
        setOpenAdminInfoAlert(!openAdminInfoAlert);
    };

    const bringAlarmData = () => {
        axios.get(common.SERVER_URL + "/report/alarm", common.headers)
            .then(res => {
                if (res.data) {
                    setAlarmData(res.data.alarmList);
                }
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        if (!adminId) {
            history.push(`/adminlogin`);
        } else {
            bringAlarmData();
        }
    }, [adminId, history]);

    const [openPatientInfo, setOpenPatientInfo] = useState(false);
    const [openPatientInfoChange, setOpenPatientInfoChange] = useState(false);

    const backToPatientList = () => { //환자 목록 돌아가기
        setOpenPatientInfo(false);
        setOpenPatientInfoChange(false);
        handlePageChange(1);
    };

    const [openAdminInfo, setOpenAdminInfo] = useState(false);
    const [openAdminInfoChange, setOpenAdminInfoChange] = useState(false);

    const backToAdminList = () => { //관리자 목록 돌아가기
        setOpenAdminInfo(false);
        setOpenAdminInfoChange(false);
        handlePageChange(1);
    };

    return (
        <>
            <div className="flex h-screen antialiased text-gray-600 bg-gray-100">
                {/* side bar */}
                <Sidebar
                    openPatientInfo={openPatientInfo}
                    openPatientInfoChange={openPatientInfoChange}
                    openAdminInfo={openAdminInfo}
                    openAdminInfoChange={openAdminInfoChange}
                    backToPatientList={backToPatientList}
                    backToAdminList={backToAdminList}
                />

                {/* content */}
                <div className="flex-1 h-full overflow-x-hidden overflow-y-auto">
                    {/* navbar */}
                    <header className="relative bg-gray-point h-12 flex flex-col md:h-28">
                        <Navbar
                            alertIcon={alarmData.length > 0 ? alertTakenIcon : alertNoneIcon}
                            arrowDownIcon={arrowDownIcon}
                            lockIcon={lockIcon}
                            unlockIcon={unlockIcon}
                            onOpenAlert={onOpenAlert}
                            onOpenAdminInfoAlert={onOpenAdminInfoAlert}
                        />
                        <SubNavbar />
                    </header>

                    {/* main content */}
                    <main className="mb-4">
                        {/* 증상 가이드 */}
                        <GuideList />
                    </main>
                </div>
            </div>

            <AlertDialog
                openAlert={openAlert}
                onOpenAlert={onOpenAlert}
                alarmData={alarmData}
            />

            {openAdminInfoAlert &&
                <AdminInfoDialog
                    onOpenAdminInfoAlert={onOpenAdminInfoAlert}
                    adminId={adminId}
                />
            }
        </>
    );
};

export default AdminGuide;
