import React, { useContext } from 'react';
import { MainContext } from "../../../mobile/datas/Store";
import { useHistory } from "react-router-dom";

const SidebarNavTitle = ({ navTitle }: any) => {
    const { handlePageChange, onOpenTab, onSetSort, onSeachTextSort, onCheckedDoctor } = useContext(MainContext);
    const { titleName, section, secondSection } = navTitle;
    const history = useHistory();
    const pathname = window.location.pathname;

    const onOpenSection = () => {
        history.push(`/` + section);
        handlePageChange(1);
        onOpenTab(0);
        onSetSort('');
        onSeachTextSort('');
        onCheckedDoctor('');
    }

    return (

        <li onClick={onOpenSection}
            className={'group flex justify-between items-center p-2 mb-2 border-l-8 border-white cursor-pointer ' +
                ((pathname === `/` + section || pathname === `/` + secondSection) ? 'border-yellow-point' : 'border-white')
            }>
            <span className='font-bold ml-4 md:ml-8 text-sm md:text-lg'>{titleName}</span>

            <span className={(pathname === `/` + section || pathname === `/` + secondSection) ? ' block' : ' hidden'}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white text-yellow-point" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                </svg>
            </span>
        </li>

    );
};

export default SidebarNavTitle;