import React from "react";
import InfoHeader from "./InfoHeader";
import PatientInfo from "./PatientInfo";
import PercentCard from "./PercentCard";
import GradeCard from "./GradeCard";
import TotalGradeChart from "../Charts/TotalGradeChart";
import TrendCharts from "../Charts/TrendChart";

const MainReportForPrintSamsung = ({ patinetInfo, frenquencyData, gradeData, graphData, totalGradeData, cycleDate, searchDate }: any) => {

    return (
        <div className="print-page max-w-2xl flex flex-col">
            <div className="p-4 rounded-lg">
                {patinetInfo && <InfoHeader checkedPatient={patinetInfo} />}
                {patinetInfo && <PatientInfo checkedPatient={patinetInfo} />}
            </div>

            <main>
                <div className="flex flex-col gap-4 text-sm">
                    <div className="grid xs:grid-cols-1 grid-cols-2 gap-2 rounded-md mx-4 border">
                        {/* 많이 발생한 증상 카드 */}
                        <PercentCard
                            title={"많이 발생한 증상"}
                            frenquencyData={frenquencyData}
                            cycleDate={cycleDate}
                        />

                        {/* 심각한 grade 증상 */}
                        <GradeCard
                            title={"심각한 Grade 증상"}
                            gradeData={gradeData}
                            cycleDate={cycleDate}
                        />
                    </div>

                    <div className="flex flex-col bg-white p-4 rounded-md text-sm">
                        <div className="flex justify-between items-center">
                            <div className="font-extrabold text-lg mb-1">
                                증상 트렌드
                            </div>
                            <div className="flex flex-col text-sm text-gray-400 font-bold">
                                {searchDate.startD
                                    ? searchDate.startD + " ~ " + searchDate.endD
                                    : cycleDate.startDate + " ~ " + cycleDate.endDate}
                            </div>
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <div className="flex flex-col gap-4 h-44 border-gray-400">
                                {totalGradeData && <TotalGradeChart item={totalGradeData} />}
                            </div>
                            {graphData &&
                                graphData.map((item: any, idx: number) => (
                                    <div key={idx} className="flex flex-col gap-4 h-44 border-gray-400">
                                        <TrendCharts item={item} />
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default MainReportForPrintSamsung;
