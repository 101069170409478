import React, { useState, useContext, useEffect } from "react";
import { MainContext } from "../../mobile/datas/Store";
import axios from "axios";
import common from "../../mobile/datas/common";
import Sidebar from "../components/Common/Sidebar";
import Navbar from "../components/Common/Navbar";
import SubNavbar from "../components/Common/SubNavbar";
import AlertDialog from "../components/Common/AlertDialog";
import AdminInfoDialog from "../components/Authority/AdminInfoDialog";
import ContentHeader from "../components/Common/ContentHeader";
import MedicineChart from "../components/Charts/MedicineChart";
import DoctorChart from "../components/Charts/DoctorChart";
import AgeChart from "../components/Charts/AgeChart";
import GenderChart from "../components/Charts/GenderChart";
import VisitorChart from "../components/Charts/VisitorChart";
import LtVisitorChart from "../components/Charts/LtVisitorChart";

const StatusTable = () => {
  const { adminId } = useContext(MainContext);
  const alertNoneIcon = { backgroundImage: "url('/images/alert-none.svg')" };
  const alertTakenIcon = { backgroundImage: "url('/images/alert-notice.svg')" };
  const arrowDownIcon = {
    backgroundImage: "url('/images/arrow-yellow-down.svg')",
  };
  const lockIcon = { backgroundImage: "url('/images/lock.svg')" };
  const unlockIcon = { backgroundImage: "url('/images/unlock.svg')" };
  const [openAlert, setOpenAlert] = useState(false);
  const [alarmData, setAlarmData] = useState([]);
  const [openAdminInfoAlert, setOpenAdminInfoAlert] = useState(false);
  const [changeGraph, setChangeGraph] = useState(true);

  const AttiChangeGraph = () => {
    setChangeGraph(true);
    // console.log(changeGraph, "changeGraph");
  };
  const LtChangeGraph = () => {
    setChangeGraph(false);
    // console.log(changeGraph, "changeGraph");
  };

  const onOpenAlert = () => {
    setOpenAlert(!openAlert);
  };

  const onOpenAdminInfoAlert = () => {
    setOpenAdminInfoAlert(!openAdminInfoAlert);
  };

  const bringAlarmData = () => {
    axios
      .get(common.SERVER_URL + "/report/alarm", common.headers)
      .then((res) => {
        if (res.data) {
          setAlarmData(res.data.alarmList);
        }
      })
      .catch((err) => console.log(err));
  };

  // 항암제
  const [medicine1, setMedicine1] = useState([]);
  const [medicine2, setMedicine2] = useState([]);

  const [age, setAge] = useState([]);
  const [ltage, setLtAge] = useState([]);

  const [doctor, setDoctor] = useState([]);
  const [ltdoctor, setLtDoctor] = useState([]);

  const [gender, setGender] = useState([]);
  const [ltgender, setLtGender] = useState([]);

  const [user, setUser] = useState({
    todayConnect: 0,
    totalJoin: 0,
  });
  // 레이저티닙 사용자 방문자 가입자
  const [ltuser, setLtUser] = useState({
    todayConnectLazertinib: 0,
    totalJoinLazertinib: 0,
  });

  useEffect(() => {
    bringStatistics();
    bringAlarmData();
  }, []);

  const bringStatistics = () => {
    axios
      .get(common.SERVER_URL + "/patient/statistics", common.headers)
      .then((res) => {
        if (res.data) {
          setMedicine1(res.data.medicine1);
          setMedicine2(res.data.medicine2);

          setAge(res.data.age);
          setLtAge(res.data.ageLazertinib);

          setDoctor(res.data.doctor);
          setLtDoctor(res.data.doctorLazertinib);

          setGender(res.data.gender);
          setLtGender(res.data.genderLazertinib);

          setUser({
            todayConnect: res.data.todayConnect,
            totalJoin: res.data.totalJoin,
          });
          setLtUser({
            todayConnectLazertinib: res.data.todayConnectLazertinib,
            totalJoinLazertinib: res.data.totalJoinLazertinib,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="flex h-screen antialiased text-gray-600 bg-gray-100">
        {/* side bar */}
        <Sidebar />

        {/* content */}
        <div className="flex-1 h-full overflow-x-hidden overflow-y-auto">
          {/* navbar */}
          <header className="relative bg-gray-point h-12 flex flex-col md:h-28">
            <Navbar
              alertIcon={alarmData.length > 0 ? alertTakenIcon : alertNoneIcon}
              arrowDownIcon={arrowDownIcon}
              lockIcon={lockIcon}
              unlockIcon={unlockIcon}
              onOpenAlert={onOpenAlert}
              onOpenAdminInfoAlert={onOpenAdminInfoAlert}
            />
            <SubNavbar />
          </header>

          {/* main content */}
          <main className="mb-4">
            {/* 접속 및 통계 */}
            <ContentHeader
              headerTitle={
                changeGraph === true && adminId !== "smc09"
                  ? "통계 및 현황(Atti)"
                  : "통계 및 현황(Lazertinib)"
              }
            />
            <div className="mx-4">
              {/* 한가지 연구에만 참여할 경우 Atti, Lazertinib 버튼 제공 안함 */}
              {adminId !== "smc08" && adminId !== "smc09" ? (
                <>
                  <button
                    className="border border-current w-20 ml-0 mb-3 p-2 h-14 rounded-2xl bg-white focus:bg-yellow-200 font-semibold text-center tracking-wide subpixel-antialiased text-base font-mono"
                    onClick={AttiChangeGraph}
                  >
                    Atti
                  </button>
                  <button
                    className="border border-current ml-4 p-2 h-14 rounded-2xl bg-white focus:bg-yellow-200 font-semibold text-center tracking-wide subpixel-antialiased text-base font-mono"
                    onClick={LtChangeGraph}
                  >
                    Lazertinib
                  </button>
                </>
              ) : null}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {/* Lazertinib 담당 간호사일 경우 */}
                {adminId === "smc09" ? (
                  <>
                    {/* Lazertinib 사용자 통계 */}
                    <div className="flex flex-col gap-2 h-80 border-gray-400 bg-white rounded-md">
                      {/* LT 교수별 환자 */}
                      {ltdoctor && <DoctorChart doctor={ltdoctor} />}
                    </div>
                    <div className="flex flex-col gap-2 h-80 border-gray-400 bg-white rounded-md">
                      {/* LT 연령 */}
                      {ltage && <AgeChart age={ltage} />}
                    </div>
                    <div className="flex flex-col gap-2 h-80 border-gray-400 bg-white rounded-md">
                      {/* LT 성별 */}
                      {ltgender && <GenderChart gender={ltgender} />}
                    </div>

                    <div className="flex flex-col gap-2 h-80 border-gray-400 bg-white rounded-md">
                      {/* LT 앱접속자 */}
                      {ltuser && <LtVisitorChart user={ltuser} />}
                    </div>
                  </>
                ) : // atti 연구 간호사일 경우
                adminId === "smc08" ? (
                  <>
                    <div className="flex flex-col gap-2 h-[200px] border-gray-400 bg-white rounded-md">
                      {/* 항암제군 */}
                      {medicine1 && (
                        <MedicineChart
                          medicine={medicine1}
                          option={"option1"}
                        />
                      )}
                    </div>
                    <div className="flex flex-col gap-2 h-80 border-gray-400 bg-white rounded-md">
                      {/* 항암제군 */}
                      {medicine2 && (
                        <MedicineChart
                          medicine={medicine2}
                          option={"option2"}
                        />
                      )}
                    </div>
                    <div className="flex flex-col gap-2 h-80 border-gray-400 bg-white rounded-md">
                      {/* 교수별 환자 */}
                      {doctor && <DoctorChart doctor={doctor} />}
                    </div>
                    <div className="flex flex-col gap-2 h-80 border-gray-400 bg-white rounded-md">
                      {/* 연령 */}
                      {age && <AgeChart age={age} />}
                    </div>
                    <div className="flex flex-col gap-2 h-80 border-gray-400 bg-white rounded-md">
                      {/* 성별 */}
                      {gender && <GenderChart gender={gender} />}
                    </div>
                    <div className="flex flex-col gap-2 h-80 border-gray-400 bg-white rounded-md">
                      {/* 앱접속자 */}
                      {user && <VisitorChart user={user} />}
                    </div>
                  </>
                ) : // 두 연구 다 참여하는 의사의 경우 (버튼 true or false)
                changeGraph === true ? (
                  <>
                    <div className="flex flex-col gap-2 h-[200px] border-gray-400 bg-white rounded-md">
                      {/* 항암제군 */}
                      {medicine1 && (
                        <MedicineChart
                          medicine={medicine1}
                          option={"option1"}
                        />
                      )}
                    </div>
                    <div className="flex flex-col gap-2 h-80 border-gray-400 bg-white rounded-md">
                      {/* 항암제군 */}
                      {medicine2 && (
                        <MedicineChart
                          medicine={medicine2}
                          option={"option2"}
                        />
                      )}
                    </div>
                    <div className="flex flex-col gap-2 h-80 border-gray-400 bg-white rounded-md">
                      {/* 교수별 환자 */}
                      {doctor && <DoctorChart doctor={doctor} />}
                    </div>
                    <div className="flex flex-col gap-2 h-80 border-gray-400 bg-white rounded-md">
                      {/* 연령 */}
                      {age && <AgeChart age={age} />}
                    </div>
                    <div className="flex flex-col gap-2 h-80 border-gray-400 bg-white rounded-md">
                      {/* 성별 */}
                      {gender && <GenderChart gender={gender} />}
                    </div>
                    <div className="flex flex-col gap-2 h-80 border-gray-400 bg-white rounded-md">
                      {/* 앱접속자 */}
                      {user && <VisitorChart user={user} />}
                    </div>
                  </>
                ) : (
                  <>
                    {/* Lazertinib 사용자 통계 */}
                    <div className="flex flex-col gap-2 h-80 border-gray-400 bg-white rounded-md">
                      {/* LT 교수별 환자 */}
                      {ltdoctor && <DoctorChart doctor={ltdoctor} />}
                    </div>
                    <div className="flex flex-col gap-2 h-80 border-gray-400 bg-white rounded-md">
                      {/* LT 연령 */}
                      {ltage && <AgeChart age={ltage} />}
                    </div>
                    <div className="flex flex-col gap-2 h-80 border-gray-400 bg-white rounded-md">
                      {/* LT 성별 */}
                      {ltgender && <GenderChart gender={ltgender} />}
                    </div>

                    <div className="flex flex-col gap-2 h-80 border-gray-400 bg-white rounded-md">
                      {/* LT 앱접속자 */}
                      {ltuser && <LtVisitorChart user={ltuser} />}
                    </div>
                  </>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>

      <AlertDialog
        openAlert={openAlert}
        onOpenAlert={onOpenAlert}
        alarmData={alarmData}
      />

      {openAdminInfoAlert && (
        <AdminInfoDialog
          onOpenAdminInfoAlert={onOpenAdminInfoAlert}
          adminId={adminId}
        />
      )}
    </>
  );
};

export default StatusTable;
