import React from "react";
import { ResponsivePie } from "@nivo/pie";

const MedicineChart = ({ medicine, option }) => {
  const data = medicine.map((one) => {
    var obj = {
      id: one ? one.name : "",
      label: one ? one.name : "",
      value: one ? one.count : "",
    };
    return obj;
  });

  return (
    <>
      <h6 className="text-sm font-bold m-2 text-center">
        {option === "option1" ? "항암제군-1차 치료제" : "항암제군-2차 치료제"}
      </h6>
      <ResponsivePie
        data={data}
        margin={{ top: 20, right: 50, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        colors={{ scheme: "nivo" }}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: option === "option1" ? -100 : 0,
            translateY: 76,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: "#999",
            itemDirection: "left-to-right",
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "#000",
                },
              },
            ],
          },
        ]}
      />
    </>
  );
};

export default MedicineChart;
