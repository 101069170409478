import React from "react";

const DangerGuideDialog = ({ onOpenDangerGuide }: any) => {

  return (
    <>
      <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-60 z-30">
        <div className="bg-white rounded w-10/12 md:w-1/3 text-gray-500">
          <div className="px-4 py-2 flex justify-end items-center">
            <span onClick={onOpenDangerGuide}>
              <svg
                className="w-6 h-6 cursor-pointer"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </span>
          </div>
          <div className='flex justify-center items-center'>
            <img src='images/danger.svg' alt='위험경고' />
          </div>
          <div className='px-4 py-4 text-center border-b'>
            <h2 className='mb-2 font-bold text-lg text-red-600'>빠른 시일 내에<br /><strong>병원 응급 방문</strong>이 필요합니다.</h2>
            <p className='text-xs mb-2'>가까운 병원이나 응급실로 연락 후 방문하세요.</p>
          </div>
          <div className="flex flex-col items-center py-4 px-8 gap-4 border-b">
            <p className='font-bold text-gray-600'>삼성서울병원 고객센터</p>
            <a href="tel:1599-3114" className="w-full text-center bg-yellow-point py-2 rounded text-gray-600">전화 : 1599-3114</a>
          </div>
          <div className="flex flex-col items-center py-4 px-8 gap-4">
            <p className='font-bold text-gray-600'>삼성서울병원 응급진료</p>
            <a href="tel:02-3410-2055" className="w-full text-center bg-yellow-point py-2 rounded text-gray-600">전화 : 02-3410-2055</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default DangerGuideDialog;
