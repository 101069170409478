import React, { useContext, useEffect, useState } from "react";
import formData from "../datas/FormData";
import formDataLazertinib from "../datas/FormDataLazertinib";

import { MainContext } from "../../mobile/datas/Store";

import { InnerFormView, InnerFormView2, InnerFormView3 } from "./InnerFormView";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const InnerContent = ({
  i,
  onChangeAnswer,
  missingInfo,
  onNextMove,
  onClickPrev,
}: any) => {
  const { userId, noneSelect } = useContext(MainContext);
  const [edit, setEdit] = useState({});
  const [checked, setChecked] = useState(0);
  const [allNone, setAllNone] = useState(false);
  const [selectIn, setSelectIn] = useState(0);

  const onSavedAnswer = (e: any) => {
    setEdit({ [e.target.name]: e.target.value });
    // console.log(edit, "onSavedAnswer");
  };

  // * 다수 질문에 경우 첫 질문 전혀없음인지 여부 확인
  const allNones = (selectIns: number) => {
    if (selectIns === 1) {
      setAllNone(true);
    } else {
      setAllNone(false);
    }
  };

  useEffect(() => {
    allNones(selectIn);
    setAllNone(allNone);
  }, [allNone]);
  useEffect(() => {
    updatePoint(noneSelect);
  }, [allNone]);

  // * checked 값
  const updatePoint = (noneSelect: any) => {
    if (userId.indexOf("E") === 0) {
      if (missingInfo !== undefined) {
        if (missingInfo[i].list[0] === undefined) {
          setChecked(checked + 1);
        } else if (allNone === true && checked === 0 && noneSelect === 1) {
          setChecked(missingInfo[i].list.length);

          // console.log(" 정상!! 실행되고 있습니다.");
        } else if (allNone === true && checked === 1 && noneSelect === 1) {
          missingInfo[i].list.length === 2
            ? setChecked(checked + 1)
            : setChecked(checked + 2);
        } else if (allNone === false && checked === 1 && noneSelect > 1) {
          setChecked(missingInfo[i].list.length);
        } else if (allNone === false && checked === 2 && noneSelect > 1) {
          setChecked(missingInfo[i].list.length - 1);
        } else if (allNone === false && checked === 3 && noneSelect > 1) {
          setChecked(missingInfo[i].list.length - 2);
        } else if (missingInfo[i].list.length === checked) {
          setChecked(checked + 0);
        } else if (allNone === false && checked === 0 && noneSelect === 0) {
          setChecked(checked + 0);
        } else {
          setChecked(checked + 1);
        }
      } else if (formDataLazertinib !== undefined) {
        if (formDataLazertinib[i].list[0] === undefined) {
          setChecked(checked + 1);
        } else if (allNone === true && checked === 0 && noneSelect === 1) {
          setChecked(formDataLazertinib[i].list.length);
        } else if (allNone === true && checked === 1 && noneSelect === 1) {
          formDataLazertinib[i].list.length === 2
            ? setChecked(checked + 1)
            : setChecked(checked + 2);
        } else if (allNone === false && checked === 1 && noneSelect > 1) {
          setChecked(formDataLazertinib[i].list.length);
        } else if (allNone === false && checked === 2 && noneSelect > 1) {
          setChecked(formDataLazertinib[i].list.length - 1);
        } else if (allNone === false && checked === 3 && noneSelect > 1) {
          setChecked(formDataLazertinib[i].list.length - 2);
        } else if (formDataLazertinib[i].list.length === checked) {
          setChecked(checked + 0);
        } else if (allNone === false && checked === 0 && noneSelect === 0) {
          setChecked(checked + 0);
        } else {
          setChecked(checked + 1);
        }
      }
    }
  };

  // * checked 값에 따라 다음 질문으로 넘어갈 수 있는지 체크
  const checkPoint = () => {
    if (
      (missingInfo && missingInfo[i].list.length === 0 && checked === 1) ||
      (formDataLazertinib &&
        formDataLazertinib[i].list.length === 0 &&
        checked === 1)
    ) {
      onNextMove();
    } else if (
      (missingInfo &&
        missingInfo[i].list.length === checked &&
        missingInfo[i].list.length !== 0) ||
      (formDataLazertinib &&
        formDataLazertinib[i].list.length === checked &&
        formDataLazertinib[i].list.length !== 0)
    ) {
      onNextMove();
    } else {
      toast.error("답변을 선택해 주세요", {
        autoClose: 3000,
        position: toast.POSITION.TOP_RIGHT,
      });
      // alert("답변을 순서대로 다시 모두 선택해 주세요.");
      // toast.error("답변을 선택해 주세요.", {
      //   autoClose: 3000,
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    }
  };
  useEffect(() => {
    setSelectIn(0);
    setAllNone(false);
    setChecked(0);
  }, [i]);

  return (
    <>
      {missingInfo && missingInfo !== undefined ? (
        userId.indexOf("E") !== 0 ? (
          <>
            <div className="text-center px-6 py-6 mb-4 bg-gray-100">
              <div className="flex justify-center items-center font-extrabold w-7 h-7 rounded-full bg-gray-300 text-white mx-auto">
                {i + 1}
              </div>
              <div
                className="text-gray-800 text-2xl font-extrabold my-4"
                style={{ wordBreak: "keep-all" }}
              >
                {missingInfo ? missingInfo[i].question : formData[i].question}
              </div>
              <div className="flex items-center text-sm bg-white rounded-3xl px-2 py-1.5 gap-3">
                <div className="flex-shrink-0 w-9 h-9 bg-gray-200 rounded-full flex justify-center items-center">
                  <img
                    src={
                      missingInfo ? missingInfo[i].bgIcon : formData[i].bgIcon
                    }
                    alt={missingInfo ? missingInfo[i].name : formData[i].name}
                  />
                </div>

                <div className="text-left">
                  <span className="font-bold">
                    {missingInfo ? missingInfo[i].title : formData[i].title}
                  </span>
                  <span>
                    :{" "}
                    {missingInfo ? missingInfo[i].meaning : formData[i].meaning}
                  </span>
                </div>
              </div>
            </div>
            <div className="bg-white">
              <InnerFormView
                i={i}
                formViewData={missingInfo ? missingInfo : formData}
                onChangeAnswer={onChangeAnswer}
                missingInfo={missingInfo}
              />
            </div>
          </>
        ) : // *  Lazertinib 단일 질문
        missingInfo[i].list[0] === undefined ? (
          <>
            <div className="text-center px-6 py-6 mb-4 bg-gray-100">
              <div className="flex justify-center items-center font-extrabold w-7 h-7 rounded-full bg-gray-300 text-white mx-auto">
                {i + 1}
              </div>
              <div
                className="text-gray-800 text-2xl font-extrabold my-4"
                style={{ wordBreak: "keep-all" }}
              >
                {/* {missingInfo
                  ? missingInfo[i].question
                  : formDataLazertinib[i].question} */}
                {missingInfo[i].question}
              </div>
            </div>
            <div className="bg-white">
              <InnerFormView
                i={i}
                formViewData={missingInfo}
                onChangeAnswer={onChangeAnswer}
                missingInfo={missingInfo}
                checked={checked}
                updatePoint={updatePoint}
                allNones={allNones}
                allNone={allNone}
                selectIn={selectIn}
                onClickPrev={onClickPrev}
                onNextMove={onNextMove}
                onSavedAnswer={onSavedAnswer}
                savedAnswer={
                  Object.keys(edit).length > 0 && Object.values(edit)
                }
              />
            </div>
          </>
        ) : missingInfo[i].list[2] === undefined ? (
          // * Lazertinib 복수 질문
          <>
            <div className="text-center px-4 py-4 mb-2 bg-gray-100">
              <div className="flex justify-center items-center font-extrabold w-7 h-7 rounded-full bg-gray-300 text-white mx-auto">
                {i + 1}
              </div>
              <div
                className="text-gray-800 text-2xl font-extrabold my-4"
                style={{ wordBreak: "keep-all" }}
              >
                {/* {missingInfo
                  ? missingInfo[i].list[0].question
                  : formDataLazertinib[i].list[0].question} */}
                {missingInfo[i].list[0].question}
              </div>
            </div>
            <div className="bg-white">
              <InnerFormView
                i={i}
                formViewData={missingInfo}
                onChangeAnswer={onChangeAnswer}
                missingInfo={missingInfo}
                checked={checked}
                updatePoint={updatePoint}
                allNones={allNones}
                allNone={allNone}
                selectIn={selectIn}
                onNextMove={onNextMove}
              />
            </div>
            <div className="text-center px-4 py-4 mb-2 mt-2 bg-gray-100">
              <div
                className="text-gray-800 text-2xl font-extrabold my-4"
                style={{ wordBreak: "keep-all" }}
              >
                {/* {missingInfo
                  ? missingInfo[i].list[1].question
                  : formDataLazertinib[i].list[1].question} */}
                {missingInfo[i].list[1].question}
              </div>
            </div>
            <div className="bg-white">
              <InnerFormView2
                i={i}
                formViewData={missingInfo}
                onChangeAnswer={onChangeAnswer}
                missingInfo={missingInfo}
                checked={checked}
                updatePoint={updatePoint}
                allNones={allNones}
                allNone={allNone}
                selectIn={selectIn}
                onNextMove={onNextMove}
              />
            </div>
          </>
        ) : missingInfo[i].list[2] !== undefined ? (
          <>
            <div className="text-center px-2 py-2 mb-2 bg-gray-100">
              <div className="flex justify-center items-center font-extrabold w-7 h-7 rounded-full bg-gray-300 text-white mx-auto">
                {i + 1}
              </div>
              <div
                className="text-gray-800 text-xl font-extrabold my-2"
                style={{ wordBreak: "keep-all" }}
              >
                {/* {missingInfo
                  ? missingInfo[i].list[0].question
                  : formDataLazertinib[i].list[0].question} */}
                {missingInfo[i].list[0].question}
              </div>
            </div>
            <div className="bg-white">
              <InnerFormView
                i={i}
                formViewData={missingInfo}
                onChangeAnswer={onChangeAnswer}
                missingInfo={missingInfo}
                checked={checked}
                updatePoint={updatePoint}
                allNones={allNones}
                allNone={allNone}
                selectIn={selectIn}
                onNextMove={onNextMove}
              />
            </div>
            <div className="text-center px-4 py-4 mb-2 mt-2 bg-gray-100">
              <div
                className="text-gray-800 text-xl font-extrabold my-2"
                style={{ wordBreak: "keep-all" }}
              >
                {/* {missingInfo
                  ? missingInfo[i].list[1].question
                  : formDataLazertinib[i].list[1].question} */}
                {missingInfo[i].list[1].question}
              </div>
            </div>
            <div className="bg-white">
              <InnerFormView2
                i={i}
                formViewData={missingInfo}
                onChangeAnswer={onChangeAnswer}
                missingInfo={missingInfo}
                checked={checked}
                updatePoint={updatePoint}
                allNones={allNones}
                allNone={allNone}
                selectIn={selectIn}
              />
            </div>
            <div className="text-center px-4 py-4 mb-2 mt-2 bg-gray-100">
              <div
                className="text-gray-800 text-xl font-extrabold my-2"
                style={{ wordBreak: "keep-all" }}
              >
                {/* {missingInfo
                  ? missingInfo[i].list[2].question
                  : formDataLazertinib[i].list[2].question} */}
                {missingInfo[i].list[2].question}
              </div>
            </div>
            {/* <div>{formDataLazertinib[i].list[2].question}</div> */}
            <div className="bg-white">
              <InnerFormView3
                i={i}
                formViewData={missingInfo}
                onChangeAnswer={onChangeAnswer}
                missingInfo={missingInfo}
                checked={checked}
                updatePoint={updatePoint}
                allNones={allNones}
                allNone={allNone}
                selectIn={selectIn}
                onNextMove={onNextMove}
              />
            </div>
          </>
        ) : null
      ) : // * 이어서 하기 x 새로 시작 ok
      userId.indexOf("E") !== 0 ? (
        <>
          <div className="text-center px-6 py-6 mb-4 bg-gray-100">
            <div className="flex justify-center items-center font-extrabold w-7 h-7 rounded-full bg-gray-300 text-white mx-auto">
              {i + 1}
            </div>
            <div
              className="text-gray-800 text-2xl font-extrabold my-4"
              style={{ wordBreak: "keep-all" }}
            >
              {missingInfo ? missingInfo[i].question : formData[i].question}
            </div>
            <div className="flex items-center text-sm bg-white rounded-3xl px-2 py-1.5 gap-3">
              <div className="flex-shrink-0 w-9 h-9 bg-gray-200 rounded-full flex justify-center items-center">
                <img
                  src={missingInfo ? missingInfo[i].bgIcon : formData[i].bgIcon}
                  alt={missingInfo ? missingInfo[i].name : formData[i].name}
                />
              </div>

              <div className="text-left">
                <span className="font-bold">
                  {missingInfo ? missingInfo[i].title : formData[i].title}
                </span>
                <span>
                  : {missingInfo ? missingInfo[i].meaning : formData[i].meaning}
                </span>
              </div>
            </div>
          </div>
          <div className="bg-white">
            <InnerFormView
              i={i}
              formViewData={missingInfo ? missingInfo : formData}
              onChangeAnswer={onChangeAnswer}
              missingInfo={missingInfo}
            />
          </div>
        </>
      ) : // *  Lazertinib 단일 질문

      formDataLazertinib[i].list[0] === undefined ? (
        <>
          <div className="text-center px-6 py-6 mb-4 bg-gray-100">
            <div className="flex justify-center items-center font-extrabold w-7 h-7 rounded-full bg-gray-300 text-white mx-auto">
              {i + 1}
            </div>
            <div
              className="text-gray-800 text-2xl font-extrabold my-4"
              style={{ wordBreak: "keep-all" }}
            >
              {/* {missingInfo
                ? missingInfo[i].question
                : formDataLazertinib[i].question} */}
              {formDataLazertinib[i].question}
            </div>
          </div>
          <div className="bg-white">
            <InnerFormView
              i={i}
              formViewData={formDataLazertinib}
              onChangeAnswer={onChangeAnswer}
              missingInfo={missingInfo}
              checked={checked}
              updatePoint={updatePoint}
              allNones={allNones}
              allNone={allNone}
              selectIn={selectIn}
              onNextMove={onNextMove}
            />
          </div>
        </>
      ) : formDataLazertinib[i].list[2] === undefined ? (
        // * Lazertinib 복수 질문
        <>
          <div className="text-center px-4 py-4 mb-2 bg-gray-100">
            <div className="flex justify-center items-center font-extrabold w-7 h-7 rounded-full bg-gray-300 text-white mx-auto">
              {i + 1}
            </div>
            <div
              className="text-gray-800 text-2xl font-extrabold my-4"
              style={{ wordBreak: "keep-all" }}
            >
              {/* {missingInfo
                ? missingInfo[i].list[0].question
                : formDataLazertinib[i].list[0].question} */}
              {formDataLazertinib[i].list[0].question}
            </div>
          </div>
          <div className="bg-white">
            <InnerFormView
              i={i}
              formViewData={formDataLazertinib}
              onChangeAnswer={onChangeAnswer}
              missingInfo={missingInfo}
              checked={checked}
              updatePoint={updatePoint}
              allNones={allNones}
              allNone={allNone}
              selectIn={selectIn}
              onNextMove={onNextMove}
            />
          </div>
          <div className="text-center px-4 py-4 mb-2 mt-2 bg-gray-100">
            <div
              className="text-gray-800 text-2xl font-extrabold my-4"
              style={{ wordBreak: "keep-all" }}
            >
              {/* {missingInfo
                ? missingInfo[i].list[1].question
                : formDataLazertinib[i].list[1].question} */}
              {formDataLazertinib[i].list[1].question}
            </div>
          </div>
          <div className="bg-white">
            <InnerFormView2
              i={i}
              formViewData={formDataLazertinib}
              onChangeAnswer={onChangeAnswer}
              missingInfo={missingInfo}
              checked={checked}
              updatePoint={updatePoint}
              allNones={allNones}
              allNone={allNone}
              selectIn={selectIn}
              onNextMove={onNextMove}
            />
          </div>
        </>
      ) : formDataLazertinib[i].list[2] !== undefined ? (
        <>
          <div className="text-center px-2 py-2 mb-2 bg-gray-100">
            <div className="flex justify-center items-center font-extrabold w-7 h-7 rounded-full bg-gray-300 text-white mx-auto">
              {i + 1}
            </div>
            <div
              className="text-gray-800 text-xl font-extrabold my-2"
              style={{ wordBreak: "keep-all" }}
            >
              {/* {missingInfo
                ? missingInfo[i].list[0].question
                : formDataLazertinib[i].list[0].question} */}
              {formDataLazertinib[i].list[0].question}
            </div>
          </div>
          <div className="bg-white">
            <InnerFormView
              i={i}
              formViewData={formDataLazertinib}
              onChangeAnswer={onChangeAnswer}
              missingInfo={missingInfo}
              checked={checked}
              updatePoint={updatePoint}
              allNones={allNones}
              allNone={allNone}
              selectIn={selectIn}
              onNextMove={onNextMove}
            />
          </div>
          <div className="text-center px-4 py-4 mb-2 mt-2 bg-gray-100">
            <div
              className="text-gray-800 text-xl font-extrabold my-2"
              style={{ wordBreak: "keep-all" }}
            >
              {/* {missingInfo
                ? missingInfo[i].list[1].question
                : formDataLazertinib[i].list[1].question} */}
              {formDataLazertinib[i].list[1].question}
            </div>
          </div>
          <div className="bg-white">
            <InnerFormView2
              i={i}
              formViewData={formDataLazertinib}
              onChangeAnswer={onChangeAnswer}
              missingInfo={missingInfo}
              checked={checked}
              updatePoint={updatePoint}
              allNones={allNones}
              allNone={allNone}
              selectIn={selectIn}
            />
          </div>
          <div className="text-center px-4 py-4 mb-2 mt-2 bg-gray-100">
            <div
              className="text-gray-800 text-xl font-extrabold my-2"
              style={{ wordBreak: "keep-all" }}
            >
              {/* {missingInfo
                ? missingInfo[i].list[2].question
                : formDataLazertinib[i].list[2].question} */}
              {formDataLazertinib[i].list[2].question}
            </div>
          </div>
          {/* <div>{formDataLazertinib[i].list[2].question}</div> */}
          <div className="bg-white">
            <InnerFormView3
              i={i}
              formViewData={formDataLazertinib}
              onChangeAnswer={onChangeAnswer}
              missingInfo={missingInfo}
              checked={checked}
              updatePoint={updatePoint}
              allNones={allNones}
              allNone={allNone}
              selectIn={selectIn}
              onNextMove={onNextMove}
            />
          </div>
        </>
      ) : null}
      {userId.indexOf("E") === 0 && (
        <button
          className=" mx-auto w-48 h-14 border rounded-full text-xl mt-6 mb-3 border-black bg-black text-white"
          onClick={checkPoint}
          // onChange={onNextMove}
        >
          다음
        </button>
      )}
      <ToastContainer />
    </>
  );
};

export default InnerContent;
