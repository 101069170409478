import React, { useState, useContext } from "react";
import formData from "../../datas/FormData";
import formDataLazertinib from "../../datas/FormDataLazertinib";
import { useHistory } from "react-router-dom";
import InnerContent from "../InnerContent";
import InnerHeader from "../InnerHeader";
import Result from "../Result/Result";
import InputSummary from "../Result/InputSummary";
import CommonDialog from "../Common/CommonDialog";
import { MainContext } from "../../../mobile/datas/Store";

const RegularContent = ({
  reportId,
  onClickPrev,
  onNextMove,
  onClickNext,
  i,
  onClickBack,
  onReset,
  onSubmitForm,
  onChangeAnswer,
  summaryOpen,
  missingInfo,
}: any) => {
  const history = useHistory();
  const prevIcon = { backgroundImage: "url('/images/arrow-left.svg')" };
  const closeIcon = { backgroundImage: "url('/images/close.svg')" };
  const [closeDiaglog, setCloseDialog] = useState(false);
  const [resultOpen, setResultOpen] = useState(false);
  const [scrollToBlock, setScrollToBlock] = useState(false);

  const { userId } = useContext(MainContext);

  const onOpenCloseDialog = () => {
    setCloseDialog(true);
  };

  const onCloseDialog = () => {
    setCloseDialog(false);
  };

  const onCheck = () => {
    onCloseDialog();
    history.push("/intro");
  };

  const onOpenResult = () => {
    setTimeout(() => setResultOpen(true), 500);
  };

  const onBack = () => {
    setResultOpen(false);
  };

  const onBlockScroll = () => {
    setScrollToBlock(true);
  };

  const onScroll = () => {
    setScrollToBlock(false);
  };
  return (
    <div
      className={
        "max-w-md h-screen flex flex-col justify-between mx-auto" +
        (scrollToBlock ? "fixed w-full h-full overflow-hidden" : "")
      }
    >
      {userId.indexOf("E") !== 0 ? (
        <div className="flex-shrink-0 flex flex-col text-gray-500">
          {!summaryOpen ? (
            <>
              <InnerHeader
                i={i}
                headerData={missingInfo ? missingInfo : formData}
                leftIcon={prevIcon}
                rightIcon={closeIcon}
                onClickPrev={onClickPrev}
                missingInfo={missingInfo}
                onOpenCloseDialog={onOpenCloseDialog}
              />
              <InnerContent
                i={i}
                onClickNext={onClickNext}
                onNextMove={onNextMove}
                onClickPrev={onClickPrev}
                onClickBack={onClickBack}
                onChangeAnswer={onChangeAnswer}
                onSubmitForm={onSubmitForm}
                missingInfo={missingInfo}
              />
              {closeDiaglog && (
                <CommonDialog
                  title={
                    "정기 증상위험 입력을 그만하시겠습니까? 입력하던 내용은 저장됩니다."
                  }
                  onCheck={onCheck}
                  onCloseDialog={onCloseDialog}
                />
              )}
            </>
          ) : !resultOpen ? (
            <InputSummary
              reportId={reportId}
              onOpenResult={onOpenResult}
              onBlockScroll={onBlockScroll}
              onScroll={onScroll}
              missingInfo={missingInfo}
              i={i}
            />
          ) : (
            <Result
              reportId={reportId}
              onReset={onReset}
              onBack={onBack}
              onBlockScroll={onBlockScroll}
              onScroll={onScroll}
            />
          )}
        </div>
      ) : (
        // * formDataLazertinib
        <div className="flex-shrink-0 flex flex-col text-gray-500">
          {!summaryOpen ? (
            <>
              <InnerHeader
                i={i}
                headerData={missingInfo ? missingInfo : formDataLazertinib}
                leftIcon={prevIcon}
                rightIcon={closeIcon}
                onClickPrev={onClickPrev}
                missingInfo={missingInfo}
                onOpenCloseDialog={onOpenCloseDialog}
              />
              <InnerContent
                i={i}
                onClickNext={onClickNext}
                onNextMove={onNextMove}
                onClickPrev={onClickPrev}
                onClickBack={onClickBack}
                onChangeAnswer={onChangeAnswer}
                onSubmitForm={onSubmitForm}
                missingInfo={missingInfo}
              />
              {closeDiaglog && (
                <CommonDialog
                  title={
                    "정기 증상위험 입력을 그만하시겠습니까? 입력하던 내용은 저장됩니다."
                  }
                  onCheck={onCheck}
                  onCloseDialog={onCloseDialog}
                />
              )}
            </>
          ) : !resultOpen ? (
            <InputSummary
              reportId={reportId}
              onOpenResult={onOpenResult}
              onBlockScroll={onBlockScroll}
              onScroll={onScroll}
              missingInfo={missingInfo}
              i={i}
            />
          ) : (
            <Result
              reportId={reportId}
              onReset={onReset}
              onBack={onBack}
              onBlockScroll={onBlockScroll}
              onScroll={onScroll}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default RegularContent;
