import React, { useContext } from "react";
import { MainContext } from "../../../mobile/datas/Store";

const Navbar = ({
  alertIcon,
  arrowDownIcon,
  lockIcon,
  unlockIcon,
  onOpenAlert,
  onOpenAdminInfoAlert,
}: any) => {
  const { adminId, onLoggedAdmin } = useContext(MainContext);
  const onLogOut = () => {
    onLoggedAdmin(""); //store 보냄
    window.localStorage.removeItem("adminId");
  };

  return (
    <>
      <div className="flex justify-end items-center md:justify-end h-16">
        {/* nav button */}
        <nav className="flex items-center">
          {adminId !== "smc09" ? (
            // Lazertinib연구 간호사는 위험 알림 제공 x
            <div
              onClick={onOpenAlert}
              className="flex justify-center items-center gap-2 px-6 h-full cursor-pointer"
            >
              <button
                className="w-8 h-6 bg-cover pr-2"
                style={alertIcon}
              ></button>
              <span className="hidden text-gray-300 font-bold md:inline-block">
                알림
              </span>
            </div>
          ) : null}

          {adminId && (
            <div
              onClick={onOpenAdminInfoAlert}
              className="flex justify-center items-center gap-2 px-6 h-full border-l border-r border-gray-500 cursor-pointer"
            >
              <span className="font-bold text-yellow-point md:inline-block">
                {adminId}
              </span>
              <button
                className="w-4 h-4 bg-cover"
                style={arrowDownIcon}
              ></button>
            </div>
          )}

          <div
            onClick={onLogOut}
            className="flex justify-center items-center gap-2 px-6 h-full"
          >
            <button
              className="w-6 h-6 bg-cover"
              style={adminId ? lockIcon : unlockIcon}
            ></button>
            <span className="hidden md:inline-block text-gray-300 font-bold cursor-pointer">
              로그아웃
            </span>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
