import React from "react";
import { InnerFormView } from "../InnerFormView";

const TempInnerContent = ({ onChangeAnswer, tempInfo, i }: any) => {
  return (
    <>
      {tempInfo[i] && (
        <>
          <div className="text-center px-7 py-7 mb-4 bg-gray-100">
            <div className="flex justify-center items-center font-extrabold w-6 h-6 rounded-full bg-gray-300 text-white mx-auto">
              {i + 1}
            </div>
            <div
              className="text-gray-800 text-2xl font-extrabold my-4"
              style={{ wordBreak: "keep-all" }}
            >
              {tempInfo[i].question.substr(7)}
            </div>
            <div className="flex items-center text-sm bg-white rounded-3xl px-2 py-1.5 gap-3">
              <div className="flex-shrink-0 w-9 h-9 bg-gray-200 rounded-full flex justify-center items-center">
                <img src={tempInfo[i].bgIcon} alt={tempInfo[i].nameKo} />
              </div>

              <div className="text-left">
                <span className="font-bold">{tempInfo[i].title} </span>
                <span>: {tempInfo[i].meaning}</span>
              </div>
            </div>
          </div>

          <InnerFormView
            temp={"temp"}
            i={i}
            formViewData={tempInfo}
            onChangeAnswer={onChangeAnswer}
          />
        </>
      )}
    </>
  );
};

export default TempInnerContent;
