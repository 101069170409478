import React, { useState, useContext } from 'react';
import { MainContext } from "../../../mobile/datas/Store";
import { useHistory } from "react-router-dom";
import ContentHeader from '../Common/ContentHeader';
import AdminAccountDetails from './AdminAccountDetails';
import AdminInfoChange from './AdminInfoChange';
import AdminTableData from '../Charts/AdminTableData';
import Paging from '../Common/Paging';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminAccountList = ({ bringAdminList, adminData, totalCount,
    openAdminInfo, openAdminInfoChange, onOpenAdminDetails, onOpenAdminChange, backToAdminList }: any) => {
    const { page, postPerPage, onSetSort, onSeachTextSort, handlePageChange } = useContext(MainContext);
    const history = useHistory();
    const [onCheck1, setOnCheck1] = useState(false);
    const [onCheck2, setOnCheck2] = useState(false);
    const [onCheck3, setOnCheck3] = useState(false);
    const [onCheck4, setOnCheck4] = useState(false);
    const [searchText, setSearchText] = useState('');


    const onSorting = (sort: string, searchText: string, page: number, postPerPage: number) => {
        bringAdminList(sort, searchText, page, postPerPage);
    }

    const onCheckAdminId = () => {
        if (!onCheck1) {  //체크 안되어있을때
            setOnCheck1(true);
            onSorting('adminId', '', page, postPerPage);
            setOnCheck2(false);
            setOnCheck3(false);
            setOnCheck4(false);
            setSearchText('');
            onSetSort('adminId');
            onSeachTextSort('');
        } else {//체크 되어있을때
            setOnCheck1(false);
            onSorting('', '', page, postPerPage);
            onSeachTextSort('');
        }
    }

    const onCheckType = () => {
        if (!onCheck2) {  //체크 안되어있을때
            setOnCheck2(true);
            onSorting('type', '', page, postPerPage);
            setOnCheck1(false);
            setOnCheck3(false);
            setOnCheck4(false);
            setSearchText('');
            onSetSort('type');
            onSeachTextSort('');
        } else {//체크 되어있을때
            setOnCheck2(false);
            onSorting('', '', page, postPerPage);
            onSeachTextSort('');
        }
    }

    const onCheckCreatedAt = () => {
        if (!onCheck3) {  //체크 안되어있을때
            setOnCheck3(true);
            onSorting('createdAt', '', page, postPerPage)
            setOnCheck1(false);
            setOnCheck2(false);
            setOnCheck4(false);
            setSearchText('');
            onSetSort('createdAt');
            onSeachTextSort('');
        } else {//체크 되어있을때
            setOnCheck3(false);
            onSorting('', '', page, postPerPage);
            onSeachTextSort('');
        }
    }

    const onCheckOrganization = () => {
        if (!onCheck4) {  //체크 안되어있을때
            setOnCheck4(true);
            onSorting('organization', '', page, postPerPage)
            setOnCheck1(false);
            setOnCheck2(false);
            setOnCheck3(false);
            setSearchText('');
            onSetSort('organization');
            onSeachTextSort('');
        } else {//체크 되어있을때
            setOnCheck4(false);
            onSorting('', '', page, postPerPage);
            onSeachTextSort('');
        }
    }

    const onSearchText = (e: any) => {
        e.preventDefault();
        if (!searchText) {
            onSorting('', '', page, postPerPage);
        } else {
            onSorting('', searchText, page, postPerPage);
            setSearchText('');
            onSetSort('');
            setSearchText('');
            setOnCheck1(false);
            setOnCheck2(false);
            setOnCheck3(false);
            setOnCheck4(false);
            handlePageChange(1);
            onSeachTextSort(searchText);
        }
    }

    const onOpenAdminRegister = () => {
        history.push(`/adminregister`);
    }

    return (
        <>
            {(!openAdminInfo && !openAdminInfoChange) && <ContentHeader headerTitle={'권한 관리 - 관리자 목록'} btnRight={'신규 관리자 등록'} onClickBtn={onOpenAdminRegister} />}
            {(openAdminInfo && !openAdminInfoChange) && <ContentHeader headerTitle={'관리자 정보 내역'} btnRight={'신규 관리자 등록'} onClickBtn={onOpenAdminRegister} />}
            {(openAdminInfo && openAdminInfoChange) && <ContentHeader headerTitle={'관리자 정보 수정'} btnRight={'신규 관리자 등록'} onClickBtn={onOpenAdminRegister} />}
            <div className='grid grid-cols-1 mx-4'>
                {!openAdminInfo ? (
                    <>
                        <div className="flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-md border border-gray-50 bg-white text-center">
                            <div className="rounded-t mb-0 px-4 py-3 border-0">
                                <form onSubmit={onSearchText} className="flex justify-end flex-wrap items-center gap-3">
                                    <span className='text-sm'>이름 / 아이디</span>
                                    <input value={searchText} onChange={e => setSearchText(e.target.value)}
                                        className='pl-2 pr-2 border focus:outline-none rounded-md focus:ring' />
                                    <button type='submit' className='px-4 py-1 text-sm text-white rounded-md bg-gray-point font-bold focus:outline-none focus:ring focus:ring-primary focus:ring-offset-1'>검색</button>
                                </form>
                            </div>
                            <div className="block w-full overflow-x-auto">

                                <table className="items-center w-full bg-transparent border-collapse">
                                    <thead>
                                        <tr className='font-extrabold text-sm whitespace-nowrap align-middle'>
                                            <th onClick={onCheckAdminId} className="border border-solid px-6 py-3 border-l-0 border-r-0 cursor-pointer">
                                                <div className='flex gap-1 justify-center items-center'>
                                                    <span>아이디</span>
                                                    <div className={'transform transition duration-500 ease-in-out ' +
                                                        (!onCheck1 ? " " : "rotate-180")}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </th>
                                            <th className="border border-solid px-6 py-3 border-l-0 border-r-0 cursor-pointer">
                                                <div className='flex gap-1 justify-center items-center'>
                                                    <span>이름</span>
                                                </div>
                                            </th>
                                            <th onClick={onCheckType} className="border border-solid px-6 py-3 border-l-0 border-r-0 cursor-pointer">
                                                <div className='flex gap-1 justify-center items-center'>
                                                    <span>구분</span>
                                                    <div className={'transform transition duration-500 ease-in-out ' +
                                                        (!onCheck2 ? " " : "rotate-180")}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </th>
                                            <th onClick={onCheckCreatedAt} className="border border-solid px-6 py-3 border-l-0 border-r-0 cursor-pointer">
                                                <div className='flex gap-1 justify-center items-center'>
                                                    <span>등록일자</span>
                                                    <div className={'transform transition duration-500 ease-in-out ' +
                                                        (!onCheck3 ? " " : "rotate-180")}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </th>
                                            <th onClick={onCheckOrganization} className="border border-solid px-6 py-3 border-l-0 border-r-0 cursor-pointer">
                                                <div className='flex gap-1 justify-center items-center'>
                                                    <span>소속</span>
                                                    <div className={'transform transition duration-500 ease-in-out ' +
                                                        (!onCheck4 ? " " : "rotate-180")}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </th>
                                            <th className="border border-solid px-6 py-3 border-l-0 border-r-0">
                                                <div className='flex gap-1 justify-center items-center'>
                                                    <span>더보기</span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {adminData && adminData.map((data: any) => (<AdminTableData key={data.adminId} data={data} onOpenAdminDetails={onOpenAdminDetails} />))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Paging totalCount={totalCount} />
                    </>) : (<>
                        {!openAdminInfoChange ? <AdminAccountDetails onOpenAdminChange={onOpenAdminChange} backToList={backToAdminList} /> :
                            <AdminInfoChange backToList={backToAdminList} bringAdminList={bringAdminList} />}
                    </>)}
            </div>
            <ToastContainer />
        </>
    );
};

export default AdminAccountList;