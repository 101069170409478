import React, { useState, useContext } from 'react';
import { MainContext } from "../../../mobile/datas/Store";
import { useHistory } from "react-router-dom";

const SidebarNavBottomTitle = ({ navBottomTitle, backToPatientList, openPatientInfo, openPatientInfoChange, openAdminInfo, openAdminInfoChange, backToAdminList }: any) => {
    const { handlePageChange, onSetSort, onSeachTextSort, onCheckedDoctor } = useContext(MainContext);
    const { titleName, section1, section2, subMenu1, subMenu2, } = navBottomTitle;
    const history = useHistory();
    const [openSubMenu, setOpenSubMenu] = useState(false);

    const onOpenSubMenu = () => {
        history.push(`/` + section1);
        handlePageChange(1);
        onSetSort('');
        onSeachTextSort('');
        onCheckedDoctor('');
        if (openPatientInfo || openPatientInfoChange) {
            backToPatientList();
        }
        if (openAdminInfo || openAdminInfoChange) {
            backToAdminList();
        }
    }

    const onOpenSec1 = () => {
        history.push(`/` + section1);
        if (backToPatientList) backToPatientList();
        if (backToAdminList) backToAdminList();
    }

    const onOpenSec2 = () => {
        setOpenSubMenu(true);
        history.push(`/` + section2);
    }

    const pathname = window.location.pathname;

    return (
        <>
            <li onClick={onOpenSubMenu} className="flex items-center p-2 text-gray-500 transition-colors rounded-md cursor-pointer">
                <span className='font-bold ml-4 md:ml-8 text-sm md:text-lg '>{titleName}</span>
                <span className='ml-auto'>
                    <svg xmlns="http://www.w3.org/2000/svg" className={"w-4 h-4 transition-transform transform " + (openSubMenu ? 'rotate-180' : '')} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </span>
            </li>

            <ul className={'space-y-1 ' + ((pathname === `/` + section1 || pathname === `/` + section2) ? 'block' : 'hidden')}>
                <ul onClick={onOpenSec1} className={"flex justify-between border-l-8 cursor-pointer " +
                    (pathname === `/` + section1 ? 'border-yellow-point text-gray-700' : 'border-white text-gray-400')}>
                    <li className='text-sm ml-4 md:ml-8 my-2'>{subMenu1}</li>
                    <li className={pathname === `/` + section1 ? ' flex items-center' : ' hidden'}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white text-yellow-point mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                        </svg>
                    </li>
                </ul>

                <ul onClick={onOpenSec2} className={"flex justify-between border-l-8 cursor-pointer " +
                    (pathname === `/` + section2 ? 'border-yellow-point text-gray-700' : 'border-white text-gray-400')}>
                    <li className='text-sm ml-4 md:ml-8 my-2'>{subMenu2}</li>
                    <li className={pathname === `/` + section2 ? ' flex items-center' : ' hidden'}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white text-yellow-point mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                        </svg>
                    </li>
                </ul>
            </ul>
        </>
    );
};

export default SidebarNavBottomTitle;