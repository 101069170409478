import dayjs from "dayjs";

const AlertDangerData = ({ item }: any) => {
  let danger = [];
  danger = item.reportDanger;

  return (
    <div className="flex flex-col gap-4 divide-y">
      <div className="flex flex-col gap-2 py-2">
        {item.reportDanger.isGradeDanger ? (
          <>
            {item.patientId.indexOf("E") !== 0 ? (
              // * Lazertinib 사용자는 출력 x
              <>
                <div className="flex justify-between text-sm text-center p-2 bg-red-100 border-l-4 border-red-300">
                  <span>Grade 위험</span>
                  <div>{item.patientId}</div>
                </div>
                <div className="text-xs px-2">
                  {danger.gradeDangerList.map((one: any, idx: number) => (
                    <span key={idx} className="w-1/2 inline-block">
                      {one.name} ( {dayjs(one.date).format("YY.MM.DD")})
                    </span>
                  ))}
                </div>
              </>
            ) : null}
          </>
        ) : (
          <div></div>
        )}
        {item.reportDanger.isRecurrenceDanger ? (
          <>
            {item.patientId.indexOf("E") !== 0 ? (
              // * Lazertinib 사용자는 출력 x
              <>
                <div className="flex justify-between text-sm text-center p-2 bg-red-100 border-l-4 border-red-300">
                  <span>재발 위험</span>
                  <div>{item.patientId}</div>
                </div>
                <div className="text-xs px-2">
                  {danger.recurrenceDangerList.map((one: any, idx: number) => (
                    <span key={idx} className="w-1/2 inline-block">
                      {one.description}
                    </span>
                  ))}
                </div>
              </>
            ) : null}
          </>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default AlertDangerData;
