import React from 'react';
import { bgColor } from '../../../mobile/datas/stepColor';

const GradeCard = ({ title, gradeData, cycleDate }: any) => {

    return (
        <div className='flex flex-col gap-3 px-4 py-4 bg-white rounded-md'>
            <div className='flex justify-between items-center border-b pb-2'>
                <h1 className='font-extrabold'>{title}</h1>
                <span className='font-bold text-sm text-gray-400'>{cycleDate.startDate + ' ~ ' + cycleDate.endDate}</span>
            </div>
            {gradeData.length > 0 ? (gradeData.map(
                (item: any, idx: any) => (
                    <div key={item.name} className='flex justify-between pb-1'>
                        <div className="font-bold">
                            <span className='w-5 h-5 inline-flex justify-center items-center bg-gray-400 rounded-full text-white text-xs font-bold mr-2'>{idx + 1}</span>
                            <span>{item.name}</span>
                        </div>
                        <div className="flex gap-3 items-center font-bold">
                            <span className={'w-5 h-5 rounded-full flex-grow-0' + bgColor(item.num)}></span>
                            <div>Grade {item.num}</div>
                        </div>
                    </div>
                )
            )) : <p>입력된 리포트 데이터가 없습니다.</p>}
        </div >
    );
};

export default GradeCard;