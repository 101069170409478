import React, { useState, useContext } from "react";
import { MainContext } from "../../mobile/datas/Store";
import ProfTable from "./Charts/ProfTable";
import ContentHeader from "./Common/ContentHeader";
import Paging from "./Common/Paging";
import PrintAtOnce from "./Patient/PrintAtOnce";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MainContent = ({
  patientData,
  doctorList,
  bringPatientList,
  totalCount,
}: any) => {
  const {
    handlePageChange,
    onCheckedDoctor,
    openTab,
    onOpenTab,
    onSeachTextSort,
  } = useContext(MainContext);
  const printIcon = { backgroundImage: "url('/images/print-check.svg')" };
  const [openPrint, setOpenPrint] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState(new Set());

  const openDoctorPatientList = (idx: number, doctor: any) => {
    onCheckedDoctor(doctor);
    handlePageChange(1);
    onOpenTab(idx);
    onSeachTextSort("");
  };

  const onOpenPrint = () => {
    if (checkedItems.size > 0) {
      setOpenPrint(true); //인쇄용 페이지
      setTimeout(() => onPrint(), 1000);
    } else {
      toast.error("출력할 환자를 선택해주세요", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const onPrint = () => {
    //프린트 설정
    const html = document.querySelector<any>("html");
    const print = document.querySelectorAll<any>(".print-page");
    print.forEach((one: any) => {
      const printContents = one.innerHTML;
      const printDiv = document.createElement("DIV");
      printDiv.className = "print-div";
      html.appendChild(printDiv);
      printDiv.innerHTML = printContents;
      document.body.style.display = "none";
      window.print();
      setOpenPrint(false);
      document.body.style.display = "block";
      printDiv.style.display = "none";
      setCheckedItems(new Set());
    });
  };

  const checkHandler = ({ target }: any) => {
    setIsChecked(!isChecked);
    checkedItemHandler(target.parentNode, target.value, target.checked);
  };

  const checkedItemHandler = (box: any, id: any, isChecked: any) => {
    if (isChecked) {
      //환자 체크 되었을때
      checkedItems.add(id);
      setCheckedItems(checkedItems);
      box.style.backgroundColor = "#F6CB44";
    } else if (!isChecked && checkedItems.has(id)) {
      //체크가 안되었고, id가 있을때(클릭 2번시)
      checkedItems.delete(id);
      setCheckedItems(checkedItems);
      box.style.backgroundColor = "#fff";
    }
    return checkedItems;
  };

  const pathname = window.location.pathname;

  return (
    <>
      {!openPrint ? (
        <>
          {/* header */}
          {(pathname === "/adminmain" || pathname === "/") && (
            <ContentHeader
              onClickBtn={onOpenPrint}
              printIcon={printIcon}
              headerTitle={"환자 목록"}
              btnRight={"선택한 환자 모두 출력하기"}
            />
          )}
          {pathname === "/graph" && (
            <ContentHeader headerTitle={"환자별 통계 목록"} />
          )}

          {/* content */}
          <div className="grid grid-cols-1 px-4">
            <ul className="flex mb-0 list-none flex-wrap flex-row">
              {doctorList.map((doctor: any, idx: number) => (
                <li
                  key={idx}
                  onClick={() => openDoctorPatientList(idx, doctor.adminId)}
                  className="w-1/6 lg:w-1/12"
                >
                  <div
                    className={
                      "py-3 pl-2 border font-bold rounded-t-md border-gray-300 leading-normal cursor-pointer hover:text-gray-800 " +
                      (openTab === idx
                        ? "text-gray-800 bg-white"
                        : "text-gray-600 bg-gray-200")
                    }
                  >
                    {doctor.name}
                  </div>
                </li>
              ))}
            </ul>
            <div className="relative flex flex-col break-words border border-gray-50 text-center">
              <div>
                {/* 교수별 환자 리스트 */}
                <ProfTable
                  patientData={patientData}
                  bringPatientList={bringPatientList}
                  checkHandler={checkHandler}
                />
                <Paging totalCount={totalCount} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <PrintAtOnce checkedItems={checkedItems} />
      )}
    </>
  );
};

export default MainContent;
