import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { MainContext } from "../../datas/Store";
import axios from "axios";
import common from "../../datas/common";
import RegisterVisit from "./RegisterVisit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BottomMenuBar = ({
  onReset,
  onBlockScroll,
  onScroll,
  alertInput,
  onStopAlertInput,
}: any) => {
  const { userId } = useContext(MainContext);
  const homeIcon = { background: "url('/images/home.svg') no-repeat center" };
  const chartIcon = {
    background: "url('/images/clipboard.svg') no-repeat center",
  };
  const visitIcon = {
    background: "url('/images/visitday.svg') no-repeat center",
  };
  const pollIcon = { background: "url('/images/poll.svg') no-repeat center" };
  const userIcon = { background: "url('/images/user.svg') no-repeat center" };
  const homeActive = {
    background: "url('/images/home_active.svg') no-repeat center",
  };
  const chartActive = {
    background: "url('/images/clipboard_active.svg') no-repeat center",
  };
  const visitActive = {
    background: "url('/images/visitday_active.svg') no-repeat center",
  };
  const pollActive = {
    background: "url('/images/poll_active.svg') no-repeat center",
  };
  const userActive = {
    background: "url('/images/user_active.svg') no-repeat center",
  };
  const pathname = window.location.pathname;

  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (e: any) => {
    setStartDate(e);
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    setIsOpen(!isOpen);
    if (!isOpen && onBlockScroll) {
      onBlockScroll();
    } else if (isOpen && onBlockScroll) onScroll(); //스크롤 방지
  };

  const onSaveVisitDay = () => {
    let params = {
      patientId: userId,
      visitDay: startDate,
    };

    axios
      .post(common.SERVER_URL + "/patient/treatment", params, common.headers)
      .then((res) => {
        if (res.data.ok) {
          setIsOpen(!isOpen);
          setStartDate(new Date());
          toast.success(
            userId.indexOf("E") !== 0
              ? `투약일이 저장되었습니다`
              : `방문일이 저장되었습니다`,
            {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          onStopAlertInput();
        } else {
          // console.log(res.data.error); 이미 등록된 투약일입니다 back에 구현 되어있음
          toast.error(
            userId.indexOf("E") !== 0
              ? `이미 등록된 투약일입니다`
              : `이미 등록된 방문일입니다`,
            {
              autoClose: 3000,
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="fixed bottom-0 left-0 z-20 w-full flex-shrink-0 bg-white text-gray-600">
        <div className="grid grid-cols-5 gap-4 text-center text-xs mx-2 my-1 font-bold">
          <Link to="/intro">
            <div
              onClick={onReset}
              className="flex gap-0.5 flex-col justify-center items-center"
            >
              <div
                className="flex justify-center items-center rounded-full w-9 h-9 bg-cover"
                style={pathname === "/intro" ? homeActive : homeIcon}
              ></div>
              <div>홈</div>
            </div>
          </Link>
          <Link to="/analysis">
            <div className="flex gap-0.5 flex-col justify-center items-center">
              <div
                className="flex justify-center items-center rounded-full w-9 h-9 bg-cover"
                style={pathname === "/analysis" ? chartActive : chartIcon}
              ></div>
              <div>증상차트</div>
            </div>
          </Link>

          <div
            onClick={handleClick}
            className="relative flex gap-0.5 flex-col justify-center items-center"
          >
            <div
              className="flex justify-center items-center rounded-full w-9 h-9 bg-cover"
              style={isOpen ? visitActive : visitIcon}
            ></div>
            {userId.indexOf("E") !== 0 ? (
              <div>
                항암제
                <br />
                투약일
              </div>
            ) : (
              <div>
                병원
                <br />
                방문일
              </div>
            )}
            {alertInput ? (
              <span className="flex absolute h-3 w-3 top-1 right-1.5 -mt-1 -mr-1">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-point opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-yellow-point"></span>
              </span>
            ) : (
              <span></span>
            )}
          </div>

          <Link to="/stats">
            <div className="flex gap-0.5 flex-col justify-center items-center">
              <div
                className="flex justify-center items-center rounded-full w-9 h-9 bg-cover"
                style={pathname === "/stats" ? pollActive : pollIcon}
              ></div>
              <div>누적통계</div>
            </div>
          </Link>
          <Link to="/info">
            <div className="flex gap-0.5 flex-col justify-center items-center">
              <div
                className="flex justify-center items-center rounded-full w-9 h-9 bg-cover"
                style={
                  pathname === "/info" ||
                  pathname === "/setting" ||
                  pathname === "/visit"
                    ? userActive
                    : userIcon
                }
              ></div>
              <div>내정보</div>
            </div>
          </Link>
        </div>
      </div>

      <RegisterVisit
        isOpen={isOpen}
        startDate={startDate}
        handleChange={handleChange}
        handleClick={handleClick}
        onSaveVisitDay={onSaveVisitDay}
      />
      <ToastContainer />
    </>
  );
};

export default BottomMenuBar;
