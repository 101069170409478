const formDataLazertinib = [
  {
    id: 1,
    name: "constipation",
    nameKo: "변비",
    question: "지난 일주일 동안, 변비가 가장 심할 때는 어느 정도였습니까?",
    answer: {
      0: "전혀 없다",
      1: "약간 있다",
      2: "보통이다",
      3: "심하다",
      4: "매우 심하다",
    },
    list: [],
  },
  {
    id: 2,
    name: "diarrhea",
    nameKo: "설사",
    question:
      "지난 일주일 동안, 무른 변을 보거나 설사를 한 적이 얼마나 자주 있었습니까?",
    answer: {
      0: "전혀 없다",
      1: "드물게 있다",
      2: "가끔 있다",
      3: "자주 있다",
      4: "거의 항상 있다",
    },
    list: [],
  },

  {
    id: 3,
    nameKo: "피로함",
    name: "fatigueDgr",

    list: [
      {
        subid: 1,
        name: "fatigueDgr",
        nameKo: "피로함(심각도)",
        question:
          "Q1. 지난 일주일 동안, 피로, 피곤함, 또는 기운 없음이 가장 심할 때는 어느 정도였습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "보통이다",
          3: "심하다",
          4: "매우 심하다",
        },
      },
      {
        subid: 2,
        name: "fatigueAffct",
        nameKo: "피로함(일상지장)",
        question:
          "Q2. 지난 일주일 동안, 피로, 피곤함, 또는 기운 없음이 가장 심할 때는 일상생활에 얼마나 지장을 주었습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "다소 있다",
          3: "많다",
          4: "매우 많다",
        },
      },
    ],
  },

  {
    id: 4,
    nameKo: "불면증",
    name: "insomniaDgr",

    list: [
      {
        subid: 1,
        name: "insomniaDgr",
        nameKo: "불면증(심각도)",
        question:
          "Q1. 지난 일주일 동안, 불면증(잠들기 어려움, 자주 깸, 일찍 깸)이 심할 때는 어느 정도였습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "보통이다",
          3: "심하다",
          4: "매우 심하다",
        },
      },
      {
        subid: 2,
        name: "insomniaAffct",
        nameKo: "불면증(일상지장)",
        question:
          "Q2. 지난 일주일 동안, 불면증(잠들기 어려움, 자주 깸, 일찍 깸)이 일상생활에 얼마나 지장을 주었습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "다소 있다",
          3: "많다",
          4: "매우 많다",
        },
      },
    ],
  },

  {
    id: 5,
    nameKo: "두통",
    name: "headacheFrq",

    list: [
      {
        subid: 1,
        name: "headacheFrq",
        nameKo: "두통(빈도)",
        question: "Q1. 지난 일주일 동안, 두통이 얼마나 자주 있었습니까?",
        answer: {
          0: "전혀 없다",
          1: "드물게 있다",
          2: "가끔 있다",
          3: "자주 있다",
          4: "거의 항상 있다",
        },
      },
      {
        subid: 2,
        name: "headacheDgr",
        nameKo: "두통(심각도)",
        question:
          "Q2. 지난 일주일 동안, 두통이 가장 심할 때는 어느 정도였습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "보통이다",
          3: "심하다",
          4: "매우 심하다",
        },
      },
      {
        subid: 3,
        name: "headacheAffct",
        nameKo: "두통(일상지장)",
        question:
          "Q3. 지난 일주일 동안, 두통이 일상생활에 얼마나 지장을 주었습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "다소 있다",
          3: "많다",
          4: "매우 많다",
        },
      },
    ],
  },

  {
    id: 6,
    nameKo: "식욕 감소",
    name: "decreasedAppetiteDrg",

    list: [
      {
        subid: 1,
        name: "decreasedAppetiteDrg",
        nameKo: "식욕 감소(심각도)",
        question:
          "Q1. 지난 일주일 동안, 식욕 감소가 가장 심할 때는 어느 정도였습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "보통이다",
          3: "심하다",
          4: "매우 심하다",
        },
      },
      {
        subid: 2,
        name: "decreasedAppetiteAffct",
        nameKo: "식욕 감소(일상지장)",
        question:
          "Q2. 지난 일주일 동안, 식욕 감소가 일상생활에 얼마나 지장을 주었습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "다소 있다",
          3: "많다",
          4: "매우 많다",
        },
      },
    ],
  },

  {
    id: 7,
    nameKo: "매스꺼움",
    name: "nauseaFrq",
    list: [
      {
        subid: 1,
        name: "nauseaFrq",
        nameKo: "매스꺼움(빈도)",
        question: "Q1. 지난 일주일 동안, 매스꺼움을 얼마나 자주 느꼈습니까?",
        answer: {
          0: "전혀 없다",
          1: "드물게 있다",
          2: "가끔 있다",
          3: "자주 있다",
          4: "거의 항상 있다",
        },
      },
      {
        subid: 2,
        name: "nauseaDgr",
        nameKo: "매스꺼움(심각도)",
        question:
          "Q2. 지난 일주일 동안, 매스꺼움이 가장 심할 때는 어느 정도였습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "보통이다",
          3: "심하다",
          4: "매우 심하다",
        },
      },
    ],
  },

  {
    id: 8,
    nameKo: "구토",
    name: "vomitingFrq",

    list: [
      {
        subid: 1,
        name: "vomitingFrq",
        nameKo: "구토(빈도)",
        question: "Q1. 지난 일주일 동안, 구토를 얼마나 자주 느꼈습니까?",
        answer: {
          0: "전혀 없다",
          1: "드물게 있다",
          2: "가끔 있다",
          3: "자주 있다",
          4: "거의 항상 있다",
        },
      },
      {
        subid: 2,
        name: "vomitingDgr",
        nameKo: "구토(심각도)",
        question:
          "Q2. 지난 일주일 동안, 구토가 가장 심할 때는 어느 정도였습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "보통이다",
          3: "심하다",
          4: "매우 심하다",
        },
      },
    ],
  },

  {
    id: 9,
    nameKo: "구내염",
    name: "mouthSoresDgr",

    list: [
      {
        subid: 1,
        name: "mouthSoresDgr",
        nameKo: "구내염(심각도)",
        question:
          "Q1. 지난 일주일 동안, 입안이 헐어서 아프거나 목이 따가운 증상이 가장 심할 때는 어느 정도였습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "보통이다",
          3: "심하다",
          4: "매우 심하다",
        },
      },
      {
        subid: 2,
        name: "mouthSoresAffct",
        nameKo: "구내염(일상지장)",
        question:
          "Q2. 지난 일주일 동안, 입안이 헐어서 아프거나 목이 따가운 증상이 일상생활에 얼마나 지장을 주었습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "다소 있다",
          3: "많다",
          4: "매우 많다",
        },
      },
    ],
  },

  {
    id: 10,
    nameKo: "호흡곤란",
    name: "shortnessBreathDgr",

    list: [
      {
        subid: 1,
        name: "shortnessBreathDgr",
        nameKo: "호흡곤란(심각도)",
        question:
          "Q1. 지난 일주일 동안, 숨이 차는 증상이 가장 심할 때는 어느 정도였습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "보통이다",
          3: "심하다",
          4: "매우 심하다",
        },
      },
      {
        subid: 2,
        name: "shortnessBreathAffct",
        nameKo: "호흡곤란(일상지장)",
        question:
          "Q2. 지난 일주일 동안, 숨이 차는 증상이 일상생활에 얼마나 지장을 주었습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "다소 있다",
          3: "많다",
          4: "매우 많다",
        },
      },
    ],
  },

  {
    id: 11,
    nameKo: "기침",
    name: "coughDgr",

    list: [
      {
        subid: 1,
        name: "coughDgr",
        nameKo: "기침(심각도)",
        question:
          "Q1. 지난 일주일 동안, 기침이 가장 심할 때는 어느 정도였습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "보통이다",
          3: "심하다",
          4: "매우 심하다",
        },
      },
      {
        subid: 2,
        name: "coughAffct",
        nameKo: "기침(일상지장)",
        question:
          "Q2. 지난 일주일 동안, 기침 때문에 일상생활에 얼마나 지장을 받았습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "다소 있다",
          3: "많다",
          4: "매우 많다",
        },
      },
    ],
  },

  {
    id: 12,
    name: "acne",
    nameKo: "여드름",
    question:
      "지난 일주일 동안, 얼굴 혹은 가슴 부위에 여드름이나 뾰루지가 가장 심할 때는 어느 정도였습니까?",
    answer: {
      0: "전혀 없다",
      1: "약간 있다",
      2: "보통이다",
      3: "심하다",
      4: "매우 심하다",
    },
    list: [],
  },

  {
    id: 13,
    name: "itching",
    nameKo: "피부 가려움",
    question:
      "지난 일주일 동안, 피부 가려움이 가장 심할 때는 어느 정도였습니까?",
    answer: {
      0: "전혀 없다",
      1: "약간 있다",
      2: "보통이다",
      3: "심하다",
      4: "매우 심하다",
    },
    list: [],
  },

  {
    id: 14,
    name: "handfootSyndrome",
    nameKo: "수족 증후군",
    question:
      "지난 일주일 동안, 손이나 발에 발진이 생겨 갈라지거나 벗겨짐, 빨개지고 통증이 있음(수족증후군)이 가장 심할 때는 어느 정도였습니까?",
    answer: {
      0: "전혀 없다",
      1: "약간 있다",
      2: "보통이다",
      3: "심하다",
      4: "매우 심하다",
    },
    list: [],
  },

  {
    id: 15,
    nameKo: "팔다리 저림",
    name: "muscleCrampFrq",

    list: [
      {
        subid: 1,
        name: "muscleCrampFrq",
        nameKo: "팔다리 저림(빈도)",
        question:
          "Q1. 지난 일주일 동안, 팔다리에 쥐가 나는 증상이 얼마나 자주 있었습니까?",
        answer: {
          0: "전혀 없다",
          1: "드물게 있다",
          2: "가끔 있다",
          3: "자주 있다",
          4: "거의 항상 있다",
        },
      },
      {
        subid: 2,
        name: "muscleCrampDgr",
        nameKo: "팔다리 저림(심각도)",
        question:
          "Q2. 지난 일주일 동안, 팔다리에 쥐가나는 증상이 가장 심할 때는 어느 정도였습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "보통이다",
          3: "심하다",
          4: "매우 심하다",
        },
      },

      {
        subid: 3,
        name: "muscleCrampAffct",
        nameKo: "팔다리 저림(일상지장)",
        question:
          "Q3. 지난 일주일 동안, 팔다리에 쥐가 나는 증상이 일상생활에 얼마나 지장을 주었습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "다소 있다",
          3: "많다",
          4: "매우 많다",
        },
      },
    ],
  },

  {
    id: 16,
    nameKo: "어지러움",
    name: "dizzinessDgr",

    list: [
      {
        subid: 1,
        name: "dizzinessDgr",
        nameKo: "어지러움(심각도)",
        question:
          "Q1. 지난 일주일 동안, 어지러운 증상이 가장 심할 때는 어느 정도였습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "보통이다",
          3: "심하다",
          4: "매우 심하다",
        },
      },
      {
        subid: 2,
        name: "dizzinessAffct",
        nameKo: "어지러움(일상지장)",
        question:
          "Q2. 지난 일주일 동안, 어지러운 증상이 일상생활에 얼마나 지장을 주었습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "다소 있다",
          3: "많다",
          4: "매우 많다",
        },
      },
    ],
  },

  {
    id: 17,
    nameKo: "손발저림/감각저하",
    name: "numbnessDgr",

    list: [
      {
        subid: 1,
        name: "numbnessDgr",
        nameKo: "손발저림/감각저하(심각도)",
        question:
          "Q1. 지난 일주일 동안, 손발이 저리거나 감각이 둔해지는 증상이 가장 심할 때는 어느 정도였습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "보통이다",
          3: "심하다",
          4: "매우 심하다",
        },
      },
      {
        subid: 2,
        name: "numbnessAffct",
        nameKo: "손발저림/감각저하(일상지장)",
        question:
          "Q2. 지난 일주일 동안, 손발이 저리거나 감각이 둔해지는 증상이 일상생활에 얼마나 지장을 주었습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "다소 있다",
          3: "많다",
          4: "매우 많다",
        },
      },
    ],
  },

  {
    id: 18,
    nameKo: "근육통",
    name: "musclePainFrq",

    list: [
      {
        subid: 1,
        name: "musclePainFrq",
        nameKo: "근육통(빈도)",
        question: "Q1. 지난 일주일 동안, 근육통이 얼마나 자주 있었습니까?",
        answer: {
          0: "전혀 없다",
          1: "드물게 있다",
          2: "가끔 있다",
          3: "자주 있다",
          4: "거의 항상 있다",
        },
      },
      {
        subid: 2,
        name: "musclePainDgr",
        nameKo: "근육통(심각도)",
        question:
          "Q2. 지난 일주일 동안, 근육통이 가장 심할 때는 어느 정도였습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "보통이다",
          3: "심하다",
          4: "매우 심하다",
        },
      },
      {
        subid: 3,
        name: "musclePainAffct",
        nameKo: "근육통(일상지장)",
        question:
          "Q3. 지난 일주일 동안, 근육통이 일상생활에 얼마나 지장을 주었습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "다소 있다",
          3: "많다",
          4: "매우 많다",
        },
      },
    ],
  },

  {
    id: 19,
    nameKo: "통증",
    name: "generalPainFrq",

    list: [
      {
        subid: 1,
        name: "generalPainFrq",
        nameKo: "통증(빈도)",
        question: "Q1. 지난 일주일 동안, 통증이 얼마나 자주 있었습니까?",
        answer: {
          0: "전혀 없다",
          1: "드물게 있다",
          2: "가끔 있다",
          3: "자주 있다",
          4: "거의 항상 있다",
        },
      },
      {
        subid: 2,
        name: "generalPainDgr",
        nameKo: "통증(심각도)",
        question:
          "Q2. 지난 일주일 동안, 통증이 가장 심할 때는 어느 정도였습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "보통이다",
          3: "심하다",
          4: "매우 심하다",
        },
      },
      {
        subid: 3,
        name: "generalPainAffct",
        nameKo: "통증(일상지장)",
        question:
          "Q3. 지난 일주일 동안, 통증이 일상생활에 얼마나 지장을 주었습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "다소 있다",
          3: "많다",
          4: "매우 많다",
        },
      },
    ],
  },

  {
    id: 20,
    name: "tasteChange",
    nameKo: "맛을 느끼기 어려움",
    question:
      "지난 일주일 동안, 음식이나 음료의 맛을 느끼기 어려운 게 가장 심할 때는 어느 정도였습니까?",
    answer: {
      0: "전혀 없다",
      1: "약간 있다",
      2: "보통이다",
      3: "심하다",
      4: "매우 심하다",
    },
    list: [],
  },

  {
    id: 21,
    name: "nailLoss",
    nameKo: "손톱빠짐",
    question: "지난 일주일 동안, 손톱이나 발톱이 빠진 적이 있었습니까?",
    answer: {
      0: "아니오",
      1: "네",
    },
    list: [],
  },

  {
    id: 22,
    nameKo: "코피",
    name: "noseBleedDgr",

    list: [
      {
        subid: 1,
        name: "noseBleedDgr",
        nameKo: "코피(빈도)",
        question: "Q1. 지난 일주일 동안, 코피가 얼마나 자주 났습니까?",
        answer: {
          0: "전혀 없다",
          1: "드물게 있다",
          2: "가끔 있다",
          3: "자주 있다",
          4: "거의 항상 있다",
        },
      },
      {
        subid: 2,
        name: "noseBleedFrq",
        nameKo: "코피(심각도)",
        question:
          "Q2. 지난 일주일 동안, 코피가 가장 심할 때는 어느 정도였습니까?",
        answer: {
          0: "전혀 없다",
          1: "약간 있다",
          2: "보통이다",
          3: "심하다",
          4: "매우 심하다",
        },
      },
    ],
  },

  {
    id: 23,
    name: "rashLt",
    nameKo: "발진",
    question: "지난 일주일 동안, 피부 발진(붉은 두드러기나 염증)이 있었습니까?",
    answer: {
      0: "아니오",
      1: "네",
    },
    list: [],
  },

  {
    id: 24,
    name: "drySkinLt",
    nameKo: "피부 건조",
    question: "지난 일주일 동안, 피부 건조가 가장 심할 때는 어느 정도였습니까?",
    answer: {
      0: "전혀 없다",
      1: "약간 있다",
      2: "보통이다",
      3: "심하다",
      4: "매우 심하다",
    },
    list: [],
  },
];

export default formDataLazertinib;
// * 값에 _ 언더바 사용하면 자동으로 값 입력되는 버그있음
// * ex) drySkin_lt
